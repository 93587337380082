import React from 'react';
import Layout from '../../components/Layout';
import pdf2_1 from './assets/2.1 แผนพัฒนาการศึกษาระดับบัณฑิตศึกษามหาวิทยาลัยเชียงใหม่ ระยะที่ 13.pdf';
import pdf2_2 from './assets/2.2 แผนยุทธศาสตร์ 4 ปี บัณฑิตวิทยาลัย.pdf';
import pdf2_3 from './assets/2.3_โครงการสนับสนุนการพัฒนาคุณภาพการจัดการบัณฑิตศึกษา_ประจำปีงบประมาณ_2565.pdf';
import pdf2_4 from './assets/2.4_แผนปฏิบัติการเชิงยุทธศาสตร์_ประจำปี_2566.pdf';
import pdf3_1 from './assets/3.1 คำรับรองการปฏิบัติงาน ประจำปีงบประมาณ 2565.pdf';
import pdf3_2 from './assets/3.2 ผลลัพธ์สำคัญ.pdf';
import pdf4_1_66_edit_new from './assets/แผนยุทธศาสตร์ประจำปี 2566 (ฉบับปรับปรุง กรกฏาคม 2566).pdf';
import pdf4_1_66_edit from './assets/แผนยุทธศาสตร์ประจำปี 2566 (ฉบับปรับปรุง ธันวาคม 2565).pdf';
import pdf4_1_66 from './assets/แผนยุทธศาสตร์66.pdf';
import pdf4_1_65 from './assets/แผนยุทธศาสตร์ประจำปี 2565.pdf';
import pdf4_1_65_new from './assets/แผนยุทธศาสตร์ประจำปี 2565 (ฉบับปรับปรุง).pdf';
import pdf4_2_1 from './assets/แผนงานประจำ66.pdf';
import pdf4_2_2 from './assets/แผนงานประจำ2565.pdf';
import pdf5_1 from './assets/5.1 แผนบริหารความเสี่ยงและการควบคุมภายใน ประจำปีงบประมาณ 2565.pdf';
import pdf5_2 from './assets/แผนบริหารความเสี่ยงและการควบคุมภายใน ประจำปีงบประมาณ 2566.pdf';
import pdf6_1 from './assets/6.1 ปฏิทินการดำเนินงาน CMU ITA ประจำปีงบประมาณ 2565.pdf';
import pdf6_2 from './assets/ปฏิทินการดำเนินงาน CMU ITA ประจำปีงบประมาณ 2566.pdf';
import pdf7_1 from './assets/7.1 แผนพัฒนาบุคลากรปี 2565.pdf';
import pdf7_2 from './assets/แผนพัฒนาบุคลากรปี 2566.pdf';
import pdf8_1_1 from './assets/งบประมาณเงินแผ่นดินประจำปี 2565.pdf';
import pdf8_1_2 from './assets/งบประมาณเงินแผ่นดินประจำปี 2566.pdf';
import pdf8_2_1 from './assets/งบประมาณเงินรายได้ประจำปี 2565.pdf';
import pdf8_2_2 from './assets/งบประมาณเงินรายได้ประจำปี 2566.pdf';
import pdf9 from './assets/รายละเอียดผู้รับผิดชอบรายงานผลการดำเนินงานประจำปีงบประมาณ 2565.pdf';
import pdf9_1_2 from './assets/ปีงบประมาณ 2565.pdf';
import pdf9_1_4 from './assets/ผลการเบิกจ่ายงบประมาณ ประจำปี 2565.pdf';
import pdf9_1_5 from './assets/ผลการดำเนินงาน ประจำปีงบประมาณ 2564.pdf';
import pdf9_1_6 from './assets/ผลการดำเนินงาน ประจำปีงบประมาณ 2565.pdf';
import pdf10 from './assets/ปฏิทินกิจกรรมงานนโยบายและแผน ประจำปีงบประมาณ 2566.pdf';
import pdf11 from './assets/ปฏิทินกิจกรรมงานนโยบายและแผน ประจำปีงบประมาณ 2565.pdf';
import pdf12_1 from './assets/FM-PP-03 แบบฟอร์มจัดทำรายละเอียดแผนปฏิบัติการ.docx';
import pdf12_2 from './assets/FM-PP-04 แบบฟอร์มขออนุมัติโครงการ-ปรับปรุง.doc';
import pdf12_3 from './assets/FM-PP-06 แบบฟอร์มรายงานผลการดำเนินงาน.docx';
import pdf12_4 from './assets/FM-PP-07 แบบฟอร์มวิเคราะห์ประเด็นความเสี่ยง.docx';
import pdf12_5 from './assets/FM-PP-08 แบบฟอร์มขอตั้งงบประมาณ.xlsx';
import pdf12_6 from './assets/แบบฟอร์ม A3 Report.docx';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';
import Row from '../../components/CommonTable/Row';
import Cell from '../../components/CommonTable/Cell';
import DetailItem from '../../components/CommonTable/DetailItem';
import DetailItemLink from '../../components/CommonTable/DetailItemLink';
import TableContainer from '../../components/CommonTable/TableContainer';

const PolicyAndPlanPage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <PageTitleContainer>
        <CustomContainer> {t('Nav_PolicyPlan')}</CustomContainer>
      </PageTitleContainer>
      <CustomContainer>
        <div className=" mt-[56px] font-ibm-plex-sans text-UIBlack">
          <div className="text-center text-[32px] leading-[52px]">{t('PolicyPlanTopic')}</div>
          <TableContainer>
            <table class="mx-auto table-fixed">
              <thead>
                <tr>
                  <th className="pb-2 pl-10 text-left text-[20px] font-bold">รายการข้อมูล</th>
                  <th className="pb-2 pl-14 text-left text-[20px] font-bold">รายละเอียดของข้อมูล</th>
                </tr>
              </thead>
              <tbody className="border-t-2 border-Lightgray400">
                <Row>
                  <Cell>1. นโยบายยุทธศาสตร์ของมหาวิทยาลัย</Cell>
                  <Cell>
                  <DetailItem>
                    <DetailItemLink url="https://planning.oou.cmu.ac.th/?p=1970">
                      1.1 นโยบายและยุทธศาสตร์ของมหาวิทยาลัย
                    </DetailItemLink>
                    </DetailItem>
                    <DetailItem>
                    <DetailItemLink url="https://policy13.cmu.ac.th/policies">
                      1.2 92 นโยบายมหาวิทยาลัยเชียงใหม่
                    </DetailItemLink>
                    </DetailItem>
                  </Cell>
                </Row>
                <Row>
                  <Cell>2. แผนยุทธศาสตร์/แผนพัฒนาส่วนงาน</Cell>
                  <Cell>
                    <DetailItem>
                      <DetailItemLink url={pdf2_1}>
                        2.1 แผนพัฒนาการศึกษาระดับบัณฑิตศึกษามหาวิทยาลัยเชียงใหม่ ระยะที่ 13
                      </DetailItemLink>
                    </DetailItem>
                    <DetailItem>
                      <DetailItemLink url={pdf2_2}> 2.2 แผนยุทธศาสตร์ 4 ปี บัณฑิตวิทยาลัย</DetailItemLink>
                    </DetailItem>
                    <DetailItem>
                      <DetailItemLink url={pdf2_3}> 2.3 โครงการสนับสนุนการพัฒนาคุณภาพการจัดการบัณฑิตศึกษา (แผนงาน
ยุทธศาสตร์) ประจำปีงบประมาณ 2565</DetailItemLink>
                    </DetailItem>
                    <DetailItem>
                      <DetailItemLink url={pdf2_4}> 2.4 โครงการเชิงยุทธศาสตร์ประจำปี 2566</DetailItemLink>
                    </DetailItem>
                  </Cell>
                </Row>
                <Row>
                  <Cell>3. คำรับรองการปฏิบัติงาน/ผลลัพธ์สำคัญ</Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={pdf3_1}> 3.1 คำรับรองการปฏิบัติงาน ประจำปีงบประมาณ 2565</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={pdf3_2}> 3.2 ผลลัพธ์สำคัญ</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>4. แผนปฏิบัติการประจำปีงบประมาณ</Cell>
                  <Cell>
                    <div>4.1 แผนงานยุทธศาสตร์</div>
                    <div>
                      <DetailItemLink url={pdf4_1_66_edit}>- แผนยุทธศาสตร์ประจำปี 2566 (ฉบับปรับปรุงธันวาคม 2565)</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={pdf4_1_66}>- แผนยุทธศาสตร์ประจำปี 2566</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={pdf4_1_65}>- แผนยุทธศาสตร์ประจำปี 2565</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={pdf4_1_65_new}>- แผนยุทธศาสตร์ประจำปี 2565 (ฉบับปรับปรุง)</DetailItemLink>
                    </div>
                    <div>4.2 แผนงานประจำ</div>
                    <div>
                      <DetailItemLink url={pdf4_1_66_edit_new}>- แผนงานประจำปี 2566 (ฉบับปรับปรุง กรกฏาคม 2566)</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={pdf4_1_66_edit}>- แผนงานประจำปี 2566 (ฉบับปรับปรุง ธันวาคม 2565)</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={pdf4_2_1}>- แผนงานประจำปีงบประมาณ 2566</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={pdf4_2_2}>- แผนงานประจำปีงบประมาณ 2565</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>5. แผนบริหารจัดการความเสี่ยงและควบคุมภายใน </Cell>
                  <Cell>
                  <div>
                    <DetailItemLink url={pdf5_2}>
                      - แผนบริหารความเสี่ยงและควบคุมภายใน ประจำปีงบประมาณ 2566
                    </DetailItemLink>
                    </div><div>
                    <DetailItemLink url={pdf5_1}>
                      - แผนบริหารความเสี่ยงและการควบคุมภายใน ประจำปีงบประมาณ 2565
                    </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell> 6. CMU-ITA </Cell>
                  <Cell>
                  <div><DetailItemLink url={pdf6_2}>- ปฏิทินการดำเนินงาน CMU ITA ประจำปีงบประมาณ 2566</DetailItemLink></div>
                  <div><DetailItemLink url={pdf6_1}>- ปฏิทินการดำเนินงาน CMU ITA ประจำปีงบประมาณ 2565</DetailItemLink></div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>7. แผนพัฒนาบุคลากร</Cell>
                  <Cell>
                    <div><DetailItemLink url={pdf7_2}>- แผนพัฒนาบุคลากรปี 2566</DetailItemLink></div>
                    <div><DetailItemLink url={pdf7_1}>- แผนพัฒนาบุคลากรปี 2565</DetailItemLink></div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>8. แผนงบประมาณ</Cell>
                  <Cell>
                    <div><DetailItemLink> 8.1 งบประมาณรายจ่ายเงินแผ่นดิน</DetailItemLink></div>
                    <div><DetailItemLink url={pdf8_1_2}> - งบประมาณเงินแผ่นดินประจำปี พ.ศ. 2566</DetailItemLink></div>
                    <div><DetailItemLink url={pdf8_1_1}> - งบประมาณเงินแผ่นดินประจำปี พ.ศ. 2565</DetailItemLink></div>
                    <div><DetailItemLink>8.2 งบประมาณรายจ่ายเงินรายได้</DetailItemLink></div>
                    <div><DetailItemLink url={pdf8_2_2}> - งบประมาณเงินรายได้ประจำปี พ.ศ. 2566</DetailItemLink></div>
                    <div><DetailItemLink url={pdf8_2_1}> - งบประมาณเงินรายได้ประจำปี พ.ศ. 2565</DetailItemLink></div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>9. รายงานการกำกับติดตาม การดำเนินงาน</Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={pdf9}>
                        รายละเอียดผู้รับผิดชอบรายงานผลการดำเนินงานประจำปีงบประมาณ 2565
                      </DetailItemLink>
                    </div>
                    <div>9.1 การดำเนินงานด้าน OKRs, KPIs</div>
                    <div><DetailItemLink>- ปีงบประมาณ 2566</DetailItemLink></div>
                    <div><DetailItemLink url={pdf9_1_2}>- ปีงบประมาณ 2565</DetailItemLink></div>
                    <div>9.2 การดำเนินงานด้านงบประมาณ</div>
                    <div><DetailItemLink>- ปีงบประมาณ 2566</DetailItemLink></div>
                    <div><DetailItemLink url={pdf9_1_4}>- ปีงบประมาณ 2565</DetailItemLink></div>
                    <div>9.3 การดำเนินงานประจำปี</div>
                    <div><DetailItemLink>- ปีงบประมาณ 2566</DetailItemLink></div>
                    <div><DetailItemLink url={pdf9_1_6}>- ปีงบประมาณ 2565</DetailItemLink></div>
                    <div><DetailItemLink url={pdf9_1_5}>- ปีงบประมาณ 2564</DetailItemLink></div>

                  </Cell>
                </Row>
                <Row>
                  <Cell>10. ปฏิทินการดำเนินงาน</Cell>
                  <Cell>
                  <div><DetailItemLink url={pdf10}>ปฏิทินกิจกรรมงานนโยบายและแผน ประจำปีงบประมาณ 2566</DetailItemLink></div>
                  <div><DetailItemLink url={pdf11}>ปฏิทินกิจกรรมงานนโยบายและแผน ประจำปีงบประมาณ 2565</DetailItemLink></div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>11. แบบฟอร์มการดำเนินงาน</Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={pdf12_1}>FM-PP-03 แบบฟอร์มจัดทำรายละเอียดแผนปฏิบัติการ</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={pdf12_2}>FM-PP-04 แบบฟอร์มขออนุมัติโครงการ (ปรับปรุง)</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={pdf12_3}>FM-PP-06 แบบฟอร์มรายงานผลการดำเนินงาน</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={pdf12_4}>FM-PP-07 แบบฟอร์มวิเคราะห์ประเด็นความเสี่ยง</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={pdf12_5}>FM-PP-08 แบบฟอร์มคำขอตั้งงบประมาณ</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={pdf12_6}>แบบฟอร์ม A3 Report</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
              </tbody>
            </table>
          </TableContainer>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default PolicyAndPlanPage;
