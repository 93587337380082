export const MOCK_TARA_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_YEAR = [
  {
    year: '2563',
    student_amount: 23,
    student_percentage: '9.27%',
  },
  {
    year: '2564',
    student_amount: 102,
    student_percentage: '41.13%',
  },
  {
    year: '2565',
    student_amount: 123,
    student_percentage: '49.60%',
  },
];
