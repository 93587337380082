import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
// import routeUrlProvider, { HOME } from '../../constants/route-paths';
import { convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { fetchPost } from '../../../utils/services/fetch';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../../../node_modules/react-datepicker/dist/react-datepicker.min.css';
import ReactDatePicker from 'react-datepicker';
import AttatchFilePinkIcon from '../../../components/Icons/AttatchFilePinkIcon';
import routeUrlProvider, { AdminRoutePath } from '../../../constants/route-paths';
import apiUrlProvider, { ADMIN_ADD_BANNER, ADMIN_ADD_IMAGE } from '../../../constants/api-endpoints';
import { getAccessToken } from '../../../utils/token';

const BannerAdd = ({ history }) => {
  const [bannerType, setBannerType] = useState('');
  const [imageTh, setImageTh] = useState(null);
  const [imageEn, setImageEn] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    let startDate = new Date();
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);

    let endDate = new Date();
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    endDate.setMilliseconds(999);
    endDate.setDate(endDate.getDate() + 30);

    setStartDate(startDate);
    setEndDate(endDate);
  }, []);

  const onBannerTypeChange = (value) => {
    setBannerType(value);
  };

  const bannerTypes = [
    { name: 'Graduate School Banner', value: 'graduate-school-banner' },
    { name: 'Admission Banner', value: 'admission-banner' },
  ];

  const onUploadImageThChange = (e) => {
    const file = e.target.files[0];

    const data = new FormData();

    data.append(`file`, file, file.name);

    fetch(
      apiUrlProvider.get(ADMIN_ADD_IMAGE), 
      {
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${getAccessToken() || ''}`,
        }
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setImageTh(data);
      })
      .catch((err) => alert(err.error));
  };

  const onUploadImageEnChange = (e) => {
    const file = e.target.files[0];

    const data = new FormData();

    data.append(`file`, file, file.name);

    fetch(
      apiUrlProvider.get(ADMIN_ADD_IMAGE), 
      {
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${getAccessToken() || ''}`,
        }
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setImageEn(data);
      })
      .catch((err) => alert(err.error));
  };

  const onCreateBannerSubmit = (e) => {
    e.preventDefault()
    fetchPost(apiUrlProvider.get(ADMIN_ADD_BANNER), {
      startAt: startDate,
      endAt: endDate,
      type: bannerType,
      imageTh: imageTh,
      imageEn: imageEn,
    }, true)
    .then( res => {
      alert("เพิ่ม Banner สำเร็จ")
      history.push(routeUrlProvider.getForLink(AdminRoutePath.ADMIN_BANNER_LIST))
    })
    .catch(e => {
      alert(e.error)
    })
  }

  const renderUploadImageTh = () => {
    if (imageTh != null) {
      return (
        <div className="mx-2 box-content grid h-64 w-64 flex-initial content-center rounded-[10px] border-2 border-NeonPink hover:cursor-pointer">
          <img src={imageTh.imageUrl} alt={imageTh.imageName} width={332} height={187} className="rounded-[2px]" />
        </div>
      );
    }
    return (
      <div className="mx-2 box-content grid h-64 w-64 flex-initial content-center rounded-[10px] border-2 border-NeonPink hover:cursor-pointer">
        <label className="h-max hover:cursor-pointer">
          <div className="rounded-full text-center text-4xl font-black text-NeonPink">+</div>
          <input 
            className='hidden' 
            type="file" 
            accept="image/png, image/jpeg" 
            onChange={onUploadImageThChange} 
          />
        </label>
      </div>
    );
  };

  const renderUploadImageEn = () => {
    if (imageEn != null) {
      return (
        <div className="mx-2 box-content grid h-64 w-64 flex-initial content-center rounded-[10px] border-2 border-NeonPink hover:cursor-pointer">
          <img src={imageEn.imageUrl} alt={imageEn.imageName} width={332} height={187} className="rounded-[2px]" />
        </div>
      );
    }
    return (
      <div className="mx-2 box-content grid h-64 w-64 flex-initial content-center rounded-[10px] border-2 border-NeonPink hover:cursor-pointer">
        <label className="h-max hover:cursor-pointer">
          <div className="rounded-full text-center text-4xl font-black text-NeonPink">+</div>
          <input 
            className='hidden' 
            type="file" 
            onChange={onUploadImageEnChange} 
            accept="image/png, image/jpeg"
          />
        </label>
      </div>
    );
  };

  return (
    <Layout>
      <div className="px-20">
        <div className="text-center text-lg">เพิ่ม Banner</div>
        <form>
          <div className="mb-4">
            <div>ประเภทของ Banner</div>
            {bannerTypes.map((el, index) => (
              <div key={index}>
                <label className="flex">
                  <input
                    type="radio"
                    value={el.value}
                    checked={bannerType === el.value}
                    onChange={(e) => onBannerTypeChange(e.target.value)}
                  />
                  <div className="ml-1">{el.name}</div>
                </label>
              </div>
            ))}
          </div>

          <div className="mb-4">
            <div>วันที่เริ่มแสดง</div>
            <ReactDatePicker
              className="bg-gray-50 border-gray-300 focus:ring-blue-500 focus:border-blue-500 w-25 block rounded-lg border p-2.5 text-sm"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>

          <div className="mb-4">
            <div>วันที่สิ้นสุด</div>
            <ReactDatePicker
              className="bg-gray-50 border-gray-300 focus:ring-blue-500 focus:border-blue-500 w-25 block rounded-lg border p-2.5 text-sm"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </div>

          <div className="mb-4">
            <div>รูปภาพ (ภาษาไทย)</div>
            <div className="flex">{renderUploadImageTh()}</div>
          </div>

          <div className="mb-4">
            <div>รูปภาพ (ภาษาอังกฤษ)</div>
            <div className="flex">{renderUploadImageEn()}</div>
          </div>

          <div className="flex justify-center space-x-2" onClick={onCreateBannerSubmit}>
            <button
              type="button"
              className="bg-blue-600 text-white inline-block w-52 rounded bg-NeonPink px-6 py-2.5 text-xs font-medium leading-tight text-UIWhite shadow-md"
            >
              บันทึก
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default BannerAdd;
