import React from 'react';

const PodcastEpContainer = ({ children, bgLightgray100 = false }) => (
  <div
    className={`flex w-full justify-between px-3 md:px-5 lg:px-20 ${
      bgLightgray100 ? 'bg-Lightgray100' : 'bg-Lightgray50'
    }`}
  >
    {children}
  </div>
);

export default PodcastEpContainer;
