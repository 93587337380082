import React from 'react';

const NewsTab = ({ selected = false, children, onClick = () => {} }) => {
  if (selected) {
    return (
      <div className="mx-auto w-fit">
        <div className="h-[68px] whitespace-nowrap rounded-[100px] bg-GradPink bg-opacity-25 px-5 text-center text-xl font-medium  leading-[68px] text-NeonPink md:px-8 md:text-[40px]">
          {children}
        </div>
        <div className="flex w-full flex-row justify-center">
          <div className="mx-auto mt-2 h-2 w-20 bg-NeonPink" />
        </div>
      </div>
    );
  }
  return (
    <div
      onClick={onClick}
      className="mx-auto h-[30px] w-full text-center font-semibold leading-[30px] text-CMUPurple hover:cursor-pointer md:text-2xl"
    >
      {children}
    </div>
  );
};

export default NewsTab;
