import React from 'react';
import BackButton from '../../../components/Button/BackButton';
import { SEARCH_BY_FACULTY } from '../../../constants/route-paths';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';

const BackToSearchByFacultyButton = () => {
  const { t } = useTranslationUtil();
  return (
    <div className="mt-10">
      <BackButton goToRoute={SEARCH_BY_FACULTY}>{t('BackToSearchByFaculty')}</BackButton>
    </div>
  );
};

export default BackToSearchByFacultyButton;
