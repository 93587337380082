import { Route, Redirect } from 'react-router-dom';
import { useContextAuthManager } from '../index';

const PublicRoute = ({ exact, path, ...props }) => {
  const { isLoggedIn, privatePath } = useContextAuthManager();

  if (!privatePath) {
    throw new Error('You need to provide "privatePath" prop for AuthManger');
  }
  return (
    <Route
      exact={exact}
      path={path}
      render={() => {
        // if (isLoggedIn) {
        //   return <Redirect to={privatePath} />;
        // }
        return <Route path={path} exact={exact} {...props} />;
      }}
    />
  );
};

export default PublicRoute;
