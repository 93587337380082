import { fetchDelete, fetchGet } from '../../utils/services/fetch';

import apiUrlProvider, { ADMIN_GET_NEWS_GET_BY_ID, ADMIN_GET_NEWS_LIST, ADMIN_DELETE_NEWS } from '../../constants/api-endpoints';


export const getNewsByIdAdmin = (id) => {
  const url = apiUrlProvider.get(ADMIN_GET_NEWS_GET_BY_ID, { id });
  return fetchGet(url, null, true);
};

export const getListNewsAdmin = ({ newsTypeQuery, page = 1, perPage = 10 }) => {
  const url = apiUrlProvider.get(ADMIN_GET_NEWS_LIST);
  const urlWithQuery = `${url}?page=${page}&perPage=${perPage}${newsTypeQuery ? `&type=${newsTypeQuery}` : ''}`;
  return fetchGet(urlWithQuery, null, true);
};

export const deleteNewsAdmin = (id) => {
  const url = apiUrlProvider.get(ADMIN_DELETE_NEWS, {id});
  return fetchDelete(url, null, true);
};