import React, { useRef, useState } from 'react';
import PinkFulfilWithRightArrowButton from '../../components/Button/PinkFulfilWithRightArrowButton';
import CollapseSection from '../../components/CollapseSection';
import CollapseItem from '../../components/CollapseSection/CollapseItem';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';

import prnews01 from './assets/คำจำกัดความ/pr-news01.pdf';
import เกณฑ์63 from './assets/คำจำกัดความ/เกณฑ์-63.pdf';
import เกณฑ์ฉบับที่2พศ2561 from './assets/คำจำกัดความ/เกณฑ์-ฉบับที่-2-พ.ศ.-2561.pdf';

import FMASL06 from './assets/ผู้ทรงคุณวุฒิภายนอก/FM-AS-L-06.doc';
import FMASL07 from './assets/ผู้ทรงคุณวุฒิภายนอก/FM-AS-L-07.doc';
import FMASL08 from './assets/ผู้ทรงคุณวุฒิภายนอก/FM-AS-L-08.docx';
import ระบบแต่งต้งผู้ทรงคุณวุมิภายนอก from './assets/ผู้ทรงคุณวุฒิภายนอก/ระบบแต่งต้ง-ผู้ทรงคุณวุมิภายนอก.pdf';
import แบบเสนอรายชื่อผู้ทรงคุณวุฒิภายนอก from './assets/ผู้ทรงคุณวุฒิภายนอก/3_แบบเสนอรายชื่อผู้ทรงคุณวุฒิภายนอก.doc';

import AddWorkLoad from './assets/ภาระงานอาจารย์/AddWorkLoad.doc';
import AddWorkLoadInterdis from './assets/ภาระงานอาจารย์/AddWorkLoadInterdis.doc';
import ThesisADV2560 from './assets/ภาระงานอาจารย์/ThesisADV2560.pdf';

import แบบเสนอรายชื่ออาจารย์ประจำ2566 from './assets/อาจารย์ประจำในบัณฑิตวิทยาลัย/แบบเสนอรายชื่ออาจารย์ประจำ2566.doc';
import FMASL01 from './assets/อาจารย์ประจำในบัณฑิตวิทยาลัย/FM-AS-L-01.doc';

import แบบเสนอรายชื่อนักวิจัยประจำ from './assets/นักวิจัยประจำ/แบบเสนอรายชื่อนักวิจัยประจำ.doc';

import FMASL04 from './assets/อาจารย์ผู้เชี่ยวชาญเฉพาะ/FM-AS-L-04.doc';
import FMASL05 from './assets/อาจารย์ผู้เชี่ยวชาญเฉพาะ/FM-AS-L-05.docx';

import แนวปฏิบัติเกี่ยวกับอาจารย์พิเศษ from './assets/อาจารย์พิเศษ/1-แนวปฏิบัติเกี่ยวกับอาจารย์พิเศษ.pdf';
import FMASL02 from './assets/อาจารย์พิเศษ/FM-AS-L-02.doc';
import FMASL03 from './assets/อาจารย์พิเศษ/FM-AS-L-03.doc';
import แบบเสนอรายอาจารย์พิเศษ from './assets/อาจารย์พิเศษ/แบบเสนอรายอาจารย์พิเศษ.doc';

import PeopleIcon from './assets/icons/people.svg';
import PeopleSearchIcon from './assets/icons/people-search-icon.svg';
import PeoplDoorIcon from './assets/icons/people-door-icon.svg';
import PeoplBoardIcon from './assets/icons/people-board-icon.svg';
import BookIcon from './assets/icons/book-icon.svg';
import OpenBookIcon from './assets/icons/open-book.svg';
import TodoWithClockIcon from './assets/icons/todo-with-clock.svg';
import TodoIcon from './assets/icons/todo.svg';
import PenIcon from './assets/icons/pen-icon.svg';
import GraphIcon from './assets/icons/graph.svg';
import CustomContainer from '../../components/CustomContainer';
import CollapseSectionContainer from '../../components/CollapseSection/CollapseSectionContainer';

const InfoCard = ({ img, title, externalUrl }) => (
  <div
    className="m-auto flex h-[204px] w-full  max-w-[252px] flex-col justify-center rounded-sm bg-UIWhite px-6 shadow-md hover:cursor-pointer hover:bg-Lightgray50"
    onClick={() => window.open(externalUrl, '_blank', 'noopener,noreferrer')}
  >
    <div className="m-auto w-full ">
      <img src={img} alt="" className="rounded-sm bg-UIWhite" />
      <div className="mt-3 font-ibm-plex-sans">{title}</div>
    </div>
  </div>
);

const LecturerPage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <div className="bg-foreign-lang-title pt-[32px] pb-[48px] text-center font-ibm-plex-sans text-[56px] font-medium leading-[71px] text-UIWhite">
          {t('อาจารย์ (การแต่งตั้ง/ภาระงาน)')}
        </div>

        <div className="bg-Lightgray50">
          <div className="pt-8 text-center font-ibm-plex-sans text-5xl font-medium leading-[100px] ">
            สารสนเทศเกี่ยวกับอาจารย์
          </div>
          <div className="grid gap-4 py-7 sm:grid-cols-2 md:grid-cols-3 md:px-[112px] lg:grid-cols-4">
            <InfoCard
              img={PeopleSearchIcon}
              title="การค้นหาข้อมูลอาจารย์"
              externalUrl="https://smart.grad.cmu.ac.th/?p=staffSearch"
            />
            <InfoCard
              img={PeoplDoorIcon}
              title="ผู้ทรงคุณวุฒิภายนอก"
              externalUrl="http://mis.grad.cmu.ac.th/graduate_report/reports/person/subreport/Search_Primarydata_from_name.aspx?fname=&lname=&status=0&type=5"
            />
            <InfoCard
              img={PeoplBoardIcon}
              title="อาจารย์ผู้เชี่ยวชาญเฉพาะ"
              externalUrl="http://mis.grad.cmu.ac.th/graduate_report/reports/person/subreport/Search_Primarydata_from_name.aspx?fname=&lname=&status=0&type=4"
            />
            <InfoCard
              img={BookIcon}
              title="รายงานการแต่งตั้งอาจารย์"
              externalUrl="http://mis.grad.cmu.ac.th/graduate_report/reports/person/mainreport/Personal_Appiont.aspx?catalogue=2&sm=mniPersonAppointment"
            />
            <InfoCard
              img={TodoWithClockIcon}
              title="เกษียณอายุและครบสัญญา"
              externalUrl="http://mis.grad.cmu.ac.th/graduate_report/reports/person/mainreport/Personal_Retire_Contact.aspx?catalogue=2&sm=mniPersonalRetryContact"
            />
            <InfoCard
              img={PenIcon}
              title="ข้อมูลอาจารย์ตามเงื่อนไข"
              externalUrl="http://mis.grad.cmu.ac.th/graduate_report/reports/person/mainreport/Personal_Data_Table.aspx?catalogue=2&sm=mniPersonalDataTable"
            />
            <InfoCard
              img={TodoIcon}
              title="รายงานสรุปจำนวนอาจารย์"
              externalUrl="http://mis.grad.cmu.ac.th/graduate_report/reports/person/mainreport/Peraonal_Name_Detail.aspx?catalogue=2&sm=mniPersonalNameNumber"
            />
            <InfoCard
              img={OpenBookIcon}
              title="ภาระงานอาจารย์"
              externalUrl="http://dev.grad.cmu.ac.th/workload/"
            />
            <InfoCard
              img={PeopleIcon}
              title="รายงานอาจารย์ที่ปรึกษา"
              externalUrl="http://mis.grad.cmu.ac.th/graduate_report/reports/person/mainreport/Personal_Advisor.aspx?catalogue=2&sm=mniPersonalAdvisor"
            />
            <InfoCard
              img={GraphIcon}
              title="กราฟสถิติแสดงข้อมูลอาจารย์"
              externalUrl="http://mis.grad.cmu.ac.th/graduate_report/reports/person/mainreport/Person_Graph.aspx?catalogue=2&sm=mniPersonalGraph"
            />
          </div>
        </div>

        <CollapseSectionContainer>
          <CollapseSection title={t('อาจารย์ประจำในบัณฑิตวิทยาลัย')} forceUseThFont>
            <CollapseItem pdfUrl={แบบเสนอรายชื่ออาจารย์ประจำ2566}>
              {t(
                'แบบเสนอรายชื่ออาจารย์ประจำในบัณฑิตวิทยาลัย ตามเกณฑ์มาตรฐานการศึกษาระดับบัณฑิตศึกษา พ.ศ.2566'
              )}
            </CollapseItem>
            <CollapseItem pdfUrl={FMASL01}>
              {t(
                'แบบเสนอรายชื่ออาจารย์ประจำในบัณฑิตวิทยาลัย ตามข้อบังคับมหาวิทยาลัยเชียงใหม่ว่าด้วยการศึกษาระดับบัณฑิตศึกษา พ.ศ. 2559'
              )}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('อาจารย์พิเศษ')} forceUseThFont>
            <CollapseItem pdfUrl={แบบเสนอรายอาจารย์พิเศษ} forceUseThFont>
              {t('แบบเสนอรายชื่อแต่งตั้งอาจารย์พิเศษ ตามเกณฑ์มาตรฐานการศึกษาระดับบัณฑิตศึกษา พ.ศ.2566')}
            </CollapseItem>
            <CollapseItem pdfUrl={FMASL02} forceUseThFont>
              {t('แบบเสนอรายชื่อแต่งตั้งอาจารย์พิเศษ ตามข้อบังคับมหาวิทยาลัยเชียงใหม่ว่าด้วยการศึกษาระดับบัณฑิตศึกษา พ.ศ.2559')}
            </CollapseItem>
            <CollapseItem pdfUrl={FMASL03} forceUseThFont>
              {t('ประวัติอาจารย์พิเศษ')}
            </CollapseItem>
            <CollapseItem pdfUrl={แนวปฏิบัติเกี่ยวกับอาจารย์พิเศษ} forceUseThFont>
              {t('แนวปฏิบัติเกี่ยวกับการแต่งตั้งอาจารย์พิเศษ')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('ผู้ทรงคุณวุฒิภายนอก')} forceUseThFont>
          <CollapseItem pdfUrl={แบบเสนอรายชื่อผู้ทรงคุณวุฒิภายนอก} forceUseThFont>
              {t('แบบเสนอแต่งตั้งผู้ทรงคุณวุฒิภายนอก ตามเกณฑ์มาตรฐานการศึกษาระดับบัณฑิตศึกษา พ.ศ.2566')}
            </CollapseItem>
            <CollapseItem pdfUrl={FMASL06} forceUseThFont>
              {t('แบบเสนอแต่งตั้งผู้ทรงคุณวุฒิภายนอก ตามข้อบังคับมหาวิทยาลัยเชียงใหม่ว่าด้วยการศึกษาระดับบัณฑิตศึกษา พ.ศ.2559')}
            </CollapseItem>
            <CollapseItem pdfUrl={FMASL07} forceUseThFont>
              {t('ข้อมูลประกอบการแต่งตั้งผู้ทรงคุณวุฒิภายนอก')}
            </CollapseItem>
            <CollapseItem pdfUrl={FMASL08} forceUseThFont>
              {t('แบบฟอร์มขอเพิ่มรายละเอียดของผู้ทรงคุณวุฒิ')}
            </CollapseItem>
            <CollapseItem pdfUrl={ระบบแต่งต้งผู้ทรงคุณวุมิภายนอก} forceUseThFont>
              {t('ระบบการแต่งตั้งผู้ทรงคุณวุฒิภายนอก')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('อาจารย์ผู้เชี่ยวชาญเฉพาะ')} forceUseThFont>
            <CollapseItem pdfUrl={FMASL04} forceUseThFont>
              {t('แบบเสนอรายชื่ออาจารย์ผู้เชี่ยวชาญเฉพาะ ตามข้อบังคับมหาวิทยาลัยเชียงใหม่ว่าด้วยการศึกษาระดับบัณฑิตศึกษา พ.ศ.2559')}
            </CollapseItem>
            <CollapseItem pdfUrl={FMASL05} forceUseThFont>
              {t('ข้อมูลประกอบการพิจารณาการแต่งตั้งอาจารย์ผู้เชี่ยวชาญเฉพาะ')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('นักวิจัยประจำ')} forceUseThFont>
            <CollapseItem pdfUrl={แบบเสนอรายชื่อนักวิจัยประจำ} forceUseThFont>
              {t('แบบเสนอรายชื่อนักวิจัยประจำ ตามเกณฑ์มาตรฐานการศึกษาระดับบัณฑิตศึกษา พ.ศ.2566')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('ภาระงานอาจารย์')} forceUseThFont>
            <CollapseItem pdfUrl={ThesisADV2560} forceUseThFont>
              {t('หน้าที่และภาระงานของอาจารย์ที่ปรึกษาปริญญานิพนธ์หลัก/ร่วม พ.ศ. 2560')}
            </CollapseItem>
            <CollapseItem pdfUrl={AddWorkLoad} forceUseThFont>
              {t('แบบเสนอขอเพิ่มภาระงานคุมวิทยานิพนธ์และการค้นคว้าแบบอิสระ')}
            </CollapseItem>
            <CollapseItem pdfUrl={AddWorkLoadInterdis} forceUseThFont>
              {t('แบบเสนอขอเพิ่มภาระงานคุมวิทยานิพนธ์และการค้นคว้าแบบอิสระ สำหรับสหสาขาวิชา (สาขาวิชาร่วม)')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('คำจำกัดความ รูปแบบการเผยแพร่')} forceUseThFont>
            <CollapseItem pdfUrl={prnews01} forceUseThFont>
              {t('คำจำกัดความ รูปแบบ การเผยแพร่ และลักษณะคุณภาพของผลงานทางวิชาการ')}
            </CollapseItem>
            <CollapseItem pdfUrl={เกณฑ์ฉบับที่2พศ2561} forceUseThFont>
              {t('คำจำกัดความ รูปแบบ การเผยแพร่ และลักษณะคุณภาพของผลงานทางวิชาการ ปี 2561 ฉบับที่ 2')}
            </CollapseItem>
            <CollapseItem pdfUrl={เกณฑ์63} forceUseThFont>
              {t('คำจำกัดความ รูปแบบ การเผยแพร่ และลักษณะคุณภาพของผลงานทางวิชาการ ปี 2563')}
            </CollapseItem>
          </CollapseSection>
        </CollapseSectionContainer>
      </CustomContainer>
    </Layout>
  );
};

export default LecturerPage;
