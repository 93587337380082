import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import RightArrowWithoutTail from '../../../../../components/Icons/RightArrowWithoutTail';

const AdmissionImageSlider = ({ listData = [] }) => {
  const sliderRef = useRef(null);
  const [currenIndex, setCurrenIndex] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    fade: true,
    arrows: false,
    pauseOnHover: false,
  };
  return (
    <div>
      <Slider {...settings} ref={sliderRef} afterChange={(id) => setCurrenIndex(id)}>
        {listData.map((slide, id) => (
          <div key={id} className="">
            <div className="flex w-full flex-row justify-center">
              <img key={id} src={slide.imageUrl} alt="" />
            </div>
          </div>
        ))}
      </Slider>
      <div className=" mt-4  grid grid-cols-5 items-center md:ml-[80px]">
        <button className="rotate-180" onClick={() => sliderRef?.current?.slickPrev()}>
          <RightArrowWithoutTail />
        </button>
        <div className="col-span-3 mx-auto flex flex-row">
          {listData.map((_, id) => (
            <div
              key={id}
              onClick={() => {
                setCurrenIndex(id);
                sliderRef.current.slickGoTo(id);
              }}
              className={`mx-1 h-1 w-2 bg-GradPink hover:cursor-pointer md:w-8 ${
                currenIndex === id ? '' : 'opacity-40'
              }`}
            />
          ))}
        </div>

        <button onClick={() => sliderRef?.current?.slickNext()}>
          <RightArrowWithoutTail />
        </button>
      </div>
    </div>
  );
};

AdmissionImageSlider.propTypes = {
  listData: PropTypes.array,
};
export default AdmissionImageSlider;
