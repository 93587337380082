import React from 'react';
import { ReactComponent as RigthArrow } from '../../../assets/rigth-arrow-16x-Lightgray400.svg';
import FactCard from '../../../components/FactsCard';

import { useHistory } from 'react-router';
import routeUrlProvider, { FACTS_AND_FIGURES } from '../../../constants/route-paths';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';
import MoreDetailButton from '../../../components/Button/MoreDetailButton';
import CustomContainer from '../../../components/CustomContainer';
import FactAndFigureContainer from '../../../components/FactAndFigureContainer';
import { FACTS_AND_FIGURES_DATA } from '../../../constants/fact-and-figure';

const FactsAndFiguresSection = () => {
  const { t } = useTranslationUtil();
  const history = useHistory();
  return (
    <CustomContainer className=" mt-[96px] w-full px-2 lg:px-20">
      <div className="flex justify-between xs:flex-col md:flex-row">
        <div className="w-full text-[48px] font-medium leading-[61px] text-UIBlack xs:mx-auto xs:text-center md:m-0 md:text-center lg:text-center">
          {t('FactsAndFigures')}
        </div>
        <button
          className="xs:mx-auto xs:mt-[20px] md:absolute md:right-0 md:mr-[34px]  lg:mr-20 lg:inline-block"
          onClick={() => history.push({ pathname: routeUrlProvider.getForRoute(FACTS_AND_FIGURES) })}
        >
          <div className="hidden flex-col justify-center leading-[61px] md:flex">
            <MoreDetailButton
              text={t('More')}
              onClick={() => history.push({ pathname: routeUrlProvider.getForRoute(FACTS_AND_FIGURES) })}
            />
          </div>
        </button>
      </div>
      <FactAndFigureContainer>
        {FACTS_AND_FIGURES_DATA.map(({ amount, i18nKeyName }) => (
          <FactCard number={amount} title={t(i18nKeyName)} />
        ))}
      </FactAndFigureContainer>
      <div className="mt-10 flex w-full flex-row justify-center  md:hidden">
        <MoreDetailButton
          text={t('More')}
          onClick={() => history.push({ pathname: routeUrlProvider.getForRoute(FACTS_AND_FIGURES) })}
        />
      </div>
    </CustomContainer>
  );
};

export default FactsAndFiguresSection;
