import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslationUtil } from '../../../../../hooks/useTranslationUtil';

const SubpageListItem = ({ children, link, isExternal = false }) => {
  const history = useHistory();

  const { getFontFamily } = useTranslationUtil();

  const onClick = () => {
    if (link) {
      history.push(link);
    }
  };

  return (
    <li
      className={`${getFontFamily()} relative z-10 mr-8 text-lg font-bold md:pb-6 ${
        !!link ? 'hover:cursor-pointer hover:text-NeonPink' : 'hover:cursor-not-allowed'
      } `}
      onClick={onClick}
    >
      {isExternal ? (
        <a href={link} target="_blank" rel="noopener noreferrer" className="relative z-10">
          {children}
        </a>
      ) : (
        children
      )}
    </li>
  );
};

SubpageListItem.propTypes = {
  children: PropTypes.any,
  isExternal: PropTypes.bool,
  link: PropTypes.string,
};

export default SubpageListItem;
