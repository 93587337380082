import React from 'react';
import CollapseSection from '../../components/CollapseSection';
import CollapseItem from '../../components/CollapseSection/CollapseItem';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import enrollmentnewpdf from './3-enrollment.newpdf.pdf';

import AnnounDevCurr from './assets/1/1/AnnounDevCurr.pdf';
import cur001 from './assets/1/1/cur-0-01.pdf';
import CurrAddmissionFlow20211 from './assets/1/1/CurrAddmissionFlow20211.pdf';
import guidelinenewcurr from './assets/1/1/guideline-new-curr.pdf';
import Newcurriculumanalysisguidelines from './assets/1/1/New-curriculum-analysis-guidelines.pdf';
import แนวทางการเสนอหลักสูตรใหม่62 from './assets/1/1/แนวทางการเสนอหลักสูตรใหม่-62.pdf';
import แต่งตั้งคณะกรรมการร่างหลักสูตรเปิดใหม่ from './assets/1/2/แต่งตั้งคณะกรรมการร่างหลักสูตร-เปิดใหม่.docx';
import NewCurrStep0125620927่ from './assets/1/3/NewCurrStep01-25620927.doc';

import CurrNewData from './assets/1/4/1_CurrNewData_20230719_edit_20230828.doc';
import ExSumNewCurrPHD from './assets/1/4/2_ExSumNewCurrPHD_20230719_edit_20230828.doc';
import ExSumNewCurrMaster from './assets/1/4/3_ExSumNewCurrMaster_20230719_edit_20230828.doc';
import CS2PhdNew from './assets/1/4/4_CS2PhdNew_20230807.doc';
import CS2MasterNew from './assets/1/4/5_CS2MasterNew_20230807.doc';

//เหมือน 2/3 2/4
import CourseLearningOutcomesCLOs from './assets/1/5/4.12-Course-Learning-Outcomes-CLOs-บว.doc';
import มคอ3OBE from './assets/1/5/มคอ.3.-OBE-ตัดตาราง-mapping.docx';
import มคอ4 from './assets/1/5/มคอ.4.docx';
import Twopagessummaryimprovecurr from './assets/1/6/Twopages-summary_improve-curr_V2.docx';

import แต่งตั้งคณะกรรมการปรับปรุงหลักสูตร from './assets/2/1/แต่งตั้งคณะกรรมการปรับปรุงหลักสูตร.docx';
import CurrReviseData from './assets/2/2/1_CurrReviseData_20230719_edit_20230830.doc';
import ExSumReviseCurrPHD from './assets/2/2/ExSumReviseCurrPHD_20230925.doc';
import ExSumReviseCurrMaster from './assets/2/2/ExSumReviseCurrMaster_20230925.doc';
import CS2PhdRevise from './assets/2/2/4_CS2PhdRevise_20230809.doc';
import CS2MasterRevise from './assets/2/2/5_CS2MasterRevise_20230807.doc';

import s6 from './assets/3/s6.pdf';
import sormor0801 from './assets/3/sormor08-01-ปรับปรุงทั่วไป.doc';
import sormor0802 from './assets/3/sormor08-02-ปรับปรุงอาจารย์ประจำหลักสูตร.doc';
import sormor0803 from './assets/3/sormor08-03-ปรับปรุงแผนการศึกษา.doc';
import sormor0804่ from './assets/3/sormor08-04-1-ปรับการกระจายแผนที่.doc';
import SubjectDetails from './assets/3/SubjectDetails.docx';

import มคอ5 from './assets/4/มคอ.5.docx';
import มคอ6 from './assets/4/มคอ.6.docx';
import มคอ7 from './assets/4/มคอ.7.doc';

import แจ้งการเปิดปิดหลักสูตร from './assets/5/510213_2.pdf';
import closecurr from './assets/5/close-curr.pdf';
import fc_cose_cull from './assets/5/fc_cose_cull.pdf';

import แบบฟอร์มเสนอโครงการ from './assets/6/1/3-แบบฟอร์มเสนอ-โครงการ.docx';
import ฉบับใหม่65 from './assets/6/1/ฉบับใหม่-65.pdf';
import Templateหลักสูตรปริญญาคู่ปรับใหม่ล่าสุด15 from './assets/6/2/5-Template-หลักสูตรปริญญาคู่-ปรับใหม่ล่าสุด1.docx';
import Temp_2 from './assets/6/3/Temp_2.pdf';
import Template from './assets/6/3/Template.doc';
import new06 from './assets/6/4/new06.doc';
import t1 from './assets/6/5/t1.pdf';
import t2 from './assets/6/5/t2.pdf';
import t2_doc from './assets/6/5/t2.doc';
import t3 from './assets/6/5/t3.pdf';
import t3_xls from './assets/6/5/t3.xls';
import t4 from './assets/6/5/t4.pdf';
import t4_xls from './assets/6/5/t4.xls';
import t5 from './assets/6/5/t5.pdf';
import t5_xls from './assets/6/5/t5.xls';
import t6 from './assets/6/5/t6.pdf';
import t7 from './assets/6/5/t7.pdf';
import t8 from './assets/6/5/t8.pdf';

import สถานภาพหลักสูตร090965 from './assets/7/สถานภาพหลักสูตร-09.09.65.pdf';
import GraduateRegularprogram691V3 from './assets/8/GraduateRegularprogram-69-1-V3.pdf';
import manage from './assets/8/manage.ppt';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';
import CollapseSectionContainer from '../../components/CollapseSection/CollapseSectionContainer';

const CourseTemplatePage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{t('การทำหลักสูตร')}</PageTitleContainer>
        <CollapseSectionContainer>
          <CollapseSection title={t('การเสนอเปิดหลักสูตรใหม่')}>
            <CollapseItem pdfUrl={cur001}>{t('ขั้นตอนที่ 1 [1 สิงหาคม 2556]')}</CollapseItem>
            <CollapseItem pdfUrl={CurrAddmissionFlow20211}>
              {t('ขั้นตอนการร่าง / ปรับปรุงหลักสูตร และการเปิดรับสมัครนักศึกษาบัณฑิตศึกษา')}
            </CollapseItem>
            <CollapseItem pdfUrl={AnnounDevCurr}>{t('ประกาศแนวปฏิบัติการเสนอหลักสูตรใหม่ [22 ก.ค. 62]')}</CollapseItem>
            <CollapseItem pdfUrl={แนวทางการเสนอหลักสูตรใหม่62}>{t('แนวทางการเสนอหลักสูตรใหม่')}</CollapseItem>
            <CollapseItem pdfUrl={guidelinenewcurr}>{t('แนวปฏิบัติการเสนออนุมัติเปิดสอนหลักสูตรใหม่')}</CollapseItem>
            <CollapseItem pdfUrl={Newcurriculumanalysisguidelines}>{t('แนวทางการวิเคราะห์หลักสูตรใหม่')}</CollapseItem>
          </CollapseSection>
          <CollapseSection title={t('การแต่งตั้งคณะกรรมการร่างหลักสูตร')}>
            <CollapseItem pdfUrl={แต่งตั้งคณะกรรมการร่างหลักสูตรเปิดใหม่}>
              {t('แนวปฏิบัติในการแต่งตั้งคณะกรรมการร่างหลักสูตรระดับบัณฑิตศึกษา')}
            </CollapseItem>
          </CollapseSection>
          <CollapseSection title={t('แบบฟอร์มเสนอหลักสูตรใหม่ ขั้นตอนที่ 1')}>
            <CollapseItem pdfUrl={NewCurrStep0125620927่}>
              {t('แบบเสนอขอเปิดหลักสูตรใหม่ ขั้นตอนที่ 1 [27 ก.ย. 62]')}
            </CollapseItem>
          </CollapseSection>
          <CollapseSection title={t('แบบฟอร์มเสนอหลักสูตรใหม่ ขั้นตอนที่ 2')}>
            <CollapseItem pdfUrl={CurrNewData}>{t('ข้อมูลสำคัญ หลักสูตรใหม่ [19 ก.ค. 2566]')}</CollapseItem>
            <CollapseItem pdfUrl={ExSumNewCurrPHD}>{t('บทสรุปผู้บริหาร ป.เอก หลักสูตรใหม่ [19 ก.ค. 2566]')}</CollapseItem>
            <CollapseItem pdfUrl={ExSumNewCurrMaster}>{t('บทสรุปผู้บริหาร ป.โท หลักสูตรใหม่ [19 ก.ค. 2566]')}</CollapseItem>
            <CollapseItem pdfUrl={CS2PhdNew}>{t('มคอ.2 ป.เอก หลักสูตรใหม่ [10 มี.ค. 2566]')}</CollapseItem>
            <CollapseItem pdfUrl={CS2MasterNew}>{t('มคอ.2 ป.โท หลักสูตรใหม่ [4 เม.ย. 2566]')}</CollapseItem>
          </CollapseSection>
          <CollapseSection title={t('มคอ.3 - 4 รายละเอียดกระบวนวิชา')}>
            <CollapseItem pdfUrl={CourseLearningOutcomesCLOs}>
              {t('มคอ.3 (กระบวนวิชาที่มีการปรับปรุงเฉพาะ CLOs) 11 ก.พ. 65')}
            </CollapseItem>
            <CollapseItem pdfUrl={มคอ3OBE}>{t('มคอ.3 (รายละเอียดกระบวนวิชา) 15/6/2564')}</CollapseItem>
            <CollapseItem pdfUrl={มคอ4}>{t('มคอ.4 (รายละเอียดกระบวนวิชาประสบการณ์ภาคสนาม)')}</CollapseItem>
          </CollapseSection>
          <CollapseSection title={t('แบบฟอร์มเสนอหลักสูตรต่อที่ประชุม')}>
            <CollapseItem pdfUrl={Twopagessummaryimprovecurr}>
              {t('แบบฟอร์มสำหรับการนำเสนอหลักสูตรต่อที่ประชุมสภาวิชาการ')}
            </CollapseItem>
          </CollapseSection>
          <CollapseSection title={t('การแต่งตั้งคณะกรรมการปรับปรุงหลักสูตร')}>
            <CollapseItem pdfUrl={แต่งตั้งคณะกรรมการปรับปรุงหลักสูตร}>
              {t('แนวปฏิบัติในการแต่งตั้งคณะกรรมการปรับปรุงหลักสูตรระดับบัณฑิตศึกษา')}
            </CollapseItem>
          </CollapseSection>
          <CollapseSection title={t('แบบฟอร์มเสนอหลักสูตรปรับปรุง')}>
            <CollapseItem pdfUrl={CurrReviseData}>
              {t('ข้อมูลสำคัญ หลักสูตรปรับปรุง [19 ก.ค. 2566]')}
            </CollapseItem>
            <CollapseItem pdfUrl={ExSumReviseCurrPHD}>
              {t('บทสรุปผู้บริหาร ป.เอก หลักสูตรปรับปรุง [25 ก.ย. 2566]')}
            </CollapseItem>
            <CollapseItem pdfUrl={ExSumReviseCurrMaster}>
              {t('บทสรุปผู้บริหาร ป.โท หลักสูตรปรับปรุง [25 ก.ย. 2566]')}
            </CollapseItem>
            <CollapseItem pdfUrl={CS2PhdRevise}>
              {t('มคอ.2 ป.เอก หลักสูตรปรับปรุง  [10 มี.ค. 2566]')}
            </CollapseItem>
            <CollapseItem pdfUrl={CS2MasterRevise}>
              {t('มคอ.2 ป.โท หลักสูตรปรับปรุง  [4 เม.ย. 2566] ')}
            </CollapseItem>
          </CollapseSection>
          <CollapseSection title={t('แบบฟอร์มเสนอหลักสูตรต่อที่ประชุม')}>
            <CollapseItem pdfUrl={Twopagessummaryimprovecurr}>
              {t('แบบฟอร์มสำหรับการนำเสนอหลักสูตรต่อที่ประชุมสภาวิชาการ')}
            </CollapseItem>
          </CollapseSection>
          <CollapseSection title={t('การเสนอหลักสูตรปรับปรุงเล็กน้อย')}>
            <CollapseItem pdfUrl={enrollmentnewpdf}>
              {t('ขั้นตอนการจัดทำหลักสูตรปรับปรุงเล็กน้อย [18/04/2551]')}
            </CollapseItem>
            <CollapseItem pdfUrl={sormor0801}>{t('การปรับปรุงทั่วไป')}</CollapseItem>
            <CollapseItem pdfUrl={SubjectDetails}>{t('การปรับกระบวนวิชา')}</CollapseItem>
            <CollapseItem pdfUrl={sormor0802}>{t('การปรับอาจารย์')}</CollapseItem>
            <CollapseItem pdfUrl={sormor0803}>{t('การปรับแผนการศึกษา')}</CollapseItem>
            <CollapseItem pdfUrl={s6}>{t('การเปลี่ยนแปลงแผนกำหนดการศึกษา')}</CollapseItem>
            <CollapseItem pdfUrl={sormor0804่}>{t('การปรับการกระจายแผนที่')}</CollapseItem>
          </CollapseSection>
          <CollapseSection title={t('การรายงานผลการดำเนินการของกระบวนวิชา')}>
            <CollapseItem pdfUrl={มคอ5}>{t('มคอ.5 (รายงานผลการดำเนินการของกระบวนวิชา)')}</CollapseItem>
            <CollapseItem pdfUrl={มคอ6}>{t('มคอ.6 (รายงานผลการดำเนินการของประสบการณ์ภาคสนาม)')}</CollapseItem>
            <CollapseItem pdfUrl={มคอ7}>{t('มคอ.7 (รายงานผลการดำเนินงานของหลักสูตร)')}</CollapseItem>
          </CollapseSection>
          <CollapseSection title={t('การปิดหลักสูตร')}>
            <CollapseItem pdfUrl={fc_cose_cull}>{t('ขั้นตอนการปิดหลักสูตร')}</CollapseItem>
            <CollapseItem pdfUrl={แจ้งการเปิดปิดหลักสูตร}>{t('แจ้งการเปิด-ปิดหลักสูตร')}</CollapseItem>
            <CollapseItem pdfUrl={closecurr}>
              {t(
                'ประกาศมหาวิทยาลัยเชียงใหม่ เรื่อง แนวปฏิบัติในการเสนอปิดหลักสูตรระดับปริญญาตรีและระดับบัณฑิตศึกษา [9 กรกฎาคม 2556]'
              )}
            </CollapseItem>
          </CollapseSection>
          <CollapseSection title={t('การขอรับเงินอุดหนุนเพื่อการพัฒนาหลักสูตรระดับบัณฑิตศึกษา')}>
            <CollapseItem pdfUrl={ฉบับใหม่65}>
              {t('ประกาศบัณฑิตวิทยาลัย เรื่อง เงินอุดหนุนเพื่อการพัฒนาหลักสูตรระดับบัณฑิตศึกษา')}
            </CollapseItem>
            <CollapseItem pdfUrl={แบบฟอร์มเสนอโครงการ}>
              {t('แบบฟอร์มเสนอเพื่อขอรับเงินอุดหนุนเพื่อการพัฒนาหลักสูตรระดับบัณฑิตศึกษา')}
            </CollapseItem>
          </CollapseSection>
          <CollapseSection title={t('Template โครงการเปิดสอนหลักสูตรที่มีความร่วมมือ (Double degree/ Joint degree')}>
            <CollapseItem pdfUrl={Templateหลักสูตรปริญญาคู่ปรับใหม่ล่าสุด15}>
              {t('โครงการเปิดสอนหลักสูตรที่มีความร่วมมือ (Double degree/ Joint degree)')}
            </CollapseItem>
          </CollapseSection>
          <CollapseSection title={t('Template การจัดการศึกษานอกสถานที่ตั้งของสถาบัน')}>
            <CollapseItem pdfUrl={Template}>{t('Template โครงการ-นอกที่ตั้ง [03/07/2552]')}</CollapseItem>
            <CollapseItem pdfUrl={Temp_2}>
              {t('ประกาศกระทรวงศึกษาธิการ เรื่อง การจัดการศึกษานอกสถานที่ตั้งของสถาบัน [03/07/2552]')}
            </CollapseItem>
          </CollapseSection>
          <CollapseSection
            title={t('โครงการเปิดสอนหลักสูตรภาคพิเศษ (สำหรับหลักสูตรที่เปิดสอนภาคปกติ ต้องการเปิดภาคพิเศษ)')}
          >
            <CollapseItem pdfUrl={new06}>{t('โครงการเปิดสอนหลักสูตรภาคพิเศษ')}</CollapseItem>
          </CollapseSection>
          <CollapseSection title={t('การจัดทำอัตราค่าธรรมเนียมแบบเหมาจ่าย')}>
            <CollapseItem pdfUrl={t1}>{t('แนวปฏิบัติในการเสนอร่างระเบียบฯ ว่าด้วยค่าธรรมเนียมการศึกษา')}</CollapseItem>
            <CollapseItem pdfUrl={t2}>{t('ตัวอย่างระเบียบฯ')}</CollapseItem>
            <CollapseItem pdfUrl={t3}>{t('เกณฑ์การจัดสรรค่าธรรมเนียมการศึกษา')}</CollapseItem>
            <CollapseItem pdfUrl={t4}>{t('ประมาณการรายรับ - รายจ่าย ภาคปกติ')}</CollapseItem>
            <CollapseItem pdfUrl={t5}>{t('ประมาณการรายรับ - รายจ่าย ภาคพิเศษ')}</CollapseItem>
            <CollapseItem pdfUrl={t6}>
              {t('ตัวอย่าง เกณฑ์การจัดสรรประกมาณการรายรับ - รายจ่าย ตลอดหลักสูตร (Excel)')}
            </CollapseItem>
            <CollapseItem pdfUrl={t7}>
              {t('ตัวอย่าง ตารางเปรียบเทียบค่าธรรมเนียมการศึกษาอัตราเดิมและอัตราใหม่')}
            </CollapseItem>
            <CollapseItem pdfUrl={t8}>
              {t('ตัวอย่าง การเสนอข้อมูลค่าะรรมเนียมการศึกษาในสาขาเดียวกันของสถาบันอื่น')}
            </CollapseItem>
          </CollapseSection>
          ค่าธรรมเนียมติดต่อ คุณพรรณี ลีรพันธุ์ โทร. 42406
          <CollapseSection title={t('รหัสหลักสูตรระดับบัณฑิตศึกษา')}>
            <CollapseItem pdfUrl={สถานภาพหลักสูตร090965}>{t('รหัสหลักสูตรระดับบัณฑิตศึกษา')}</CollapseItem>
          </CollapseSection>
          <CollapseSection title={t('หลักสูตร')}>
            <CollapseItem pdfUrl={GraduateRegularprogram691V3}>
              {t(
                'หลักสูตรนานาชาติและหลักสูตรไทยที่สอนเป็นภาษาอังกฤษ (Graduate Regular Program, Higher diploma Open for international students (teaching in English)'
              )}
            </CollapseItem>
            <CollapseItem pdfUrl={manage}>{t('การบริหารจัดการหลักสูตร')}</CollapseItem>
          </CollapseSection>
        </CollapseSectionContainer>
      </CustomContainer>
    </Layout>
  );
};

export default CourseTemplatePage;
