export const MOCK_MULTIDISCIPLINARY_PROGRAM_PIE_GRAPH_GROUP_BY_PHD = [
  {
    name_th: 'เทคโนโลยีชีวภาพ',
    name_en: 'Biotechnology',
    student_amount: 25,
    student_percentage: '100%',
  },
  {
    name_th: 'บูรณาการศาสตร์',
    name_en: 'Integrated Science',
    student_amount: 0,
    student_percentage: '0%',
  },
];
