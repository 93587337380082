export const MOCK_STUDENT_BAR_GRAPH_GROUP_BY_PROGRAM = [
  {
    name_th: 'ปริญญาโท',
    name_en: 'Master’s',
    total: 4853,
    International_amount: 847,
    Thai_amount: 4006,
  },
  {
    name_th: 'ปริญญาเอก',
    name_en: 'Doctoral',
    total: 1431,
    International_amount: 383,
    Thai_amount: 1048,
  },
  {
    name_th: 'ประกาศนียบัตรบัณฑิต',
    name_en: 'Graduate Diploma',
    total: 137,
    International_amount: 0,
    Thai_amount: 137,
  },
  {
    name_th: 'ประกาศนียบัตรบัณฑิตชั้นสูง',
    name_en: 'High Graduate Diploma',
    total: 411,
    International_amount: 0,
    Thai_amount: 411,
  },
];
