import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslationUtil } from '../../../../hooks/useTranslationUtil';
import Modal from 'react-modal';
import DocumentPurpleIcon from '../../../../components/Icons/DocumentPurpleIcon';
import DocumentWhiteIcon from '../../../../components/Icons/DocumentWhiteIcon';
import EmailIcon from '../../../../components/Icons/EmailIcon';
import EmailWhiteIcon from '../../../../components/Icons/EmailWhiteIcon';
import PhoneIcon from '../../../../components/Icons/PhoneIcon';
import PhoneWhiteIcon from '../../../../components/Icons/PhoneWhiteIcon';
import DocumentGrayIcon from '../../../../components/Icons/DocumentGrayIcon';
import CrossIcon from '../../../../components/Icons/CrossIcon';
import './modal.css';
import { afterCloseModal, afterOpenModal } from '../../../../utils/modal';
import useWindowDimensions from '../../../../hooks/useWindowDimension';

const HistoryPurpleButton = ({ onClick = () => {} }) => {
  const { t } = useTranslationUtil();
  return (
    <button
      onClick={onClick}
      className="mt-6 flex w-fit flex-row rounded-sm bg-PrimaryDark bg-opacity-30 p-2 shadow-2xl"
    >
      <div>
        <DocumentPurpleIcon />
      </div>
      <div className="ml-3 text-lg font-bold uppercase leading-6 text-PrimaryDark">{t('History')}</div>
    </button>
  );
};

const HistoryWhiteButton = ({ onClick = () => {} }) => {
  const { t } = useTranslationUtil();

  return (
    <button onClick={onClick} className="mt-14 flex w-fit flex-row rounded-sm bg-UIWhite bg-opacity-30 p-2 shadow-2xl">
      <div>
        <DocumentWhiteIcon />
      </div>
      <div className=" ml-3 text-[14px] font-bold uppercase leading-[18px] md:text-lg md:leading-6 ">
        {t('History')}
      </div>
    </button>
  );
};

export const Email = ({ children }) => (
  <a className="mt-1 flex flex-row" href={`mailto:${children}`} target="_blank" rel="noopener noreferrer">
    <div className="my-auto">
      <EmailIcon />
    </div>
    <div className="my-auto ml-[10px] font-open-sans text-xs font-bold leading-4 text-PrimaryDark">{children}</div>
  </a>
);

export const EmailWhite = ({ children }) => (
  <a className="mt-1 ml-1 flex flex-row" href={`mailto:${children}`} target="_blank" rel="noopener noreferrer">
    <div className="my-auto">
      <EmailWhiteIcon />
    </div>
    <div className="my-auto ml-[10px] font-dosis text-lg font-bold leading-6 text-UIWhite">{children}</div>
  </a>
);

export const Phone = ({ children }) => (
  <div className="flex flex-row">
    <div className="my-auto ml-1">
      <PhoneIcon />
    </div>
    <div className="my-auto ml-[11px] text-lg font-bold leading-6 text-PrimaryDark">{children}</div>
  </div>
);

export const PhoneWhite = ({ children }) => (
  <div className="mt-6 flex flex-row">
    <div className="my-auto ml-1">
      <PhoneWhiteIcon />
    </div>
    <div className="my-auto ml-[11px] font-dosis text-lg font-bold leading-6 text-UIWhite">{children}</div>
  </div>
);

const ModalList = ({ children }) => <ul className="ml-7 list-outside list-disc">{children}</ul>;
const ModalHistorySectionHeader = ({ children }) => {
  const { getFontFamily } = useTranslationUtil();

  return <div className={`${getFontFamily(true)} mt-4`}>{children}</div>;
};
const ModalHistoryContainer = ({ children }) => (
  <div className="masked-overflow overflow-y-auto font-open-sans text-sm leading-5 text-Lightgray800">{children}</div>
);
const ModalPosition = ({ children }) => {
  const { getFontFamily } = useTranslationUtil();

  return <div className={`${getFontFamily()} mt-6 text-[32px] font-medium leading-10 md:mt-0`}>{children}</div>;
};
const ModalContainer = ({ children }) => {
  const { getFontFamily } = useTranslationUtil();

  return (
    <div
      className={`${getFontFamily(true)} m-auto flex max-w-[1120px] flex-col overflow-y-auto  bg-UIWhite lg:flex-row `}
    >
      {children}
    </div>
  );
};

const ModalHeadDetailContainer = ({ children }) => {
  const { getFontFamily } = useTranslationUtil();

  return <div className={`${getFontFamily()} flex flex-col gap-y-6 lg:flex-row lg:justify-between`}> {children}</div>;
};
const ModalContactContainer = ({ children }) => (
  <div className="flex flex-col justify-end">
    <div className="border-l-4 border-CMUPurple pl-[18px]">{children}</div>
  </div>
);
const ModalNameContainer = ({ children }) => {
  const { getFontFamily } = useTranslationUtil();
  return <div className={`${getFontFamily()} text-2xl font-semibold leading-[30px] text-UIBlack`}>{children}</div>;
};
const ModalHistoryIconAndText = () => {
  const { t, getFontFamily } = useTranslationUtil();
  return (
    <div className="felx-row mt-[18px] flex">
      <DocumentGrayIcon />
      <div className={`${getFontFamily()} my-auto ml-3 text-lg font-bold leading-6 text-Lightgray400`}>
        {t('History')}
      </div>
    </div>
  );
};

const ModalCloseButton = ({ onClick = () => {} }) => (
  <div className="top-0 right-0 flex flex-row justify-end">
    <button className="py-[30px]" onClick={() => onClick()}>
      <CrossIcon />
    </button>
  </div>
);

const ModalDetailContainer = ({ children }) => (
  <div className="px-3 md:px-10 lg:w-[800px] lg:px-[56px]">{children}</div>
);
const ModalProfileImage = ({ src }) => (
  <img src={src} alt="" className="mx-auto md:max-h-[400px]  md:w-auto lg:max-h-[584px]" />
);
const ModalTitleName = ({ children }) => {
  const { getFontFamily } = useTranslationUtil();

  return <div className={`${getFontFamily()} mt-[27px]`}>{children}</div>;
};
const LightgrayDivider = () => (
  <div className="mt-6">
    <div className="h-[2px] w-full bg-Lightgray100" />
  </div>
);

const ProfileHistoryModal = ({
  imgSrc,
  email,
  position,
  name,
  titlePositionName,
  educationalBackground,
  academicRelatedWork,
  researchArea,
  phoneNumber,
  fildsOfInterest,
  whiteButtonTheme = false,
  addPaddingTop = false,
}) => {
  const { t } = useTranslationUtil();
  const [isOpen, setIsOpen] = useState(false);
  const { widthLessThanMD } = useWindowDimensions();

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      height: widthLessThanMD ? '100%' : 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 0,
      borderRadius: '2px',
      border: 0,
    },
  };

  return (
    <>
      {whiteButtonTheme ? (
        <HistoryWhiteButton onClick={() => setIsOpen(true)} />
      ) : (
        <HistoryPurpleButton onClick={() => setIsOpen(true)} />
      )}
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        onAfterClose={afterCloseModal}
        onAfterOpen={afterOpenModal}
      >
        <ModalContainer>
          <div className={`pr-5 lg:hidden ${addPaddingTop ? 'pt-20' : ''}`}>
            <ModalCloseButton onClick={() => setIsOpen(false)} />
          </div>
          <ModalProfileImage src={imgSrc} />
          <ModalDetailContainer>
            <div className="hidden lg:block">
              <ModalCloseButton onClick={() => setIsOpen(false)} />
            </div>
            <ModalPosition>{position}</ModalPosition>
            <ModalHeadDetailContainer>
              <ModalNameContainer>
                <ModalTitleName>{titlePositionName}</ModalTitleName>
                {name}
              </ModalNameContainer>
              <ModalContactContainer>
                {phoneNumber ? <Phone>{phoneNumber}</Phone> : null}
                <Email>{email}</Email>
              </ModalContactContainer>
            </ModalHeadDetailContainer>
            <LightgrayDivider />
            <ModalHistoryIconAndText />
            <ModalHistoryContainer>
              {educationalBackground ? (
                <ModalHistorySectionHeader>
                  {t('EducationalBackground')}
                  <ModalList>{educationalBackground}</ModalList>
                </ModalHistorySectionHeader>
              ) : null}

              {academicRelatedWork ? (
                <ModalHistorySectionHeader>
                  {t('AcademicRelatedWork')}
                  <ModalList>{academicRelatedWork}</ModalList>
                </ModalHistorySectionHeader>
              ) : null}

              {researchArea ? (
                <ModalHistorySectionHeader>
                  {t('ResearchArea')}
                  <ModalList>{researchArea}</ModalList>
                </ModalHistorySectionHeader>
              ) : null}
              {fildsOfInterest ? (
                <ModalHistorySectionHeader>
                  {t('FieldsOfInterest')}
                  <ModalList>{fildsOfInterest}</ModalList>
                </ModalHistorySectionHeader>
              ) : null}
            </ModalHistoryContainer>
          </ModalDetailContainer>
        </ModalContainer>
      </Modal>
    </>
  );
};

ProfileHistoryModal.propTypes = {
  academicRelatedWork: PropTypes.elementType,
  educationalBackground: PropTypes.elementType,
  email: PropTypes.string,
  fildsOfInterest: PropTypes.elementType,
  imgSrc: PropTypes.string,
  name: PropTypes.string,
  phoneNumber: PropTypes.string,
  position: PropTypes.string,
  researchArea: PropTypes.elementType,
  titlePositionName: PropTypes.string,
  whiteButtonTheme: PropTypes.bool,
};

export default ProfileHistoryModal;
