import React from 'react';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';

const ContentWrapper = ({ children }) => {
  const { getFontFamily } = useTranslationUtil();
  return (
    <div className={`${getFontFamily()} `}>
      <div className="px-6 text-xl md:px-8 lg:px-20">{children}</div>
    </div>
  );
};

export default ContentWrapper;
