import apiUrlProvider, { GET_ADMISSION_BANNERS } from '../../constants/api-endpoints';
import { fetchGet } from '../../utils/services/fetch';

export const MOCK_DATA_UPDATE_IMAGES = [
  {
    imageUrl: 'https://sv1.picz.in.th/images/2022/12/08/GVH4sl.jpg',
    thumbnail: 'https://sv1.picz.in.th/images/2022/12/08/GVH4sl.th.jpg',
    url: 'https://sv1.picz.in.th/images/2022/12/08/GVH4sl.jpg',
  },
  {
    imageUrl: 'https://sv1.picz.in.th/images/2022/12/08/GVH4sl.jpg',
    thumbnail: 'https://sv1.picz.in.th/images/2022/12/08/GVH4sl.th.jpg',
    url: 'https://sv1.picz.in.th/images/2022/12/08/GVH4sl.jpg',
  },
  {
    imageUrl: 'https://sv1.picz.in.th/images/2022/12/08/GVH4sl.jpg',
    thumbnail: 'https://sv1.picz.in.th/images/2022/12/08/GVH4sl.th.jpg',
    url: 'https://sv1.picz.in.th/images/2022/12/08/GVH4sl.jpg',
  },
  {
    imageUrl: 'https://sv1.picz.in.th/images/2022/12/08/GVH4sl.jpg',
    thumbnail: 'https://sv1.picz.in.th/images/2022/12/08/GVH4sl.th.jpg',
    url: 'https://sv1.picz.in.th/images/2022/12/08/GVH4sl.jpg',
  },
  {
    imageUrl: 'https://sv1.picz.in.th/images/2022/12/08/GVH4sl.jpg',
    thumbnail: 'https://sv1.picz.in.th/images/2022/12/08/GVH4sl.th.jpg',
    url: 'https://sv1.picz.in.th/images/2022/12/08/GVH4sl.jpg',
  },
  {
    imageUrl: 'https://sv1.picz.in.th/images/2022/12/08/GVH4sl.jpg',
    thumbnail: 'https://sv1.picz.in.th/images/2022/12/08/GVH4sl.th.jpg',
    url: 'https://sv1.picz.in.th/images/2022/12/08/GVH4sl.jpg',
  },
];

export const getAdmissionPosterImages = (languague = 'TH', page = 1, perPage = 9) => {
  const url = apiUrlProvider.get(GET_ADMISSION_BANNERS);
  const urlWithQuery = `${url}?page=${page}&perPage=${perPage}&type=admission-banner&lang=${languague}`;
  return fetchGet(urlWithQuery, null, false);
};
