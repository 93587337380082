import React, { useState } from 'react';
import range from 'lodash/range';
import IconWrapper from './IconWrapper';
import ZoomInRoundedIcon from '@mui/icons-material/ZoomInRounded';
import ZoomOutRoundedIcon from '@mui/icons-material/ZoomOutRounded';
import ZoomOutMapRoundedIcon from '@mui/icons-material/ZoomOutMapRounded';
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import printJS from 'print-js';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './pdf.css';
import { getFilenameFromPath } from '../../utils/pdf';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import useWindowDimensions from '../../hooks/useWindowDimension';

pdfjs.GlobalWorkerOptions.workerSrc = `pdf.worker.min.js`;

const PDFViewer = ({ pdf, fileName: originalFilename }) => {
  const { t } = useTranslationUtil();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(1);
  const [showPreviewPageControl, setShowPreviewPageControl] = useState(false);
  const [error, setError] = useState('');
  const { windowDimensions, widthLessThanMD } = useWindowDimensions();

  const fileName = originalFilename ? originalFilename : getFilenameFromPath(pdf);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setError('');
  };

  if (error) {
    console.log(error);
  }

  const iconSize = widthLessThanMD ? 'small' : 'large';

  return (
    <>
      <Document
        file={pdf}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(err) => {
          console.error(err);
          console.log(' error', err);
          setError(err.message);
        }}
      >
        <div className="relative flex flex-col overflow-hidden md:flex-row">
          {showPreviewPageControl && (
            <div
              id="pdf-preview-navigation"
              className=" mr-3 hidden max-h-[800px] w-fit scroll-m-3 overflow-x-clip overflow-y-scroll border-b-2 border-b-Lightgray200 pl-3 md:block"
            >
              {range(1, numPages + 1).map((page) => {
                return (
                  <div
                    key={page}
                    className={`m-3 w-fit  ${
                      pageNumber === page
                        ? 'border-4 border-solid border-NeonPink shadow-md '
                        : 'p-1 hover:cursor-pointer'
                    } `}
                    onClick={() => page !== pageNumber && setPageNumber(page)}
                  >
                    <Page
                      pageNumber={page}
                      width={96}
                      height={135}
                      renderTextLayer={false}
                      onClick={() => page !== pageNumber && setPageNumber(page)}
                      className="mx-auto w-fit rounded-sm shadow-md"
                    />
                  </div>
                );
              })}
            </div>
          )}

          <div className="w-fit">
            <Page
              pageNumber={pageNumber}
              scale={pageScale}
              className="mx-auto rounded-sm shadow-md"
              width={widthLessThanMD ? windowDimensions.width : null}
            />
            <div className="m-auto mt-5 w-fit rounded-sm bg-UIWhite bg-opacity-90 shadow-lg  ">
              <div className="flex flex-row">
                <IconWrapper disabled={pageScale >= 2} onClick={() => setPageScale(pageScale + 0.1)}>
                  <ZoomInRoundedIcon fontSize={iconSize} />
                </IconWrapper>
                <IconWrapper disabled={pageScale <= 0.8} onClick={() => setPageScale(pageScale - 0.1)}>
                  <ZoomOutRoundedIcon fontSize={iconSize} />
                </IconWrapper>
                <IconWrapper onClick={() => setShowPreviewPageControl(!showPreviewPageControl)}>
                  <BookmarkIcon fontSize={iconSize} />
                </IconWrapper>
                <IconWrapper
                  disabled={pageNumber === 1}
                  onClick={() => pageNumber > 1 && setPageNumber(pageNumber - 1)}
                >
                  <FastRewindIcon fontSize={iconSize} />
                </IconWrapper>

                <div className="my-auto flex h-fit flex-row">
                  <div className=" rounded-l-lg bg-UIBlack px-3 py-1 text-UIWhite md:px-5">{pageNumber}</div>
                  <div className=" rounded-r-lg bg-Lightgray700 px-3 py-1 text-UIWhite md:px-5">{numPages}</div>
                </div>
                <IconWrapper
                  disabled={pageNumber === numPages}
                  onClick={() => pageNumber < numPages && setPageNumber(pageNumber + 1)}
                >
                  <FastForwardIcon fontSize={iconSize} />
                </IconWrapper>
                <IconWrapper
                  onClick={() => {
                    fetch(pdf).then((response) => {
                      response.blob().then((blob) => {
                        // Creating new object of PDF file
                        const fileURL = window.URL.createObjectURL(blob);
                        // Setting various property values
                        let alink = document.createElement('a');
                        alink.href = fileURL;
                        alink.download = fileName;
                        alink.click();
                      });
                    });
                  }}
                >
                  <DownloadRoundedIcon fontSize={iconSize} />
                </IconWrapper>

                <IconWrapper
                  onClick={() => {
                    printJS({
                      printable: pdf,
                      type: 'pdf',
                      documentTitle: fileName,
                      onError: (err) => {
                        console.log('print pdf error:', err);
                      },
                      onDocumentLoadSuccess: () => {
                        console.log(' load success');
                      },
                    });
                  }}
                >
                  <LocalPrintshopRoundedIcon fontSize={iconSize} />
                </IconWrapper>
                <a href={pdf} target="_blank" rel="noreferrer">
                  <IconWrapper>
                    <ZoomOutMapRoundedIcon fontSize={iconSize} />
                  </IconWrapper>
                </a>
              </div>
            </div>
          </div>
          {/* Mobile page navigation */}
          {showPreviewPageControl && (
            <div
              id="pdf-preview-navigation-mobile"
              className={`mx-auto mt-8 flex max-w-[360px] gap-x-2 overflow-x-auto px-3 pb-2 md:hidden`}
            >
              {range(1, numPages + 1).map((page) => {
                return (
                  <div
                    key={page}
                    className={`${
                      pageNumber === page ? 'border-4 border-solid border-NeonPink shadow-md ' : ' hover:cursor-pointer'
                    } `}
                    onClick={() => page !== pageNumber && setPageNumber(page)}
                  >
                    <Page
                      pageNumber={page}
                      renderTextLayer={false}
                      width={96}
                      height={135}
                      onClick={() => page !== pageNumber && setPageNumber(page)}
                      className="w-fit rounded-sm shadow-md"
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Document>
      {error && <div className=" mx-auto w-fit text-UIBlack ">{t('CanNotShowPdf')}</div>}
    </>
  );
};

export default PDFViewer;
