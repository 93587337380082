import BackToTopButton from '../../components/Button/BackToTopButton';
import CustomContainer from '../../components/CustomContainer';
import Layout from '../../components/Layout';
import AdmissionAndScholarshipNewsSection from './AdmissionAndScholarshipNewsSection';
import AnnounceImageSliderSection from './AnnounceImageSliderSection';
import BannerSection from './BannerSection';
import FactsAndFiguresSection from './FactsAndFiguresSection';
import FeedbackSection from './FeedbackSection';
import GraduateSchoolNewsSection from './GraduateSchoolNewsSection';
import GraduateSchoolSection from './GraduateSchoolSection';
import JobsNewsSection from './JobsNewsSection';
import LifeAtCmuSection from './LifeAtCmuSection';
import NewsSection from './NewsSection';
import VisitorsSection from './VisitorsSection';
import YourJourneyWithGradCmuSection from './YourJourneyWithGradCmuSection';
import PinkBlurCircleImg from './assets/pink-blur-circle.png';

const HomePage = () => {
  return (
    <Layout showFooter={false}>
      <BannerSection />
      <GraduateSchoolSection />
      <GraduateSchoolNewsSection />
      <YourJourneyWithGradCmuSection />
      <LifeAtCmuSection />
      <br />
      <FeedbackSection />
      <br />
      <AdmissionAndScholarshipNewsSection />
      <div className="relative z-10">
        <AnnounceImageSliderSection />
      </div>
      <CustomContainer className=" -z-10 -mt-60 h-80 overflow-hidden">
        <img src={PinkBlurCircleImg} alt="" className="absolute right-0 -z-10 -mt-20 h-[400px]" />
      </CustomContainer>
      <NewsSection />
      <JobsNewsSection />
      <FactsAndFiguresSection />
      {/* <FeedbackSection /> */}
      <div className="mx-auto w-fit pt-10 pb-[50px]">
        <BackToTopButton />
      </div>
      <VisitorsSection />
    </Layout>
  );
};
export default HomePage;
