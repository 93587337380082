import React from 'react';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import MagnifyImg from './assets/magnify.jpg';
import ReadBookImg from './assets/read-book.jpg';
import WritingImg from './assets/writing.jpg';
import TypingImg from './assets/typing.jpg';
import ThinkingImg from './assets/thinking.svg';
import CollapseItem from '../../components/CollapseSection/CollapseItem';
import WaterMarkGuildPdf from './assets/คู่มือการทำลายน้ำ (Watermark Guideline).pdf';
import WaterMarkImg from './assets/watermark.jpg';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';
import StepSectionContainer from '../../components/AddmissionProcess/StepSectionContainer';
import StepImage from '../../components/AddmissionProcess/StepImage';
import StepDetailContainer from '../../components/AddmissionProcess/StepDetailContainer';
import StepNumberCircle from '../../components/AddmissionProcess/StepNumberCircle';
import StepDescriptionContainer from '../../components/AddmissionProcess/StepDescriptionContainer';
import StepTitle from '../../components/AddmissionProcess/StepTitle';
import StepDescription from '../../components/AddmissionProcess/StepDescription';

const ThesisDissertationIScheckingPage = () => {
  const { t } = useTranslationUtil();

  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{t('ThesisDissertationISchecking')}</PageTitleContainer>
        <div className="lg:mx-auto lg:w-fit">
          <StepSectionContainer>
            <StepImage src={MagnifyImg} alt="" className="lg:-mt-5 " />
            <StepDetailContainer>
              <StepNumberCircle>1</StepNumberCircle>
              <StepDescriptionContainer>
                <StepTitle> {t('SubmitCompleteThesis')}</StepTitle>
                <StepDescription>{t('SubmitCompleteThesisInfo')}</StepDescription>
              </StepDescriptionContainer>
            </StepDetailContainer>
          </StepSectionContainer>
          <StepSectionContainer>
            <StepImage src={ReadBookImg} alt="" />
            <StepDetailContainer>
              <StepNumberCircle>2</StepNumberCircle>
              <StepDescriptionContainer>
                <StepTitle> {t('FileFormat')}</StepTitle>
                <StepDescription>{t('FileFormatInfo')}</StepDescription>
              </StepDescriptionContainer>
            </StepDetailContainer>
          </StepSectionContainer>
          <StepSectionContainer>
            <StepImage src={WritingImg} alt="" />
            <StepDetailContainer>
              <StepNumberCircle>3</StepNumberCircle>
              <StepDescriptionContainer>
                <StepTitle> {t('StaffChecking')}</StepTitle>
                <StepDescription>{t('StaffCheckingInfo')}</StepDescription>
              </StepDescriptionContainer>
            </StepDetailContainer>
          </StepSectionContainer>
          <StepSectionContainer className="py-10">
            <div>
              <img src={TypingImg} alt="" className=" h-[270px] w-full rounded-sm object-cover lg:w-[480px]" />
              {/* <img src={ThinkingImg} alt="" className=" ml-[110px] -mt-20 rounded-sm object-fill" /> */}
            </div>
            <StepDetailContainer className="-mt-10 md:-mt-10 lg:mt-10">
              <StepNumberCircle>4</StepNumberCircle>
              <StepDescriptionContainer>
                <StepTitle> {t('ReviseAndResubmit')}</StepTitle>
                <StepDescription>{t('ReviseAndResubmitInfo')}</StepDescription>
              </StepDescriptionContainer>
            </StepDetailContainer>
          </StepSectionContainer>

          {/* <div className="px-3 md:px-10 lg:px-14 xl:px-20">
            <div className="mx-auto w-fit">
              <div className="text-center text-[40px] font-medium leading-[66px]">{t('AcademicSubmissions')}</div>
              <ul className="ml-5 list-inside">
                <li>
                  1. {t('VisitWeb')}{' '}
                  <a
                    href="http://student.grad.cmu.ac.th"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" text-NeonPink"
                  >
                    http://student.grad.cmu.ac.th
                  </a>
                </li>
                <li>2. {t('SubmitFiles')}</li>
              </ul>

              <div className="mt-12 text-center text-[30px] font-medium leading-9 md:text-[40px] md:leading-[66px]">
                {t('DewateringThesis')}
              </div>
              <CollapseItem jpgUrl={WaterMarkImg} fileName="ภาพสำหรับทำลายน้ำ (Watermark).jpg">
                {t('Watermark')}
              </CollapseItem>
              <CollapseItem pdfUrl={WaterMarkGuildPdf} fileName="คู่มือการทำลายน้ำ (Watermark Guideline).pdf">
                {t('WatermarkGuideline')}
              </CollapseItem>
            </div>
          </div> */}
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default ThesisDissertationIScheckingPage;
