import { Link } from 'react-router-dom';
import { useTranslationUtil } from '../../../../hooks/useTranslationUtil';
import DropDownBlack from './assets/dropdown-black.svg';
import DropDownNeonPink from './assets/dropdown-neonpink.svg';

export function PageNavigation({
  setPageState,
  title,
  stateNumber,
  activePage,
  link,
  showSubmenuContent,
  setShowSubmenuContent,
  showAsButton = false,
  showHighlight = false,
  forceUseThFont = false,
}) {
  const { getFontFamily, getThFontFamily } = useTranslationUtil();
  const isActivePage = activePage === stateNumber && showSubmenuContent;

  const getFont = () => {
    return forceUseThFont ? getThFontFamily() : getFontFamily();
  };

  const getContent = () => {
    return (
      <>
        <div
          className={`${getFont()} text-lg font-bold text-UIBlack ${isActivePage ? 'text-NeonPink' : ''} ${
            showHighlight ? 'text-NeonPink shadow-NeonPink text-shadow-text-change' : ''
          }`}
        >
          {title}
        </div>
        {!showAsButton && (
          <div className="m-auto">
            <img src={isActivePage ? DropDownNeonPink : DropDownBlack} alt="" />
          </div>
        )}
      </>
    );
  };

  return (
    <div
      className="flex w-fit flex-row items-center px-2 text-center hover:cursor-pointer"
      onClick={() => {
        setPageState(stateNumber);
        setShowSubmenuContent(isActivePage ? !showSubmenuContent : true);
      }}
      onMouseEnter={() => {
        setPageState(stateNumber);
        setShowSubmenuContent(true);
      }}
    >
      {showAsButton ? <Link to={link}>{getContent()}</Link> : getContent()}
    </div>
  );
  // }
}
