import React from 'react';
import AdminNavigateButton from './AdminNavigateButton';
import { useTranslationUtil } from '../../../../../hooks/useTranslationUtil';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import routeUrlProvider, { AdminRoutePath } from '../../../../../constants/route-paths';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useContextAuthManager } from '../../../../AuthManager';
import { useToken } from '../../../../../utils/token';

const AdminNavigateBar = () => {
  const { t } = useTranslationUtil();
  const history = useHistory();
  const location = useLocation();
  const { setIsLoggedIn } = useContextAuthManager();
  const { removeToken } = useToken();

  const menuList = [
    {
      name: t('ManageNews'),
      path: routeUrlProvider.getForRoute(AdminRoutePath.ADMIN_NEWS_LIST),
    },
    {
      name: t('ManageBanners'),
      path: routeUrlProvider.getForRoute(AdminRoutePath.ADMIN_BANNER_LIST),
    },
    {
      name: t('ManagePosters'),
      path: routeUrlProvider.getForRoute(AdminRoutePath.ADMIN_POSTER_LIST),
    },
  ];

  return (
    <div className="CustomContainer mb-4 mt-[10px] flex flex-row justify-between overflow-x-auto  border-b-2 border-t-2 border-Lightgray100 bg-Lightgray50 px-16 py-2">
      <div className="mt-2 flex w-fit flex-row gap-x-4">
        {menuList.map((menu) => (
          <AdminNavigateButton
            selected={(() => {
              const { pathname } = location;
              const pathNameRegex = new RegExp(menu.path);
              return pathNameRegex.test(pathname);
            })()}
            onClick={() => history.push(menu.path)}
          >
            {menu.name}
          </AdminNavigateButton>
        ))}
      </div>
      <button
        className=" text font-bold text-NeonPink"
        onClick={() => {
          removeToken();
          setIsLoggedIn(false);
        }}
      >
        {t('logout')}
      </button>
    </div>
  );
};

export default AdminNavigateBar;
