import React from 'react';
import CollapseSection from '../../components/CollapseSection';
import CollapseItem from '../../components/CollapseSection/CollapseItem';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';

import ข้อบังคับมชว่าด้วยการศึกษาระดับบศ2566 from './assets/ข้อบังคับมช-ว่าด้วยการศึกษาระดับ-บศ-2566.pdf';
import ตารางเปรียบเทียบข้อบังคับบศ65สภา241265 from './assets/1-ตารางเปรียบเทียบข้อบังคับ-บศ-65-สภา-24-12-65.pdf';
import cmu_rule2 from './assets/cmu_rule2.pdf';
import CMUGEReg59Bilingual from './assets/CMU-GEReg-59-Bilingual.pdf';
import GERegulationComparison20160901 from './assets/GERegulationComparison20160901.pdf';
import GERegulation2559002 from './assets/GERegulation2559-002.pdf';
import manual55 from './assets/manual55.pdf';
import Binder1 from './assets/Binder1.pdf';
import GEForeignLangCond2020 from './assets/ขึ้นเว็บ-ต.ค.65.pdf';
import GEForeignLangCond2019 from './assets/GE-ForeignLangCond2019.pdf';
import GEForeignLangCond2016 from './assets/GE-ForeignLangCond2016.pdf';
import เกณฑ์และแนวปฏิบัติการเผยแพร่ผลงานปริญญา from './assets/เกณฑ์และแนวปฏิบัติการเผยแพร่ผลงานปริญญา-1.pdf';
import GradStudentType20230213 from './assets/GradStudentType20230213.pdf';
import ประกาศการโอนปี2564กย641 from './assets/1-ประกาศการโอน-ปี-2564-ก.ย.641.pdf';
import Ann16632020Extend from './assets/Ann1663-2020Extend.pdf';
import CMUGS_Announcement_on_Student_Status_Restoration from './assets/20160411_CMU-GS_Announcement_on_Student_Status_Restoration.pdf';
import CMUGS_Announcement_about_Resignation from './assets/20170403_CMU-GS_Announcement_about_Resignation.pdf';
import publish_7 from './assets/publish_7.pdf';
import AFWW3U1F7K7S from './assets/AFWW3U1F7K7S.pdf';
import pr111 from './assets/pr111.pdf';
import ค่าธรรมเนียมการศึกษาวิจัยและการใช้บริการมหาวิทยาลัย64 from './assets/20211125090433.pdf';
import แนวทางบริการค่าธรรมเนียมสมทบ from './assets/แนวทางบริการค่าธรรมเนียมสมทบ.pdf';
import ประการเทียบโอนหน่วยกิตLE from './assets/ประการเทียบโอนหน่วยกิต-LE.pdf';
import ส่วนลดค่าธรรมเนียมLE from './assets/ส่วนลดค่าธรรมเนียม-LE.pdf';
import CMU_Announcement_on_Using_UServices from './assets/20120120_CMU_Announcement_on_Using_U-Services.pdf';
import ลงทะเบียนข้ามส่วนงาน from './assets/123456789-ลงทะเบียนข้ามส่วนงาน.pdf';
import NewThesis64 from './assets/New-Thesis-64.pdf';
import StepandGuidelineinDoingThesisno11 from './assets/Step-and-Guideline-in-Doing-Thesis-no11.pdf';
import ประกาศบัณฑิตวิทยาลัยลงนาม12กค64 from './assets/ENG_-Thai-ประกาศบัณฑิตวิทยาลัย-ลงนาม12ก.ค.64-Note.pdf';
import ทรัพย์สินทางปัญญาipmgmt2563 from './assets/ทรัพย์สินทางปัญญา-ip-mgmt-2563.pdf';
import CmuR57 from './assets/CmuR57.pdf';
import rule2554 from './assets/rule2554.pdf';
import CMURegulations from './assets/CMURegulations.pdf';
import เปรียบเทียบข้อบังคับมหาวิทยาลัยเชียงใหม่ from './assets/50_54.pdf';
import rule2550 from './assets/rule2550.pdf';
import new10 from './assets/new10.pdf';
import การพิจารณาเกียรติและศักดิ์ของนักศึกษา from './assets/291150.pdf';
import cms from './assets/cms.pdf';
import cmu54 from './assets/cmu54.pdf';
import WS0OIOMA from './assets/WS0OIOMA.pdf';
import WP10VHBQ from './assets/WP10VHBQ.pdf';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';
import CollapseSectionContainer from '../../components/CollapseSection/CollapseSectionContainer';

const AnnouncementsRegulationsGuidelinesPage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{t('ข้อบังคับ')}</PageTitleContainer>
        <CollapseSectionContainer>
          <CollapseSection title={t('ประกาศและข้อบังคับที่ใช้กับนักศึกษา รหัส 59 เป็นต้นไป')}>
            <CollapseItem pdfUrl={ข้อบังคับมชว่าด้วยการศึกษาระดับบศ2566}>
              {t('ข้อบังคับมหาวิทยาลัยเชียงใหม่ ว่าด้วยการศึกษาระดับบัณฑิตศึกษา พ.ศ.2566')}
            </CollapseItem>
            <CollapseItem pdfUrl={ตารางเปรียบเทียบข้อบังคับบศ65สภา241265}>
              {t(
                'ตารางเปรียบเทียบข้อบังคับมหาวิทยาลัยเชียงใหม่ ว่าด้วยการศึกษาระดับบัณฑิตศึกษา พ.ศ. 2559 และฉบับที่ 2 พ.ศ.2561 กับข้อบังคับฯ พ.ศ.2566'
              )}
            </CollapseItem>
            <CollapseItem pdfUrl={cmu_rule2}>
              {t('ข้อบังคับมหาวิทยาลัยเชียงใหม่ ว่าด้วยการศึกษาระดับบัณฑิตศึกษา (ฉบับที่ 2) ปีการศึกษา 2561')}
            </CollapseItem>
            <CollapseItem pdfUrl={CMUGEReg59Bilingual}>
              {t('ข้อบังคับมหาวิทยาลัยเชียงใหม่ ว่าด้วยการศึกษาระดับบัณฑิตศึกษา ปีการศึกษา 2559')}
            </CollapseItem>
            <CollapseItem pdfUrl={GERegulationComparison20160901}>
              {t(
                'ตารางเปรียบเทียบข้อบังคับมหาวิทยาลัยเชียงใหม่ ว่าด้วยการศึกษาระดับบัณฑิตศึกษา 2554 และฉบับที่ 2 พ.ศ.2557 กับข้อบังคับฯ พ.ศ.2559'
              )}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('คู่มือการศึกษา')}>
            <CollapseItem pdfUrl={GERegulation2559002}>
              {t('คู่มือการศึกษาขั้นบัณฑิตศึกษา ปีการศึกษา 2559')}
            </CollapseItem>
            <CollapseItem pdfUrl={manual55}>{t('คู่มือการศึกษาขั้นบัณฑิตศึกษา ปีการศึกษา 2555')}</CollapseItem>
            <CollapseItem pdfUrl={Binder1}>{t('คู่มือการศึกษาขั้นบัณฑิตศึกษา ปีการศึกษา 2554')}</CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('ข้อบังคับ/ประกาศ/แนวปฏิบัติ สำหรับนักศึกษา')}>
            <CollapseItem pdfUrl={GEForeignLangCond2020}>
              {t(
                'เงื่อนไขภาษาต่างประเทศสำหรับนักศึกษาระดับบัณฑิตศึกษา สำหรับนักศึกษาที่เข้าศึกษาปีการศึกษา 2564 เป็นต้นไป'
              )}
            </CollapseItem>
            <CollapseItem pdfUrl={GEForeignLangCond2019}>
              {t('เงื่อนไขภาษาต่างประเทศสำหรับนักศึกษาระดับบัณฑิตศึกษา สำหรับนักศึกษาที่เข้าปีการศึกษา 2563 เป็นต้นไป')}
            </CollapseItem>
            <CollapseItem pdfUrl={GEForeignLangCond2016}>
              {t('เงื่อนไขภาษาต่างประเทศสำหรับนักศึกษาระดับบัณฑิตศึกษา สำหรับนักศึกษาที่เข้าปีการศึกษา 2559-2562')}
            </CollapseItem>
            <CollapseItem pdfUrl={เกณฑ์และแนวปฏิบัติการเผยแพร่ผลงานปริญญา}>
              {t('เกณฑ์และแนวปฏิบัติการเผยแพร่ปริญญานิพนธ์')}
            </CollapseItem>
            <CollapseItem pdfUrl={GradStudentType20230213}>
              {t('แนวปฏิบัติการรับและการเปลี่ยนประเภทนักศึกษาระดับบัณฑิตศึกษา')}
            </CollapseItem>
            <CollapseItem pdfUrl={ประกาศการโอนปี2564กย641}>
              {t(
                'แนวปฏิบัติการเปลี่ยนแบบการศึกษา การย้ายสาขาวิชา การรับโอนนักศึกษา และการเทียบโอนหน่วยกิต ของนักศึกษาบัณฑิตศึกษา ฉบับที่ 36 / 2564'
              )}
            </CollapseItem>
            <CollapseItem pdfUrl={Ann16632020Extend}>
              {t('แนวปฏิบัติการขออนุโลมการขยายเวลาการศึกษาระดับบัณฑิตศึกษา ')}
            </CollapseItem>
            <CollapseItem pdfUrl={CMUGS_Announcement_on_Student_Status_Restoration}>
              {t('เงื่อนไขและแนวปฏิบัติการขอคืนสภาพการเป็นนักศึกษาระดับบัณฑิตศึกษา')}
            </CollapseItem>
            <CollapseItem pdfUrl={CMUGS_Announcement_about_Resignation}>
              {t('แนวปฏิบัติการลาออกจากการเป็นนักศึกษาระดับบัณฑิตศึกษา')}
            </CollapseItem>
            <CollapseItem pdfUrl={publish_7}>
              {t(
                'แนวปฏิบัติการตรวจสอบนักศึกษาบัณฑิตศึกษาพ้นสภาพเมื่อไม่มาลงทะเบียนเรียน ภายในระยะเวลาที่มหาวิทยาลัยกำหนด และมิได้ลาพักการศึกษาภายใน ๓๐ วัน นับจากวันเปิดภาคการศึกษาปกติ'
              )}
            </CollapseItem>
            <CollapseItem pdfUrl={AFWW3U1F7K7S}>
              {t('แนวปฏิบัติเกี่ยวกับการเสนอขออนุมัติหลังกำหนดเป็นกรณีพิเศษอันเนื่องมาจากเหตุสุดวิสัย')}
            </CollapseItem>
            <CollapseItem pdfUrl={pr111}>
              {t(
                'แนวปฏิบัติการสมัครสอบประมวลความรู้ และสมัครสอบวัดคุณสมบัติของนักศึกษาระดับบัณฑิตศึกษาหลักสูตรแบบเหมาจ่าย '
              )}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection
            title={t(
              'ประกาศ/ระเบียบ/แนวปฏิบัติ มหาวิทยาลัยเชียงใหม่  เกี่ยวกับนักศึกษาสมทบ/การเรียนข้ามสถาบัน/การเรียนระบบการศึกษาตลอดชีวิต'
            )}
          >
            <CollapseItem pdfUrl={ค่าธรรมเนียมการศึกษาวิจัยและการใช้บริการมหาวิทยาลัย64}>
              {t('ค่าธรรมเนียมการศึกษา วิจัย และการใช้บริการมหาวิทยาลัยสำหรับนักศึกษาสมทบระดับบัณฑิตศึกษา พ.ศ. 2564')}
            </CollapseItem>
            <CollapseItem pdfUrl={แนวทางบริการค่าธรรมเนียมสมทบ}>
              {t('แนวทางการบริหารค่าธรรมเนียมการศึกษาสำหรับนักศึกษาสมทบระดับบัณฑิตศึกษา')}
            </CollapseItem>
            <CollapseItem pdfUrl={ประการเทียบโอนหน่วยกิตLE}>
              {t(
                'การโอนและการเทียบโอนหน่วยกิตของนักศึกษาระดับบัณฑิตศึกษา มหาวิทยาลัยเชียงใหม่ จากระบบธนาคารหน่วยกิต (Credit Bank)'
              )}
            </CollapseItem>
            <CollapseItem pdfUrl={ส่วนลดค่าธรรมเนียมLE}>
              {t(
                'ส่วนลดค่าธรรมเนียมการศึกษาระดับบัณฑิตศึกษาสำหรับผู้เรียนที่โอนหน่วยกิตจากธนาคารหน่วยกิตในระบบการศึกษาตลอดชีวิต มหาวิทยาลัยเชียงใหม่ พ.ศ. 2564 '
              )}
            </CollapseItem>
            <CollapseItem pdfUrl={CMU_Announcement_on_Using_UServices}>
              {t('การลงทะเบียนเพื่อใช้บริการของมหาวิทยาลัยสำหรับนักศึกษาระดับบัณฑิตศึกษา')}
            </CollapseItem>
            <CollapseItem pdfUrl={ลงทะเบียนข้ามส่วนงาน}>
              {t('แนวทางการบริหารเงินค่าธรรมเนียมการศึกษาระดับบัณฑิตศึกษา กรณีที่นักศึกษาลงทะเบียนเรียนข้ามส่วนงาน')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection
            title={t('ประกาศบัณฑิตวิทยาลัย มหาวิทยาลัยเชียงใหม่ เกี่ยวกับวิทยานิพนธ์และการค้นคว้าแบบอิสระ')}
          >
            <CollapseItem pdfUrl={NewThesis64}>{t('ขั้นตอนและแนวปฏิบัติการทำปริญญานิพนธ์ 53-2564')}</CollapseItem>
            <CollapseItem pdfUrl={StepandGuidelineinDoingThesisno11}>
              {t('ขั้นตอนและแนวปฏิบัติการทำปริญญานิพนธ์ (ฉบับที่ 1) ')}
            </CollapseItem>
            <CollapseItem pdfUrl={ประกาศบัณฑิตวิทยาลัยลงนาม12กค64}>
              {t('มาตรการป้องกันการทุจริตในการศึกษาและการละเมิดทรัพย์สินทางปัญญาในปริญญานิพนธ์ พ.ศ. 2564')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('ข้อบังคับมหาวิทยาลัยเชียงใหม่')}>
            <CollapseItem pdfUrl={CmuR57}>
              {t('ข้อบังคับมหาวิทยาลัยเชียงใหม่ ว่าด้วยการศึกษาระดับบัณฑิตศึกษา (ฉบับที่2) พ.ศ. 2557')}
            </CollapseItem>
            <CollapseItem pdfUrl={rule2554}>
              {t('ข้อบังคับมหาวิทยาลัยเชียงใหม่ ว่าด้วยการศึกษาระดับบัณฑิตศึกษา พ.ศ.2554')}
            </CollapseItem>
            <CollapseItem pdfUrl={CMURegulations}>
              {t('Chiang Mai University Regulations on Graduate Study, 2011')}
            </CollapseItem>
            <CollapseItem pdfUrl={เปรียบเทียบข้อบังคับมหาวิทยาลัยเชียงใหม่}>
              {t(
                'เปรียบเทียบข้อบังคับมหาวิทยาลัยเชียงใหม่ ว่าด้วยการศึกษาระดับบัณฑิตศึกษา พ.ศ.2550 กับ ฉบับปี พ.ศ. 2554'
              )}
            </CollapseItem>
            <CollapseItem pdfUrl={rule2550}>
              {t('ข้อบังคับมหาวิทยาลัยเชียงใหม่ ว่าด้วยการศึกษาระดับบัณฑิตศึกษา พ.ศ.2550')}
            </CollapseItem>
            <CollapseItem pdfUrl={new10}>
              {t('ข้อบังคับมหาวิทยาลัยเชียงใหม่ ว่าด้วยการศึกษาระดับบัณฑิตศึกษา พ.ศ.2547')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('ข้อบังคับเกียรติและศักดิ์/วินัยนักศึกษา')}>
            <CollapseItem pdfUrl={การพิจารณาเกียรติและศักดิ์ของนักศึกษา}>
              {t('ข้อบังคับมหาวิทยาลัยเชียงใหม่ ว่าด้วยการพิจารณาเกียรติและศักดิ์ของนักศึกษาฯ พ.ศ.2550')}
            </CollapseItem>
            <CollapseItem pdfUrl={cms}>{t('ข้อบังคับมหาวิทยาลัยเชียงใหม่ ว่าด้วยวินัยนักศึกษา พ.ศ.2553')}</CollapseItem>
            <CollapseItem pdfUrl={cmu54}>
              {t(
                'ประกาศ มหาวิทยาลัยเชียงใหม่ เรื่อง ข้อกำหนดพฤติกรรมด้านคุณธรรมจริยธรรมของ นักศึกษามหาวิทยาลัยเชียงใหม่'
              )}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('หลักเกณฑ์ วิธีการรับรองคุณวุฒิและการกำหนดอัตราเงินเดือนของ สำนักงาน ก.พ.')}>
            <CollapseItem pdfUrl={WS0OIOMA}>{t('พ.ศ.2548')}</CollapseItem>
            <CollapseItem pdfUrl={WP10VHBQ}>{t('พ.ศ.2550')}</CollapseItem>
            <CollapseItem pdfUrl={WP10VHBQ}>{t('พ.ศ.2551')}</CollapseItem>
          </CollapseSection>

          <CollapseItem pdfUrl={ทรัพย์สินทางปัญญาipmgmt2563}>
            {t('ระเบียบว่าด้วยการจัดการทรัพย์สินทางปัญญา พ.ศ. 2563 ')}
          </CollapseItem>
        </CollapseSectionContainer>
      </CustomContainer>
    </Layout>
  );
};

export default AnnouncementsRegulationsGuidelinesPage;
