import { createContext, useContext } from 'react';
import { useAuth } from '../../utils/auth';

export const AuthManagerContext = createContext({});

const AuthManager = ({ children, publicPath, privatePath }) => {
  const [{ isLoggedIn, setIsLoggedIn, user }, isLoading] = useAuth();

  return (
    <AuthManagerContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        publicPath: publicPath,
        privatePath: privatePath,
        user,
      }}
    >
      {!isLoading && children}
    </AuthManagerContext.Provider>
  );
};

export const useContextAuthManager = () => {
  return useContext(AuthManagerContext);
};

export default AuthManager;
