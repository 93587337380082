import React from 'react';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import {
  INTERDISCIPLINARY_PROGRAMS_BIOTECTHNOLOGY,
  INTERDISCIPLINARY_PROGRAMS_FORENSICS_SCIENCE,
  INTERDISCIPLINARY_PROGRAMS_MAN_AND_ENVIRONMENTAL,
  INTERDISCIPLINARY_PROGRAMS_SPORT_SCIENCE,
  INTERDISCIPLINARY_PROGRAMS_TECHNOLOGY_AND_INTERDISCIPLINARY,
} from '../../constants/route-paths';
import InterdisciplinaryProgramCard from '../../components/InterdisciplinaryProgramCard';
import ManAndEnvironmentalImg from '../InterdisciplinaryPrograms/assets/ManAndEnvironmental.jpg';
import BiotechnologyImg from '../InterdisciplinaryPrograms/assets/Biotechnology.jpg';
import TechnologyAndInterImg from '../InterdisciplinaryPrograms/assets/TechnologyAndInterdisciplinaryManagement.jpg';
import ForensicScienceImg from '../InterdisciplinaryPrograms/assets/ForensicScience.jpg';
import SportsScienceImg from '../InterdisciplinaryPrograms/assets/SportsScience.jpg';
import ColorGradientImg from '../InterdisciplinaryPrograms/assets/ColorGradient.png';
import BiomedicalEngineeringInstituteImg from './assets/BiomedicalEngineeringInstitute.jpg';
import ChiangMaiUniversitySchoolofPublicPolicyImg from './assets/ChiangMaiUniversitySchoolofPublicPolicy.jpg';
import CollegeofArtMediaandTechnologyImg from './assets/CollegeofArt,MediaandTechnology.jpg';
import CollegeofMarineStudiesandManagementImg from './assets/CollegeofMarineStudiesandManagement.jpg';
import FacultyofAgroIndustryImg from './assets/FacultyofAgro-Industry.jpg';
import FacultyofArchitectureImg from './assets/FacultyofArchitecture.jpg';
import FacultyofAgricultureImg from './assets/FacultyofAgriculture.jpg';
import FacultyofAssociatedMedicalScienceImg from './assets/FacultyofAssociatedMedicalScience.jpg';
import FacultyofBusinessAdministrationImg from './assets/FacultyofBusinessAdministration.jpg';
import FacultyofDentistryImg from './assets/FacultyofDentistry.jpg';
import FacultyofEconomicsImg from './assets/FacultyofEconomics.jpg';
import FacultyofEducationImg from './assets/FacultyofEducation.jpg';
import FacultyofEngineeringImg from './assets/FacultyofEngineering.jpg';
import FacultyofFineArtsImg from './assets/FacultyofFineArts.jpg';
import FacultyofHumanitiesImg from './assets/FacultyofHumanities.jpg';
import FacultyofLawImg from './assets/FacultyofLaw.jpg';
import FacultyofMassCommunicationImg from './assets/FacultyofMassCommunication.jpg';
import FacultyofMedicineImg from './assets/FacultyofMedicine.jpg';
import FacultyofNursingImg from './assets/FacultyofNursing.jpg';
import FacultyofPharmacyImg from './assets/FacultyofPharmacy.jpg';
import FacultyofPoliticalScienceandPublicAdministrationImg from './assets/FacultyofPoliticalScienceandPublicAdministration.jpg';
import FacultyofPublicHealthImg from './assets/FacultyofPublicHealth.jpg';
import FacultyofScienceImg from './assets/FacultyofScience.jpg';
import FacultyofSocialSciencesImg from './assets/FacultyofSocialSciences.jpg';
import FacultyofVeterinaryMedicalImg from './assets/FacultyofVeterinaryMedical.jpg';
import InternationalCollegeofDigitalInnovationImg from './assets/InternationalCollegeofDigitalInnovation.jpg';
import PostharvestTechnologyInnovationCenterImg from './assets/PostharvestTechnologyInnovationCenter.jpg';
import ResearchInstituteforHealthSciencesImg from './assets/ResearchInstituteforHealthSciences.jpg';
import CoverImg from './assets/cover.jpg';
import FacultyCard from './components/FacultyCard';
import GroupContainer from './components/GroupContainer';
import GroupTitle from './components/GroupTitle';
import InterdisciplinaryProgramCardWraper from './components/InterdisciplinaryProgramCardWraper';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainerWithBackgroundImage from '../../components/PageTitleContainerWithBackgroundImage';

const SearchByFacultyPage = () => {
  const { t } = useTranslationUtil();

  return (
    <Layout headerTransparent>
      <CustomContainer>
        <PageTitleContainerWithBackgroundImage coverImage={CoverImg}>
          <div className=" text-[40px] md:text-5xl">{t('SearchByFaculty')}</div>
        </PageTitleContainerWithBackgroundImage>

        <div className=" pb-[88px]">
          <GroupTitle> {t('HealthScienceGroup')}</GroupTitle>
          <GroupContainer>
            <FacultyCard
              image={FacultyofDentistryImg}
              name={t('FacultyOfDentistry')}
              url="https://www.cmu.ac.th/th/faculty/dentistry/course"
            />
            <FacultyCard
              image={FacultyofAssociatedMedicalScienceImg}
              name={t('FacultyOfAssociatedMedicalScience')}
              url="https://www.cmu.ac.th/th/faculty/associated_medical_sciences/course"
            />
            <FacultyCard
              image={FacultyofNursingImg}
              name={t('FacultyOfNursing')}
              url="https://www.cmu.ac.th/th/faculty/nursing/course"
            />
            <FacultyCard
              image={FacultyofMedicineImg}
              name={t('FacultyOfMedicine')}
              url="https://www.cmu.ac.th/th/faculty/medicine/course"
            />
            <FacultyCard
              image={FacultyofPharmacyImg}
              name={t('FacultyOfPharmacy')}
              url="https://www.cmu.ac.th/th/faculty/pharmacy/course"
            />
            <FacultyCard
              image={FacultyofVeterinaryMedicalImg}
              name={t('FacultyOfVeterinaryMedical')}
              url="https://www.cmu.ac.th/th/faculty/veterinary_medicine/course"
            />
            <FacultyCard
              image={FacultyofPublicHealthImg}
              name={t('FacultyOfPublicHealth')}
              url="https://www.cmu.ac.th/th/faculty/public_health/course"
            />
            <FacultyCard
              image={ResearchInstituteforHealthSciencesImg}
              name={t('ResearchInstituteForHealthSciences')}
              url="https://www.cmu.ac.th/th/Organization/research_institute_for_health_sciences/aboutus"
            />
          </GroupContainer>
        </div>
        <div className=" bg-Lightgray50 pt-14 pb-[88px]">
          <GroupTitle>{t('ScienceAndTechnologyGroup')}</GroupTitle>
          <GroupContainer>
            <FacultyCard
              image={FacultyofAgricultureImg}
              name={t('FacultyOfAgriculture')}
              url="https://www.cmu.ac.th/th/faculty/agriculture/course"
            />
            <FacultyCard
              image={FacultyofScienceImg}
              name={t('FacultyOfScience')}
              url="https://www.cmu.ac.th/th/faculty/science/course"
            />
            <FacultyCard
              image={FacultyofEngineeringImg}
              name={t('FacultyOfEngineering')}
              url="https://www.cmu.ac.th/th/faculty/engineering/course"
            />
            <FacultyCard
              image={FacultyofArchitectureImg}
              name={t('FacultyOfArchitecture')}
              url="https://www.cmu.ac.th/th/faculty/architecture/course"
            />
            <FacultyCard
              image={FacultyofAgroIndustryImg}
              name={t('FacultyOfAgro-Industry')}
              url="https://www.cmu.ac.th/th/faculty/agro_industry/course"
            />
            <FacultyCard
              image={CollegeofMarineStudiesandManagementImg}
              name={t('CollegeOfMarineStudiesAndManagement')}
              url="https://www.cmu.ac.th/th/faculty/college_of_marine_studies_and_management/course"
            />
            <FacultyCard
              image={InternationalCollegeofDigitalInnovationImg}
              name={t('InternationalCollegeOfDigitalInnovation')}
              url="https://www.cmu.ac.th/th/faculty/international_college_and_digital_innovation/course"
            />
            <FacultyCard
              image={CollegeofArtMediaandTechnologyImg}
              name={t('CollegeOfArtMediaAndTechnology')}
              url="https://www.cmu.ac.th/th/faculty/the_college_of_arts_media_and_technology/course"
            />
            <FacultyCard
              image={BiomedicalEngineeringInstituteImg}
              name={t('BiomedicalEngineeringInstitute')}
              url="https://www.cmu.ac.th/th/faculty/biomedical_engineering_center/course"
            />


          </GroupContainer>
        </div>
        <div className="pb-20">
          <GroupTitle>{t('HumanitiesAndSocialScienceGroup')}</GroupTitle>
          <GroupContainer>
            <FacultyCard
              image={FacultyofMassCommunicationImg}
              name={t('FacultyOfMassCommunication')}
              url="https://www.cmu.ac.th/th/faculty/mass_communication/course"
            />
            <FacultyCard
              image={FacultyofLawImg}
              name={t('FacultyOfLaw')}
              url="https://www.cmu.ac.th/th/faculty/law/course"
            />
            <FacultyCard
              image={FacultyofBusinessAdministrationImg}
              name={t('FacultyOfBusinessAdministration')}
              url="https://www.cmu.ac.th/th/faculty/business_administration/course"
            />
            <FacultyCard
              image={FacultyofHumanitiesImg}
              name={t('FacultyOfHumanities')}
              url="https://www.cmu.ac.th/th/faculty/humanities/course"
            />
            <FacultyCard
              image={FacultyofPoliticalScienceandPublicAdministrationImg}
              name={t('FacultyOfPoliticalScienceAndPublicAdministration')}
              url="https://www.cmu.ac.th/th/faculty/political_science_and_public_administration/course"
            />
            <FacultyCard
              image={FacultyofFineArtsImg}
              name={t('FacultyOfFineArts')}
              url="https://www.cmu.ac.th/th/faculty/fine_arts/course"
            />
            <FacultyCard
              image={FacultyofEducationImg}
              name={t('FacultyOfEducation')}
              url="https://www.cmu.ac.th/th/faculty/education/course"
            />
            <FacultyCard
              image={FacultyofEconomicsImg}
              name={t('FacultyOfEconomics')}
              url="https://www.cmu.ac.th/th/faculty/economics/course"
            />
            <FacultyCard
              image={FacultyofSocialSciencesImg}
              name={t('FacultyOfSocialSciences')}
              url="https://www.cmu.ac.th/th/faculty/social_sciences/course"
            />
            <FacultyCard
              image={ChiangMaiUniversitySchoolofPublicPolicyImg}
              name={t('ChiangMaiUniversitySchoolOfPublicPolicy')}
              url="https://www.cmu.ac.th/th/faculty/school_of_public_policy/course"
              startCols2
            />
          </GroupContainer>
        </div>

        <div className="bg-Lightgray50 pt-12 ">
          <GroupTitle>
            <div className="mx-auto text-center"> {t('MultidisciplinaryProgram')}</div>
            <div className="mx-auto text-center">{t('Graduate School')}</div>
          </GroupTitle>
          <GroupContainer>
            <FacultyCard
              name={t('Graduate School')}
              url="https://www.cmu.ac.th/th/faculty/the_graduate_school/course"
              startCols2
            />
          </GroupContainer>

          <div className="  -mt-[500px] flex h-[600px] w-full flex-row justify-end overflow-hidden">
            <img src={ColorGradientImg} alt="" className=" h-[917px] w-[917px]" />
          </div>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default SearchByFacultyPage;
