import React from 'react';
import UserCommentIcon from '../../../../components/Icons/UserCommentIcon';
import { useHistory } from 'react-router';
import routeUrlProvider, { NEWS_DETAIL } from '../../../../constants/route-paths';
import { useTranslationUtil } from '../../../../hooks/useTranslationUtil';
import { DefaultNoImage } from '../../../../components/Noimage';

const NewsCard = ({ newsData }) => {
  const { isThai, formatDate, getFontFamily } = useTranslationUtil();
  const history = useHistory();

  const { imagesEn, imagesTh, publisher, createdAt, titleTh, titleEn, id } = newsData;

  const images = isThai() ? imagesTh : imagesEn;
  const [imgObj] = images;
  const imageUrl = imgObj ? imgObj.imageUrl : DefaultNoImage;
  const title = isThai() ? titleTh : titleEn;

  const onClick = () => {
    history.push(routeUrlProvider.getForLink(NEWS_DETAIL, { id }), { newsData });
  };

  return (
    <div className="mx-auto mt-14 w-[332px] hover:cursor-pointer" onClick={onClick}>
      <img src={imageUrl} alt={title} className="h-[187px] w-[332px] rounded-[2px] object-contain" />
      <p className="mt-[32px] max-h-[60px]  text-[18px] font-bold leading-[29.7px] text-UIBlack line-clamp-2">
        {title}
      </p>
      <div className="mt-[18px] flex flex-row justify-between">
        <div className="my-auto flex flex-row items-center">
          <UserCommentIcon />
          <div className=" ml-[10px] font-dosis text-xs font-extrabold leading-[23px] text-Lightgray800">
            {publisher}
          </div>
        </div>
        <div className={`${getFontFamily(true)} my-auto text-xs leading-[23px] text-Lightgray800`}>
          {formatDate(createdAt)}
        </div>
      </div>
    </div>
  );
};

export default NewsCard;
