import { useState } from 'react';
import ComingSoonModal from '../../../../components/Modal/ComingSoonModal';
import { DefaultNoImage } from '../../../../components/Noimage';

const FacultyCard = ({ name, image = DefaultNoImage, url = '', startCols2 = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onRequestClose = () => {
    setIsOpen(false);
  };

  const hasUrl = url && url !== '';

  const onClickCard = () => {
    if (!isOpen && hasUrl) {
      window.open(url, '_blank', 'noopener,noreferrer');
    } else if (!isOpen) {
      setIsOpen(true);
    }
  };

  return (
    <div
      className={`${
        startCols2 ? ' lg:col-start-2' : ''
      } flex flex-col justify-end hover:cursor-pointer hover:text-GradPink`}
      onClick={onClickCard}
    >
      <div className="text-center text-2xl font-semibold leading-[30px] md:text-left ">{name}</div>
      <div className="mt-4 w-fit bg-search-by-faculty-img-filter hover:bg-none">
        <img src={image} alt="" className="mix-blend-multiply " />
      </div>
      <ComingSoonModal isOpen={isOpen} onRequestClose={onRequestClose} />
    </div>
  );
};

export default FacultyCard;
