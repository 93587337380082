import React from 'react';
import CollapseSection from '../../components/CollapseSection';
import CollapseItem from '../../components/CollapseSection/CollapseItem';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import _20220323122341 from './assets/20220323122341.pdf';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';
import CollapseSectionContainer from '../../components/CollapseSection/CollapseSectionContainer';

const GraduateStudiesCommitteeAndAdministrationPage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{'คณะกรรมการและการบริหารงานบัณฑิตศึกษา'}</PageTitleContainer>
        <CollapseSectionContainer>
          <CollapseSection title={'ประกาศมหาวิทยาลัยเชียงใหม่'}>
            <CollapseItem pdfUrl={_20220323122341}>
              {'เรื่อง  คณะกรรมการและการบริหารงานบัณฑิตศึกษา (ฉบับที่ 3) พ.ศ. 2559 '}
            </CollapseItem>
          </CollapseSection>
        </CollapseSectionContainer>
      </CustomContainer>
    </Layout>
  );
};

export default GraduateStudiesCommitteeAndAdministrationPage;
