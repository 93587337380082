export const MOCK_ACTIVITY_SCHOLARSHIP_PROGRESS_BAR_GROUP_BY_AMOUNT_LEFT = [
  {
    name_th: 'วิทยาศาสตร์และเทคโนโลยี',
    name_en: 'Science and Technology',
    total: 30000,
    budget_used: 219701,
    budget_used_percent: '36.62%',
    budget_left: 380299,
    budget_left_percent: '63.38%',
  },
];
