import React from 'react';

const VERTICAL_SPACE = 20;
const TEXT_TOO_LONG_THRESHOLD = 25;

const CustomXTick = (props) => {
  const {
    x,
    y,
    payload: { value },
    verticalSpace = VERTICAL_SPACE,
    textTooLongThreshold = TEXT_TOO_LONG_THRESHOLD,
  } = props;
  let values = [value];

  const isLengthTooLong = value.length && value.length > textTooLongThreshold;

  if (isLengthTooLong) {
    values = value.split(' ');
  }

  return (
    <g transform={`translate(${x},${y})`} className="fill-Lightgray600">
      {values.map((string, index) => renderText({ string, index, verticalSpace }))}
    </g>
  );
};

const renderText = ({ string, index, verticalSpace }) => {
  if (index === 0) {
    return (
      <text x={0} y={0} textAnchor="middle">
        {string}
      </text>
    );
  } else {
    return (
      <text x={0} y={(index - 1) * verticalSpace} textAnchor="middle" dy={verticalSpace}>
        {string}
      </text>
    );
  }
};

export default CustomXTick;
