import React from 'react';
import Layout from '../../components/Layout';
import CoverImage from './assets/cover.jpg';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import ProspectusPDF from './assets/Graduate-School_Prospectus-23-8-64.pdf';
import PDFViewer from '../../components/PDFViewer';
import CustomContainer from '../../components/CustomContainer';

const ProspectusPage = () => {
  const { t } = useTranslationUtil();

  return (
    <Layout>
      <div className="bg-Lightgray100">
        <CustomContainer>
          <div className="relative h-[304px] bg-pink-big">
            <img src={CoverImage} className="h-full w-full object-cover mix-blend-multiply" alt="" />
            <div className="absolute top-[192px] left-[80px] text-[56px] font-medium text-UIWhite">
              {t('Nav_Prospectus')}
            </div>
          </div>
          <div className="m-auto min-h-[1010px] w-fit items-center py-11">
            <PDFViewer pdf={ProspectusPDF} fileName="Graduate-School_Prospectus-23-8-64.pdf" />
          </div>
        </CustomContainer>
      </div>
    </Layout>
  );
};

export default ProspectusPage;
