import { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
// import HealtScienceImage from './assets/HealtScience.png';
// import CmuSchoolLifelongEducationImage from './assets/CmuSchoolLifelongEducation.png';
// import HumanitiesandSocialScienceGroupImage from './assets/HumanitiesandSocialScienceGroup.png';
// import ScienceandTechnologyGroupImage from './assets/ScienceandTechnologyGroup.png';
// import MultidisciplinaryProgramImage from './assets/MultidisciplinaryProgram.png';
import { getBannerImages } from '../../../../services/bannerImage/getBannerImages';

import BackgroundImg from './../assets/Background.png';
import CustomContainer from '../../../../components/CustomContainer';
import { useTranslationUtil } from '../../../../hooks/useTranslationUtil';
// const dataList = [
//   {
//     imageUrl: HealtScienceImage,
//     thumbnail: 'https://sv1.picz.in.th/images/2022/12/06/GnL3aN.th.png',
//     title: 'Health Science Group',
//   },
//   {
//     imageUrl: ScienceandTechnologyGroupImage,
//     thumbnail: 'https://sv1.picz.in.th/images/2022/12/06/GnLKWn.th.png',

//     title: 'Science and Technology Group',
//   },
//   {
//     imageUrl: HumanitiesandSocialScienceGroupImage,
//     thumbnail: 'https://sv1.picz.in.th/images/2022/12/07/GrNgmy.th.png',
//     title: 'Humanities and Social Science Group',
//   },
//   {
//     imageUrl: MultidisciplinaryProgramImage,
//     thumbnail: 'https://sv1.picz.in.th/images/2022/12/07/Gr4gEI.th.png',
//     title: 'Multidisciplinary Program',
//   },
//   {
//     imageUrl: CmuSchoolLifelongEducationImage,
//     thumbnail: 'https://sv1.picz.in.th/images/2022/12/07/Gr4ckZ.th.png',
//     title: 'Cmu School Lifelong Education',
//     // link: 'https://www2.lifelong.cmu.ac.th/?gclid=Cj0KCQiAqOucBhDrARIsAPCQL1aCaeTQO3qbl7EneNDeo8bC3AYew1nKFuZlvppRI0lDTvr6aMh2I-YaAhCtEALw_wcB',
//   },
// ];

const initialData = [
  {
    imageUrl: BackgroundImg,
    thumbnailUrl: '',
  },
];

const BgGradient = ({ children }) => (
  <div className="relative -z-10 h-fit overflow-hidden bg-home-cover">{children}</div>
);

const CoverImageSlider = ({ height = 500 }) => {
  const slider = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);
  const { getApiLanguageCode, nowLanguageCode } = useTranslationUtil();

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    lazyLoad: true,
    fade: true,
    pauseOnHover: true,
  };

  useEffect(() => {
    getBannerImages(getApiLanguageCode(), 1, 30)
      .then((res) => {
        setImages(res.data);
      })
      .catch((err) => console.error(' get banner error ', err));
  }, [nowLanguageCode]);

  const dataListToShow = initialData.concat(images);

  return (
    <div className={`relative z-30 h-[${height}px]`}>
      <Slider {...settings} ref={slider} arrows={false} afterChange={(id) => setCurrentIndex(id)}>
        {dataListToShow.map((slide, id) => (
          <BgGradient key={id} className="mx-auto">
            <CustomContainer>
              {id === 0 ? (
                <img
                  key={id}
                  src={slide.imageUrl}
                  alt={slide.title}
                  className={`h-[${height}px] w-full object-cover object-bottom  mix-blend-soft-light`}
                />
              ) : (
                <img
                  key={id}
                  src={slide.imageUrl}
                  alt={slide.title}
                  className={`h-[${height}px] mx-auto w-full object-contain`}
                />
              )}
            </CustomContainer>
          </BgGradient>
        ))}
      </Slider>

      <CustomContainer>
        <div className=" relative z-50 -mt-20 flex w-full flex-row justify-center md:ml-[80px] md:w-fit md:justify-start ">
          {dataListToShow.map((_, id) => (
            <div
              key={id}
              onClick={() => {
                setCurrentIndex(id);
                slider.current.slickGoTo(id);
              }}
              className={` relative z-50  mx-1 h-1 w-8 bg-UIWhite hover:cursor-pointer md:mt-8 ${
                currentIndex === id ? '' : 'opacity-40'
              }`}
            />
          ))}
        </div>
      </CustomContainer>
    </div>
  );
};

export default CoverImageSlider;
