import React from 'react';

const NewsGroupTitle = ({ children, selected, onClick = () => {} }) => (
  <div>
    <div
      className={`${
        selected ? 'border-b-8 border-b-NeonPink text-NeonPink' : 'text-Lightgray600'
      } whitespace-nowrap px-2 pb-2 text-center text-lg font-bold leading-6 duration-300 hover:scale-105 hover:cursor-pointer hover:text-NeonPink`}
      onClick={onClick}
    >
      {children}
    </div>
    {selected && (
      <div className="mx-auto mt-2 h-2 w-2 rounded-full bg-NeonPink shadow-[0px_2px_8px_#F06292] shadow-NeonPink" />
    )}
  </div>
);

export default NewsGroupTitle;
