import React from 'react';
import CMUPurpleUnderLine80x8 from '../../components/CMUPurpleUnderLine';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import coverImage from './assets/cover.svg';
import TodsapornPichaiyaImg from './assets/TodsapornPichaiya.png';
import KittichaiWattananikornImg from './assets/KittichaiWattananikorn.png';
import SoranitSiltharmImg from './assets/SoranitSiltharm.png';
import NorkunSitthipongImg from './assets/NorkunSitthipong.png';
import VarakornSamakosesImg from './assets/VarakornSamakoses.png';
import ApichatSopadangImg from './assets/ApichatSopadang.png';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainerWithBackgroundImage from '../../components/PageTitleContainerWithBackgroundImage';

const DirectiveBoardPage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout headerTransparent>
      <CustomContainer>
        <PageTitleContainerWithBackgroundImage
          coverImage={coverImage}
          className=" bg-pink-big"
          mixBlendMode="opacity-40 object-bottom"
        >
          <div className="text-shadow-page-title">{t('directive_board')}</div>
        </PageTitleContainerWithBackgroundImage>

        {/* <div className="mt-10 text-center text-5xl font-medium leading-[60px] text-UIBlack">
          {t('MemberofGraduateSchoolDirectiveBoard')}
        </div> */}
        {/* <div className="mt-[18px] flex justify-center">
          <CMUPurpleUnderLine80x8 />
        </div> */}

        <div className=" mt-14 grid grid-cols-1 gap-6 pb-10 lg:grid-cols-2 lg:gap-10">
          <ProfileItem
            className="lg:col-span-2"
            imgUrl={TodsapornPichaiyaImg}
            title={t('assistant_professor')}
            name={t('TodsapornPichaiya')}
            position={t('vice_president')}
            groupName={t('ChairmanGraduateSchoolDirectiveBoard')}
          />
          <ProfileItem
            imgUrl={KittichaiWattananikornImg}
            title={t('professor_emeritus')}
            name={t('KittichaiWattananikorn')}
            // position={t('vice_president')}
            groupName={t('GraduateSchoolDirectiveBoard')}
          />
          <ProfileItem
            imgUrl={SoranitSiltharmImg}
            title={t('associate_professor')}
            name={t('SoranitSiltharm')}
            // position={t('MemberOf')}
            groupName={t('GraduateSchoolDirectiveBoard')}
          />
          <ProfileItem
            imgUrl={NorkunSitthipongImg}
            title={t('associate_professor')}
            name={t('NorkunSitthipong')}
            // position={t('MemberOf')}
            groupName={t('GraduateSchoolDirectiveBoard')}
          />
          <ProfileItem
            imgUrl={VarakornSamakosesImg}
            title={t('associate_professor')}
            name={t('VarakornSamakoses')}
            // position={t('MemberOf')}
            groupName={t('GraduateSchoolDirectiveBoard')}
          />
          <ProfileItem
            className="lg:col-span-2"
            imgUrl={ApichatSopadangImg}
            title={t('associate_professor')}
            name={t('ApichatSopadang')}
            // position={t('MemberOf')}
            position={t('DeanOfGraduateSchool')}
            groupName={t('SecretaryGraduateSchoolDirectiveBoard')}
          />
        </div>
      </CustomContainer>
    </Layout>
  );
};

const ProfileItem = ({ imgUrl, title, name, position, groupName, className }) => {
  const { getFontFamily } = useTranslationUtil();
  return (
    <div className={`bg-Lightgray50 py-[18px] ${className}`}>
      <div className="mx-auto w-fit">
        <div className="drop-shadow-xl">
          <img className="-mt-3 w-[317px]" src={imgUrl} alt="" />
        </div>
        <div className=" px-6 pb-4">
          <div className="text-lg font-bold leading-[23px] text-UIBlack">{title}</div>
          <div className="text-2xl font-semibold leading-[30px] text-UIBlack">{name}</div>
          <div className={`${getFontFamily(true)} mt-4 text-xs leading-4 text-Lightgray500`}> {position}</div>
          <div className={`${getFontFamily(true)} text-xs leading-4 text-Lightgray500`}>{groupName}</div>
        </div>
      </div>
    </div>
  );
};

export default DirectiveBoardPage;
