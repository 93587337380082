import React from 'react';

const PageTitle = ({ children }) => {
  return (
    <div className="mt-6 px-6 text-[32px] font-medium leading-10 text-UIBlack md:px-8 lg:px-20 lg:text-5xl lg:leading-[61px]">
      {children}
    </div>
  );
};

export default PageTitle;
