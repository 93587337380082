import React from 'react';
import CollapseSection from '../../components/CollapseSection';
import CollapseItem from '../../components/CollapseSection/CollapseItem';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import VISAExtensionRequestForm from './assets/VISAExtensionRequestForm.pdf';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';
import CollapseSectionContainer from '../../components/CollapseSection/CollapseSectionContainer';

const VisaPage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{t('VISA')}</PageTitleContainer>

        <CollapseSectionContainer>
          <CollapseSection title={t('VISA')}>
            <CollapseItem pdfUrl={VISAExtensionRequestForm}> {t('VISARequestForm')}</CollapseItem>
            {/* <CollapseItem pdfUrl={enrollmentnewpdf}> {t('CMUIRDVISAOnline')}</CollapseItem> */}
          </CollapseSection>
        </CollapseSectionContainer>
      </CustomContainer>
    </Layout>
  );
};

export default VisaPage;
