import { Link } from 'react-router-dom';
import { useTranslationUtil } from '../../../../hooks/useTranslationUtil';
import BottomArrow from './assets/bottom-gradpink-arrow.svg';
import RightArrow from './assets/right-arrow.svg';
import SubpageNestedListItem from './components/SubpageNestedListItem';
import MobileSubpageListItem from './components/MobileSubpageListItem';

export function MobilePageNavigation({
  setPageState,
  title,
  stateNumber,
  activePage,
  link,
  showSubmenuContent,
  setShowSubmenuContent,
  showAsButton = false,
  menuList = [],
  forceUseThFont = false,
}) {
  const { getFontFamily, getThFontFamily } = useTranslationUtil();
  const isActivePage = activePage === stateNumber && showSubmenuContent;

  const getFont = () => {
    return forceUseThFont ? getThFontFamily() : getFontFamily();
  };

  const getMenuTitleContent = () => {
    return (
      <div
        className="ml-3 mr-6 flex flex-row justify-between hover:cursor-pointer"
        onClick={() => {
          setPageState(stateNumber);
          setShowSubmenuContent(isActivePage ? !showSubmenuContent : true);
        }}
      >
        <div
          className={`${getFont()} text-lg font-bold leading-[64px] text-Lightgray600  ${
            isActivePage ? 'text-NeonPink' : ''
          }  `}
        >
          {title}
        </div>
        {!showAsButton && (
          <div className="my-auto">
            <img src={isActivePage ? BottomArrow : RightArrow} alt="" />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="w-full">
      <div className="">{showAsButton ? <Link to={link}>{getMenuTitleContent()}</Link> : getMenuTitleContent()}</div>
      {isActivePage && menuList.length > 0 && (
        <div className=" divide-y-2 divide-Lightgray100 border-t-2  border-Lightgray200 bg-Lightgray50 ">
          {menuList
            .filter((menu) => menu.title)
            .map((menu, idx) => {
              return (
                <div className="leading-[64px]" key={idx}>
                  <ul className="ml-3 text-lg font-bold leading-[64px] text-Lightgray600 ">
                    <MobileSubpageListItem link={menu.link} isExternal={!!menu.external}>
                      {menu.title}
                    </MobileSubpageListItem>
                  </ul>
                  <ul className="-mt-4 ml-10 list-outside list-disc">
                    {menu.subPage &&
                      menu.subPage?.map((subPage, idx) => (
                        <SubpageNestedListItem key={idx} link={subPage.link} isExternal={!!subPage.external}>
                          {subPage.title}
                        </SubpageNestedListItem>
                      ))}
                  </ul>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}
