import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import Layout from '../../../components/Layout';
import routeUrlProvider, { NEWS } from '../../../constants/route-paths';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';
import { getNewsById } from '../../../services/news';
import LeftArrow16pxLightGrayIcon from '../../../components/Icons/LeftArrow16pxLightGrayIcon';
import FacebookPinkIcon from '../../../components/Icons/FacebookPinkIcon';
import TwitterPinkIcon from '../../../components/Icons/TwitterPinkIcon';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import { pdfjs } from 'react-pdf';
import { DefaultNoImage } from '../../../components/Noimage';
import SocialShareButton from './components/SocialShareButton';
import CustomContainer from '../../../components/CustomContainer';
import ImageSlider from './components/ImageSlider';
import NewsAttatchment from './components/NewsAttatchment';
import PDFViewer from '../../../components/PDFViewer';
// import ExampleFilePDF from './assets/example.pdf'; // just for test local

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const Container = ({ children }) => <CustomContainer className=" px-3 md:px-10 lg:px-16 ">{children}</CustomContainer>;

const NewsDetailPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const [newsData, setNewsData] = useState(location.state?.newsData || {});
  const history = useHistory();
  const { t, getDisplayText, getDisplayObject, formatDate } = useTranslationUtil();
  const fullUrl = window.location.href;

  console.log(' newsData', newsData);
  console.log(' location', location);

  const { imagesEn, imagesTh, publisher, createdAt, titleTh, titleEn, descriptionTh, descriptionEn, filesEn, filesTh } =
    newsData;
  const images = getDisplayObject(imagesTh, imagesEn);
  const [imgObj] = images || [];
  const imageUrl = imgObj ? imgObj.imageUrl : DefaultNoImage;
  const description = getDisplayObject(descriptionTh, descriptionEn);
  const files = getDisplayObject(filesTh, filesEn);

  useEffect(() => {
    if (!location?.state?.newsDetail) {
      getNewsById(id)
        .then((news) => setNewsData(news))
        .catch((err) => console.error(err));
    }
  }, [id, location.state?.newsDetail]);

  const onClickBack = () => {
    location.key ? history.goBack() : history.push(routeUrlProvider.getForRoute(NEWS));
  };

  const getContentToDisplayInHeader = () => {
    if (images && images.length > 0) {
      return <ImageSlider images={images} />;
    } else if (files && files.length === 1) {
      const [file] = files;
      if (!file) {
        return;
      }
      return (
        <div className="mx-auto w-fit">
          <PDFViewer fileName={file.fileName} pdf={file.fileUrl} />
        </div>
      );
    } else {
      return (
        <img
          src={imageUrl}
          alt=""
          className="mx-auto  h-auto max-h-[320px] w-full max-w-7xl object-contain md:max-h-[720px]"
        />
      );
    }
  };

  return (
    <Layout>
      <Container>
        <div className="mt-10 flex flex-row  justify-between">
          <button onClick={onClickBack}>
            <div className="flex flex-row whitespace-nowrap text-lg font-bold leading-6 text-Lightgray400">
              <div className="my-auto">
                <LeftArrow16pxLightGrayIcon />
              </div>
              <div className="my-auto ml-[10px]">{t('BackToNews')}</div>
            </div>
          </button>
          <div className="flex flex-row gap-x-1 md:gap-x-6 lg:gap-x-[30px]">
            <div className="my-auto flex">
              <FacebookShareButton url={fullUrl}>
                <FacebookPinkIcon />
              </FacebookShareButton>
            </div>
            <div className="my-auto flex ">
              <TwitterShareButton url={fullUrl}>
                <TwitterPinkIcon />
              </TwitterShareButton>
            </div>
            <SocialShareButton url={fullUrl} />
          </div>
        </div>
        <div></div>
        <div className="mt-10 border-l-[5px] border-l-CMUPurple">
          <div className={` ml-6 text-2xl font-semibold leading-10 text-UIBlack`}>
            {getDisplayText(titleTh, titleEn)}
          </div>
          <div className="ml-6 flex flex-row text-Lightgray800">
            <div className=" my-auto text-lg font-bold leading-10">{publisher}</div>
            <div className="my-auto ml-8 text-sm leading-5">{createdAt ? formatDate(createdAt) : ''}</div>
          </div>
        </div>
        <div className="mt-6">{getContentToDisplayInHeader()}</div>

        <p className="  mt-14 px-2 text-lg leading-[1.875rem] text-UIBlack md:px-8 lg:px-14 xl:px-40">
          {parse(draftToHtml(description))}
          {images && images.length > 0 && files && files.length > 0
            ? files.map(({ fileName, fileUrl }, id) => <NewsAttatchment key={id} fileName={fileName} url={fileUrl} />)
            : null}  
        </p>
      </Container>
    </Layout>
  );
};

export default NewsDetailPage;
