import VoiceOfCustomerButton from '../../../components/Button/VoiceOfCustomerButton';
import VoiceOfCustomerButtonGrad from '../../../components/Button/VoiceOfCustomerButtonGrad';
import VoiceOfCustomerSumButton from '../../../components/Button/VoiceOfCustomerSumButton';
import CustomContainer from '../../../components/CustomContainer';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';

const FeedbackSection = () => {
  const { t, getFontFamily } = useTranslationUtil();
  return (
    <CustomContainer className=" flex flex-row justify-center">
      <div className="mt-[90px] flex flex-col">
        <div
          className={` ${getFontFamily()} text-[40px] font-medium leading-[51px] text-Lightgray700 xs:text-center md:text-left`}
        >
          {t('Wewouldlovetohearyourfeedback')}
        </div>
        <div className="flex flex-col justify-center px-2">
          <VoiceOfCustomerButton />
          <VoiceOfCustomerButtonGrad />
          <VoiceOfCustomerSumButton />
        </div>
      </div>
    </CustomContainer>
  );
};

export default FeedbackSection;
