import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

const SubpageNestedListItem = ({ children, link, isExternal = false }) => {
  const history = useHistory();

  const onClick = () => {
    if (link) {
      history.push(link);
    }
  };

  return (
    <li
      className={` text-[16px] leading-9 md:pb-2 md:leading-normal ${
        !!link ? 'hover:cursor-pointer hover:text-NeonPink' : 'hover:cursor-not-allowed'
      } `}
      onClick={onClick}
    >
      {isExternal ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        children
      )}
    </li>
  );
};

SubpageNestedListItem.propTypes = {
  children: PropTypes.any,
  isExternal: PropTypes.bool,
  link: PropTypes.string,
};

export default SubpageNestedListItem;
