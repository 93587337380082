import React from 'react';
import CMUPurpleUnderLine80x8 from '../../components/CMUPurpleUnderLine';
import DartIcon from '../../components/Icons/DartIcon';
import ScheduleBoardIcon from '../../components/Icons/ScheduleBoardIcon';
import ShieldPeopleIcon from '../../components/Icons/ShieldPeopleIcon';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import SarunyooBuaon from './assets/SarunyooBuaon.png';
import UthoomSingkarach from './assets/UthoomSingkarach.png';
import ThinnakritManojit from './assets/ThinnakritManojit.png';
import PunyapornTowichiean from './assets/PunyapornTowichiean.png';
import DutyItem from '../../components/GraduateStaffPageComponent/DutyItem';
import DutyItemIcon from '../../components/GraduateStaffPageComponent/DutyItemIcon';
import DutyItemText from '../../components/GraduateStaffPageComponent/DutyItemText';
import ProfileContainer from '../../components/GraduateStaffPageComponent/ProfileContainer';
import ProfileImage from '../../components/GraduateStaffPageComponent/ProfileImage';
import ProfileDetailContainer from '../../components/GraduateStaffPageComponent/ProfileDetailContainer';
import ProfileName from '../../components/GraduateStaffPageComponent/ProfileName';
import ProfilePosition from '../../components/GraduateStaffPageComponent/ProfilePosition';
import List from '../../components/GraduateStaffPageComponent/List';
import ListItem from '../../components/GraduateStaffPageComponent/ListItem';
import Phone from '../../components/GraduateStaffPageComponent/Phone';
import Email from '../../components/GraduateStaffPageComponent/Email';
import CustomContainer from '../../components/CustomContainer';
import PageTitleGraduateStaff from '../../components/GraduateStaffPageComponent/PageTitleGraduateStaff';
import ProfileList from '../../components/GraduateStaffPageComponent/ProfileList';
import StaffTeamTitle from '../../components/GraduateStaffPageComponent/StaffTeamTitle';
import GraduateStaffPageContainer from '../../components/GraduateStaffPageComponent/GraduateStaffPageContainer';
import DutyContainer from '../../components/GraduateStaffPageComponent/DutyContainer';
import FrontViewGradSchool from '../../components/GraduateStaffPageComponent/FrontViewGradSchool';

const FinancialTreasuryandInventoryPage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout headerTransparent removeSpaceBeforeFooterDivider>
      <CustomContainer>
        <PageTitleGraduateStaff title={t('FinancialTreasuryandInventory')} />
        <GraduateStaffPageContainer>
          {/* <FrontViewGradSchool mobile>
            {t('FinancialTreasuryandInventory')}, {t('chiang_mai_university')}
          </FrontViewGradSchool> */}
          {/* <DutyContainer>
            <div className="col-span-2">
              <div className="mt-7 mb-12 text-[32px] font-medium leading-10 text-Lightgray500">
                {t('duty')}
                <div className="mt-14px">
                  <CMUPurpleUnderLine80x8 />
                </div>
              </div>
              <DutyItem>
                <DutyItemIcon>
                  <ShieldPeopleIcon />
                </DutyItemIcon>
                <DutyItemText> {t('BudgetDisbusement')}</DutyItemText>
              </DutyItem>
              <DutyItem>
                <DutyItemIcon>
                  <DartIcon />
                </DutyItemIcon>
                <DutyItemText> {t('SchorlarshipDisbusement')}</DutyItemText>
              </DutyItem>
              <DutyItem>
                <DutyItemIcon>
                  <ScheduleBoardIcon />
                </DutyItemIcon>
                <DutyItemText>{t('AdvanceLoanDisburse')}</DutyItemText>
              </DutyItem>
              <DutyItem>
                <DutyItemIcon>
                  <ScheduleBoardIcon />
                </DutyItemIcon>
                <DutyItemText>{t('SalaryandWelfarePayment')}</DutyItemText>
              </DutyItem>
              <DutyItem>
                <DutyItemIcon>
                  <ScheduleBoardIcon />
                </DutyItemIcon>
                <DutyItemText>{t('FeePaymentService')}</DutyItemText>
              </DutyItem>
            </div>
            <FrontViewGradSchool>
              {t('FinancialTreasuryandInventory')}, {t('chiang_mai_university')}
            </FrontViewGradSchool>
          </DutyContainer> */}
          <StaffTeamTitle>{t('FinancialTreasuryandInventoryTeam')}</StaffTeamTitle>
          <ProfileList>
            <ProfileContainer>
              <ProfileImage src={SarunyooBuaon} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('SarunyooBuaon')}</ProfileName>
                <ProfilePosition>{t('FinancierandAccountantManager')}</ProfilePosition>
                <List>
                  <ListItem>Financial Report</ListItem>
                  <ListItem>Financial Report of Graduate School Fund</ListItem>
                  <ListItem>Disbursment of Sport Subsidy and Activity Subsidy</ListItem>
                </List>
                <Phone>0-5394-2411</Phone>
                <Email>sarunyoo.boa-on@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>

            <ProfileContainer>
              <ProfileImage src={UthoomSingkarach} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('UthoomSingkarach')}</ProfileName>
                <ProfilePosition>{t('FinancierandAccountant')}</ProfilePosition>
                <List>
                  <ListItem>Salary and Welfare Payment</ListItem>
                  <ListItem>Preparation of Cheque</ListItem>
                </List>
                <Phone>0-5394-2411</Phone>
                <Email>uthum.s@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={ThinnakritManojit} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('ThinnakritManojit')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Procurement of Supply</ListItem>
                  <ListItem>Supply Disbursement</ListItem>
                  <ListItem>Rule and Regulations Regarding Inventory</ListItem>
                  <ListItem>Fees Payment Service</ListItem>
                </List>
                <Phone>0-5394-2432</Phone>
                <Email>thinnakrit.man@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={PunyapornTowichiean} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('PunyapornTowichiean')}</ProfileName>
                <ProfilePosition>{t('FinancierandAccountant')}</ProfilePosition>
                <List>
                  <ListItem>Financial Report</ListItem>
                </List>
                <Phone>0-5394-2411</Phone>
                <Email>punyaporn.to@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
          </ProfileList>
        </GraduateStaffPageContainer>
      </CustomContainer>
    </Layout>
  );
};

export default FinancialTreasuryandInventoryPage;
