import React from 'react';

const PinkFulfilWithRightArrowButton = ({ children, onClick = () => {} }) => {
  return (
    <button
      className={`font-bold bg-NeonPink rounded-sm  text-UIWhite text-lg leading-[23px] ${
        children ? 'px-5 py-2' : 'px-[12px] py-[10px]'
      }`}
      onClick={() => onClick()}
    >
      <div className="flex flex-row">
        {children}
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={children ? ' ml-4' : ''}
        >
          <path
            d="M8.31941 2.08015L15.0394 8.80015H0.399414V11.2001H15.0394L8.31941 17.9201L9.99941 19.6001L19.5994 10.0001L9.99941 0.400146L8.31941 2.08015Z"
            fill="white"
          />
        </svg>{' '}
      </div>
    </button>
  );
};

export default PinkFulfilWithRightArrowButton;
