import React, { useEffect, useState } from 'react';
import DownloadPDFButton from '../../Button/DownloadPDFButton';
import PinkFulfilWithRightArrowButton from '../../Button/PinkFulfilWithRightArrowButton';
import AttatchFilePinkIconSVG from '../../../assets/attatch-file-Icon-pink.svg';
import PDFViewer from '../../PDFViewer';
import Modal from 'react-modal';
import { afterCloseModal, afterOpenModal } from '../../../utils/modal';

const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  content: {
    border: 0,
  },
  // content: {
  //   // top: '50%',
  //   // left: '50%',
  //   // transform: 'translate(-50%, -50%)',
  // },
  // content: {
  //   top: '50%',
  //   left: '50%',
  //   right: 'auto',
  //   bottom: 'auto',
  //   marginRight: '-50%',
  //   transform: 'translate(-50%, -50%)',
  //   // padding: 30,
  //   // borderRadius: 8,
  //   border: 0,
  //   // scrollY: true,
  // },
  // content: {
  //   top: '0',
  //   left: '0',
  //   right: 'auto',
  //   bottom: 'auto',
  //   marginRight: '0',
  //   transform: 'translate(0, 0)',
  //   // padding: 30,
  //   // borderRadius: 8,
  //   border: 0,
  //   // scrollY: true,
  // },
};

const CollapseItem = ({
  children,
  pdfUrl,
  docUrl,
  jpgUrl,
  externalLinkUrl,
  light = false,
  fileName,
  forceUseThFont = false,
}) => {
  const [isOpenPreview, setIsOpenPreview] = useState(false);

  const getFileExtension = () => {
    if (pdfUrl) {
      return '.PDF';
    } else if (docUrl) {
      return '.Doc';
    } else if (jpgUrl) {
      return '.Jpg';
    } else {
      return 'Link';
    }
  };

  // const onClick = () => {
  //   if (pdfUrl) {
  //     setIsOpenPreview(true);
  //   }
  // };

  const openPdfInNewTab = () => {
    if (pdfUrl) {
      window.open(pdfUrl, '_blank', 'noopener,noreferrer');
    } else if (docUrl) {
      window.open(docUrl, '_blank', 'noopener,noreferrer');
    } else if (jpgUrl) {
      window.open(jpgUrl, '_blank', 'noopener,noreferrer');
    } else if (externalLinkUrl) {
      window.open(externalLinkUrl, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <>
      <div
        className={`my-10 flex  flex-row justify-between rounded-sm px-3 md:pl-6  ${
          light ? 'bg-UIWhite' : 'bg-collapse-item'
        }`}
      >
        <div
          className={`my-auto flex min-h-[5rem] w-full flex-row ${
            pdfUrl || docUrl || jpgUrl || externalLinkUrl ? 'hover:cursor-pointer' : ''
          }`}
          // onClick={onClick}
          onClick={openPdfInNewTab}
        >
          <img src={AttatchFilePinkIconSVG} alt="" />
          <div className="grid-cols my-auto ml-3 grid md:ml-6">
            <div className="font-open-sans text-xs font-bold leading-4 text-NeonPink">{getFileExtension()}</div>
            <div className={`${forceUseThFont ? 'font-ibm-plex-sans' : ''}  text-lg font-bold leading-6 text-UIBlack`}>
              {children}
            </div>
          </div>
        </div>
        <div className="my-auto">
          {pdfUrl ? <DownloadPDFButton pdfUrl={pdfUrl} filename={fileName} /> : null}
          {docUrl ? <DownloadPDFButton pdfUrl={docUrl} filename={fileName} /> : null}
          {jpgUrl ? <DownloadPDFButton pdfUrl={jpgUrl} filename={fileName} /> : null}
          {externalLinkUrl ? (
            <PinkFulfilWithRightArrowButton
              title=""
              onClick={() => {
                window.open(externalLinkUrl, '_blank', 'noopener,noreferrer');
              }}
            />
          ) : null}
        </div>
      </div>

      {pdfUrl && (
        <Modal
          className="mx-auto w-fit "
          isOpen={isOpenPreview}
          onRequestClose={() => {
            setIsOpenPreview(false);
          }}
          onAfterClose={afterCloseModal}
          onAfterOpen={afterOpenModal}
          style={customModalStyles}
        >
          <div className="h-[100vh] overflow-y-scroll ">
            <PDFViewer pdf={pdfUrl} fileName={fileName} />
          </div>
        </Modal>
      )}
    </>
  );
};

export default CollapseItem;
