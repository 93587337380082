import React from 'react';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import morning_talk_EP01 from './assets/EP01-morning-talk-ครั้งที่-1.mp4';
import morning_talk_EP03 from './assets/EP03-Morning-Talk.mp4';
import morning_talk_EP06 from './assets/EP06-Morning-Talk-21-01-65.mp4';
import morning_talk_EP07 from './assets/EP07-morning-talk.mp4';
import morning_talk_EP08 from './assets/EP08-Morning-Talk.mp4';
import morning_talk_EP09 from './assets/EP09-Morning-Talk.mp4';
import morning_talk_EP10 from './assets/EP10-Clip-Morning-Talk-กยศ.-ปรับ.mp4';
import morning_talk_EP11 from './assets/EP11-Morning-Talk.mp4';
import morning_talk_EP12 from './assets/EP12 Morning Talk (GIP).mp4';
import projectWork from './assets/แนวปฏิบัติการขออนุมัติโครงการ.jpg';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';
import TableContainer from '../../components/CommonTable/TableContainer';
import Row from '../../components/CommonTable/Row';
import Cell from '../../components/CommonTable/Cell';
import DetailItem from '../../components/CommonTable/DetailItem';
import DetailItemLink from '../../components/CommonTable/DetailItemLink';
import g3_1 from './assets/จริยธรรมสำหรับผู้ปฎิบัติงาน-vdo.mp4';
import g3_2 from './assets/คลิป-No Gift Policy.mp4';

const KmPage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{t('KM')}</PageTitleContainer>
        <div className=" mt-[56px] font-ibm-plex-sans text-UIBlack">
          <div className="text-center text-[32px] leading-[52px]">{t('KM')}</div>
          <TableContainer>
            <table class="mx-auto table-fixed">
              <thead>
                <tr>
                  <th className="pb-8 pl-10 text-left text-[20px] font-bold md:min-w-[300px]">รายการข้อมูล</th>
                  <th className="pb-8 pl-14 text-left text-[20px] font-bold md:min-w-[600px]">รายละเอียดของข้อมูล</th>
                </tr>
              </thead>
              <tbody className=" border-t-2 border-Lightgray400">
                <Row>
                  <Cell>1. Morning Talk</Cell>
                  <Cell>
                    <DetailItem>
                      <DetailItemLink url={morning_talk_EP01}>1.1 EP 01 วันสถาปนาบัณฑิตวิทยาลัย</DetailItemLink>
                    </DetailItem>
                    <DetailItem>
                      <DetailItemLink url={morning_talk_EP03}>
                        1.2 EP 03 กำหนดการพิธีพระราชทานปริญญาบัตร ครั้งที่ 55 และครั้งที่ 56
                      </DetailItemLink>
                    </DetailItem>
                    <DetailItem>
                      <DetailItemLink url={morning_talk_EP06}>
                        1.3 EP 06 นักศึกษาเก่าระดับบัณฑิตศึกษาดีเด่น
                      </DetailItemLink>
                    </DetailItem>
                    <DetailItem>
                      <DetailItemLink url={morning_talk_EP07}>
                        1.4 EP 07 การรับสมัครนักศึกษา ภาคเรียนที่ 1 ปีการศึกษา 2565 รอบที่ 1
                      </DetailItemLink>
                    </DetailItem>
                    <DetailItem>
                      <DetailItemLink url={morning_talk_EP08}>1.5 EP 08 การเรียนข้ามสถาบัน</DetailItemLink>
                    </DetailItem>
                    <DetailItem>
                      <DetailItemLink url={morning_talk_EP09}>1.6 EP 09 DynEd</DetailItemLink>
                    </DetailItem>
                    <DetailItem>
                      <DetailItemLink url={morning_talk_EP10}>1.7 EP 10 กยศ.</DetailItemLink>
                    </DetailItem>
                    <DetailItem>
                      <DetailItemLink url={morning_talk_EP11}>1.8 EP 11 ISO 9001 V.2015</DetailItemLink>
                    </DetailItem>
                    <DetailItem>
                      <DetailItemLink url={morning_talk_EP12}>1.9 EP 12 GIP Project</DetailItemLink>
                    </DetailItem>
                  </Cell>
                </Row>
                <Row>
                  <Cell>2. KM</Cell>
                  <Cell>
                    <DetailItem>
                      <DetailItemLink url="https://www.canva.com/design/DAFR7j_cvp4/NeWtlBAi3Yvn79PDvk7Mcg/view?utm_content=DAFR7j_cvp4&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton">
                        2.1 อบรมการนำเสนอข้อมูลอย่างไรให้เจ้านายรัก
                      </DetailItemLink>
                    </DetailItem>
                    <DetailItem>
                      <DetailItemLink url={projectWork}>
                        2.2 แนวปฏิบัติการขออนุมัติโครงการ
                      </DetailItemLink>
                    </DetailItem>
                  </Cell>
                </Row>
                <Row>
                  <Cell>3. คุณธรรมและความโปร่งใสในการปฏิบัติงาน</Cell>
                  <Cell>
                    <DetailItem>
                    <DetailItemLink url={g3_1}>
                        3.1 จริยธรรมสำหรับผู้ปฏิบัติงาน มหาวิทยาลัยเชียงใหม่
                      </DetailItemLink>
                    </DetailItem>
                    <DetailItem>
                      <DetailItemLink url={g3_2}>
                        3.2 No Gift Policy
                      </DetailItemLink>
                    </DetailItem>
                  </Cell>
                </Row>
              </tbody>
            </table>
          </TableContainer>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default KmPage;
