import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { clearToken, useToken } from '../token';
import { getUserInformation } from '../../services/user/getUserInformation';
import routeUrlProvider, {
  HOME,
  DASHBOARD,
  LOGIN,
} from '../../constants/route-paths';

export const useAuth = () => {
  const { token } = useToken();
  const [isLoggedIn, setIsLoggedIn] = useState(
    token.accessToken ? true : false,
  );
  const [isLoading] = useState(false);
  const [user, setUser] = useState();

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // if (isLoggedIn) {
    //   getUserInformation()
    //     .then((user) => {
    //       setUser(user);
    //       if (location.pathname === routeUrlProvider.getForLink(LOGIN)) {
    //         history.push(routeUrlProvider.getForLink(DASHBOARD));
    //       }
    //     })
    //     .catch(() => {
    //       clearToken();
    //       setIsLoggedIn(false);
    //       // history.push(routeUrlProvider.getForLink(HOME));
    //     });
    // } else {
    //   clearToken();
    //   setIsLoggedIn(false);
    //   // history.push(routeUrlProvider.getForLink(HOME));
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return [
    {
      isLoggedIn,
      setIsLoggedIn,
      user,
    },
    isLoading,
  ];
};
