import YourJourneyWithGradCmuCard from './YourJourneyWithGradCmuCard';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';

import VisionMobileImage from './assets/mobile/vision.jpg';
import StudentMobileImage from './assets/mobile/student.jpg';
import WhichMatchMobileImage from './assets/mobile/whichmatch.jpg';
import AdmissionMobileImage from './assets/mobile/admission.jpg';
import VisionDesktopImage from './assets/vision.jpg';
import StudentDesktopImage from './assets/student.jpg';
import WhichMatchDesktopImage from './assets/whichmatch.jpg';
import AdmissionDesktopImage from './assets/admission.jpg';
//import QualifyApplicationImage from './assets/qualifyapp.jpg';
import routeUrlProvider, { SEARCH_BY_FACULTY, VISION_AND_MISION } from '../../../constants/route-paths';
import CustomContainer from '../../../components/CustomContainer';

const YourJourneyWithGradCmuSection = () => {
  const { t } = useTranslationUtil();

  return (
    <CustomContainer className=" mt-[57px] text-Lightgray700  md:px-20 ">
      <div className="px-4 text-center text-[26px] font-medium leading-[40px] md:px-0 md:text-[32px] lg:text-left">
        {t('Begin your journey with GRAD CMU')}
      </div>
      <div className="md:mt-[32px]  ">
        <div className="flex flex-col md:hidden">
          <YourJourneyWithGradCmuCard
            imageUrl={VisionMobileImage}
            title={t('HomeVisionAndMission')}
            description={t('HomeVisionAndMissionDescription')}
            url={routeUrlProvider.getForRoute(VISION_AND_MISION)}
          />
          <YourJourneyWithGradCmuCard
            imageUrl={WhichMatchMobileImage}
            title={t('HomeWhichMatchYou')}
            description={t('HomeWhichMatchYouDescription')}
            url={routeUrlProvider.getForRoute(SEARCH_BY_FACULTY)}
          />
          <YourJourneyWithGradCmuCard
            imageUrl={AdmissionMobileImage}
            title={t('HomeAdmission')}
            description={t('HomeAdmissionDescription')}
            url="https://admission.grad.cmu.ac.th/admissions/indexeng.php?p=104"
          />
          <YourJourneyWithGradCmuCard
            imageUrl={StudentMobileImage}
            title={t('HomeStudents')}
            description={t('HomeStudentsDescription')}
            url="https://student.grad.cmu.ac.th"
          />
        </div>
        <div className="justify-between xs:hidden  md:grid  md:grid-cols-2 md:gap-y-4 xl:flex xl:flex-row ">
          <YourJourneyWithGradCmuCard
            imageUrl={VisionDesktopImage}
            title={t('HomeVisionAndMission')}
            description={t('HomeVisionAndMissionDescription')}
            url={routeUrlProvider.getForRoute(VISION_AND_MISION)}
          />
          <YourJourneyWithGradCmuCard
            imageUrl={WhichMatchDesktopImage}
            title={t('HomeWhichMatchYou')}
            description={t('HomeWhichMatchYouDescription')}
            url={routeUrlProvider.getForRoute(SEARCH_BY_FACULTY)}
          />
          <YourJourneyWithGradCmuCard
            imageUrl={AdmissionDesktopImage}
            title={t('HomeAdmission')}
            description={t('HomeAdmissionDescription')}
            url="https://admission.grad.cmu.ac.th/admissions/indexeng.php?p=104"
          />
          <YourJourneyWithGradCmuCard
            imageUrl={StudentDesktopImage}
            title={t('HomeStudents')}
            description={t('HomeStudentsDescription')}
            url="https://student.grad.cmu.ac.th"
          />
        </div>
      </div>
    </CustomContainer>
  );
};

export default YourJourneyWithGradCmuSection;
