import React from 'react';
import CMUPurpleUnderLine80x8 from '../../components/CMUPurpleUnderLine';
import DartIcon from '../../components/Icons/DartIcon';
import LockedShieldIcon from '../../components/Icons/LockedShieldIcon';
import ScheduleBoardIcon from '../../components/Icons/ScheduleBoardIcon';
import ShieldPeopleIcon from '../../components/Icons/ShieldPeopleIcon';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import coverImage from '../../components/GraduateStaffPageComponent/assets/cover.png';
import Image1 from '../../components/GraduateStaffPageComponent/assets/front-view-grad.png';
import PanneeLeeraphan from './assets/PanneeLeeraphan.png';
import TanwaratSunhim from './assets/TanwaratSunhim.png';
import SaisawathChansom from './assets/SaisawathChansom.png';
import ApidechTacha from './assets/ApidechTacha.png';
import AukkrapongChumjai from './assets/AukkrapongChumjai.png';
import NipitponJanin from './assets/NipitponJanin.png';
import DutyItem from '../../components/GraduateStaffPageComponent/DutyItem';
import DutyItemIcon from '../../components/GraduateStaffPageComponent/DutyItemIcon';
import DutyItemText from '../../components/GraduateStaffPageComponent/DutyItemText';
import ProfileContainer from '../../components/GraduateStaffPageComponent/ProfileContainer';
import ProfileImage from '../../components/GraduateStaffPageComponent/ProfileImage';
import ProfileDetailContainer from '../../components/GraduateStaffPageComponent/ProfileDetailContainer';
import ProfileName from '../../components/GraduateStaffPageComponent/ProfileName';
import ProfilePosition from '../../components/GraduateStaffPageComponent/ProfilePosition';
import List from '../../components/GraduateStaffPageComponent/List';
import ListItem from '../../components/GraduateStaffPageComponent/ListItem';
import Phone from '../../components/GraduateStaffPageComponent/Phone';
import Email from '../../components/GraduateStaffPageComponent/Email';
import CustomContainer from '../../components/CustomContainer';
import PageTitleGraduateStaff from '../../components/GraduateStaffPageComponent/PageTitleGraduateStaff';
import ProfileList from '../../components/GraduateStaffPageComponent/ProfileList';
import StaffTeamTitle from '../../components/GraduateStaffPageComponent/StaffTeamTitle';
import GraduateStaffPageContainer from '../../components/GraduateStaffPageComponent/GraduateStaffPageContainer';
import DutyContainer from '../../components/GraduateStaffPageComponent/DutyContainer';
import FrontViewGradSchool from '../../components/GraduateStaffPageComponent/FrontViewGradSchool';

const GeneralAdministrationPage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout removeSpaceBeforeFooterDivider>
      <CustomContainer>
        <PageTitleGraduateStaff title={t('GeneralAdministration')} />
        <GraduateStaffPageContainer>
          {/* <FrontViewGradSchool mobile>
            {t('GeneralAdministration')}, {t('chiang_mai_university')}
          </FrontViewGradSchool> */}
          {/* <DutyContainer>
            <div className="col-span-2">
              <div className="mt-7 mb-12 text-[32px] font-medium leading-10 text-Lightgray500">
                {t('duty')}
                <div className="mt-14px">
                  <CMUPurpleUnderLine80x8 />
                </div>
              </div>
              <DutyItem>
                <DutyItemIcon>
                  <ShieldPeopleIcon />
                </DutyItemIcon>
                <DutyItemText> {t('GraduateStudentAdmission')}</DutyItemText>
              </DutyItem>
              <DutyItem>
                <DutyItemIcon>
                  <DartIcon />
                </DutyItemIcon>
                <DutyItemText> {t('Correspondence')}</DutyItemText>
              </DutyItem>
              <DutyItem>
                <DutyItemIcon>
                  <ScheduleBoardIcon />
                </DutyItemIcon>
                <DutyItemText>{t('HumanitiesResource')}</DutyItemText>
              </DutyItem>
              <DutyItem>
                <DutyItemIcon>
                  <LockedShieldIcon />
                </DutyItemIcon>
                <DutyItemText> {t('PremisesandVehiclesSubdivision')}</DutyItemText>
              </DutyItem>
            </div>
            <FrontViewGradSchool>
              {t('GeneralAdministration')}, {t('chiang_mai_university')}
            </FrontViewGradSchool>
          </DutyContainer> */}
          <StaffTeamTitle>{t('GeneralAdministrationTeam')}</StaffTeamTitle>
          <ProfileList>
            <ProfileContainer>
              <ProfileImage src={PanneeLeeraphan} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('PanneeLeeraphan')}</ProfileName>
                <ProfilePosition>{t('GeneralAdministrationOfficerManager')}</ProfilePosition>
                <List>
                  <ListItem>Graduate Student Admissions</ListItem>
                  <ListItem>Proposal for Appointment </ListItem>
                  <ListItem>Determination of Graduate Education Fees</ListItem>
                  <ListItem>Execusive Commitee Meeting</ListItem>
                </List>
                <Phone>0-5394-2406</Phone>
                <Email>pannee.lee@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={TanwaratSunhim} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('TanwaratSunhim')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Humanities Resourse</ListItem>
                  <ListItem>Graduate Student Admissions</ListItem>
                  <ListItem>Proposal for Appointment </ListItem>
                  <ListItem>Determination of Graduate Education Fees</ListItem>
                </List>
                <Phone>0-5394-2406</Phone>
                <Email>tanwarat.s@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={SaisawathChansom} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('SaisawathChansom')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Humanities Resource</ListItem>
                  <ListItem>Correspondence Program</ListItem>
                </List>
                <Phone>0-5394-2405</Phone>
                <Email>saisawath.c@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={ApidechTacha} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('ApidechTacha')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Premises and Vehicles Information</ListItem>
                  <ListItem>Classroom and Meeting Room</ListItem>
                </List>
                <Phone>0-5394-2405</Phone>
                <Email>apidech.t@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={AukkrapongChumjai} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('AukkrapongChumjai')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Premises and Vehicles Information</ListItem>
                  <ListItem>Classroom and Meeting Room</ListItem>
                </List>
                <Phone>0-5394-2405</Phone>
                <Email>aukkarapong.c@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={NipitponJanin} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('NipitponJanin')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Premises and Vehicles Information</ListItem>
                  <ListItem>Classroom and Meeting Room</ListItem>
                </List>
                <Phone>0-5394-2591</Phone>
                <Email>nipitpon.j@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
          </ProfileList>
        </GraduateStaffPageContainer>
      </CustomContainer>
    </Layout>
  );
};

export default GeneralAdministrationPage;
