import React from 'react';

const CustomContainer = ({ children, className }) => {
  return (
    <div
      className={`relative mx-auto max-w-[1920px] overflow-hidden md:overflow-visible ${className ? className : ''}`}
    >
      {children}
    </div>
  );
};

export default CustomContainer;
