import { Switch, Redirect } from 'react-router-dom';

import PrivateRoute from './components/AuthManager/PrivateRoute';
import PublicRoute from './components/AuthManager/PublicRoute';
import routeUrlProvider, {
  HOME,
  DASHBOARD,
  INFO,
  AdminRoutePath,
  FOREIGN_LANGUAGE_CONDITION,
  NEWS,
  ABOUT,
  ANNUA_REPORT,
  DIRECTIVE_BOARD,
  EXECUTIVE,
  EXECUTIVE_CALENDAR,
  FACTS_AND_FIGURES,
  GRADUATE_SCHOOL_TEAM,
  GRADUATE_SCHOOL_OFFICE,
  INTERDISCIPLINARY_PROGRAMS,
  INTERDISCIPLINARY_PROGRAMS_BIOTECTHNOLOGY,
  INTERDISCIPLINARY_PROGRAMS_FORENSICS_SCIENCE,
  INTERDISCIPLINARY_PROGRAMS_MAN_AND_ENVIRONMENTAL,
  INTERDISCIPLINARY_PROGRAMS_SPORT_SCIENCE,
  INTERDISCIPLINARY_PROGRAMS_TECHNOLOGY_AND_INTERDISCIPLINARY,
  INTERDISCIPLINARY_PROGRAMS_MENTAL_HEALTH,
  INTERDISCIPLINARY_PROGRAMS_CONTEMPORARY_CHINESE_LANGUAGE_TEACHING,
  INTERDISCIPLINARY_PROGRAMS_INTEGRATED_SCIENCE,
  LIFE_CMU,
  NEWS_DETAIL,
  ORGANIZATION_STRUCTURE,
  PODCAST_LIVE,
  POLICY_AND_PLAN,
  RoutePath,
  QUALIFY_APPLICATION,
  VISION_AND_MISION,
  ENROLLMENT_ACADEMIC_CALENDAR,
  REGULATIONS_ANNOUNCEMENT_GUIDELINES,
  THESIS,
  THESIS_AWARD_IN_GRADUATE_SCHOOL_CMU,
  GRADUATION,
  GUIDELINE_PROCEDURE,
  VISA,
  GRADUATE_STUDIES_COMMITTEE_AND_ADMINISTRATION,
  ANNOUNCEMENTS_REGULATIONS_GUIDELINES,
  CURRICULUM_GUIDELINES,
  COURSE_TEMPLATE,
  GRADUATE_STUDENTS_ACCEPTANCE,
  CRITERIA_GRADUATION,
  LECTURER,
  ANNUAL_MEETING_SCHEDULE,
  GRADUATION_PROPOSAL_DOCUMENTS_SCHEDULE,
  GENERAL_ADMINISTRATION,
  FINANCIAL_TREASURY_AND_INVENTORY,
  POLICY_AND_PLANNING_SECTION,
  ACADEMIC_SERVICE,
  STUDENT_DEVELOPMENT,
  INTERDISCIPLINARY_PROGRAM_AND_QA,
  SEARCH_BY_FACULTY,
  KM,
  OPEN_DATA,
  OPEN_DATA_2565,
  OPEN_DATA_2566,
  PROGRAM_TUTION_FEE,
  SCHOLARSHIP,
  DEAN_REPORT,
  PROSPECTUS,
  CHECKLIST_OF_JOURNALS,
  THESIS_DISSERTATION_IS_CHECKING,
  ADMISSION_PROCESS,
  CONTACT_US,
  OPEN_DATA_NEWS,
} from './constants/route-paths';

import AboutPage from './pages/About';
import NewsCreate from './pages/Admin/NewsCreate';
import AnnualReport from './pages/AnnualReport';
import DirectiveBoardPage from './pages/DirectiveBoard';
import ExecutivePage from './pages/Executive';
import FactsAndFiguresPage from './pages/FactsAndFigures';
import ForeignLanguageConditionPage from './pages/ForeignLanguageCondition';
import GraduateSchoolTeamPage from './pages/GraduateSchoolTeam';
import GraduateSchoolOfficePage from './pages/GraduateSchoolOffice';
import HomePage from './pages/Home';
import Info from './pages/Info';
import InterdisciplinaryProgramsPage from './pages/InterdisciplinaryPrograms';
import BiotechnologyPage from './pages/InterdisciplinaryPrograms/Biotechnology';
import ForensicSciencePage from './pages/InterdisciplinaryPrograms/ForensicScience';
import ManAndEnvironmentalManagementPage from './pages/InterdisciplinaryPrograms/ManAndEnvironmentalManagement';
import SportsSciencePage from './pages/InterdisciplinaryPrograms/SportsScience';
import TechnologyAndInterdisciplinaryManagementPage from './pages/InterdisciplinaryPrograms/TechnologyAndInterdisciplinaryManagement';
import MentalHealthPage from './pages/InterdisciplinaryPrograms/MentalHealth';
import ContemporaryChineseLanguageTeachingPage from './pages/InterdisciplinaryPrograms/ContemporaryChineseLanguageTeaching';
import IntegratedSciencePage from './pages/InterdisciplinaryPrograms/IntegratedScience';
import PosterAdd from './pages/Admin/PosterAdd';
import LifeCMU from './pages/LifeCMU';
import NewsPage from './pages/News';
import NewsDetailPage from './pages/News/NewsDetail';
import OrganizationStructurePage from './pages/OrganizationStructure';
import PodcastLive from './pages/PodcastLive';
import PolicyAndPlanPage from './pages/Policy&Plan';
import VisionAndMissionPage from './pages/VisionAndMission';
import EnrollmentAcademicCalendarPage from './pages/EnrollmentAcademicCalendar';
import AdmissionProcessPage from './pages/AdmissionProcess';
import RegulationsAnnouncementGuidelinesPage from './pages/RegulationsAnnouncementGuidelines';
import ThesisPage from './pages/Thesis';
import ThesisAwardInGraduateSchoolCmuPage from './pages/ThesisAwardInGraduateSchoolCmu';
import GraduationPage from './pages/Graduation';
import GuidelineProcedurePage from './pages/GuidelineProcedure';
import VisaPage from './pages/Visa';
import QualifyApplicationPage from './pages/QualifyApplication';
import GraduateStudiesCommitteeAndAdministrationPage from './pages/GraduateStudiesCommitteeAndAdministration';
import AnnouncementsRegulationsGuidelinesPage from './pages/AnnouncementsRegulationsGuidelines';
import GraduateStudentsAcceptancePage from './pages/GraduateStudentsAcceptance';
import LecturerPage from './pages/Lecturer';
import GraduationProposalDocumentsSchedulePage from './pages/GraduationProposalDocumentsSchedule';
import AnnualMeetingSchedulePage from './pages/AnnualMeetingSchedule';
import CriteriaGraduationPage from './pages/CriteriaGraduation';
import CourseTemplatePage from './pages/CourseTemplate';
import CurriculumGuidelinesPage from './pages/CurriculumGuidelines';
import GeneralAdministrationPage from './pages/GeneralAdministration';
import FinancialTreasuryandInventoryPage from './pages/FinancialTreasuryandInventory';
import PolicyandPlanningSectionPage from './pages/PolicyandPlanningSection';
import AcademicServicePage from './pages/AcademicService';
import StudentDevelopmentPage from './pages/StudentDevelopment';
import InterdisciplinaryProgramandQAPage from './pages/InterdisciplinaryProgramandQA';
import SearchByFacultyPage from './pages/SearchByFaculty';
import KmPage from './pages/KM';
import OpenDataPage from './pages/OpenData';
import OpenDataPage2565 from './pages/OpenData2565';
import OpenDataPage2566 from './pages/OpenData2566';
import ProgramTutionFeePage from './pages/ProgramTutionFee';
import ScholarshipPage from './pages/Scholarship';
import DeansReportPage from './pages/DeansReport';
import ProspectusPage from './pages/Prospectus';
import CheckListOfJournalsPage from './pages/CheckListOfJournals';
import ThesisDissertationIScheckingPage from './pages/ThesisDissertationISchecking';
import FaqPage from './pages/FAQ';
import ExecutiveCalendar from './pages/ExecutiveCalendar';

import NewsList from './pages/Admin/NewsList';
import NewsEdit from './pages/Admin/NewsEdit';
import BannerAdd from './pages/Admin/BannerAdd';
import BannerList from './pages/Admin/BannerList';
import PosterList from './pages/Admin/PosterList';
import { ADMIN_LOGIN_REDIRECT_WITH_CODE } from './constants/api-endpoints';
import { fetchPost } from './utils/services/fetch';
import LoginRedirect from './pages/Admin/LoginRedirect';
import ContactUsPage from './pages/ContactUs';
import LoginCallbackGoogle from './pages/Admin/LoginRedirectGoogle';
import BannerEdit from './pages/Admin/BannerEdit';
import OpenDataNewsPage from './pages/OpenData/OpenDataNews';


export const Router = () => {
  return (
    <Switch>
      <PublicRoute exact path={routeUrlProvider.getForRoute(INFO)} component={Info} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(HOME)} component={HomePage} />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(FOREIGN_LANGUAGE_CONDITION)}
        component={ForeignLanguageConditionPage}
      />
      <PublicRoute exact path={routeUrlProvider.getForRoute(NEWS)} component={NewsPage} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(NEWS_DETAIL)} component={NewsDetailPage} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(ABOUT)} component={AboutPage} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(QUALIFY_APPLICATION)} component={QualifyApplicationPage} />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(ORGANIZATION_STRUCTURE)}
        component={OrganizationStructurePage}
      />
      <PublicRoute exact path={routeUrlProvider.getForRoute(VISION_AND_MISION)} component={VisionAndMissionPage} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(DIRECTIVE_BOARD)} component={DirectiveBoardPage} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(EXECUTIVE_CALENDAR)} component={ExecutiveCalendar} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(KM)} component={KmPage} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(OPEN_DATA)} component={OpenDataPage} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(OPEN_DATA_2565)} component={OpenDataPage2565} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(OPEN_DATA_2566)} component={OpenDataPage2566} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(GRADUATE_SCHOOL_TEAM)} component={GraduateSchoolTeamPage} />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(GRADUATE_SCHOOL_OFFICE)}
        component={GraduateSchoolOfficePage}
      />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(GENERAL_ADMINISTRATION)}
        component={GeneralAdministrationPage}
      />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(FINANCIAL_TREASURY_AND_INVENTORY)}
        component={FinancialTreasuryandInventoryPage}
      />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(POLICY_AND_PLANNING_SECTION)}
        component={PolicyandPlanningSectionPage}
      />
      <PublicRoute exact path={routeUrlProvider.getForRoute(ACADEMIC_SERVICE)} component={AcademicServicePage} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(STUDENT_DEVELOPMENT)} component={StudentDevelopmentPage} />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(INTERDISCIPLINARY_PROGRAM_AND_QA)}
        component={InterdisciplinaryProgramandQAPage}
      />

      <PublicRoute exact path={routeUrlProvider.getForRoute(EXECUTIVE)} component={ExecutivePage} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(FACTS_AND_FIGURES)} component={FactsAndFiguresPage} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(PROGRAM_TUTION_FEE)} component={ProgramTutionFeePage} />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(INTERDISCIPLINARY_PROGRAMS)}
        component={InterdisciplinaryProgramsPage}
      />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(INTERDISCIPLINARY_PROGRAM_AND_QA)}
        component={InterdisciplinaryProgramandQAPage}
      />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(INTERDISCIPLINARY_PROGRAMS_MAN_AND_ENVIRONMENTAL)}
        component={ManAndEnvironmentalManagementPage}
      />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(INTERDISCIPLINARY_PROGRAMS_BIOTECTHNOLOGY)}
        component={BiotechnologyPage}
      />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(INTERDISCIPLINARY_PROGRAMS_FORENSICS_SCIENCE)}
        component={ForensicSciencePage}
      />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(INTERDISCIPLINARY_PROGRAMS_SPORT_SCIENCE)}
        component={SportsSciencePage}
      />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(INTERDISCIPLINARY_PROGRAMS_TECHNOLOGY_AND_INTERDISCIPLINARY)}
        component={TechnologyAndInterdisciplinaryManagementPage}
      />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(INTERDISCIPLINARY_PROGRAMS_MENTAL_HEALTH)}
        component={MentalHealthPage}
      />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(INTERDISCIPLINARY_PROGRAMS_CONTEMPORARY_CHINESE_LANGUAGE_TEACHING)}
        component={ContemporaryChineseLanguageTeachingPage}
      />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(INTERDISCIPLINARY_PROGRAMS_INTEGRATED_SCIENCE)}
        component={IntegratedSciencePage}
      />
      <PublicRoute exact path={routeUrlProvider.getForRoute(POLICY_AND_PLAN)} component={PolicyAndPlanPage} />

      <PublicRoute exact path={routeUrlProvider.getForRoute(ANNUA_REPORT)} component={AnnualReport} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(LIFE_CMU)} component={LifeCMU} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(PODCAST_LIVE)} component={PodcastLive} />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(ENROLLMENT_ACADEMIC_CALENDAR)}
        component={EnrollmentAcademicCalendarPage}
      />
      <PublicRoute exact path={routeUrlProvider.getForRoute(ADMISSION_PROCESS)} component={AdmissionProcessPage} />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(REGULATIONS_ANNOUNCEMENT_GUIDELINES)}
        component={RegulationsAnnouncementGuidelinesPage}
      />

      <PublicRoute exact path={routeUrlProvider.getForRoute(THESIS)} component={ThesisPage} />

      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(THESIS_AWARD_IN_GRADUATE_SCHOOL_CMU)}
        component={ThesisAwardInGraduateSchoolCmuPage}
      />
      <PublicRoute exact path={routeUrlProvider.getForRoute(GRADUATION)} component={GraduationPage} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(GUIDELINE_PROCEDURE)} component={GuidelineProcedurePage} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(VISA)} component={VisaPage} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(QUALIFY_APPLICATION)} component={QualifyApplicationPage} />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(GRADUATE_STUDIES_COMMITTEE_AND_ADMINISTRATION)}
        component={GraduateStudiesCommitteeAndAdministrationPage}
      />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(ANNOUNCEMENTS_REGULATIONS_GUIDELINES)}
        component={AnnouncementsRegulationsGuidelinesPage}
      />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(CURRICULUM_GUIDELINES)}
        component={CurriculumGuidelinesPage}
      />
      <PublicRoute exact path={routeUrlProvider.getForRoute(COURSE_TEMPLATE)} component={CourseTemplatePage} />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(GRADUATE_STUDENTS_ACCEPTANCE)}
        component={GraduateStudentsAcceptancePage}
      />
      <PublicRoute exact path={routeUrlProvider.getForRoute(CRITERIA_GRADUATION)} component={CriteriaGraduationPage} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(LECTURER)} component={LecturerPage} />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(ANNUAL_MEETING_SCHEDULE)}
        component={AnnualMeetingSchedulePage}
      />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(GRADUATION_PROPOSAL_DOCUMENTS_SCHEDULE)}
        component={GraduationProposalDocumentsSchedulePage}
      />
      <PublicRoute exact path={routeUrlProvider.getForRoute(ANNUA_REPORT)} component={AnnualReport} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(LIFE_CMU)} component={LifeCMU} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(SEARCH_BY_FACULTY)} component={SearchByFacultyPage} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(SCHOLARSHIP)} component={ScholarshipPage} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(DEAN_REPORT)} component={DeansReportPage} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(PROSPECTUS)} component={ProspectusPage} />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(CHECKLIST_OF_JOURNALS)}
        component={CheckListOfJournalsPage}
      />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(THESIS_DISSERTATION_IS_CHECKING)}
        component={ThesisDissertationIScheckingPage}
      />
      <PublicRoute exact path={routeUrlProvider.getForRoute(CONTACT_US)} component={ContactUsPage} />
      <PublicRoute exact path={routeUrlProvider.getForRoute(OPEN_DATA_NEWS)} component={OpenDataNewsPage} />

      <PrivateRoute path={routeUrlProvider.getForRoute(DASHBOARD)} component={() => <div>Mock Dashboard</div>} />

      {/* Admin */}
      <PrivateRoute exact path={routeUrlProvider.getForRoute(AdminRoutePath.ADMIN_NEWS_LIST)} component={NewsList} />
      <PrivateRoute
        exact
        path={routeUrlProvider.getForRoute(AdminRoutePath.ADMIN_NEWS_CREATE)}
        component={NewsCreate}
      />
      <PrivateRoute exact path={routeUrlProvider.getForRoute(AdminRoutePath.ADMIN_NEWS_EDIT)} component={NewsEdit} />
      <PrivateRoute
        exact
        path={routeUrlProvider.getForRoute(AdminRoutePath.ADMIN_BANNER_LIST)}
        component={BannerList}
      />
      <PrivateRoute exact path={routeUrlProvider.getForRoute(AdminRoutePath.ADMIN_BANNER_ADD)} component={BannerAdd} />
      <PrivateRoute
        exact
        path={routeUrlProvider.getForRoute(AdminRoutePath.ADMIN_BANNER_EDIT)}
        component={BannerEdit}
      />
      <PrivateRoute
        exact
        path={routeUrlProvider.getForRoute(AdminRoutePath.ADMIN_POSTER_LIST)}
        component={PosterList}
      />
      <PrivateRoute exact path={routeUrlProvider.getForRoute(AdminRoutePath.ADMIN_POSTER_ADD)} component={PosterAdd} />

      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(AdminRoutePath.ADMIN_LOGIN_REDIRECT)}
        component={LoginRedirect}
      />
      <PublicRoute
        exact
        path={routeUrlProvider.getForRoute(AdminRoutePath.ADMIN_LOGIN_REDIRECT_GOOGLE)}
        component={LoginCallbackGoogle}
      />

      <Redirect from="*" to={routeUrlProvider.getForRoute(HOME)} />
    </Switch>
  );
};

export default Router;
