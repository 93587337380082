import React from 'react';
import { useTranslationUtil } from '../../../../hooks/useTranslationUtil';
import WomanImg from './assets/1.png';
import CustomContainer from '../../../../components/CustomContainer';

const AddmissionHeader = () => {
  const { t, getFontFamily } = useTranslationUtil();
  return (
    <>
      <div className="bg-home-admission pt-12 md:pt-[300px] lg:mt-0 lg:pt-20">
        <CustomContainer className=" overflow-hidden md:overflow-visible">
          {/* bubble with woman inside */}
          <div className="absolute mt-[350px] h-9 md:mt-[170px] lg:relative lg:mt-0 lg:h-0">
            <div className="relative right-14 h-[170px] w-[170px] overflow-hidden rounded-full bg-CMUPurple md:h-[230px] md:w-[230px] lg:right-[33px] lg:h-[300px] lg:w-[300px]">
              <div className="relative left-3">
                <img src={WomanImg} alt="" className="ml-10 mt-2 h-[150px] md:h-[229px] lg:ml-0 lg:h-[360px] " />
              </div>
            </div>
          </div>
          <div>
            <div className="text-center text-5xl font-medium leading-[60px] text-UIBlack">{t('2023 Admission')}</div>
            <div className="mx-auto w-[342px] text-center text-5xl font-medium  leading-[60px]  text-UIBlack md:w-full md:text-3xl md:leading-10">
              {t('Boost Your Success')}
            </div>
            <div className="mx-auto mt-8 h-2 w-20 bg-CMUPurple" />
            <p
              className={`${getFontFamily(
                true
              )} mx-auto mt-4 max-w-[294px] text-center text-base leading-[22px] text-Lightgray800 md:max-w-[434px]`}
            >
              {t('Admission Detail')}
            </p>
            <div className="mt-6 text-center">
              <button
                onClick={() =>
                  window.open(
                    'https://cmu.to/apply2cmu',
                    '_blank',
                    'noopener,noreferrer'
                  )
                }
              >
                <div className=" mt-4 h-[55px] w-[142px] rounded-sm bg-NeonPink text-center leading-[55px] text-UIWhite duration-300 hover:scale-105 hover:border-2 hover:border-NeonPink hover:bg-UIWhite hover:text-NeonPink">
                  {t('ApplyNow')}
                </div>
              </button>
            </div>
          </div>
          {/* Little bubble on right side */}
          <div className="flex flex-row justify-end">
            <div className="relative bottom-72 h-[69px] w-[69px] rounded-full bg-CMUPurple mix-blend-multiply xs:left-5 md:left-0 md:bottom-40" />
          </div>
        </CustomContainer>
      </div>
    </>
  );
};

export default AddmissionHeader;
