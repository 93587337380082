import React from 'react';
import BackButton from '../../../components/Button/BackButton';
import { INTERDISCIPLINARY_PROGRAMS } from '../../../constants/route-paths';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';

const BackToInterdisciplinaryProgramsButton = () => {
  const { t } = useTranslationUtil();
  return (
    <div className="mt-10 pl-6 md:pl-8 lg:pl-20">
      <BackButton goToRoute={INTERDISCIPLINARY_PROGRAMS}>{t('BackToInterdisciplinaryPrograms')}</BackButton>
    </div>
  );
};

export default BackToInterdisciplinaryProgramsButton;
