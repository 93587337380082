export const RightArrow16pxIcon = ({ className }) => {
  const fillClass = className ? className : '#78909C';
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={fillClass}
    >
      <path
        d="M6.6 1.9L12.2 7.5H0V9.5H12.2L6.6 15.1L8 16.5L16 8.5L8 0.5L6.6 1.9Z"
        fill={fillClass}
      />
    </svg>
  );
};
export default RightArrow16pxIcon;
