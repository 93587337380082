import React from 'react';
import CollapseItem from '../../components/CollapseSection/CollapseItem';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';

import กำหนดการรับเอกสาร67 from './assets/กำหนดการรับเอกสารเสนอสำเร็จการศึกษาฯ พ.ศ.2567-20231206.doc';
import กำหนดการรับเอกสาร66 from './assets/กำหนดการรับเอกสารเสนอสำเร็จการศึกษาฯ-พ.ศ.25.doc';
import กำหนดการรับเอกสาร65 from './assets/FM-AS-A-01.doc';
import กำหนดการรับเอกสาร64 from './assets/ตารางส่งเอกสารจากส่วนงาน-ใหม่64-ตามมติ-กบม.pdf';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';
import CollapseSectionContainer from '../../components/CollapseSection/CollapseSectionContainer';

const GraduationProposalDocumentsSchedulePage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer forceUseThFont>
          {t('กำหนดการรับเอกสารเสนอสำเร็จการศึกษา และการขยายระยะเวลาการศึกษา')}
        </PageTitleContainer>
        <CollapseSectionContainer>
          <CollapseItem pdfUrl={กำหนดการรับเอกสาร67} forceUseThFont>
            {t('กำหนดการรับเอกสารเสนอสำเร็จการศึกษา และการขยายระยะเวลาการศึกษา ปี 2567')}
          </CollapseItem>
          <CollapseItem pdfUrl={กำหนดการรับเอกสาร66} forceUseThFont>
            {t('กำหนดการรับเอกสารเสนอสำเร็จการศึกษา และการขยายระยะเวลาการศึกษา ปี 2566')}
          </CollapseItem>
          <CollapseItem pdfUrl={กำหนดการรับเอกสาร65} forceUseThFont>
            {t('กำหนดการรับเอกสารเสนอสำเร็จการศึกษา และการขยายระยะเวลาการศึกษา ปี 2565')}
          </CollapseItem>
          <CollapseItem pdfUrl={กำหนดการรับเอกสาร64} forceUseThFont>
            {t('กำหนดการรับเอกสารเสนอสำเร็จการศึกษา และการขยายระยะเวลาการศึกษา ปี 2564')}
          </CollapseItem>
        </CollapseSectionContainer>
      </CustomContainer>
    </Layout>
  );
};

export default GraduationProposalDocumentsSchedulePage;
