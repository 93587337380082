export const MOCK_STUDENT_PIE_GRAPH_GROUP_BY_NATIONALITY = [
  // {
  //   name_th: 'ไทย',
  //   name_en: 'Thailand',
  //   student_amount: 5602,
  //   student_percentage: '82.00%',
  // },
  // {
  //   name_th: 'จีน',
  //   name_en: 'China',
  //   student_amount: 617,
  //   student_percentage: '9.03%',
  // },
  // {
  //   name_th: 'พม่า',
  //   name_en: 'Myanmar',
  //   student_amount: 172,
  //   student_percentage: '2.52%',
  // },
  // {
  //   name_th: 'ลาว',
  //   name_en: 'Lao',
  //   student_amount: 83,
  //   student_percentage: '1.21%',
  // },
  // {
  //   name_th: 'ปากีสถาน',
  //   name_en: 'Pakistan',
  //   student_amount: 74,
  //   student_percentage: '1.08%',
  // },
  // {
  //   name_th: 'อินโดนีเซีย',
  //   name_en: 'Indonesia',
  //   student_amount: 74,
  //   student_percentage: '1.08%',
  // },
  // {
  //   name_th: 'อื่น ๆ',
  //   name_en: 'Others',
  //   student_amount: 284,
  //   student_percentage: '4.16%',
  // },
  {
    name_th: 'จีน',
    name_en: 'China',
    student_amount: 617,
    student_percentage: '50.16%',
  },
  {
    name_th: 'พม่า',
    name_en: 'Myanmar',
    student_amount: 172,
    student_percentage: '13.98%',
  },
  {
    name_th: 'ลาว',
    name_en: 'Lao',
    student_amount: 83,
    student_percentage: '6.75%',
  },
  {
    name_th: 'ปากีสถาน',
    name_en: 'Pakistan',
    student_amount: 74,
    student_percentage: '6.02%',
  },
  {
    name_th: 'อินโดนีเซีย',
    name_en: 'Indonesia',
    student_amount: 74,
    student_percentage: '2.44%',
  },
  {
    name_th: 'อื่น ๆ',
    name_en: 'Others',
    student_amount: 284,
    student_percentage: '20.65%',
  },
];
