import React from 'react';
import Layout from '../../components/Layout';
import O6_2 from './assets/O6_2-ข้อบังคับมช-ว่าด้วยการศึกษาระดับ-บศ-2566.pdf';
import O6_3 from './assets/O6_3-ข้อบังคับการสรรหาคณบดีบัณฑิตวิทยาลัย.pdf';
import O10 from './assets/O10-CMU-Privacy-Policy.pdf';
import O12 from './assets/O12-ผลการดำเนินงานไตรมาส 2.pdf';
import O13 from './assets/O13-ผลการดำเนินงานปีงบประมาณ 2565.pdf';
import O14_1 from './assets/O14_1-ระเบียบปฏิบัติการจัดการแผน(วันบังคับใช้ 15.03.66).pdf';
import O14_2 from './assets/O14_2-วิธีปฏิบัติงานการทบทวนและวางแผน (วันบังคับใช้ 9.9.65).pdf';
import O14_3 from './assets/O14_3-วิธีปฏิบัติงานการกำกับ ติดตามและรายงานผล (วันบังคับใช้ 15.03.66).pdf';
import O15 from './assets/O15-คู่มือการจัดการข้อร้องเรียน.pdf';
import O15_1 from './assets/O15_1-Grad-CMU-Academic-Activity-Procedure.pdf';
import O15_2 from './assets/O15_2-publish_7.pdf';
import O15_3 from './assets/O15_3-แนวปฏิบัติการลาพัก-Request_Leave_step.pdf';
import O15_4 from './assets/O15_4-แนวปฏิบัติการขอย้ายสาขาวิชา.pdf';
import O16 from './assets/O16-สถิติการให้บริการ.pdf';
import O17 from './assets/O17-รายงานความพึงพอใจ ปีการศึกษา 65.pdf';
import O19 from './assets/O19-แผนปฎิบัติการจัดซื้อจัดจ้าง ปีงบ 2566.pdf';
import O21_1 from './assets/O21_1-ประกาศผลจัดซื้อจัดจ้างตุลา 65.pdf';
import O21_3 from './assets/O21_3-ประกาศผลจัดซื้อจัดจ้างธันวา 65.pdf';
import O21_4 from './assets/O21_4-ประกาศผลจัดซื้อจัดจ้างมกรา 66.pdf';
import O21_5 from './assets/O21_5-ประกาศผลจัดซื้อจัดจ้างกุมภา 66.pdf';
import O21_6 from './assets/O21_6-ประกาศผลจัดซื้อจัดจ้างมีนา 66.pdf';
import O22 from './assets/O22-รายงานผลการจัดซื้อจัดจ้างประจำปี 2565.pdf';
import O23 from './assets/O23-แผนพัฒนาบุคลากร ปี 2566.pdf';
import O24 from './assets/O24-รายงานความก้าวการดำเนินงานแผนพัฒนาบุคลากร66 (ไตรมาส 1-2).pdf';
import O25 from './assets/O25-หลักเกณฑ์และการสรรหาคณบดี.pdf';
import O25_1 from './assets/O25_1-หลักเกณฑ์การบรรจุพนักงานประจำ.pdf';
import O25_2 from './assets/O25-2-หลักเกณฑ์จ้างพนักงานชั่วคราว.pdf';
import O25_3 from './assets/O25_2-แบบประเมินผลการปฏิบัติงานของบุคลากรบัณฑิตวิทยาลัย.pdf';
import O25_4 from './assets/O25_4-ประกาศหลักเกณฑ์การประเมินผลการปฏิบัติงาน บว.pdf';
import O25_5 from './assets/O25_3-ตารางมาตรฐานภาระงาน.pdf';
import O25_6 from './assets/O25-QP-GA-01 การบริหารทรัพยากรบุคคล 9.9.65.pdf';
import O26 from './assets/O26-รายงานผลการบริหารและพัฒนาทรัพยากรบุคคลประจำปี งบประมาณ 2565.pdf';
import O29 from './assets/O29-รายงานข้อร้องเรียนการทุจริต.pdf';
import O31 from './assets/O31-นโยบาย NO Gift Policy.pdf';
import O32 from './assets/O32-กิจกรรม No gift policy.pdf';
import O33 from './assets/O33-รายงานการรับของขวัญ 2566 บัณฑิตวิทยาลัย.pdf';
import O34 from './assets/O34-ความเสี่ยงทุจริตและประพฤติมิชอบ.pdf';
import O35_1 from './assets/O35_1-รายงานผลการประเมินความเสี่ยงไตรมาส 1.pdf';
import O35_2 from './assets/O35_2-รายงานผลการประเมินความเสี่ยงไตรมาส 2.pdf';
import O36 from './assets/O36-แผนปฏิบัติการป้องกันการทุจริต.pdf';
import O37 from './assets/O37-รายงานการกำกับติดตามการดำเนินงานป้องกันการทุจริตประจำปี รอบ 6 เดือน.pdf';
import O38 from './assets/O38-รายงานผลการดำเนินการป้องกันการทุจริตประจำปี.pdf';
import O39_1 from './assets/O39_1-ข้อบังคับ - ประมวลผลจริยธรรรมผู้ปฏิบัติงาน มช.pdf';
import O39_2 from './assets/O39_2-ประมวลจริยธรรม มช.pdf';
import O39_3 from './assets/O39_3-ประมวลจริยธรรมข้าราชการพลเรือนในสถาบันอุดมศึกษา.pdf';
import O39_4 from './assets/O39_4-ประมวลจริยธรรมผู้ปฏิบัติงานใน มช.pdf';
import O40_1 from './assets/O40_1-คณะทำงานขับเคลื่อนคุณธรรมและความโปร่งใส บัณฑิตวิทยาลัย.pdf';
import O40_2 from './assets/O40_2-Dos Donts.pdf';
import O40_3 from './assets/O40_3-Grad Meeting-No Gift Policy.pdf';
import O41 from './assets/O41-แบบรายงานการนำผลการประเมินจริยธรรม (ลายเซ็นต์).pdf';
import O42 from './assets/O42-รายงานประเมินผล CMU-ITA 65.pdf';
import O43 from './assets/O43-การดำเนินการตามมาตรการส่งเสริมคุณธรรมและความโปร่งใสภายในส่วนงาน.pdf';
import O43_2 from './assets/O43_2-ประชาสัมพันธ์ EIT หน้าเว็บไซต์.pdf';
import O43_3 from './assets/O43_3-ประชาสัมพันธ์ EIT.pdf';
import routeUrlProvider, {
  ANNOUNCEMENTS_REGULATIONS_GUIDELINES,
  CONTACT_US,
  EXECUTIVE,
  HOME,
  NEWS,
  OPEN_DATA_NEWS,
  ORGANIZATION_STRUCTURE,
  VISION_AND_MISION,
} from '../../constants/route-paths';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import CustomContainer from '../../components/CustomContainer';
import TableContainer from '../../components/CommonTable/TableContainer';
import Row from '../../components/CommonTable/Row';
import Cell from '../../components/CommonTable/Cell';
import DetailItemLink from '../../components/CommonTable/DetailItemLink';
import DetailItem from '../../components/CommonTable/DetailItem';
import { NEWS_TYPES } from '../../utils/news';
import { OPEN_DATA_NEWS_KEY } from './OpenDataNews/news-data';

const OpenDataPage2566 = () => {
  // const history = useHistory();
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <div className="bg-gradien-GradPink-to-CMUPurple pt-[32px] pb-[48px] text-center text-[56px] font-medium leading-[71px] text-UIWhite  ">
          {t('OIT')}
        </div>
        <div className=" mt-[56px] font-ibm-plex-sans text-UIBlack">
          <div className="text-center text-[32px] leading-[52px]">{t('OIT2566')}</div>
          <TableContainer>
            <table class="mx-auto table-fixed">
              <thead>
                <tr>
                  <th className="pb-8 pl-10 text-left text-[20px] font-bold">รายการข้อมูล</th>
                  <th className="pb-8 pl-14 text-left text-[20px] font-bold">รายละเอียดของข้อมูล</th>
                </tr>
              </thead>
              <tbody className=" border-t-2 border-Lightgray400">
                <Row>
                  <Cell>O1-โครงสร้าง</Cell>
                  <Cell>
                    <DetailItemLink url={routeUrlProvider.getForRoute(ORGANIZATION_STRUCTURE)}>
                      โครงสร้างองค์กรและการบริหารงานของบัณฑิตวิทยาลัย
                    </DetailItemLink>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O2-ข้อมูลผู้บริหาร</Cell>
                  <Cell>
                    <DetailItem>
                      <DetailItemLink url={routeUrlProvider.getForRoute(EXECUTIVE)}>
                        รายนามของผู้บริหารของบัณฑิตวิทยาลัย
                      </DetailItemLink>
                    </DetailItem>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O3-อำนาจหน้าที่ </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={routeUrlProvider.getForRoute(VISION_AND_MISION)}>
                        หน้าที่และความรับผิดชอบ
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O4-แผนยุทธศาสตร์หรือแผนพัฒนาส่วนงาน</Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url="https://cmu.to/UYMr3">
                        แผนพัฒนาการศึกษาระดับบัณฑิตศึกษามหาวิทยาลัยเชียงใหม่ ระยะที่ 13
                      </DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url="https://cmu.to/As9zK">แผนยุทธศาสตร์ 4 ปี บัณฑิตวิทยาลัย</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O5-ข้อมูลการติดต่อ </Cell>
                  <Cell>
                    <DetailItemLink url={routeUrlProvider.getForRoute(CONTACT_US)}>
                      รายละเอียดเพื่อติดต่อบัณฑิตวิทยาลัย
                    </DetailItemLink>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O6-กฎหมายที่เกี่ยวข้อง </Cell>
                  <Cell>
                  <div>
                    <DetailItemLink url="https://w3.grad.cmu.ac.th/regulations-announcement-guidelines">
                      กฎหมายที่เกี่ยวข้องกับการดำเนินงาน</DetailItemLink>
                      </div><div>
                    <DetailItemLink url={O6_2}>ข้อบังคับ มช. ว่าด้วยการศึกษาระดับบัณฑิตศึกษา พ.ศ.2566</DetailItemLink>
                  </div><div>
                    <DetailItemLink url={O6_3}>ข้อบังคับว่าด้วยการสรรหาคณบดีบัณฑิตวิทยาลัย</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O7-ข่าวประชาสัมพันธ์</Cell>
                  <Cell>
                    <DetailItemLink
                      url={`${routeUrlProvider.getForRoute(NEWS)}?type=${NEWS_TYPES.GRADUATE_SCHOOL_NEWS}`}
                    >
                      ข่าวประชาสัมพันธ์และกิจกรรมของบัณฑิตวิทยาลัย
                    </DetailItemLink>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O8-Q&A</Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url="https://w3.grad.cmu.ac.th/contact-us">
                        ช่องทางการสื่อสารหลักของบัณฑิตวิทยาลัย
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O9-Social Network</Cell>
                  <Cell>
                    <div>GSCMU Social Network</div>
                  <div>
                      <DetailItemLink url="https://www.facebook.com/gradcmu/">
                        Facebook
                      </DetailItemLink>
                      </div><div>
                      <DetailItemLink url="https://www.linkedin.com/in/graduate-school-cmu-a917b1182">
                        Linkedin
                      </DetailItemLink>
                      </div><div>
                      <DetailItemLink url="https://www.instagram.com/gradcmu/">
                        Instagram
                      </DetailItemLink>   
                      </div><div>   
                      <DetailItemLink url="https://twitter.com/i/flow/login?redirect_after_login=%2Fgradcmu">
                        Twitter
                      </DetailItemLink>  
                      </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O10-นโยบายคุ้มครองข้อมูลส่วนบุคคล</Cell>
                  <Cell>
                    <DetailItemLink url={O10}>นโยบายคุ้มครองข้อมูลส่วนบุคคล</DetailItemLink>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O11-แผนดำเนินงานและการใช้งบประมาณประจำปี</Cell>
                  <Cell>
                  <div>
                    <DetailItemLink url="https://cmu.to/YKSRW">
                      แผนปฏิบัติการประจำปีงบประมาณ 2566 (ฉบับปรับปรุง กรกฎาคม 2566)
                    </DetailItemLink>
                    </div><div>
                    <DetailItemLink url="https://cmu.to/7j3SV">
                    แผนปฏิบัติการประจำปีงบประมาณ 2566 (ฉบับปรับปรุง ธันวาคม 2565)
                    </DetailItemLink>
                  </div><div>
                    <DetailItemLink url="https://cmu.to/pNRpP">
                    แผนปฏิบัติการประจำปีงบประมาณ 2566
                    </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                </Row>
                <Row>
                  <Cell>O12-รายงานการกำกับติดตามการดำเนินงานและการใช้งบประมาณประจำปีรอบ 6 เดือน</Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O12}>รายงานความก้าวหน้าการดำเนินงานประจำปี 2566 (รอบ 6 เดือน)</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O13-รายงานผลการดำเนินงานประจำปี</Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O13}>รายงานผลการดำเนินงานประจำปี 2565</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O14-คู่มือมาตรฐานการปฏิบัติงาน </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O14_1}>
                      ระเบียบปฏิบัติการจัดการแผน(วันบังคับใช้ 15.03.66)
                      </DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O14_2}> 
                      วิธีปฏิบัติงานการทบทวนและวางแผน (วันบังคับใช้ 9.9.65)
                      </DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O14_3}>
                        วิธีปฏิบัติงานการกำกับ ติดตามและรายงานผล (วันบังคับใช้ 15.03.66)
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O15-คู่มือหรือมาตรฐานการให้บริการ </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url="https://w3.grad.cmu.ac.th/guideline-procedure">
                        คู่มือ หลักเกณฑ์และแนวปฏิบัติของการจัดการบัณฑิตศึกษา
                      </DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O15}> คู่มือการจัดการข้อผิดพลาด ข้อบกพร่อง จากการปฏิบัติงาน</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O15_1}> แนวปฏิบัติและขั้นตอนการเสนอและพัฒนาหลักสูตร</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O15_2}> แนวปฏิบัติการตรวจสอบนักศึกษาพ้นสภาพ</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O15_3}> แนวปฏิบัติการลาพักการศึกษา</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O15_4}> แนวปฏิบัติการขอย้ายสาขา</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O16-ข้อมูลเชิงสถิติการให้บริการ </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O16}> สถิติการให้บริการ ประจำปีงบประมาณ 2566 (รอบ 6 เดือน)</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O17-รายงานผลการสำรวจความพึงพอใจการให้บริการ </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O17}> ผลสำรวจความพึงพอใจการให้บริการของบัณฑิตวิทยาลัย</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O18-E-Service</Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url="https://apply.grad.cmu.ac.th/certificate/dist/index.html">
                        ระบบขอหนังสือรับรองแบบออนไลน์
                      </DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url="https://admission.grad.cmu.ac.th/">
                        ระบบรับสมัครนักศึกษาบัณฑิตศึกษาแบบออนไลน์
                      </DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url="https://student.grad.cmu.ac.th">
                        ระบบจัดการข้อมูลส่วนบุคคลของนักศึกษาบัณฑิตศึกษา
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O19-แผนการจัดซื้อจัดจ้างหรือแผนการจัดหาพัสดุ </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O19}> แผนการจัดหาพัสดุประจำปีงบประมาณ 2566</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O20-ประกาศต่างๆ เกี่ยวกับการจัดซื้อจัดจ้าง หรือแผนการจัดหาพัสดุ </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={`${routeUrlProvider.getForRoute(NEWS)}?type=${NEWS_TYPES.SUPPLY_NEWS}`}> 
                      SUPPLY NEWS
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O21-สรุปผลการจัดซื้อจัดจ้างหรือการจัดหาพัสดุรายเดือน </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O21_1}> ผลการจัดซื้อจัดจ้างหรือการจัดหาพัสดุเดือนตุลาคม 2565 </DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O21_3}> ผลการจัดซื้อจัดจ้างหรือการจัดหาพัสดุเดือนธันวาคม 2565 </DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O21_4}> ผลการจัดซื้อจัดจ้างหรือการจัดหาพัสดุเดือนมกราคม 2566 </DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O21_5}> ผลการจัดซื้อจัดจ้างหรือการจัดหาพัสดุเดือนกุมภาพันธ์ 2566 </DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O21_6}> ผลการจัดซื้อจัดจ้างหรือการจัดหาพัสดุเดือนมีนาคม 2566 </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O22-รายงานผลการจัดซื้อจัดจ้างหรือการจัดหาพัสดุประจำปี </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O22}> รายงานผลการจัดซื้อจัดจ้าง ประจำปี 2565</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O23-นโยบายหรือแผนการบริหารทรัพยากรบุคคล </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O23}> แผนบริหารทรัพยากรบุคคล ประจำปี 2566</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O24-การดำเนินการตามนโยบายหรือแผนการบริหารทรัพยากรบุคคล </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O24}> รายงานความก้าวหน้าการดำเนินงานแผนพัฒนาบุคลากรปีงบประมาณ 2566 ไตรมาส 1-2 </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O25-หลักเกณฑ์การบริหาร และพัฒนาทรัพยากรบุคคล </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O25}> หลักเกณฑ์และวิธีการสรรหาคณบดี</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O25_1}>  หลักเกณฑ์การบรรจุพนักงานประจำ</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O25_2}> หลักเกณฑ์การจ้างพนักงานชั่วคราว</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O25_3}> หลักเกณฑ์การประเมินผลการปฏิบัติงาน</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O25_4}> ประกาศหลักเกณฑ์การประเมินผลการปฏิบัติงานสายปฏิบัติการบัณฑิตวิทยาลัย</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O25_5}> ตารางมาตรฐานภาระงาน</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O25_6}> ระเบียบวิธีปฏิบัติการบริหารทรัพยากรบุคคล บัณฑิตวิทยาลัย</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O26-รายงานผลการบริหารและพัฒนาทรัพยากรบุคคลประจำปี </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O26}> รายงานผลการบริหารและพัฒนาทรัพยากรบุคคลประจำปีงบประมาณ 2565</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O27-แนวปฏิบัติการจัดการเรื่องร้องเรียนการทุจริตและประพฤติมิชอบ </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url=""> แนวปฏิบัติการจัดการข้อร้องเรียน</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O28-ช่องทางแจ้งเรื่องร้องเรียนการทุจริตและประพฤติมิชอบ </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url="https://voc.cmu.ac.th/VOC1.aspx?OID=23">
                      ช่องทางการรับเรื่องร้องเรียนการทุจริตและประพฤติมิชอบ
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O29-ข้อมูลเชิงสถิติ เรื่องร้องเรียนการทุจริตและประพฤติมิชอบ </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O29}> ข้อมูลสถิติข้อร้องเรียนการทุจริตและประพฤติมิชอบ</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O30-การเปิดโอกาสให้เกิดการมีส่วนร่วม </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url="https://smart.grad.cmu.ac.th/?p=101">รายงานการประชุมคณะกรรมการบริหารวิชาการประจำบัณฑิตวิทยาลัย</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url="https://www.grad.cmu.ac.th/grad_wp2/?p=19971">บัณฑิตวิทยาลัยเปิดโอกาสให้ภาคอุตสาหกรรมมีส่วนร่วมในการเปิดหลักสูตร</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O31-ประกาศเจตนารมณ์นโยบาย No Gift Policy จากการปฏิบัติหน้าที่ </Cell>
                  <Cell>
                    <div>
                    <DetailItemLink url={O31}> นโยบายไม่รับของขวัญและของกำนัลทุกชนิดจากการปฏิบัติหน้าที่ (No Gift Policy)</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O32-การสร้างวัฒนธรรม No Gift Policy </Cell>
                  <Cell>
                    <div>
                    <DetailItemLink url={O32}> กิจกรรมปลุกจิตสำนึกและสร้างวัฒนธรรม No Gift Policy</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O33-รายงานผลตามนโยบาย No Gift Policy </Cell>
                  <Cell>
                    <div>
                    <DetailItemLink url={O33}> รายงานผลตามนโยบาย No Gift Policy</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O34-การประเมินความเสี่ยงการทุจริตและประพฤติมิชอบประจำปี </Cell>
                  <Cell>
                    <div>
                    <DetailItemLink url={O34}> ประเมินความเสี่ยงการทุจริตและประพฤติมิชอบประจำปี 2566</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O35-การดำเนินการเพื่อจัดการความเสี่ยงการทุจริตและประพฤติมิชอบ </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O35_1}> การดำเนินมาตรการควบคุมความเสี่ยงการทุจริตและประพฤติมิชอบ ไตรมาส 1/2566</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O35_2}> การดำเนินมาตรการควบคุมความเสี่ยงการทุจริตและประพฤติมิชอบ ไตรมาส 1/2566</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O36-แผนปฏิบัติการป้องกันการทุจริต </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O36}>
                      แผนปฏิบัติการป้องกันการทุจริต ประจำปี 2566
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O37-รายงานการกำกับติดตามการดำเนินการป้องกันการทุจริตประจำปี รอบ 6 เดือน </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O37}>
                      รายงานการดำเนินงานตามแผนปฏิบัติการป้องกันการทุจริต ประจำปี 2566 รอบ 6 เดือน
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O38-รายงานผลการดำเนินการป้องกันการทุจริตประจำปี </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O38}> รายงานผลการดำเนินการป้องกันการทุจริตประจำปี 2565</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O39-ประมวลจริยธรรมสำหรับเจ้าหน้าที่ของรัฐ </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O39_1}> ข้อบังคับว่าด้วยประมวลจริยธรรมผู้ปฏิบัติงาน มช.</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O39_2}> จริยธรรมผู้ปฏิบัติงาน</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O39_4}> ประมวลจริยธรรมผู้ปฏิบัติงานใน มช.</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O39_3}> ประมวลจริยธรรม ข้าราชการ พลเรือนในสถาบันอุดมศึกษา</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O40-การขับเคลื่อนจริยธรรม </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O40_1}> คณะทำงานขับเคลื่อนคุณธรรมและความโปร่งใสในการดำเนินงานบัณฑิตวิทยาลัย</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O40_2}> แนวปฏิบัติ Dos/Don’ts เพื่อเสริมสร้างมาตรฐานคุณธรรมจริยธรรมในการปฏิบัติงาน</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url="https://w3.grad.cmu.ac.th/news/01gw4btej6hyvwtz3abxsbdx3t"> ข่าวกิจกรรมส่งเสริมจริยธรรมของหน่วยงาน</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O40_3}> เอกสารประกอบการจัดกิจกรรมส่งเสริมจริยธรรมของหน่วยงาน</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O41-การประเมินจริยธรรมเจ้าหน้าที่ของรัฐ </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O41}>
                      รายงานการประเมินจริยธรรมไปใช้ในกระบวนการบริหารทรัพยากรบุคคล
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O42-มาตรการส่งเสริมคุณธรรมและความโปร่งใสภายในหน่วยงาน </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O42}>
                      รายงานการดำเนินการตามมาตรการส่งเสริมคุณธรรมและความโปร่งใสบัณฑิตวิทยาลัย
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>O43-การดำเนินการตามมาตรการส่งเสริมคุณธรรมและความโปร่งใสภายในหน่วยงาน </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O43}>ผลการดำเนินการตามมาตรการส่งเสริมคุณธรรมและความโปร่งใสภายในส่วนงาน</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O43_2}> แบบวัด EIT แบบที่ 1</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O43_3}> แบบวัด EIT แบบที่ 2</DetailItemLink>
                    </div>
                  </Cell>
                </Row>

              </tbody>
            </table>
          </TableContainer>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default OpenDataPage2566;
