import React, { useRef, useState } from 'react';
import PinkFulfilWithRightArrowButton from '../../components/Button/PinkFulfilWithRightArrowButton';
import CollapseSection from '../../components/CollapseSection';
import CollapseItem from '../../components/CollapseSection/CollapseItem';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import LanguageInstituteCMUImage from './assets/lil.jpg';
import HASCenterImage from './assets/has.png';
import pdf64 from './assets/pdf/Foreign Language Conditions for Graduate Students 64.pdf';
import pdf63 from './assets/pdf/Foreign Language Conditions for Graduate Students 63.pdf';
import pdf59_62 from './assets/pdf/Foreign Language Conditions for Graduate Students 59-62.pdf';
import PodcastImage from './assets/podcast.png';
import CircleBG from './assets/CircleBG.png';
import PodcastAudio from './assets/podcast/Grad-CMU-Podcast-EP.02.mp3';
import PlayIcon from './assets/playIcon.svg';
import ReactAudioPlayer from 'react-audio-player';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';
import CollapseSectionContainer from '../../components/CollapseSection/CollapseSectionContainer';

const EtagsItem = ({ imgSrc, title, subTitle, url }) => (
  <div
    className="flex w-full flex-col rounded-sm hover:cursor-pointer md:flex-row"
    onClick={() => {
      window.open(url, '_blank', 'noopener,noreferrer');
    }}
  >
    <img src={imgSrc} alt="" className=" w-full  object-cover md:h-[80px] md:w-[205px]" />
    <div className="my-auto flex w-full flex-row justify-between rounded-sm bg-gradient-pink-foreign-language-page md:h-[80px]">
      <div className="my-auto py-4 pl-4 md:pl-6">
        <div className=" text-2xl font-bold leading-6 text-UIBlack">{title}</div>
        <div className=" text-sm leading-6 text-UIBlack">{subTitle}</div>
      </div>
      <div className="my-auto p-4">
        <PinkFulfilWithRightArrowButton
          title=""
          onClick={() => {
            window.open(url, '_blank', 'noopener,noreferrer');
          }}
        />
      </div>
    </div>
  </div>
);

const ForeignLanguageConditionPage = () => {
  let audioPlayerRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    audioPlayerRef.current?.audioEl?.current?.play();
  };

  const handlePause = () => {
    audioPlayerRef.current?.audioEl?.current?.pause();
  };

  const handlePlayPause = () => {
    isPlaying ? handlePause() : handlePlay();
  };

  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{t('ForeignLanguageConditions')}</PageTitleContainer>
        <div className="mx-auto my-14 w-fit rounded-sm bg-Lightgray50 px-10 pt-6 pb-7 md:px-28">
          <div className="text-UIBlack">
            <div className=" mt-4 text-center text-[32px] font-medium leading-10">{t('SubmitScore')}</div>
            <div className="mt-3 justify-center text-center font-ibm-plex-sans text-base leading-[26px] md:flex md:flex-row">
              <div className="my-auto mr-6">{t('SubmitHere')}</div>
              <div className="mt-3 md:mt-0">
                <PinkFulfilWithRightArrowButton
                  onClick={() =>
                    window.open('http://student.grad.cmu.ac.th/index.php?p=100105070', '_blank', 'noopener,noreferrer')
                  }
                >
                  {t('Submit')}
                </PinkFulfilWithRightArrowButton>
              </div>
            </div>
          </div>
        </div>
        <CollapseSectionContainer>
          <CollapseSection title={t('ForeignLanguageConditionGuideline')}>
            <CollapseItem pdfUrl={pdf64}>{t('ForeignLanguageCondition64')}</CollapseItem>
            <CollapseItem pdfUrl={pdf63}>{t('ForeignLanguageCondition63')}</CollapseItem>
            <CollapseItem pdfUrl={pdf59_62}>{t('ForeignLanguageCondition5962')}</CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('CMUETEGS')}>
            <CollapseItem externalLinkUrl="https://www.li.cmu.ac.th/?page=etegs&detail=etegs_rule_regular">
              {t('CMUETEGSGuideline')}
            </CollapseItem>
          </CollapseSection>

          <div className=" mt-[72px] text-[32px] font-medium leading-10 text-UIBlack">{t('TestLanguageCenter')}</div>
          <div className=" mb-8 text-[64px] font-light leading-[81px] text-UIBlack">{t('CMUETEGS')}</div>

          <EtagsItem
            imgSrc={LanguageInstituteCMUImage}
            title={t('LanguageInstituteCMU')}
            subTitle={t('')}
            url={'https://www.li.cmu.ac.th/?p=th&page=etegs'}
          />
          <div className="mt-6">
            <EtagsItem
              imgSrc={HASCenterImage}
              title={t('HASCenter')}
              subTitle={t('')}
              url={'https://facebook.com/hascenter.chiangmaiuniversity/'}
            />
          </div>
        </CollapseSectionContainer>
        <div className="mb-52 mt-6 max-h-[633px] bg-gradient-CMUPurple-to-top-UIWhite">
          <div className=" pt-28 text-center text-[32px] font-medium leading-10 text-UIBlack">{t('PodcastLive')}</div>
          <div className=" pt-11 text-center font-open-sans text-base leading-[22px] text-UIBlack">
            {t('LetknowTests')}
          </div>
          <div className="relative flex flex-row justify-center ">
            <img src={CircleBG} alt="" className=" absolute z-0  hidden lg:inline-block xl:-mt-56  " />
            <div className="relative z-10 mt-5 rounded-sm">
              <div className={`flex flex-row justify-center hover:cursor-pointer ${isPlaying ? 'hidden' : ''}`}>
                <img src={PlayIcon} alt="" className=" absolute mt-28" onClick={handlePlayPause} />
              </div>

              <img
                src={PodcastImage}
                alt=""
                width="434px"
                height="434px"
                className=" mt-10 rounded-lg hover:cursor-pointer"
                onClick={() => handlePlayPause()}
              />
              <div className={` relative z-10 mt-1 flex flex-row justify-center  ${isPlaying ? '' : 'hidden'} `}>
                <ReactAudioPlayer
                  src={PodcastAudio}
                  ref={audioPlayerRef}
                  controls
                  onPlay={() => setIsPlaying(true)}
                  onPause={() => setIsPlaying(false)}
                  onEnded={() => setIsPlaying(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default ForeignLanguageConditionPage;
