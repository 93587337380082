import apiUrlProvider, { GET_POSTERS } from '../../constants/api-endpoints';
import { fetchGet } from '../../utils/services/fetch';

export const MOCK_AnounceImagesSliderData = [
  {
    imageUrl: 'https://sv1.picz.in.th/images/2022/12/06/Gnzink.jpg',
    thumbnail: 'https://sv1.picz.in.th/images/2022/12/06/Gnzink.th.jpg',
    url: 'https://www.google.co.th/?hl=th',
  },
  {
    imageUrl: 'https://sv1.picz.in.th/images/2022/12/06/GnzQKE.jpg',
    thumbnail: 'https://sv1.picz.in.th/images/2022/12/06/GnzQKE.th.jpg',
    url: 'https://www.google.co.th/?hl=th',
  },
  {
    imageUrl: 'https://sv1.picz.in.th/images/2022/12/06/GnzqeV.jpg',
    thumbnail: 'https://sv1.picz.in.th/images/2022/12/06/GnzqeV.th.jpg',
    url: 'https://www.google.co.th/?hl=th',
  },
];

export const getAnounceImagesSliderData = (language) => {
  const urlWithQuery = `${apiUrlProvider.get(GET_POSTERS)}?lang=${language}`;
  return fetchGet(urlWithQuery, null, false);
};
