export const MOCK_PRESIDENT_SCHOLARSHIP_STACK_BAR_GRAPH_GROUP_BY_YEAR = [
  {
    year: '2562',
    student_amount: 28,
    master: 8,
    doctoral: 20,
  },
  {
    year: '2563',
    student_amount: 53,
    master: 17,
    doctoral: 36,
  },
  {
    year: '2564',
    student_amount: 79,
    master: 36,
    doctoral: 43,
  },
  {
    year: '2565',
    student_amount: 93,
    master: 38,
    doctoral: 55,
  },
];
