import React from 'react';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import coverImage from './assets/cover_reduced.jpg';
import organizationStructure1Image from './assets/organization_structure_1_reduced.jpg';
import organizationStructure2Image from './assets/organization_structure_2_reduced.jpg';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainerWithBackgroundImage from '../../components/PageTitleContainerWithBackgroundImage';

const OrganizationStructurePage = () => {
  const { t } = useTranslationUtil();

  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainerWithBackgroundImage coverImage={coverImage} className="h-[250px] md:h-[195px]">
          {t('organization_structure')}
        </PageTitleContainerWithBackgroundImage>
        <div className="mt-14 px-20">
          <img src={organizationStructure1Image} alt="" />
        </div>
        <div className="mt-14 px-20">
          <img src={organizationStructure2Image} alt="" />
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default OrganizationStructurePage;
