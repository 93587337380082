import { fetchDelete, fetchGet } from '../../utils/services/fetch';

import apiUrlProvider, {
  GET_NEWS,
  GET_NEWS_ID,
  ADMIN_GET_NEWS_LIST,
  ADMIN_DELETE_NEWS,
} from '../../constants/api-endpoints';

export const getListNews = ({ newsTypeQuery, page = 1, perPage = 10, language = 'TH' }) => {
  const url = apiUrlProvider.get(GET_NEWS);
  const urlWithQuery = `${url}?page=${page}&perPage=${perPage}${
    newsTypeQuery ? `&type=${newsTypeQuery}` : ''
  }&lang=${language}`;
  return fetchGet(urlWithQuery, null, false);
};

export const getNewsById = (id) => {
  const url = apiUrlProvider.get(GET_NEWS_ID, { id });
  return fetchGet(url, null, false);
};
