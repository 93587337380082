import React from 'react';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import _1 from './assets/1.jpg';
import _2 from './assets/2.jpg';
import _3 from './assets/3.jpg';
import _4 from './assets/4.jpg';
import ThinkingImg from './assets/thinking.svg';
import CustomContainer from '../../components/CustomContainer';
import StepImage from '../../components/AddmissionProcess/StepImage';
import StepNumberCircle from '../../components/AddmissionProcess/StepNumberCircle';
import StepTitle from '../../components/AddmissionProcess/StepTitle';
import StepDescription from '../../components/AddmissionProcess/StepDescription';
import StepDetailContainer from '../../components/AddmissionProcess/StepDetailContainer';
import StepSectionContainer from '../../components/AddmissionProcess/StepSectionContainer';
import StepDescriptionContainer from '../../components/AddmissionProcess/StepDescriptionContainer';
import PageTitleContainer from '../../components/PageTitleContainer';

const ContentContainer = ({ children }) => <div className="px-3 md:px-10 lg:px-14 xl:px-20">{children}</div>;

const AdmissionProcessPagePage = () => {
  const { t } = useTranslationUtil();

  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{t('Admission Process')}</PageTitleContainer>
        <div className="lg:mx-auto lg:w-fit">
          <StepSectionContainer>
            <StepImage src={_1} alt="" className="lg:-mt-5 " />
            <StepDetailContainer>
              <StepNumberCircle>1</StepNumberCircle>
              <StepDescriptionContainer>
                <StepTitle>{t('Admission/Scholarship Apply')}</StepTitle>
                <StepDescription>{t('Admission/ScholarshipApplyDetail')}</StepDescription>
              </StepDescriptionContainer>
            </StepDetailContainer>
          </StepSectionContainer>
          <StepSectionContainer className="flex flex-row pt-14 pb-6">
            <StepImage src={_2} alt="" />
            <StepDetailContainer>
              <StepNumberCircle>2</StepNumberCircle>
              <StepDescriptionContainer>
                <StepTitle>{t('Candidate Announcement / Interview schedule/Scholarship Apply')}</StepTitle>
                <StepDescription>
                  {t('Candidate Announcement / Interview schedule/Scholarship Apply Detail')}
                </StepDescription>
              </StepDescriptionContainer>
            </StepDetailContainer>
          </StepSectionContainer>
          <StepSectionContainer className="flex flex-row  py-10">
            <StepImage src={_3} alt="" />
            <StepDetailContainer>
              <StepNumberCircle>3</StepNumberCircle>
              <StepDescriptionContainer>
                <StepTitle>{t('Admission / Scholarship Result Announcement')}</StepTitle>
                <StepDescription>{t('Admission / Scholarship Result Announcement detail')}</StepDescription>
              </StepDescriptionContainer>
            </StepDetailContainer>
          </StepSectionContainer>
          <StepSectionContainer className="flex flex-row  py-10">
            <div>
              <img src={_4} alt="" className=" h-[270px] w-full rounded-sm object-cover lg:w-[480px]" />
              <img src={ThinkingImg} alt="" className="ml-[110px] -mt-20 rounded-sm object-cover" />
            </div>
            <StepDetailContainer className="-mt-10 md:-mt-10 lg:mt-10">
              <StepNumberCircle>4</StepNumberCircle>
              <StepDescriptionContainer>
                <StepTitle>{t('Student Register  Payment')}</StepTitle>
                <StepDescription>{t('Student Register  Payment detail')}</StepDescription>
              </StepDescriptionContainer>
            </StepDetailContainer>
          </StepSectionContainer>

          <ContentContainer>
            <div className="mx-auto w-fit leading-[26px]">{t('ProcedureMayDifferent')}</div>
          </ContentContainer>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default AdmissionProcessPagePage;
