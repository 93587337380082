import { useTranslationUtil } from '../../../hooks/useTranslationUtil';
import ContentSwaper from './ContentSwaper';
import Img1 from './assets/1.jpg';
import Img2 from './assets/2.jpg';

const LifeAtCmuSection = () => {
  const { t } = useTranslationUtil();
  const listData = [
    {
      image: Img1,
      thumbnail: 'https://sv1.picz.in.th/images/2022/12/06/GnS5cE.th.png',
      description: t('LifeAtCmuMainpage1'),
    },
    {
      image: Img2,
      thumbnail: 'https://sv1.picz.in.th/images/2022/12/06/GnSovn.th.jpg',
      description: t('LifeAtCmuMainpage2'),
    },
  ];

  return <ContentSwaper datas={listData} />;
};

export default LifeAtCmuSection;
