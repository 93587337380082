import React from 'react';
import { useHistory } from 'react-router-dom';
import routeUrlProvider, { NEWS_DETAIL } from '../../../constants/route-paths';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';
import { DefaultNoImage } from '../../Noimage';
import UserCommentIcon from '../../Icons/UserCommentIcon';

const HomePageNewsCard = ({ newsData }) => {
  const history = useHistory();
  const { getDisplayText, getFontFamily, formatDate, getDisplayObject } = useTranslationUtil();
  const { imagesTh, imagesEn, titleEn, titleTh, publisher, createdAt, id } = newsData;

  const getLocalizedImageURL = (imagesTh, imagesEn) => {
    let images = getDisplayObject(imagesTh, imagesEn);
    return images && images.length > 0 ? images[0].imageUrl : DefaultNoImage;
  };

  return (
    <div
      key={id}
      className="mx-auto w-[320px] rounded-sm bg-UIWhite pb-4 shadow-[0px_17px_32px_rgba(0,0,0,0.1)] duration-300 hover:scale-105 hover:cursor-pointer"
      onClick={() => {
        history.push(routeUrlProvider.getForLink(NEWS_DETAIL, { id }), { newsData });
      }}
    >
      <img src={getLocalizedImageURL(imagesTh, imagesEn)} alt="" className="mx-auto h-[180px] w-full" />
      <div className="mt-6 px-4">
        <div className=" text-lg font-bold leading-[30px]">{getDisplayText(titleTh, titleEn)}</div>
        <div className="my-auto mt-10 flex flex-row  ">
          <UserCommentIcon />
          <div className="ml-[10px] font-dosis text-[18px] font-extrabold leading-[23px]">{publisher}</div>
        </div>
        <div className={`${getFontFamily(true)} my-auto text-[14px] leading-[19px] text-Lightgray800`}>
          {formatDate(createdAt || new Date())}
        </div>
      </div>
    </div>
  );
};

export default HomePageNewsCard;
