import React from 'react';

const StaffTeamTitle = ({ children }) => {
  return (
    <div className="mx-auto px-12 pt-8 text-center text-[24px] font-medium leading-[30px] text-Lightgray500 md:text-[32px] md:leading-10 lg:mx-0 lg:ml-20 lg:px-0 xl:text-left">
      {children}
    </div>
  );
};

export default StaffTeamTitle;
