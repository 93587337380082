import { useEffect, useState } from 'react';
import MoreDetailButton from '../../../components/Button/MoreDetailButton';
import { getAdmissionPosterImages } from '../../../services/admissionPosterImages/admissionPosterImages';
import AddmissionHeader from './AddmissionHeader';
import AdmissionImageSlider from './components/AdmissionImageSlider';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';
import { useHistory } from 'react-router-dom';
import routeUrlProvider, { NEWS, NEWS_DETAIL } from '../../../constants/route-paths';
import { NEWS_TYPES } from '../../../utils/news';
import { getListNews } from '../../../services/news';
import UserCommentIcon from '../../../components/Icons/UserCommentIcon';
import { DefaultNoImage } from '../../../components/Noimage';
import CustomContainer from '../../../components/CustomContainer';
import HomePageNewsCard from '../../../components/Home/NewsCard';

const AdmissionProcessStep = ({ stepNo, title, detail, PrimaryDark = false }) => {
  const { getFontFamily } = useTranslationUtil();
  return (
    <div className="mt-8 max-w-[302px] md:mt-1 md:max-w-[264px]">
      <div
        className={`  h-14 w-14 items-center rounded-full border-[1.5px]  border-solid ${
          PrimaryDark ? ' border-PrimaryDark' : 'border-NeonPink'
        }   text-center align-middle text-[28px] font-medium leading-[3.2rem]  ${
          PrimaryDark ? 'text-PrimaryDark' : 'text-NeonPink'
        }`}
      >
        {stepNo}
      </div>
      <div className="mt-2 text-lg font-bold leading-6 text-Lightgray400">{title}</div>
      <div className={`${getFontFamily(true)} mt-2 text-xs leading-4 text-Lightgray800`}>{detail}</div>
    </div>
  );
};

const AdmissionAndScholarshipNewsSection = () => {
  const {
    t,
    getDisplayText,
    isThai,
    formatDate,
    getFontFamily,
    getDisplayObject,
    getApiLanguageCode,
    nowLanguageCode,
  } = useTranslationUtil();
  const history = useHistory();

  const [posterImagesTh, setPosterImagesTh] = useState([]);
  const [posterImagesEn, setPosterImagesEn] = useState([]);
  const [admissionNews, setAdmissionNews] = useState([]);

  useEffect(() => {
    getAdmissionPosterImages('TH')
      .then((res) => {
        setPosterImagesTh(res.data);
      })
      .catch((err) => console.log('getAdmissionPosterImages Th error ', err));

    getAdmissionPosterImages('EN')
      .then((res) => {
        setPosterImagesEn(res.data);
      })
      .catch((err) => console.log('getAdmissionPosterImages En error ', err));
  }, []);
  useEffect(() => {
    getListNews({
      newsTypeQuery: NEWS_TYPES.ADMISSION_SCHOLARSHIP_NEWS,
      page: 1,
      perPage: 3,
      language: getApiLanguageCode(),
    })
      .then((res) => setAdmissionNews(res.data))
      .catch((err) => console.log('getAdmissionNews error ', err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nowLanguageCode]);

  const getLocalizedImageURL = (imagesTh, imagesEn) => {
    let images = isThai() ? imagesTh : imagesEn;
    return images && images.length > 0 ? images[0].imageUrl : DefaultNoImage;
  };

  const posterImages = getDisplayObject(posterImagesTh, posterImagesEn);

  return (
    <div>
      <AddmissionHeader />
      <CustomContainer>
        {posterImages.length > 0 && (
          <>
            <div className=" grid grid-cols-1 items-center bg-home-updates pb-5 text-UIWhite md:h-16 md:pb-0">
              <div className=" relative  text-center text-[32px] font-medium md:absolute md:ml-20">
                {t('Admission News')}
              </div>
              <div className="mt-3 text-center  md:mr-20 md:mt-0 md:text-right">
                <MoreDetailButton text={t('View All')} textColor="text-UIWhite" fillArrowColor="fill-UIWhite" />
              </div>
            </div>
            <AdmissionImageSlider listData={posterImages} />
          </>
        )}

        <div className=" mt-14 px-20 pb-16">
          <div className=" text-2xl leading-[30px] text-Lightgray700">{t('Admission Process')}</div>
          <div className=" mt-4 grid grid-cols-1 md:grid-cols-2 md:gap-y-10 lg:grid-cols-4 lg:gap-2">
            <AdmissionProcessStep
              stepNo={1}
              title={t('Admission/Scholarship Apply')}
              detail={t('Admission/ScholarshipApplyDetail')}
            />
            <AdmissionProcessStep
              stepNo={2}
              title={t('Candidate Announcement / Interview schedule/Scholarship Apply')}
              detail={t('Candidate Announcement / Interview schedule/Scholarship Apply Detail')}
            />
            <AdmissionProcessStep
              PrimaryDark
              stepNo={3}
              title={t('Admission / Scholarship Result Announcement')}
              detail={t('Admission / Scholarship Result Announcement detail')}
            />
            <AdmissionProcessStep
              PrimaryDark
              stepNo={4}
              title={t('Student Register  Payment')}
              detail={t('Student Register  Payment detail')}
            />
          </div>
        </div>
      </CustomContainer>
      {admissionNews.length > 0 && (
        <div className=" bg-Lightgray50 pt-16">
          <CustomContainer>
            <div className=" text-center text-[48px] font-medium leading-[61px]">{t('Admission_Scholarship_News')}</div>
            {/* <div className=" text-center text-[40px] font-medium leading-[51px]">{t('Admission_Scholarship_News')}</div> */}
            <div className="mt-9 flex flex-row flex-wrap gap-y-10 md:px-8">
              {admissionNews.map((newsData) => {
                return <HomePageNewsCard newsData={newsData} />;
              })}
            </div>
            <div className=" m-auto w-fit py-14 ">
              <MoreDetailButton
                text={t('All Admission News')}
                onClick={() => {
                  history.push({
                    pathname: routeUrlProvider.getForLink(NEWS),
                    search: `?type=${NEWS_TYPES.ADMISSION_SCHOLARSHIP_NEWS}`,
                  });
                }}
              />
            </div>
          </CustomContainer>
        </div>
      )}
    </div>
  );
};

export default AdmissionAndScholarshipNewsSection;
