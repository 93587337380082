import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import { fetchPut } from '../../../utils/services/fetch'
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../../../node_modules/react-datepicker/dist/react-datepicker.min.css';
import ReactDatePicker from 'react-datepicker';
import routeUrlProvider, { AdminRoutePath } from '../../../constants/route-paths';
import { getBannerByIdAdmin } from '../../../services/adminBanner';
import { useParams } from 'react-router-dom';
import apiUrlProvider, { ADMIN_GET_BANNER_GET_BY_ID } from '../../../constants/api-endpoints';

const BannerEdit = ({ history }) => {
  const { id } = useParams();

  const [bannerType, setBannerType] = useState("")
  const [languague, setLanguage] = useState("")
  const [imageUrl, setImageUrl] = useState(null)
  const [imageName, setImageName] = useState(null)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const onBannerTypeChange = (value) => {setBannerType(value)}

  const bannerTypes = [
    { name: 'Graduate School Banner', value: 'graduate-school-banner' },
    { name: 'Admission Banner', value: 'admission-banner' },
  ]

  useEffect(() => {
    getBannerByIdAdmin(id)
      .then((res) => {
        setBannerType(res.type)
        setLanguage(res.language)
        setImageUrl(res.imageUrl)
        setImageName(res.imageName)
        setStartDate(new Date(res.startAt))
        setEndDate(new Date(res.endAt))
      })
      .catch((err) => console.log('getBanner error', err));
  }, [])

  const onEditBannerSubmit = (e) => {
    e.preventDefault()
    fetchPut(apiUrlProvider.get(ADMIN_GET_BANNER_GET_BY_ID, { id }), {
      startAt: startDate,
      endAt: endDate,
      type: bannerType,
    }, true)
    .then( res => {
      alert("แก้ไข Banner สำเร็จ")
      history.push(routeUrlProvider.getForLink(AdminRoutePath.ADMIN_BANNER_LIST))
    })
    .catch(e => {
      alert(e.error)
    })
  }

  return (
    <Layout>
      <div className='px-20'>
        <div className="text-lg text-center">แก้ไข Banner</div>
        <form>
          <div className="mb-4">
            <div>รูปภาพ</div>
            <div className='flex'>
              <div className='flex-initial box-content h-64 w-64 border-2 mx-2 border-NeonPink rounded-[10px] grid content-center hover:cursor-pointer'>
                <img
                  src={imageUrl}
                  alt={imageName}
                  width={332}
                  height={187}
                  className="rounded-[2px]"
                />
              </div>
            </div>
          </div>

          <div className="mb-4">
            <div>ภาษา</div>
            <div>
              <label className='flex'>
                <input
                  type="radio"
                  checked={languague === 'TH'}
                  disabled
                />
                <div className='ml-1'>ภาษาไทย</div>
              </label>
            </div>
            <div>
              <label className='flex'>
                <input
                  type="radio"
                  checked={languague === 'EN'}
                  disabled
                />
                <div className='ml-1'>ภาษาอังกฤษ</div>
              </label>
            </div>
          </div>

          <div className="mb-4">
            <div>ประเภทของ Banner</div>
            {
              bannerTypes.map((el, index) => (
                <div key={index}>
                  <label className='flex'>
                    <input
                      type="radio"
                      value={el.value}
                      checked={bannerType === el.value}
                      onChange={(e) => onBannerTypeChange(e.target.value)}
                    />
                    <div className='ml-1'>{el.name}</div>
                  </label>
                </div>
                ))
            }
          </div>

          <div className="mb-4">
            <div>วันที่เริ่มแสดง</div>
            <ReactDatePicker 
              className='bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-25 p-2.5' 
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>

          <div className="mb-4">
            <div>วันที่สิ้นสุด</div>
            <ReactDatePicker 
              className='bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-25 p-2.5' 
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </div>

          <div className="flex space-x-2 justify-center" onClick={onEditBannerSubmit}>
            <button type="button" className="bg-NeonPink text-UIWhite inline-block w-52 px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight rounded shadow-md">
              บันทึก
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default BannerEdit;
