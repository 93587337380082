import { useState } from 'react';
import RightArrow16pxIcon from '../../../../components/Icons/RightArrow16pxIcon';
import { useTranslationUtil } from '../../../../hooks/useTranslationUtil';

const YourJourneyWithGradCmuCard = ({ imageUrl, title, description, url }) => {
  const { t } = useTranslationUtil();
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      className="mx-auto mt-6 text-center hover:cursor-pointer md:mt-0 md:max-w-[220px] md:text-left"
      onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div>
        <img
          src={imageUrl}
          alt={title}
          className={`h-[200px] w-full rounded-[2px]  object-cover duration-500 md:h-[270px] md:w-[220px] md:object-cover ${
            isHover ? '-translate-y-[-10px]' : ''
          }`}
        />
      </div>
      <div className="font-meduim mt-2 text-[32px]  leading-[40px] md:mt-4">{title}</div>
      <div className="mt-[8px] text-[16px] leading-[26px] line-clamp-2">{description}</div>
      <div className="my-auto mt-2 flex flex-row justify-center text-[18px] font-bold leading-[23px]  md:justify-start lg:mt-6">
        <div className={`${isHover ? 'text-NeonPink' : 'text-Lightgray400'}`}>{t('Details')}</div>
        <div className="ml-[10px] flex flex-col justify-center">
          <RightArrow16pxIcon className={`${isHover ? 'fill-NeonPink' : ''}`} />
        </div>
      </div>
    </div>
  );
};

export default YourJourneyWithGradCmuCard;
