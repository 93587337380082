import React from 'react';
import Layout from '../../components/Layout';
import CoverImage from './assets/cover.png';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import CmuTextGradient from './assets/CmuTextGradient.svg';
import GradCmuTextGradient from './assets/GradCmuTextGradient.svg';
import CourseTextGradient from './assets/CourseTextGradient.svg';
import StudentTextGradient from './assets/StudentTextGradient.svg';
import LecturerTextGradient from './assets/LecturerTextGradient.svg';
import ScholarshipTextGradient from './assets/ScholarshipTextGradient.svg';
import MultidisciplinaryProgramGradient from './assets/MultidisciplinaryProgramGradient.svg';
import ProgramsGradient from './assets/ProgramsGradient.svg';
import CMUPurpleUnderLine80x8 from '../../components/CMUPurpleUnderLine';
import { CustomXAxis } from './components/CustomXAxis';
import { CustomBarLabel } from './components/CustomBarLabel';
import Image1 from './assets/image1.jpg';
import Image2 from './assets/image2.jpg';
import Image3 from './assets/image3.jpg';
import { MOCK_TARA_BAR_GRAPH_GROUP_BY_FACULTY } from './data/StudentByFaculty';
import { MOCK_MULTIDISCIPLINARY_PROGRAM_PIE_GRAPH_GROUP_BY_MASTER } from './data/MultidisciplinaryProgramMaster';
import { MOCK_MULTIDISCIPLINARY_PROGRAM_PIE_GRAPH_GROUP_BY_PHD } from './data/MultidisciplinaryProgramPhd';
import { MOCK_MULTIDISCIPLINARY_PROGRAM_PIE_GRAPH_GROUP_BY_THAI_VS_INTERNATIONAL } from './data/MultidisciplinaryProgramThaivsInter';
import { MOCK_MULTIDISCIPLINARY_PROGRAM_PIE_GRAPH_GROUP_BY_MASTER_VS_PHD } from './data/MultidisciplonaryMastervsPhd';
import ColorGradientCircle from './assets/ColorGradientCircle.svg';
import CustomContainer from '../../components/CustomContainer';
import FactCard from '../../components/FactsCard';
import FactAndFigureContainer from '../../components/FactAndFigureContainer';
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Sector,
} from 'recharts';
import { formatNumberWithComma, truncate } from './../../utils/String';
import GraphTitle from './components/GraphTitle';
import { MOCK_TARA_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_YEAR } from './data/TARAScholarshipByYear';
import { MOCK_TARA_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_MASTER_VS_PHD } from './data/TARAScholarshipMastervsPhd';
import { MOCK_TARA_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_BRANCH } from './data/TARAScholarshipByBranch';
import { MOCK_TARA_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_NATIONALITY } from './data/TARAScholarshipByNationality';
import { MOCK_PRESIDENT_SCHOLARSHIP_STACK_BAR_GRAPH_GROUP_BY_YEAR } from './data/PresidentScholarshipByYear';
import { MOCK_PRESIDENT_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_BRANCH } from './data/PresidentScholarshipByBranch';
import { MOCK_PRESIDENT_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_CONTINENT } from './data/PresidentScholarshipByContinent';
import { MOCK_ACTIVITY_SCHOLARSHIP_PROGRESS_BAR_GROUP_BY_AMOUNT_LEFT } from './data/ActivityScholarshipByAmountLeft';
import { MOCK_ACTIVITY_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_BRANCH } from './data/ActivityScholarshipByBranch';
import { MOCK_ACTIVITY_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_TYPE_OF_USE } from './data/ActivityScholarshipByTypeUsed';
import { MOCK_PROGRAM_PIE_GRAPH_GROUP_BY_EDUCATION_LEVEL } from './data/ProgramByEducationLevel';
import { MOCK_PROGRAM_PIE_GRAPH_GROUP_BY_STYDY_GROUP } from './data/ProgramByBranch';
import { MOCK_STUDENT_BAR_GRAPH_GROUP_BY_PROGRAM } from './data/StudedntByProgram';
import { FACTS_AND_FIGURES_DATA } from '../../constants/fact-and-figure';
import CustomPieChartToolTip from './components/CustomPieChartToolTip';
import GraphSubTitle from './components/GraphSubTitle';
import { MOCK_STUDENT_PIE_GRAPH_GROUP_BY_NATIONALITY } from './data/StudentByNationality';
import CustomTable from './components/CustomTable';
import CustomXTick from './components/CustomXTick';

const PIE_COLORS = [
  '#BA68C8',
  '#E494D3',
  '#FFC6FF',
  '#CBBAFF',
  '#998AD3',
  '#7986CB',
  '#695DA1',
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
];

const renderCustomizedPieLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  value,
  index,
  name,
  percent,
  amountUnit,
  ...props
}) => {
  const RADIAN = Math.PI / 180;
  // eslint-disable-next-line
  const radius = 50 + innerRadius + (outerRadius - innerRadius);
  // eslint-disable-next-line
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  // eslint-disable-next-line
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  // if (percent < 0.05) {
  //   return;
  // }
  return (
    <text
      // className="fill-Lightgray600 text-[20px] font-bold"
      className="fill-Lightgray600 text-[16px]"
      x={x}
      y={y > cy ? y + 20 : y - 20}
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      <tspan x={x}>{truncate(name, 30)}</tspan>
      <tspan x={x} dy="1.2em" textAnchor={x > cx ? 'start' : 'end'}>
        {value} {amountUnit} ({(percent * 100).toFixed(2)} %)
      </tspan>
    </text>
  );
};

const TitleImage = ({ children }) => (
  <div className="mt-12 text-center font-ibm-plex-sans text-[32px] font-medium leading-[53px] text-UIBlack">
    {children}
  </div>
);
const SubTitleImage = ({ children }) => (
  <div className="mt-6 text-center font-ibm-plex-sans leading-[26px] text-UIBlack">{children}</div>
);

const ImageWrapper = ({ children }) => <div className="mt-4">{children}</div>;

const FactsAndFiguresPage = () => {
  const { t, isThai } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <div className="relative ">
          <div className="absolute w-full bg-opacity-30 bg-pink-big mix-blend-multiply">
            <div className="w-full bg-facts-and-figure-cover">
              <img
                src={CoverImage}
                className="z-0 h-[307px] w-full object-cover object-left mix-blend-overlay"
                alt=""
              />
            </div>
          </div>

          <div className="absolute -z-0 flex h-[307px] flex-col justify-center px-2 text-center text-6xl font-medium leading-[71px] text-UIWhite text-shadow-xl lg:px-40 lg:text-left">
            {t('FactsAndFigures')}
          </div>
        </div>
        <img src={ColorGradientCircle} alt="" className=" absolute  mt-[219px]" />
        <div className="flex flex-row flex-wrap px-10 pt-[307px] lg:px-20">
          <div>
            <div className="mt-16">
              <CMUPurpleUnderLine80x8 />
            </div>
            <div className="mt-16 flex flex-col lg:flex-row">
              <img src={CmuTextGradient} alt="" className="h-[126px] w-[179px]" />
              <div className="mt-4 flex flex-col justify-end text-5xl font-medium leading-[3.75rem] text-Lightgray800 lg:ml-10">
                {t('chiang_mai_university')}
              </div>
            </div>
          </div>
        </div>
        <FactAndFigureContainer>
          {FACTS_AND_FIGURES_DATA.map(({ amount, i18nKeyName }) => (
            <FactCard number={amount} title={t(i18nKeyName)} />
          ))}
        </FactAndFigureContainer>
        {/* ########################################################## 
        ################## Students Sections ###################### 
        ##########################################################*/}
        <div className="flex flex-row flex-wrap px-10 pt-[120px] lg:px-20">
          <img src={StudentTextGradient} alt="" />
        </div>
        <div className="mx-auto mt-20 lg:w-1/2">
          <GraphTitle className="px-20">{t('by_Levels')} 2565</GraphTitle>
          <GraphSubTitle className="px-20">
            {t('StudentAmount')} 6,832 {t('people_unit')}{' '}
          </GraphSubTitle>
          <GraphSubTitle className="px-20">{t('Informationasof')} 25/04/2023 </GraphSubTitle>
        </div>
        <div className="mx-auto mt-20 hidden md:block">
          <div className="mx-auto mt-10 w-fit">
            <ResponsiveContainer width={800} height={600}>
              <BarChart width="100%" height={500} barGap={20} data={MOCK_STUDENT_BAR_GRAPH_GROUP_BY_PROGRAM}>
                <Bar name={t('edu_thai')} dataKey="Thai_amount" fill="#FFC6FF" stackId="a" />
                <Bar name={t('edu_inter')} dataKey="International_amount" fill="#E494D3" stackId="a" />

                <CartesianGrid strokeDasharray="1" vertical={false} />
                <XAxis
                  dataKey={isThai() ? 'name_th' : 'name_en'}
                  interval={0}
                  tickMargin={30}
                  height={100}
                  tick={<CustomXTick />}
                />
                <YAxis />
                <Tooltip />
                <Legend verticalAlign="top" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="mx-auto mt-10 md:mt-0 lg:w-1/2">
          <GraphTitle className="px-20">{t('edu_inter')} 2565</GraphTitle>
          <GraphSubTitle className="px-20">{t('Informationasof')} 25/04/2023 </GraphSubTitle>
          <ResponsiveContainer width="100%" height={600}>
            <PieChart width={400} height={600}>
              <Tooltip
                content={
                  <CustomPieChartToolTip
                    labelKey="name"
                    amountUnit={t('people_unit')}
                    percentDataKey="student_percentage"
                  />
                }
              />
              <Pie
                data={MOCK_STUDENT_PIE_GRAPH_GROUP_BY_NATIONALITY}
                cx="50%"
                cy="50%"
                labelLine
                label={renderCustomizedPieLabel}
                outerRadius={150}
                fill="#8884d8"
                dataKey="student_amount"
              >
                {MOCK_STUDENT_PIE_GRAPH_GROUP_BY_NATIONALITY.map((entry, index) => (
                  <Cell
                    name={isThai() ? entry.name_th : entry.name_en}
                    key={`cell-${index}`}
                    fill={PIE_COLORS[index % PIE_COLORS.length]}
                    amountUnit={t('people_unit')}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="mx-auto mt-20 lg:w-1/2">
          <GraphTitle className="px-20">{t('StudentsSeparatedbyBranchs')}</GraphTitle>
          <div className="mx-auto mt-10">
            <CustomTable
              header={[t('ScienceandTechnology'), t('HumanitiesandSocialScience'), t('HealthScience')]}
              body={[['2,124', '2,412', '2,301']]}
            ></CustomTable>
            {/* <ResponsiveContainer width="100%" height={100}>
              <table className="border-slate-500 table-fixed border-collapse border text-center text-Lightgray600">
                <thead>
                  <tr>
                    <th className="border-slate-500 border">{t('ScienceandTechnology')}</th>
                    <th className="border-slate-500 border">{t('HumanitiesandSocialScience')}</th>
                    <th className="border-slate-500 border">{t('HealthScience')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border-slate-500 border">94</td>
                    <td className="border-slate-500 border">61</td>
                    <td className="border-slate-500 border">73</td>
                  </tr>
                </tbody>
              </table>
            </ResponsiveContainer> */}
          </div>
        </div>
        {/* <div className="mt-8 px-10 pt-[0px] text-lg lg:px-20">
          <GraphTitle className="text-left"> {t('จำนวนนักศึกษาปีการศึกษา 2565')} </GraphTitle>
          <GraphSubTitle className="text-left"> {t('จำนวนนักษารับเข้า ณ วันรายงานตัว 216 คน')} </GraphSubTitle>
        </div> */}
        {/* <ResponsiveContainer width="100%" height={600}>
          <BarChart
            width="100%"
            height={500}
            barGap={0}
            data={MOCK_TARA_BAR_GRAPH_GROUP_BY_FACULTY.map((data) => ({
              ...data,
              name_th: truncate(data.name_th, 30),
              name_en: truncate(data.name_en, 30),
            }))}
          >
            <Bar name={t('จำนวน ณ วันประกาศรับสมัคร')} dataKey="scholar_request" fill="#BA68C8" />
            <Bar name={t('จำนวน ณ วันที่สมัคร')} dataKey="scholar_received" fill="#E494D3" />
            <Bar name={t('จำนวน ณ วันรายงานตัว')} dataKey="suspend_or_cancel" fill="#FFC6FF" />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey={isThai() ? 'name_th' : 'name_en'}
              interval={0}
              tick={CustomXAxis}
              tickMargin={120}
              height={200}
            />
            <YAxis />
            <Tooltip
              content={
                <CustomPieChartToolTip
                  labelKey="name"
                  amountUnit={t('people_unit')}
                  percentDataKey="student_percentage"
                />
              }
            />
            <Legend verticalAlign="top" />
          </BarChart>
        </ResponsiveContainer> */}
        {/* <div className="lg:flex lg:flex-row">
          <ResponsiveContainer width="100%" height={600}>
            <PieChart width={400} height={400}>
              <Tooltip
                content={
                  <CustomPieChartToolTip
                    labelKey="name"
                    amountUnit={t('people_unit')}
                    percentDataKey="student_percentage"
                  />
                }
              />
              <Pie
                data={MOCK_MULTIDISCIPLINARY_PROGRAM_PIE_GRAPH_GROUP_BY_MASTER}
                cx="50%"
                cy="50%"
                labelLine
                label={renderCustomizedPieLabel}
                outerRadius={150}
                fill="#8884d8"
                dataKey="student_amount"
              >
                {MOCK_MULTIDISCIPLINARY_PROGRAM_PIE_GRAPH_GROUP_BY_MASTER.map((entry, index) => (
                  <Cell
                    name={isThai() ? entry.name_th : entry.name_en}
                    key={`cell-${index}`}
                    fill={PIE_COLORS[index % PIE_COLORS.length]}
                    amountUnit={t('people_unit')}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <ResponsiveContainer width="100%" height={600}>
            <PieChart width={400} height={400}>
              <Tooltip
                content={
                  <CustomPieChartToolTip
                    labelKey="name"
                    amountUnit={t('people_unit')}
                    percentDataKey="student_percentage"
                  />
                }
              />
              <Pie
                data={MOCK_STUDENT_PIE_GRAPH_GROUP_BY_NATIONALITY}
                cx="50%"
                cy="50%"
                labelLine
                label={renderCustomizedPieLabel}
                outerRadius={150}
                fill="#8884d8"
                dataKey="student_amount"
              >
                {MOCK_STUDENT_PIE_GRAPH_GROUP_BY_NATIONALITY.map((entry, index) => (
                  <Cell
                    name={isThai() ? entry.name_th : entry.name_en}
                    key={`cell-${index}`}
                    fill={PIE_COLORS[index % PIE_COLORS.length]}
                    amountUnit={t('people_unit')}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div> */}
        {/* ########################################################## 
        ################## Program Sections ###################### 
        ##########################################################*/}
        <div className="flex flex-row flex-wrap px-10 pt-[180px] lg:px-20">
          <img src={ProgramsGradient} alt="" />
        </div>
        <div className="mx-auto mt-20 lg:w-1/2">
          <GraphTitle className="px-20">{t('byBranch')}</GraphTitle>
          <GraphSubTitle className="px-20">{t('Informationasof')} 25/04/2023 </GraphSubTitle>
        </div>
        {/* <div>
          <div className="mx-auto lg:w-1/2">
            <GraphTitle className="px-20">จำนวนหลักสูตรปีการศึกษา 2565</GraphTitle>
            <GraphSubTitle className="px-20">จำนวนหลักสูตร 500 หลักสูตร</GraphSubTitle>
            <ResponsiveContainer width="100%" height={600}>
              <BarChart width="100%" height={500} barGap={0} data={MOCK_PROGRAM_PIE_GRAPH_GROUP_BY_EDUCATION_LEVEL}>
                <Bar name={t('edu_normal')} dataKey="normal_amount" fill="#FFC6FF" stackId="a" />
                <Bar name={t('edu_inter')} dataKey="International_amount" fill="#E494D3" stackId="a" />
                <Bar name={t('edu_bilingual')} dataKey="Bilingual_amount" fill="#BA68C8" stackId="a" />
                <CartesianGrid strokeDasharray="1" vertical={false} />
                <XAxis dataKey={isThai() ? 'name_th' : 'name_en'} interval={0} tickMargin={30} height={200} />
                <YAxis />
                <Tooltip />
                <Legend verticalAlign="top" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div> */}
        <div className="mx-auto mt-10">
          <ResponsiveContainer width="100%" height={500}>
            <PieChart width={'100%'} height={400}>
              <Tooltip
                content={
                  <CustomPieChartToolTip
                    labelKey="name"
                    amountUnit={t('people_unit')}
                    percentDataKey="student_percentage"
                  />
                }
              />
              <Pie
                data={MOCK_PROGRAM_PIE_GRAPH_GROUP_BY_STYDY_GROUP}
                cx="50%"
                cy="50%"
                labelLine
                label={renderCustomizedPieLabel}
                outerRadius={150}
                fill="#8884d8"
                dataKey="student_amount"
                formatter
              >
                {MOCK_PROGRAM_PIE_GRAPH_GROUP_BY_STYDY_GROUP.map((entry, index) => (
                  <Cell
                    name={isThai() ? entry.name_th : entry.name_en}
                    key={`cell-${index}`}
                    fill={PIE_COLORS[index % PIE_COLORS.length]}
                    amountUnit={t('Programs')}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="mx-auto mt-20 lg:w-1/2">
          <GraphTitle className="px-20">{t('CoursesSeparatedbyLevels')}</GraphTitle>

          <div className="mx-auto mt-10">
            <ResponsiveContainer width="100%" height={100}>
              <CustomTable
                header={[' ', t('master'), t('doctoral'), t('GraduateDiploma'), t('HighGraduateDiploma')]}
                body={[
                  [t('NormalCourse'), 102, 50, 3, 1],
                  [t('InternationalCourse'), 27, 44, 0, 0],
                  [t('Bilingual Course'), 1, 0, 0, 0],
                ]}
              ></CustomTable>
              {/* <table class="border-slate-500 table-fixed border-collapse border">
                <thead>
                  <tr>
                    <th class="border-slate-500 border"></th>
                    <th class="border-slate-500 border">{t('master')}</th>
                    <th class="border-slate-500 border">{t('doctoral')}</th>
                    <th class="border-slate-500 border">{t('GraduateDiploma')}</th>
                    <th class="border-slate-500 border">{t('HighGraduateDiploma')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border-slate-500 border">{t('NormalCourse')}</td>
                    <td class="border-slate-500 border">102</td>
                    <td class="border-slate-500 border">50</td>
                    <td class="border-slate-500 border">3</td>
                    <td class="border-slate-500 border">1</td>
                  </tr>
                  <tr>
                    <td class="border-slate-500 border">{t('InternationalCourse')}</td>
                    <td class="border-slate-500 border">27</td>
                    <td class="border-slate-500 border">44</td>
                    <td class="border-slate-500 border">0</td>
                    <td class="border-slate-500 border">0</td>
                  </tr>
                  <tr>
                    <td class="border-slate-500 border">{t('Bilingual Course')}</td>
                    <td class="border-slate-500 border">1</td>
                    <td class="border-slate-500 border">0</td>
                    <td class="border-slate-500 border">0</td>
                    <td class="border-slate-500 border">0</td>
                  </tr>
                </tbody>
              </table> */}
            </ResponsiveContainer>
          </div>
        </div>
        {/* ########################################################## 
        ################## Scholarship Sections ###################### 
        ##########################################################*/}

        <div className="flex flex-row flex-wrap px-10 pt-[180px] lg:px-20">
          <img src={ScholarshipTextGradient} alt="" />
        </div>
        <div className="mx-auto mt-20 lg:w-1/2">
          <GraphTitle className="px-20">{t('TARAScholarship')} 2565</GraphTitle>
          <GraphSubTitle className="px-20">
            {t('StudentsReceivingScholarships')} 248 {t('people_unit')}{' '}
          </GraphSubTitle>
          <GraphSubTitle className="px-20">{t('Informationasof')} 25/04/2023 </GraphSubTitle>
          <div className="mx-auto mt-20 hidden md:block">
            <div className="mx-auto mt-10 w-fit">
              <ResponsiveContainer width={600} height={600}>
                <BarChart width="100%" height={500} barGap={0} data={MOCK_TARA_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_YEAR}>
                  <Bar name={t('scholarship_amt')} dataKey="student_amount" fill="#FFC6FF" stackId="a" />

                  <CartesianGrid strokeDasharray="1" vertical={false} />
                  <XAxis
                    dataKey={isThai() ? 'year' : 'year'}
                    interval={0}
                    tickMargin={30}
                    height={50}
                    tick={<CustomXTick />}
                  />
                  <YAxis />
                  <Tooltip />
                  <Legend verticalAlign="top" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <div className=" mt-24">
          <GraphTitle>{t('byBranch')}</GraphTitle>

          <div className="mt-10 lg:flex lg:flex-row">
            {/* <ResponsiveContainer width="100%" height={600}>
              <PieChart width={400} height={400}>
                <Tooltip
                  content={
                    <CustomPieChartToolTip
                      labelKey="name"
                      amountUnit={t('people_unit')}
                      percentDataKey="student_percentage"
                    />
                  }
                />
                <Pie
                  data={MOCK_TARA_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_YEAR}
                  cx="50%"
                  cy="50%"
                  labelLine
                  label={renderCustomizedPieLabel}
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="student_amount"
                >
                  {MOCK_TARA_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_YEAR.map((entry, index) => (
                    <Cell
                      name={entry.year}
                      key={`cell-${index}`}
                      fill={PIE_COLORS[index % PIE_COLORS.length]}
                      amountUnit={t('people_unit')}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer> */}
            {/* <ResponsiveContainer width="100%" height={600}>
              <PieChart width={400} height={400}>
                <Tooltip
                  content={
                    <CustomPieChartToolTip
                      labelKey="name"
                      amountUnit={t('people_unit')}
                      percentDataKey="student_percentage"
                    />
                  }
                />
                <Pie
                  data={MOCK_TARA_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_MASTER_VS_PHD}
                  cx="50%"
                  cy="50%"
                  labelLine
                  label={renderCustomizedPieLabel}
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="student_amount"
                >
                  {MOCK_TARA_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_MASTER_VS_PHD.map((entry, index) => (
                    <Cell
                      name={isThai() ? entry.name_th : entry.name_en}
                      key={`cell-${index}`}
                      fill={PIE_COLORS[index % PIE_COLORS.length]}
                      amountUnit={t('people_unit')}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer> */}
          </div>
          <ResponsiveContainer width="100%" height={500}>
            <PieChart width={'100%'} height={400}>
              <Tooltip
                content={
                  <CustomPieChartToolTip
                    labelKey="name"
                    amountUnit={t('people_unit')}
                    percentDataKey="student_percentage"
                  />
                }
              />
              <Pie
                data={MOCK_TARA_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_BRANCH}
                cx="50%"
                cy="50%"
                labelLine
                label={renderCustomizedPieLabel}
                outerRadius={150}
                fill="#8884d8"
                dataKey="student_amount"
                formatter
              >
                {MOCK_TARA_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_BRANCH.map((entry, index) => (
                  <Cell
                    name={isThai() ? entry.name_th : entry.name_en}
                    key={`cell-${index}`}
                    fill={PIE_COLORS[index % PIE_COLORS.length]}
                    amountUnit={t('people_unit')}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>

          {/* <div className="lg:flex lg:flex-row"> */}
          {/* <ResponsiveContainer width="100%" height={600}>
              <PieChart width={400} height={400}>
                <Tooltip
                  content={
                    <CustomPieChartToolTip
                      labelKey="name"
                      amountUnit={t('people_unit')}
                      percentDataKey="student_percentage"
                    />
                  }
                />
                <Pie
                  data={MOCK_TARA_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_BRANCH}
                  cx="50%"
                  cy="50%"
                  labelLine
                  label={renderCustomizedPieLabel}
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="student_amount"
                >
                  {MOCK_TARA_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_BRANCH.map((entry, index) => (
                    <Cell
                      name={isThai() ? entry.name_th : entry.name_en}
                      key={`cell-${index}`}
                      fill={PIE_COLORS[index % PIE_COLORS.length]}
                      amountUnit={t('people_unit')}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            <ResponsiveContainer width="100%" height={600}>
              <PieChart width={400} height={400}>
                <Tooltip
                  content={
                    <CustomPieChartToolTip
                      labelKey="name"
                      amountUnit={t('people_unit')}
                      percentDataKey="student_percentage"
                    />
                  }
                />
                <Pie
                  data={MOCK_TARA_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_NATIONALITY}
                  cx="50%"
                  cy="50%"
                  labelLine
                  label={renderCustomizedPieLabel}
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="student_amount"
                >
                  {MOCK_TARA_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_NATIONALITY.map((entry, index) => (
                    <Cell
                      name={isThai() ? entry.name_th : entry.name_en}
                      key={`cell-${index}`}
                      fill={PIE_COLORS[index % PIE_COLORS.length]}
                      amountUnit={t('people_unit')}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer> */}
          {/* </div> */}
        </div>

        <div>
          <div className="mx-auto mt-20 lg:w-1/2">
            <GraphTitle className="px-20">{t('StudentsSeparatedbyNationality')}</GraphTitle>

            <div className="mx-auto mt-10">
              <ResponsiveContainer width="100%" height={100}>
                <CustomTable
                  header={[t('Thai'), t('China'), t('Indonesia'), t('Myanmar'), t('Jpan')]}
                  body={[[240, 4, 2, 1, 1]]}
                ></CustomTable>
                {/* <table class="border-slate-500 table-fixed border-collapse border">
                  <thead>
                    <tr>
                      <th class="border-slate-500 border">{t('Thai')}</th>
                      <th class="border-slate-500 border">{t('China')}</th>
                      <th class="border-slate-500 border">{t('Indonesia')}</th>
                      <th class="border-slate-500 border">{t('Myanmar')}</th>
                      <th class="border-slate-500 border">{t('Jpan')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="border-slate-500 border">240</td>
                      <td class="border-slate-500 border">4</td>
                      <td class="border-slate-500 border">2</td>
                      <td class="border-slate-500 border">1</td>
                      <td class="border-slate-500 border">1</td>
                    </tr>
                  </tbody>
                </table> */}
              </ResponsiveContainer>
            </div>
          </div>

          <div className="mx-auto mt-20 lg:w-1/2">
            <GraphTitle className="px-20">{t('PresidentScholarship')} 2565</GraphTitle>
            {/* <GraphSubTitle className="text-center"> */}
            <GraphSubTitle className="px-20">
              {t('StudentsReceivingScholarships')}{' '}
              {MOCK_PRESIDENT_SCHOLARSHIP_STACK_BAR_GRAPH_GROUP_BY_YEAR.reduce(
                (sum, entry) => (sum += entry.student_amount),
                0
              )}{' '}
              ราย
            </GraphSubTitle>
            <GraphSubTitle className="px-20">{t('Informationasof')} 25/04/2023 </GraphSubTitle>
            <div className="mx-auto mt-10 hidden max-w-3xl md:block">
              <ResponsiveContainer width="100%" height={600}>
                <BarChart
                  width="100%"
                  height={500}
                  barGap={0}
                  data={MOCK_PRESIDENT_SCHOLARSHIP_STACK_BAR_GRAPH_GROUP_BY_YEAR}
                >
                  <Bar name={t('master')} dataKey="master" fill="#BA68C8" stackId="a" />
                  <Bar name={t('doctoral')} dataKey="doctoral" fill="#E494D3" stackId="a" />
                  <CartesianGrid strokeDasharray="1" vertical={false} />
                  <XAxis dataKey="year" interval={0} tickMargin={30} height={100} />
                  <YAxis />
                  <Tooltip />
                  <Legend verticalAlign="top" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className=" mt-24">
            <GraphTitle>{t('byBranch')}</GraphTitle>
          </div>
          <div className="lg:flex lg:flex-row">
            <ResponsiveContainer width="100%" height={500}>
              <PieChart width={'100%'} height={400}>
                <Tooltip
                  content={
                    <CustomPieChartToolTip
                      labelKey="name"
                      amountUnit={t('people_unit')}
                      percentDataKey="student_percentage"
                    />
                  }
                />
                <Pie
                  data={MOCK_PRESIDENT_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_BRANCH}
                  cx="50%"
                  cy="50%"
                  labelLine
                  label={renderCustomizedPieLabel}
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="student_amount"
                  formatter
                >
                  {MOCK_PRESIDENT_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_BRANCH.map((entry, index) => (
                    <Cell
                      name={isThai() ? entry.name_th : entry.name_en}
                      key={`cell-${index}`}
                      fill={PIE_COLORS[index % PIE_COLORS.length]}
                      amountUnit={t('people_unit')}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          {/* <div className="lg:flex lg:flex-row">
            <ResponsiveContainer width="100%" height={600}>
              <PieChart width={400} height={400}>
                <Tooltip
                  content={
                    <CustomPieChartToolTip
                      labelKey="name"
                      amountUnit={t('people_unit')}
                      percentDataKey="student_percentage"
                    />
                  }
                />
                <Pie
                  data={MOCK_PRESIDENT_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_BRANCH}
                  cx="50%"
                  cy="50%"
                  labelLine
                  label={renderCustomizedPieLabel}
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="student_amount"
                >
                  {MOCK_PRESIDENT_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_BRANCH.map((entry, index) => (
                    <Cell
                      name={isThai() ? entry.name_th : entry.name_en}
                      key={`cell-${index}`}
                      fill={PIE_COLORS[index % PIE_COLORS.length]}
                      amountUnit={t('people_unit')}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            <ResponsiveContainer width="100%" height={600}>
              <PieChart width={400} height={400}>
                <Tooltip
                  content={
                    <CustomPieChartToolTip
                      labelKey="name"
                      amountUnit={t('people_unit')}
                      percentDataKey="student_percentage"
                    />
                  }
                />
                <Pie
                  data={MOCK_PRESIDENT_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_CONTINENT}
                  cx="50%"
                  cy="50%"
                  labelLine
                  label={renderCustomizedPieLabel}
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="student_amount"
                >
                  {MOCK_PRESIDENT_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_CONTINENT.map((entry, index) => (
                    <Cell
                      name={isThai() ? entry.name_th : entry.name_en}
                      key={`cell-${index}`}
                      fill={PIE_COLORS[index % PIE_COLORS.length]}
                      amountUnit={t('people_unit')}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div> */}
        </div>
        <div className="mx-auto mt-20 lg:w-1/2">
          <GraphTitle className="px-20">{t('StudentsbyContinents')}</GraphTitle>

          <div className="mx-auto mt-10">
            <ResponsiveContainer width="100%" height={100}>
              <CustomTable
                header={[t('Thai'), t('Asia'), t('Africa'), t('America'), t('Europe'), t('Oceania')]}
                body={[[80, 157, 6, 5, 4, 1]]}
              ></CustomTable>
              {/* <table class="border-slate-500 table-fixed border-collapse border">
                <thead>
                  <tr>
                    <th class="border-slate-500 border">{t('Thai')}</th>
                    <th class="border-slate-500 border">{t('Asia')}</th>
                    <th class="border-slate-500 border">{t('Africa')}</th>
                    <th class="border-slate-500 border">{t('America')}</th>
                    <th class="border-slate-500 border">{t('Europe')}</th>
                    <th class="border-slate-500 border">{t('Oceania')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border-slate-500 border">80</td>
                    <td class="border-slate-500 border">157</td>
                    <td class="border-slate-500 border">6</td>
                    <td class="border-slate-500 border">5</td>
                    <td class="border-slate-500 border">4</td>
                    <td class="border-slate-500 border">1</td>
                  </tr>
                </tbody>
              </table> */}
            </ResponsiveContainer>
          </div>
        </div>
        <div className="mt-20">
          <GraphTitle>{t('AcademicActivityScholarship')} 2565</GraphTitle>
          {/* /////////////////////////////////////////////// */}
          <div className="mx-auto w-1/2">
            <div className="font-meduim mx-auto mt-2 flex  w-fit  flex-row gap-x-[60px] text-[25px] leading-[30px]">
              <GraphSubTitle>
                {`${t('budget_used')} ${formatNumberWithComma(
                  MOCK_ACTIVITY_SCHOLARSHIP_PROGRESS_BAR_GROUP_BY_AMOUNT_LEFT[0].budget_used
                )} ${t('baht')} (${
                  MOCK_ACTIVITY_SCHOLARSHIP_PROGRESS_BAR_GROUP_BY_AMOUNT_LEFT[0].budget_used_percent
                })`}
              </GraphSubTitle>
              <GraphSubTitle>
                {`${t('budget_left')} ${formatNumberWithComma(
                  MOCK_ACTIVITY_SCHOLARSHIP_PROGRESS_BAR_GROUP_BY_AMOUNT_LEFT[0].budget_left
                )} ${t('baht')} (${
                  MOCK_ACTIVITY_SCHOLARSHIP_PROGRESS_BAR_GROUP_BY_AMOUNT_LEFT[0].budget_left_percent
                })`}
              </GraphSubTitle>
            </div>
            <div className="mt-4 hidden md:block">
              {' '}
              <ResponsiveContainer width="100%" height={200}>
                <BarChart
                  width="100%"
                  height={150}
                  barGap={0}
                  data={MOCK_ACTIVITY_SCHOLARSHIP_PROGRESS_BAR_GROUP_BY_AMOUNT_LEFT}
                  layout="vertical"
                >
                  <Bar name={t('budget_used')} dataKey="budget_used" fill="#BA68C8" stackId="a" />
                  <Bar name={t('budget_left')} dataKey="budget_left" fill="#E494D3" stackId="a" />
                  {/* <CartesianGrid strokeDasharray="1" vertical={false} />    */}
                  <XAxis hide type="number" />
                  <YAxis
                    hide
                    dataKey={isThai() ? 'name_th' : 'name_en'}
                    interval={0}
                    tick={CustomXAxis}
                    type="category"
                    tickMargin={30}
                    height={200}
                  />
                  <Tooltip />
                  <Legend verticalAlign="top" align="right" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className=" mt-24">
            <GraphTitle>{t('ScholarshipsSeparatedbyTypes')}</GraphTitle>
          </div>
          <div className="lg:flex lg:flex-row">
            {/* <ResponsiveContainer width="100%" height={600}>
              <PieChart width={400} height={400}>
                <Tooltip
                  content={
                    <CustomPieChartToolTip
                      labelKey="name"
                      amountUnit={t('people_unit')}
                      percentDataKey="student_percentage"
                    />
                  }
                />
                <Pie
                  data={MOCK_ACTIVITY_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_BRANCH}
                  cx="50%"
                  cy="50%"
                  labelLine
                  label={renderCustomizedPieLabel}
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="student_amount"
                >
                  {MOCK_ACTIVITY_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_BRANCH.map((entry, index) => (
                    <Cell
                      name={isThai() ? entry.name_th : entry.name_en}
                      key={`cell-${index}`}
                      fill={PIE_COLORS[index % PIE_COLORS.length]}
                      amountUnit={t('people_unit')}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer> */}
            <ResponsiveContainer width="100%" height={600}>
              <PieChart width={400} height={400}>
                <Tooltip
                  content={
                    <CustomPieChartToolTip
                      labelKey="name"
                      amountUnit={t('people_unit')}
                      percentDataKey="student_percentage"
                    />
                  }
                />
                <Pie
                  data={MOCK_ACTIVITY_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_TYPE_OF_USE}
                  cx="50%"
                  cy="50%"
                  labelLine
                  label={renderCustomizedPieLabel}
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="student_amount"
                >
                  {MOCK_ACTIVITY_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_TYPE_OF_USE.map((entry, index) => (
                    <Cell
                      name={isThai() ? entry.name_th : entry.name_en}
                      key={`cell-${index}`}
                      fill={PIE_COLORS[index % PIE_COLORS.length]}
                      amountUnit={t('people_unit')}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
        {/* /////////////////////////////////////////////// */}
        {/* <div className="flex flex-row flex-wrap px-10 pt-[180px] lg:px-20">
          <img src={LecturerTextGradient} alt="" />
        </div>
        <div className="px-10 pt-10 lg:px-20">
          <GraphTitle>{t('WaitingForData')}</GraphTitle>
        </div> */}
        {/* ########################################################## 
        ################## Multidisciplinary Sections ###################### 
        ##########################################################*/}
        <div className="flex flex-row flex-wrap px-10 pt-[180px] lg:px-20">
          <img src={MultidisciplinaryProgramGradient} alt="" />
        </div>
        <div className="mx-auto mt-20 lg:w-1/2">
          <GraphTitle className="px-20">{t('MultidisciplinaryProgramMasters')}</GraphTitle>
          <GraphSubTitle className="px-20 ">{t('Informationasof')} 25/04/2023 </GraphSubTitle>
        </div>
        {/* <div className="mx-auto mt-20">
          <div className="mx-auto mt-10 w-fit">
            <ResponsiveContainer width={800} height={600}>
              <BarChart width="100%" height={500} barGap={20} */}
        <div className="mx-auto mt-10 hidden w-fit md:block">
          <ResponsiveContainer width={1500} height={600}>
            <BarChart
              width="100%"
              height={500}
              barGap={20}
              data={MOCK_MULTIDISCIPLINARY_PROGRAM_PIE_GRAPH_GROUP_BY_MASTER}
            >
              <Bar name={t('edu_thai')} dataKey="student_amount" fill="#FFC6FF" stackId="a" />

              <CartesianGrid strokeDasharray="1" vertical={false} />
              <XAxis
                dataKey={isThai() ? 'name_th' : 'name_en'}
                interval={0}
                tickMargin={30}
                height={100}
                tick={<CustomXTick />}
              />
              <YAxis />
              <Tooltip />
              <Legend verticalAlign="top" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="mx-auto mt-20 lg:w-1/2">
          <GraphTitle className="px-20">{t('MultidisciplinaryProgramPHD')}</GraphTitle>
          <GraphSubTitle className="px-20">{t('Informationasof')} 25/04/2023 </GraphSubTitle>
        </div>
        <div className="mx-auto mt-10 hidden w-fit md:block">
          <ResponsiveContainer width={500} height={600}>
            <BarChart
              width="100%"
              height={500}
              barGap={20}
              data={MOCK_MULTIDISCIPLINARY_PROGRAM_PIE_GRAPH_GROUP_BY_PHD}
            >
              <Bar name={t('edu_thai')} dataKey="student_amount" fill="#FFC6FF" stackId="a" />
              {/* <Bar name={t('edu_inter')} dataKey="International_amount" fill="#E494D3" stackId="a" /> */}

              <CartesianGrid strokeDasharray="1" vertical={false} />
              <XAxis
                dataKey={isThai() ? 'name_th' : 'name_en'}
                interval={0}
                tickMargin={30}
                height={100}
                tick={<CustomXTick />}
              />
              <YAxis />
              <Tooltip />
              <Legend verticalAlign="top" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="mx-auto mt-20 lg:w-1/2">
          {/* <GraphTitle className="px-20">จำนวนนักศึกษาทุนตามเชื้อชาติ</GraphTitle> */}

          <div className="mx-auto mt-10">
            <ResponsiveContainer width="100%" height={100}>
              <CustomTable
                header={[t('StudentAmount'), t('edu_inter'), t('edu_thai')]}
                body={[
                  [t('master'), 26, 120],
                  [t('doctoral'), 4, 21],
                ]}
              ></CustomTable>
              {/* <table class="border-slate-500 table-fixed border-collapse border">
                <thead>
                  <tr>
                    <th class="border-slate-500 border">{t('StudentAmount')}</th>
                    <th class="border-slate-500 border">{t('edu_inter')}</th>
                    <th class="border-slate-500 border">{t('edu_thai')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border-slate-500 border">{t('master')}</td>
                    <td class="border-slate-500 border">26</td>
                    <td class="border-slate-500 border">120</td>
                  </tr>
                  <tr>
                    <td class="border-slate-500 border">{t('doctoral')}</td>
                    <td class="border-slate-500 border">4</td>
                    <td class="border-slate-500 border">21</td>
                  </tr>
                </tbody>
              </table> */}
            </ResponsiveContainer>
          </div>
        </div>
        {/* <div className="mx-auto mt-20">
          <div className="mx-auto mt-10 w-fit"> */}
        {/* <div className="mt-20 lg:flex lg:flex-row">
          <div className="lg:w-1/2">
            <GraphTitle>{t('master')}</GraphTitle>
            <div className="mx-auto mt-20">
              <div className="mx-auto mt-10 w-fit">
                <ResponsiveContainer width={800} height={600}>
                  <BarChart width="100%" height={500} barGap={20} data={MOCK_STUDENT_BAR_GRAPH_GROUP_BY_PROGRAM}>
                    <Bar name={t('edu_thai')} dataKey="Thai_amount" fill="#FFC6FF" stackId="a" />
                    <Bar name={t('edu_inter')} dataKey="International_amount" fill="#E494D3" stackId="a" />

                    <CartesianGrid strokeDasharray="1" vertical={false} />
                    <XAxis dataKey={isThai() ? 'name_th' : 'name_en'} interval={0} tickMargin={30} height={100} />
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div>
              <div className="mx-auto mt-20 lg:w-1/2">
                <GraphTitle className="px-20">จำนวนนักศึกษาทุนตามเชื้อชาติ</GraphTitle>

                <div className="mx-auto mt-10">
                  <ResponsiveContainer width="100%" height={100}>
                    <table class="border-slate-500 table-fixed border-collapse border">
                      <thead>
                        <tr>
                          <th class="border-slate-500 border">ไทย</th>
                          <th class="border-slate-500 border">จีน</th>
                          <th class="border-slate-500 border">อินโดนีเซีย</th>
                          <th class="border-slate-500 border">พม่า</th>
                          <th class="border-slate-500 border">ญี่ปุ่น</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="border-slate-500 border">240</td>
                          <td class="border-slate-500 border">4</td>
                          <td class="border-slate-500 border">2</td>
                          <td class="border-slate-500 border">1</td>
                          <td class="border-slate-500 border">1</td>
                        </tr>
                      </tbody>
                    </table>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
            <ResponsiveContainer width="100%" height={450}>
              <PieChart width={400} height={450}>
                <Tooltip
                  content={
                    <CustomPieChartToolTip
                      labelKey="name"
                      amountUnit={t('people_unit')}
                      percentDataKey="student_percentage"
                    />
                  }
                />
                <Pie
                  data={MOCK_MULTIDISCIPLINARY_PROGRAM_PIE_GRAPH_GROUP_BY_MASTER}
                  cx="50%"
                  cy="50%"
                  labelLine
                  label={renderCustomizedPieLabel}
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="student_amount"
                >
                  {MOCK_MULTIDISCIPLINARY_PROGRAM_PIE_GRAPH_GROUP_BY_MASTER.map((entry, index) => (
                    <Cell
                      name={isThai() ? entry.name_th : entry.name_en}
                      key={`cell-${index}`}
                      fill={PIE_COLORS[index % PIE_COLORS.length]}
                      amountUnit={t('people_unit')}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div> */}

        {/* <div className="mt-12 lg:mt-0 lg:w-1/2">
            <GraphTitle>{t('doctoral')}</GraphTitle>
            <ResponsiveContainer width="100%" height={450}>
              <PieChart width={400} height={450}>
                <Tooltip
                  content={
                    <CustomPieChartToolTip
                      labelKey="name"
                      amountUnit={t('people_unit')}
                      percentDataKey="student_percentage"
                    />
                  }
                />
                <Pie
                  data={MOCK_MULTIDISCIPLINARY_PROGRAM_PIE_GRAPH_GROUP_BY_PHD}
                  cx="50%"
                  cy="50%"
                  labelLine
                  label={renderCustomizedPieLabel}
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="student_amount"
                >
                  {MOCK_MULTIDISCIPLINARY_PROGRAM_PIE_GRAPH_GROUP_BY_PHD.map((entry, index) => (
                    <Cell
                      name={isThai() ? entry.name_th : entry.name_en}
                      key={`cell-${index}`}
                      fill={PIE_COLORS[index % PIE_COLORS.length]}
                      amountUnit={t('people_unit')}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div> */}
        {/* </div> */}
        <div className="mt-20 lg:flex lg:flex-row">
          {/* <div className="lg:w-1/2">
            <GraphTitle>{t('master')}</GraphTitle>
            <ResponsiveContainer width="100%" height={400}>
              <PieChart width={400} height={400}>
                <Tooltip
                  content={
                    <CustomPieChartToolTip
                      labelKey="name"
                      amountUnit={t('people_unit')}
                      percentDataKey="student_percentage"
                    />
                  }
                />
                <Pie
                  data={MOCK_MULTIDISCIPLINARY_PROGRAM_PIE_GRAPH_GROUP_BY_THAI_VS_INTERNATIONAL}
                  cx="50%"
                  cy="50%"
                  labelLine
                  label={renderCustomizedPieLabel}
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="student_amount"
                >
                  {MOCK_MULTIDISCIPLINARY_PROGRAM_PIE_GRAPH_GROUP_BY_THAI_VS_INTERNATIONAL.map((entry, index) => (
                    <Cell
                      name={isThai() ? entry.name_th : entry.name_en}
                      key={`cell-${index}`}
                      fill={PIE_COLORS[index % PIE_COLORS.length]}
                      amountUnit={t('people_unit')}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div> */}

          {/* <div className="lg:w-1/2">
            <GraphTitle>{t('doctoral')}</GraphTitle>
            <ResponsiveContainer width="100%" height={400}>
              <PieChart width={400} height={400}>
                <Tooltip
                  content={
                    <CustomPieChartToolTip
                      labelKey="name"
                      amountUnit={t('people_unit')}
                      percentDataKey="student_percentage"
                    />
                  }
                />
                <Pie
                  data={MOCK_MULTIDISCIPLINARY_PROGRAM_PIE_GRAPH_GROUP_BY_MASTER_VS_PHD}
                  cx="50%"
                  cy="50%"
                  labelLine
                  label={renderCustomizedPieLabel}
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="student_amount"
                >
                  {MOCK_MULTIDISCIPLINARY_PROGRAM_PIE_GRAPH_GROUP_BY_MASTER_VS_PHD.map((entry, index) => (
                    <Cell
                      name={isThai() ? entry.name_th : entry.name_en}
                      key={`cell-${index}`}
                      fill={PIE_COLORS[index % PIE_COLORS.length]}
                      amountUnit={t('people_unit')}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div> */}
        </div>
        {/* <div className="flex flex-row flex-wrap px-10 pt-[180px] lg:px-20">
          <img src={CourseTextGradient} alt="" />
        </div> */}

        {/* <TitleImage>หลักสูตรระดับบัณฑิตศึกษา</TitleImage>
        <ImageWrapper>
          <img src={Image1} alt="" />
        </ImageWrapper>
        <SubTitleImage>ข้อมูล ณ วันที่ 20 มิถุนายน 2565</SubTitleImage>
        <TitleImage>หลักสูตรสหสาขาวิชา สังกัดบัณฑิตวิทยาลัย</TitleImage>
        <ImageWrapper>
          <img src={Image2} alt="" />
        </ImageWrapper>
        <SubTitleImage>ข้อมูล ณ วันที่ 1 สิงหาคม 2565</SubTitleImage>

        <TitleImage>โครงการพัฒนาทักษะภาษาอังกฤษสำหรับนักศึกษาบัณฑิตศึกษา</TitleImage>
        <ImageWrapper>
          <img src={Image3} alt="" />
        </ImageWrapper>
        <SubTitleImage> ข้อมูล ณ วันที่ 1 สิงหาคม 2565</SubTitleImage> */}
      </CustomContainer>
    </Layout>
  );
};

export default FactsAndFiguresPage;
