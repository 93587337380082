export const CustomXAxis = (props) => {
  const {
    x,
    y,
    payload: { value },
  } = props;

  return (
    <g transform={`translate(${x + 4},${y})`}>
      <text className="-rotate-90" x={0} y={0} textAnchor="middle" fill="#666">
        {value}
      </text>
    </g>
  );
};
