import React from 'react';

const CustomPieChartToolTip = (defaultProps) => {
  const { active, payload, label, labelKey = 'name', percentDataKey = '', amountUnit, ...props } = defaultProps;

  if (active && payload && payload.length) {
    return (
      <div className="bg-UIWhite p-3">
        <p className="text-[16px] text-Lightgray700">{`${payload[0][labelKey]} : ${payload[0]?.value} ${
          amountUnit ?? ''
        } ${
          percentDataKey && payload[0]?.payload[percentDataKey] ? `(${payload[0]?.payload[percentDataKey]})` : ''
        }`}</p>
      </div>
    );
  }
};

export default CustomPieChartToolTip;
