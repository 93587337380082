export const MOCK_PROGRAM_PIE_GRAPH_GROUP_BY_STYDY_GROUP = [
  {
    name_th: 'วิทยาศาสตร์และเทคโนโลยี',
    name_en: 'Science and Technology',
    student_amount: 94,
    student_percentage: '41.23%',
  },
  {
    name_th: 'มนุษยศาสตร์และสังคมศาสตร์',
    name_en: 'Humanities and Social Science',
    student_amount: 61,
    student_percentage: '26.75%',
  },
  {
    name_th: 'วิทยาศาสตร์สุขภาพ',
    name_en: 'Health Science',
    student_amount: 73,
    student_percentage: '32.02%',
  },
];
