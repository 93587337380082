import React from 'react';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';

const GraduateStaffPageTitle = ({ title }) => {
  const { t } = useTranslationUtil();
  return (
    <>
      <div className="pt-48 text-4xl font-medium text-UIWhite text-shadow-page-title md:text-[56px] md:leading-[71px] ">
        {title}
      </div>
      <div className=" text-lg font-semibold leading-[30px] text-UIWhite text-shadow-page-title md:text-2xl">
        {t('GraduateSchoolSubtitle')}
      </div>
    </>
  );
};

export default GraduateStaffPageTitle;
