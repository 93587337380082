import { useState } from 'react';
import PhoneCallIcon from '../../Icons/PhoneCallIcon';

const PhonecallButton = ({ onClick = () => {} }) => {
  const [isHover, setIsHover] = useState(false);
  const fillColor = isHover ? 'fill-SecondaryDark' : ' fill-NeonPink';

  return (
    <button
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className=" w-16 h-16 bg-UIWhite shadow-md rounded-[50%] m-auto flex justify-center items-center">
        <PhoneCallIcon className={fillColor} />
      </div>
    </button>
  );
};
export default PhonecallButton;
