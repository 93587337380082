import { useState } from 'react';
import { PageNavigation } from './PageNavigate';
import { MobilePageNavigation } from './MobilePageNavigate';
import routeUrlProvider, {
  ABOUT,
  ANNOUNCEMENTS_REGULATIONS_GUIDELINES,
  ANNUAL_MEETING_SCHEDULE,
  ANNUA_REPORT,
  COURSE_TEMPLATE,
  CRITERIA_GRADUATION,
  CURRICULUM_GUIDELINES,
  DEAN_REPORT,
  DIRECTIVE_BOARD,
  ENROLLMENT_ACADEMIC_CALENDAR,
  EXECUTIVE,
  FACTS_AND_FIGURES,
  FOREIGN_LANGUAGE_CONDITION,
  GRADUATE_SCHOOL_OFFICE,
  GENERAL_ADMINISTRATION,
  FINANCIAL_TREASURY_AND_INVENTORY,
  POLICY_AND_PLANNING_SECTION,
  REGULATIONS_ANNOUNCEMENT_GUIDELINES,
  ACADEMIC_SERVICE,
  STUDENT_DEVELOPMENT,
  INTERDISCIPLINARY_PROGRAM_AND_QA,
  GRADUATE_STUDENTS_ACCEPTANCE,
  GRADUATE_STUDIES_COMMITTEE_AND_ADMINISTRATION,
  GRADUATION,
  GRADUATION_PROPOSAL_DOCUMENTS_SCHEDULE,
  GUIDELINE_PROCEDURE,
  INTERDISCIPLINARY_PROGRAMS,
  LECTURER,
  LIFE_CMU,
  NEWS,
  ORGANIZATION_STRUCTURE,
  POLICY_AND_PLAN,
  SCHOLARSHIP,
  SEARCH_BY_FACULTY,
  THESIS,
  THESIS_AWARD_IN_GRADUATE_SCHOOL_CMU,
  VISA,
  QUALIFY_APPLICATION,
  VISION_AND_MISION,
  KM,
  OPEN_DATA,
  OPEN_DATA_2565,
  OPEN_DATA_2566,
  PROGRAM_TUTION_FEE,
  PODCAST_LIVE,
  PROSPECTUS,
  CHECKLIST_OF_JOURNALS,
  THESIS_DISSERTATION_IS_CHECKING,
  HOME,
  ADMISSION_PROCESS,
  AdminRoutePath,
  GRADUATE_SCHOOL_TEAM,
  EXECUTIVE_CALENDAR,
} from '../../../../constants/route-paths';
import { NEWS_TYPES } from '../../../../utils/news';
import { useTranslationUtil } from '../../../../hooks/useTranslationUtil';
import SubpageNestedListItem from './components/SubpageNestedListItem';
import SubpageListItem from './components/SubpageListItem';

export default function NavigateBarV2() {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [showSubmenuContent, setShowSubmenuContent] = useState(false);
  const { t } = useTranslationUtil();

  const AboutPage = [
    {/* title: t('Nav_AboutCMU'), link: routeUrlProvider.getForRoute(ABOUT) },
    { title: t('Nav_AboutGradCMU'), link: `${routeUrlProvider.getForRoute(ABOUT)}#gradcmu` },
    { title: t('Nav_VisionMission'), link: routeUrlProvider.getForRoute(VISION_AND_MISION) */},
    { title: t('Nav_PolicyPlan'), link: routeUrlProvider.getForRoute(POLICY_AND_PLAN) },
    {/* title: t('Nav_OrganizationStructure'), link: routeUrlProvider.getForRoute(ORGANIZATION_STRUCTURE) },
    { title: t('Nav_DirectiveBoard'), link: routeUrlProvider.getForRoute(DIRECTIVE_BOARD) },
    { title: t('Nav_Executive'), link: routeUrlProvider.getForRoute(EXECUTIVE) },
    { title: t('Nav_ExecutiveCalendar'), link: routeUrlProvider.getForRoute(EXECUTIVE_CALENDAR) },
    {
      title: t('Nav_GraduateSchoolTeam'),
      link: routeUrlProvider.getForRoute(GRADUATE_SCHOOL_TEAM),
      subPage: [
        { title: t('Nav_GraduateSchoolOffice'), link: routeUrlProvider.getForRoute(GRADUATE_SCHOOL_OFFICE) },
        { title: t('Nav_GeneralAdministration'), link: routeUrlProvider.getForRoute(GENERAL_ADMINISTRATION) },
        {
          title: t('Nav_FinancialTreasuryInventory'),
          link: routeUrlProvider.getForRoute(FINANCIAL_TREASURY_AND_INVENTORY),
        },
        { title: t('Nav_PolicyandPlanningSection'), link: routeUrlProvider.getForRoute(POLICY_AND_PLANNING_SECTION) },
        { title: t('Nav_AcademicService'), link: routeUrlProvider.getForRoute(ACADEMIC_SERVICE) },
        {
          title: t('Nav_InterdisciplinaryProgramQA'),
          link: routeUrlProvider.getForRoute(INTERDISCIPLINARY_PROGRAM_AND_QA),
        },
        { title: t('Nav_StudentDevelopment'), link: routeUrlProvider.getForRoute(STUDENT_DEVELOPMENT) },
      ],
    },

  { title: t('Nav_DeanReport'), link: routeUrlProvider.getForRoute(DEAN_REPORT) */},
    { title: t('Nav_KM'), link: routeUrlProvider.getForRoute(KM) },
    //{ title: t('Nav_OpenData'), link: routeUrlProvider.getForRoute(OPEN_DATA) },
    {
      title: t('Nav_OpenData'),
      //link: routeUrlProvider.getForRoute(OPEN_DATA),
      subPage: [
        { title: t('Nav_OpenData2565'), link: routeUrlProvider.getForRoute(OPEN_DATA_2565) },
        { title: t('Nav_OpenData2566'), link: routeUrlProvider.getForRoute(OPEN_DATA_2566) },
      ],
    },
    { title: t('Nav_AnnualReport'), link: routeUrlProvider.getForRoute(ANNUA_REPORT) },
    { title: t('Nav_FactFigures'), link: routeUrlProvider.getForRoute(FACTS_AND_FIGURES) },
    // { title: t('Nav_Dashboard'), link: 'https://grad-report.netlify.app/', external: true },
    {
      title: t('Nav_GraduateSchoolNews'),
      link: `${routeUrlProvider.getForRoute(NEWS)}?type=${NEWS_TYPES.GRADUATE_SCHOOL_NEWS}`,
    },
    {/*
      title: t('Old_Web'),
      link: 'https://www.grad.cmu.ac.th/grad_wp2/',
      external: true,*/
    },
  ];

  const ProspectivePage = [
    { title: t('Nav_Life@CMU'), link: routeUrlProvider.getForRoute(LIFE_CMU) },
    { title: t('Nav_PodcastLive'), link: routeUrlProvider.getForRoute(PODCAST_LIVE) },
    // { title: t('Nav_Traditional'), link: '' },
    // { title: t('Nav_Facility'), link: '' },
    // { title: t('Nav_Opportunity'), link: '' },
    {
      title: t('Nav_Prospectus'),
      link: routeUrlProvider.getForRoute(PROSPECTUS),
      subPage: [
        {
          title: t('Nav_CurriculumStructure'),
          link: 'https://mis.cmu.ac.th/TQF/TQF2/CurriculumPublicList.aspx',
          external: true,
        },
        { title: t('Nav_ProgramTutionFee'), link: routeUrlProvider.getForRoute(PROGRAM_TUTION_FEE) },
      ],
    },
    {
      title: '',
    },
    {
      title: '',
    },
    {
      title: '',
    },
    {
      title: t('Nav_SearchbyProgram'),
      link: 'https://mis.cmu.ac.th/TQF/TQF2/CurriculumPublicList.aspx',
      external: true,
      subPage: [
        { title: t('Nav_SearchbyFaculty'), link: routeUrlProvider.getForRoute(SEARCH_BY_FACULTY) },
        { title: t('Nav_SearchbyKeyword'), link: 'https://smart.grad.cmu.ac.th/?p=curr', external: true },
        { title: t('Nav_CourseTeachinEnglish'), link: 'https://smart.grad.cmu.ac.th/?p=ceng', external: true },
        { title: t('Nav_InterdisciplinaryPrograms'), link: 'https://www.mids.cmu.ac.th/?p=programs&&lang=en', external: true },
      ],
    },
    {
      title: t('Nav_Scholarship'),
      link: routeUrlProvider.getForRoute(SCHOLARSHIP),
      subPage: [
        { title: t('Nav_PresidentScholarship'), link: `${routeUrlProvider.getForRoute(SCHOLARSHIP)}#president` },
        { title: t('Nav_TARAScholarship'), link: `${routeUrlProvider.getForRoute(SCHOLARSHIP)}#ta-ra` },
        { title: t('Nav_OtherScholarship'), link: `${routeUrlProvider.getForRoute(SCHOLARSHIP)}#other` },
      ],
    },
    {
      title: t('Nav_ApplyAdmission'),
      link: 'https://cmu.to/apply2cmu',
      external: true,
      subPage: [
        {
          title: t('Nav_AdmissionProcess'),
          link: routeUrlProvider.getForRoute(ADMISSION_PROCESS),
        },
        {
          title: t('Nav_HowtoUseAdmissionSystem'),
          link: 'https://cmu.to/How2Apply',
          external: true,
        },
        {
          title: t('Nav_HowtoApplyforScholarship'),
          link: 'https://cmu.to/ApplyScholarship',
          external: true,
        },
        {
          title: t('Nav_Payment'),
          link: 'https://cmu.to/Payment',
          external: true,
        },
        {
          title: t('Nav_QualificationofApplication'),
          link: 'https://cmu.to/ApplicationQualification',
          external: true,
        },
        { title: t('Nav_ForeignLanguageConditions'), link: routeUrlProvider.getForRoute(FOREIGN_LANGUAGE_CONDITION) },
      ],
    },

    {
      title: t('Nav_AdmissionScholarshipNews'),
      link: `${routeUrlProvider.getForRoute(NEWS)}?type=${NEWS_TYPES.ADMISSION_SCHOLARSHIP_NEWS}`,
    },
  ];

  const StudentsPage = [
    {
      title: t('Nav_StudentPortal'),
      link: 'https://student.grad.cmu.ac.th',
      external: true,
    },
    {
      title: t('Nav_CurriculumStructure'),
      link: 'https://mis.cmu.ac.th/TQF/TQF2/CurriculumPublicList.aspx',
      external: true,
    },
    {
      title: t('Nav_ProgramTutionFee'), link: routeUrlProvider.getForRoute(PROGRAM_TUTION_FEE)
    },

    {
      title: t('GraduateStudentUnion'),
      link: 'https://www.facebook.com/GradUnionCmu/?locale=th_TH',
      external: true,
    },
    { title: t('Nav_EnrollmentAcademicCalendar'), link: routeUrlProvider.getForRoute(ENROLLMENT_ACADEMIC_CALENDAR) },
    {
      title: t('Nav_RegulationsAnnouncementGuidelines'),
      link: routeUrlProvider.getForRoute(REGULATIONS_ANNOUNCEMENT_GUIDELINES),
    },
    { title: t('Nav_GuidelineProcedure'), link: routeUrlProvider.getForRoute(GUIDELINE_PROCEDURE) },
    { title: t('Nav_Scholarship'), link: routeUrlProvider.getForRoute(SCHOLARSHIP) },

    { title: t('Nav_ForeignLanguageConditions'), link: routeUrlProvider.getForRoute(FOREIGN_LANGUAGE_CONDITION) },
    {
      title: t('Nav_ThesisDissertationISchecking'),
      link: routeUrlProvider.getForRoute(THESIS_DISSERTATION_IS_CHECKING),
      subPage: [
        { title: t('Nav_Thesis'), link: routeUrlProvider.getForRoute(THESIS) },
        { title: t('Nav_ChecklistofJournals'), link: routeUrlProvider.getForRoute(CHECKLIST_OF_JOURNALS) },
      ],
    },

    { title: t('Nav_Graduation'), link: routeUrlProvider.getForRoute(GRADUATION) },
    {
      title: t('Nav_RequestforRecommendationLetterSystem'),
      link: 'https://apply.grad.cmu.ac.th/certificate/dist/index.html',
      external: true,
    },
    {
      title: t('Nav_RequestforRecommendation'),
      link: 'https://www.reg.cmu.ac.th/web/reg-document/',
      external: true,
    },
    { title: t('Nav_Visa'), link: routeUrlProvider.getForRoute(VISA) },

    { title: t('Nav_ThesisAward'), link: routeUrlProvider.getForRoute(THESIS_AWARD_IN_GRADUATE_SCHOOL_CMU) },
    { title: t('Nav_StudentNews'), link: `${routeUrlProvider.getForRoute(NEWS)}?type=${NEWS_TYPES.STUDENT_NEWS}` },
    { title: t('Nav_SeminarNews'), link: `${routeUrlProvider.getForRoute(NEWS)}?type=${NEWS_TYPES.SEMINAR_NEWS}` },
  ];

  const StaffsPage = [
    {
      title: t('ระบบ Staff Portal'),
      link: 'https://staff.grad.cmu.ac.th',
      external: true,
    },
    {
      title: t('คณะกรรมการและการบริหารงานบัณฑิตศึกษา'),
      link: routeUrlProvider.getForRoute(GRADUATE_STUDIES_COMMITTEE_AND_ADMINISTRATION),
    },

    { title: t('อาจารย์'), link: routeUrlProvider.getForRoute(LECTURER) },
    { title: t('รายงานการประชุมคณะกรรมการบริหาร'), link: 'https://smart.grad.cmu.ac.th/?p=101', external: true },
    { title: t('กำหนดการประชุมประจำปี'), link: routeUrlProvider.getForRoute(ANNUAL_MEETING_SCHEDULE) },

    {
      title: t('เกี่ยวกับนักศึกษา'),
      subPage: [
        {
          title: t('ข้อบังคับ ประกาศ แนวปฏิบัติ'),
          link: routeUrlProvider.getForRoute(ANNOUNCEMENTS_REGULATIONS_GUIDELINES),
        },
        { title: t('เกณฑ์การสำเร็จการศึกษา'), link: routeUrlProvider.getForRoute(CRITERIA_GRADUATION) },
        {
          title: t('กำหนดการรับเอกสารเสนอสำเร็จการศึกษา และการขยายระยะเวลาการศึกษา'),
          link: routeUrlProvider.getForRoute(GRADUATION_PROPOSAL_DOCUMENTS_SCHEDULE),
        },
        { title: t('ฐานข้อมูลวารสาร'), link: routeUrlProvider.getForRoute(CHECKLIST_OF_JOURNALS) },
      ],
    },

    {
      title: t('เกี่ยวกับหลักสูตร'),
      subPage: [
        {
          title: t('ข้อบังคับ ประกาศ แนวปฏิบัติ (หลักสูตร) มหาวิทยาลัยเชียงใหม่'),
          link: routeUrlProvider.getForRoute(CURRICULUM_GUIDELINES),
        },
        { title: t('แบบฟอร์มการทำหลักสูตร'), link: routeUrlProvider.getForRoute(COURSE_TEMPLATE) },
        {
          title: t('การรับนักศึกษาระดับบัณฑิตศึกษา'),
          link: routeUrlProvider.getForRoute(GRADUATE_STUDENTS_ACCEPTANCE),
        },
//        {
//          title: t('การติดตามหลักสูตร'),
//          link: 'https://o365cmu-my.sharepoint.com/:x:/g/personal/nartchulee_t_cmu_ac_th/Ed2XC58BSAhCi6rwHMSgj6cBfuMBKW0x5ZY3B5Nt6hxfVA?rtime=f_09oCXY2kg',
//          external: true,
//        },
//        { title: t('สถานะการสอบตามหลักสูตร'), link: 'https://smart.grad.cmu.ac.th/?p=102', external: true },
      ],
    },

    { title: t('ระบบ e-meeting'), link: 'https://emeeting.mis.cmu.ac.th/', external: true },

    {
      title: t('ระบบสมัครเรียน'),
      link: 'https://cmu.to/apply2cmu',
      external: true,
    },
    { title: t('ผู้ดูแลเว็บ'), link: 'https://w3.grad.cmu.ac.th/grad-service/api/admin/login', external: true },
    { title: t('ข่าวประกาศ'), link: `${routeUrlProvider.getForRoute(NEWS)}?type=${NEWS_TYPES.SUPPLY_NEWS}` },
  ];
  // const StaffsPage = [
  //   { title: t('Nav_StaffPortal'), link: 'https://oauth.cmu.ac.th/v1/Login.aspx?continue=Staff%20GS', external: true },
  //   {
  //     title: t('Nav_GraduateStudiesCommitteeandAdministration'),
  //     link: routeUrlProvider.getForRoute(GRADUATE_STUDIES_COMMITTEE_AND_ADMINISTRATION),
  //   },

  //   { title: t('Nav_Lecturer'), link: routeUrlProvider.getForRoute(LECTURER) },
  //   { title: t('Nav_MeetingMinutesofExecutiveCommittee'), link: 'https://smart.grad.cmu.ac.th/?p=101', external: true },
  //   { title: t('Nav_AnnualMeetingSchedule'), link: routeUrlProvider.getForRoute(ANNUAL_MEETING_SCHEDULE) },

  //   {
  //     title: t('Nav_Student'),
  //     subPage: [
  //       {
  //         title: t('Nav_AnnouncementsRegulationsGuidelines'),
  //         link: routeUrlProvider.getForRoute(ANNOUNCEMENTS_REGULATIONS_GUIDELINES),
  //       },
  //       { title: t('Nav_CriteriaGraduation'), link: routeUrlProvider.getForRoute(CRITERIA_GRADUATION) },
  //       {
  //         title: t('Nav_GraduationProposalDocumentsSchedule'),
  //         link: routeUrlProvider.getForRoute(GRADUATION_PROPOSAL_DOCUMENTS_SCHEDULE),
  //       },
  //       { title: t('Nav_ChecklistofJournals'), link: routeUrlProvider.getForRoute(CHECKLIST_OF_JOURNALS) },
  //     ],
  //   },

  //   {
  //     title: t('Nav_AboutCurriculum'),
  //     subPage: [
  //       { title: t('Nav_CurriculumGuidelines'), link: routeUrlProvider.getForRoute(CURRICULUM_GUIDELINES) },
  //       { title: t('Nav_CourseTemplate'), link: routeUrlProvider.getForRoute(COURSE_TEMPLATE) },
  //       {
  //         title: t('Nav_GraduateStudentsAcceptance'),
  //         link: routeUrlProvider.getForRoute(GRADUATE_STUDENTS_ACCEPTANCE),
  //       },
  //       {
  //         title: t('Nav_CurriculumTracking'),
  //         link: 'https://o365cmu-my.sharepoint.com/:x:/g/personal/nartchulee_t_cmu_ac_th/Ed2XC58BSAhCi6rwHMSgj6cBfuMBKW0x5ZY3B5Nt6hxfVA?rtime=f_09oCXY2kg',
  //         external: true,
  //       },
  //       { title: t('Nav_CurriculumExaminationStatus'), link: 'https://smart.grad.cmu.ac.th/?p=102', external: true },
  //     ],
  //   },

  //   { title: t('Nav_Emeeting'), link: 'https://emeeting.mis.cmu.ac.th/', external: true },

  //   {
  //     title: t('Nav_StaffAdmission'),
  //     link: 'https://admission.grad.cmu.ac.th/admissions/indexeng.php?p=106',
  //     external: true,
  //   },
  //   { title: t('Nav_webadmin'), link: 'https://pattama.site/api/admin/login/google', external: true },
  //   { title: t('Nav_SupplyNews'), link: `${routeUrlProvider.getForRoute(NEWS)}?type=${NEWS_TYPES.SUPPLY_NEWS}` },
  // ];

  const PageList = [
    { title: t('Nav_Home'), link: routeUrlProvider.getForRoute(HOME), showAsButton: true },
    { title: t('Nav_AboutGradCMU'), link: '', showAsSubPage: true, menuList: AboutPage },
    {
      title: t('Nav_ProspectiveStudents'),
      link: '',
      showAsSubPage: true,
      menuList: ProspectivePage,
      showHighlight: true,
    },
    { title: t('Nav_Students'), link: '', showAsSubPage: true, menuList: StudentsPage },
    // { title: t('Nav_Staffs'), link: '', showAsSubPage: true, menuList: StaffsPage },
    { title: t('เจ้าหน้าที่'), link: '', showAsSubPage: true, menuList: StaffsPage, forceUseThFont: true },
    {
      title: t('news'),
      link: routeUrlProvider.getForRoute(NEWS),
      showAsButton: true,
    },
    // {
    //   title: t('Old_Web'),
    //   link: 'https://www.google.com/',
    //   showAsButton: true,
    //   external: true,
    // },
  ];

  const isShowSubpage = () => {
    return showSubmenuContent && !PageList[currentPageIndex].showAsButton;
  };

  const onMouseLeave = () => {
    setShowSubmenuContent(false);
  };

  return (
    <>
      {/* Desktop view */}
      <div className="hidden h-full w-full xl:block" onMouseLeave={onMouseLeave}>
        <div className="flex h-full w-full text-Lightgray600">
          <div className="flex h-full w-full flex-col ">
            <div className="flex h-full flex-row">
              {PageList.map((data, index) => (
                <PageNavigation
                  activePage={currentPageIndex}
                  showHighlight={data.showHighlight}
                  setPageState={setCurrentPageIndex}
                  title={data.title}
                  stateNumber={index}
                  link={data.link}
                  showSubmenuContent={showSubmenuContent}
                  setShowSubmenuContent={setShowSubmenuContent}
                  showAsButton={data.showAsButton}
                  forceUseThFont={data.forceUseThFont}
                />
              ))}
            </div>
          </div>
        </div>
        {isShowSubpage() && (
          <div className="relative hidden text-UIBlack xl:block">
            <div className="absolute z-10 mx-auto w-[878px] rounded-sm bg-menu-content px-14 py-6">
              <ul className="columns-3">
                {PageList[currentPageIndex].menuList?.map((data, idx) =>
                  !data.subPage ? (
                    <SubpageListItem link={data.link} isExternal={!!data.external} key={idx}>
                      {data.title}
                    </SubpageListItem>
                  ) : (
                    <>
                      <SubpageListItem key={idx} isExternal={data.external} link={data.link}>
                        {data.title}
                      </SubpageListItem>
                      <ul className="relative z-10 -mt-6 list-outside list-disc pl-6">
                        {data.subPage.map((page, idx) => (
                          <SubpageNestedListItem key={idx} link={page.link} isExternal={!!page.external}>
                            {page.title}
                          </SubpageNestedListItem>
                        ))}
                      </ul>
                    </>
                  )
                )}
              </ul>
            </div>
          </div>
        )}
      </div>

      {/* Tablet */}
      <div className="relative mx-auto hidden bg-menu-content md:block xl:hidden" onMouseLeave={onMouseLeave}>
        <div className="absolute z-50 mx-auto w-full">
          <div
            className={`flex h-[64px] flex-row justify-center bg-menu-content text-Lightgray600 ${
              isShowSubpage() ? ' rounded-t-md  ' : 'rounded-md  '
            }`}
          >
            {PageList.map((data, index) => (
              <PageNavigation
                key={index}
                activePage={currentPageIndex}
                setPageState={setCurrentPageIndex}
                title={data.title}
                stateNumber={index}
                link={data.link}
                showSubmenuContent={showSubmenuContent}
                setShowSubmenuContent={setShowSubmenuContent}
                showAsButton={data.showAsButton}
                forceUseThFont={data.forceUseThFont}
              />
            ))}
          </div>

          {isShowSubpage() && (
            <div className="relative  text-UIBlack md:block xl:hidden">
              <div className="absolute w-full ">
                <div className="mx-auto w-[878px] rounded-b-md bg-menu-content px-14 py-6 ">
                  <ul className="mx-auto columns-3 gap-y-6">
                    {PageList[currentPageIndex].menuList?.map((data, idx) =>
                      !data.subPage ? (
                        <SubpageListItem key={idx} link={data.link} isExternal={!!data.external}>
                          {data.title}
                        </SubpageListItem>
                      ) : (
                        <>
                          <SubpageListItem key={idx} isExternal={data.external} link={data.link}>
                            {data.title}
                          </SubpageListItem>
                          <ul key={idx} className="list-inside list-disc pl-4">
                            {data.subPage.map((page, idx) => (
                              <SubpageNestedListItem key={idx} link={page.link} isExternal={!!page.external}>
                                {page.title}
                              </SubpageNestedListItem>
                            ))}
                          </ul>
                        </>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Mobile view */}
      <div className="absolute z-40 w-full bg-UIWhite md:hidden">
        <div className=" w-full divide-y-2 divide-Lightgray200 border-t-2 border-b-2 border-Lightgray200  text-lg font-bold text-Lightgray600">
          {PageList.map((data, index) => (
            <MobilePageNavigation
              activePage={currentPageIndex}
              setPageState={setCurrentPageIndex}
              title={data.title}
              stateNumber={index}
              link={data.link}
              showSubmenuContent={showSubmenuContent}
              setShowSubmenuContent={setShowSubmenuContent}
              showAsButton={data.showAsButton}
              menuList={data.menuList}
              showAsSubPage={data.showAsSubPage}
              forceUseThFont={data.forceUseThFont}
            />
          ))}
        </div>
      </div>
    </>
  );
}
