import { useHistory } from 'react-router-dom';
import MoreDetailButton from './Button/MoreDetailButton';
import routeUrlProvider from '../constants/route-paths';
import { useTranslationUtil } from '../hooks/useTranslationUtil';

const InterdisciplinaryProgramCard = ({ children, img, route, fromSearchByFaculty = false }) => {
  const history = useHistory();
  const { t } = useTranslationUtil();

  const onClick = () => {
    history.push({ pathname: routeUrlProvider.getForRoute(route), state: { fromSearchByFaculty } });
  };

  return (
    <div
      className="mx-auto w-full items-center duration-150 hover:scale-105 hover:cursor-pointer md:w-[220px]"
      onClick={onClick}
    >
      <img src={img} alt="" className="h-[270px] w-full object-cover" />
      <div className="px-6 lg:px-0">
        <div className="mt-4 text-[32px] font-medium leading-10 text-Lightgray700">{children}</div>
        <div className="mt-2">
          <MoreDetailButton text={t('Details')} onClick={onClick} />
        </div>
      </div>
    </div>
  );
};

export default InterdisciplinaryProgramCard;
