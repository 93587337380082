// import { useState, useEffect } from 'react';
// import { getListHomeCoverData } from '../../../services/homeCoverSlider/getListHomeCoverData';
import CoverImageSlider from './CoverImageSlider';
import FacebookButton from '../../../components/Button/FacebookButton';
import LinkedInButton from '../../../components/Button/LinkedInButton';
import TwitterButton from '../../../components/Button/TwitterButton';
import YouTubeButton from '../../../components/Button/YouTubeButton';
import PhoneCallModal from '../../../components/Modal/PhonecallModal';
import CustomContainer from '../../../components/CustomContainer';

const BannerSection = () => {
  const bannerHeight = 500; // in px unit
  return (
    <div className={`h-[${bannerHeight}px]`}>
      <CustomContainer>
        <div className={`h-[${bannerHeight}px] absolute right-0 z-40 my-auto mr-12 hidden w-fit grid-cols-1 lg:grid`}>
          <div className="my-auto rounded-3xl bg-PrimaryDark bg-opacity-70 px-4 py-5 text-center">
            <div>
              <div className="h-[24px]">
                <FacebookButton />
              </div>
              <SocialButtonWrapper>
                <TwitterButton />
              </SocialButtonWrapper>
              <SocialButtonWrapper>
                <LinkedInButton />
              </SocialButtonWrapper>
              <SocialButtonWrapper>
                <YouTubeButton />
              </SocialButtonWrapper>
            </div>
            <PhoneCallModal className="mt-[110px] flex flex-row justify-end" />
          </div>
        </div>
      </CustomContainer>

      <CoverImageSlider height={bannerHeight} />
    </div>
  );
};

const SocialButtonWrapper = ({ children }) => {
  return <div className="mt-[40px] h-[24px]">{children}</div>;
};

export default BannerSection;
