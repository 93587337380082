import React from 'react';
import Layout from '../../components/Layout';
import CustomContainer from '../../components/CustomContainer';
import CoverImage from './assets/cover.png';
import PageTitleContainerWithBackgroundImage from '../../components/PageTitleContainerWithBackgroundImage';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import GoogleMapPinIcon from './assets/googlemap-pin.svg';
import GoogleMapImg from './assets/map.png';
import { EmailIcon, FacebookIcon, LinkedinIcon, TwitterIcon } from 'react-share';
import InstagramIcon from '../../assets/instagram-round-color-icon.webp';

const GoogleMapPinUrl =
  'https://www.google.com/maps/place/%E0%B8%9A%E0%B8%B1%E0%B8%93%E0%B8%91%E0%B8%B4%E0%B8%95%E0%B8%A7%E0%B8%B4%E0%B8%97%E0%B8%A2%E0%B8%B2%E0%B8%A5%E0%B8%B1%E0%B8%A2+%E0%B8%A1%E0%B8%AB%E0%B8%B2%E0%B8%A7%E0%B8%B4%E0%B8%97%E0%B8%A2%E0%B8%B2%E0%B8%A5%E0%B8%B1%E0%B8%A2%E0%B9%80%E0%B8%8A%E0%B8%B5%E0%B8%A2%E0%B8%87%E0%B9%83%E0%B8%AB%E0%B8%A1%E0%B9%88/@18.7941058,98.9582625,15z/data=!4m2!3m1!1s0x0:0xb310013d50940d52?sa=X&ved=2ahUKEwjXu4CF8v38AhWxWHwKHZP8DzUQ_BJ6BAhhEAg';

const PurpleDivider = () => <div className="mx-auto mt-4 h-2 w-20 bg-CMUPurple xl:mx-0" />;
const Title = ({ children }) => <div className=" text-[32px] leading-10 text-Lightgray500">{children}</div>;
const Description = ({ children }) => <div className=" mt-4 text-2xl leading-[30px] text-Lightgray800">{children}</div>;
const SectionWrapper = ({ children, className }) => (
  <div
    className={`mx-auto max-w-[200px] text-center lg:max-w-[324px] lg:px-0 xl:mx-0 xl:pl-20 xl:text-left 3xl:max-w-full ${className}`}
  >
    {children}
  </div>
);
const MapPinButton = () => {
  const { t } = useTranslationUtil();
  return (
    <button
      className="mx-auto mt-4 flex w-fit gap-x-[14px] duration-300 hover:scale-110 hover:cursor-pointer hover:text-NeonPink xl:mx-0 "
      onClick={() => window.open(GoogleMapPinUrl, '_blank', 'noopener, noreferrer')}
    >
      <img src={GoogleMapPinIcon} alt="" />
      <div className=" max-w-[230px] text-xl font-bold leading-6 md:max-w-full">
        {t('GraduateSchoolOfficeChiangMaiUniversity')}
      </div>
    </button>
  );
};

const ContactUsPage = () => {
  const { t } = useTranslationUtil();

  const onImageClick = () => window.open(GoogleMapPinUrl, '_blank', 'noopener, noreferrer');

  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainerWithBackgroundImage coverImage={CoverImage} mixBlendMode="mix-blend-overlay">
          {t('ContactUs')}
        </PageTitleContainerWithBackgroundImage>
        <div className="lg:justify-between xl:flex xl:flex-row">
          <div className="xl:hidden ">
            <img
              src={GoogleMapImg}
              alt=""
              className="h-[154px] w-full object-cover duration-300 hover:scale-105 hover:cursor-pointer md:h-[300px] lg:h-[400px]"
              onClick={onImageClick}
            />
            <MapPinButton />
          </div>
          <div>
            <SectionWrapper className="mt-10 md:mt-12">
              <Title>{t('Address')}</Title>
              <PurpleDivider />
              <Description>{t('AddressDetail')}</Description>
            </SectionWrapper>
            <SectionWrapper className="mt-12">
              <Title>{t('Contact')}</Title>
              <PurpleDivider />
              <Description>{t('Telephone')}</Description>
              <Description>+66-5394-2400</Description>
            </SectionWrapper>
            <SectionWrapper className="mt-12">
              <Title>{t('WorkingHour')}</Title>
              <PurpleDivider />
              <Description>8:30 - 16:30</Description>
              <Description>{t('MonToFri')}</Description>
            </SectionWrapper>
          </div>
          <div className="z-10 -mt-12 hidden xl:block">
            <img
              src={GoogleMapImg}
              alt=""
              className="w-full rounded-sm object-cover duration-300 hover:scale-105 hover:cursor-pointer xl:w-[774px] 2xl:h-[664px]
                2xl:w-[1058px]"
              onClick={onImageClick}
            />
            <MapPinButton />
          </div>
        </div>
        <div className="mx-auto mt-20 flex w-fit flex-row gap-x-3 md:gap-x-6 lg:gap-x-8">
          <a href="https://www.facebook.com/gradcmu/" target="_blank" rel="noopener noreferrer">
            <FacebookIcon round />
          </a>
          <a href="https://twitter.com/gradcmu" target="_blank" rel="noopener noreferrer">
            <TwitterIcon round />
          </a>
          <a href="https://www.linkedin.com/in/graduate-school-cmu-a917b1182" target="_blank" rel="noopener noreferrer">
            <LinkedinIcon round />
          </a>
          <a href="https://www.instagram.com/gradcmu/" target="_blank" rel="noopener noreferrer" className="my-auto">
            <img src={InstagramIcon} alt="instagram-icon" width={64} height={64} />
          </a>
          <a href="mailto:pr.grad@cmu.ac.th" target="_blank" rel="noopener noreferrer">
            <EmailIcon round />
          </a>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default ContactUsPage;
