export const MOCK_ACTIVITY_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_TYPE_OF_USE = [
  {
    name_th: 'ตีพิมพ์ผลงานในวารสารระดับชาติ',
    name_en: 'Publication in an Academic Journal (National Level)',
    student_amount: 2,
    student_percentage: '4.76%',
  },
  {
    name_th: 'ตีพิมพ์ผลงานในวารสารระดับนานาชาติ',
    name_en: 'Publication in an Academic Journal (International Level)',
    student_amount: 3,
    student_percentage: '7.14%',
  },
  {
    name_th: 'นำเสนอผลงานวิชาการในประเทศ',
    name_en: 'Participation and Presentation (National Level',
    student_amount: 23,
    student_percentage: '54.76%',
  },
  {
    name_th: 'นำเสนอผลงานวิชาการต่างประเทศ',
    name_en: 'Participation and Presentation (International Level)',
    student_amount: 13,
    student_percentage: '30.95%',
  },
  {
    name_th: 'อบรมระสั้น ณ ต่างประเทศ',
    name_en: 'Short Course Training in a Foreign Country',
    student_amount: 1,
    student_percentage: '2.38%',
  },
];
