import { useState, useEffect } from 'react';
import MoreDetailButton from '../../../components/Button/MoreDetailButton';
import WorkCaseIcon from '../../../components/Icons/WorkCaseIcon';
import routeUrlProvider, { NEWS } from '../../../constants/route-paths';
import { NEWS_TYPES, getNewsQueryParams } from '../../../utils/news';
import JobNewsCard from './JobNewsCard';
import { useHistory } from 'react-router-dom';
import { getListNews } from '../../../services/news';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';
import CustomContainer from '../../../components/CustomContainer';

const JobsNewsSection = () => {
  const history = useHistory();
  const { t, nowLanguageCode, getApiLanguageCode } = useTranslationUtil();

  const [jobNewsList, setJobNewsList] = useState([]);

  useEffect(() => {
    getListNews({
      newsTypeQuery: getNewsQueryParams(NEWS_TYPES.JOBS_NEWS),
      page: 1,
      perPage: 3,
      language: getApiLanguageCode(),
    })
      .then((res) => {
        setJobNewsList(res.data || []);
      })
      .catch((err) => console.log(' err ', err));
  }, [nowLanguageCode]);

  if (jobNewsList.length === 0) {
    return;
  }

  return (
    <CustomContainer className=" mt-[102px] flex min-h-[308px] flex-col justify-center px-6 lg:flex-row">
      <div className="flex flex-col md:w-fit lg:flex-row">
        <div className="w-full">
          <div className="relative h-[2px] w-full bg-Lightgray400" />
          <div className="mt-6 flex w-full flex-row justify-center  md:justify-start">
            <div>
              <WorkCaseIcon />
            </div>
            <div className="ml-4 text-2xl font-semibold leading-[30px] text-UIBlack">{t('Jobs News')}</div>
            <div className="ml-8 mt-1 hidden md:block">
              <MoreDetailButton
                text={t('More News')}
                onClick={() =>
                  history.push({
                    pathname: routeUrlProvider.getForRoute(NEWS),
                    search: `?type=${NEWS_TYPES.JOBS_NEWS}`,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col  md:mt-10 md:flex-row lg:ml-[66px] lg:mt-0">
        {jobNewsList.slice(0, 3).map((data, index) => (
          <JobNewsCard key={index} newsData={data} />
        ))}
      </div>
    </CustomContainer>
  );
};

export default JobsNewsSection;
