import { useTranslation } from 'react-i18next';
import { languages } from '../../constants/languages';

import TimeAgo from 'javascript-time-ago';

import en from 'javascript-time-ago/locale/en';
import th from 'javascript-time-ago/locale/th';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(th);
const timeAgoEN = new TimeAgo('en-US');
const timeAgoTH = new TimeAgo('th-TH');

export const useTranslationUtil = () => {
  const { t, i18n } = useTranslation();

  const isThai = () => {
    return i18n.resolvedLanguage === languages.th.code;
  };

  const isEng = () => {
    return i18n.resolvedLanguage === languages.en.code;
  };

  const getDisplayText = (thaiText, englishText) => {
    return isThai() ? thaiText : englishText;
  };

  const getDisplayObject = (thaiObject, englishObject) => {
    return isThai() ? thaiObject : englishObject;
  };

  const getFontFamily = (useOpenSansInEng = false) => {
    if (isThai()) {
      return ' font-ibm-plex-sans ';
    }
    if (useOpenSansInEng) {
      return ' font-open-sans ';
    }
    return ' font-dosis ';
  };

  const getThFontFamily = () => {
    return ' font-ibm-plex-sans ';
  };

  const changeLanguage = () => {
    if (isThai()) {
      i18n.changeLanguage(languages.en.code);
    } else {
      i18n.changeLanguage(languages.th.code);
    }
  };

  const changeLanguageTo = (languageCode) => {
    i18n.changeLanguage(languageCode);
  };

  const formatDate = (date) => {
    const locale = isThai() ? 'th-TH' : 'en-US';
    return new Intl.DateTimeFormat(locale, {
      locale: locale,
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(new Date(date));
  };

  const getApiLanguageCode = () => {
    return isThai() ? 'TH' : 'EN';
  };

  return {
    t,
    timeAgo: isThai() ? timeAgoTH : timeAgoEN,
    i18n,
    formatDate,
    nowLanguageCode: i18n.resolvedLanguage,
    isThai,
    isEng,
    getFontFamily,
    getDisplayText,
    getDisplayObject,
    changeLanguage,
    changeLanguageTo,
    getThFontFamily,
    getApiLanguageCode,
  };
};
