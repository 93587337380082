import i18n from 'i18next';
import { getAccessToken } from '../token';

/* eslint-disable  no-console */
const genRequestOptions = (method, body = {}, isAuth, token) => {
  let parsedBody;
  const languageKey = i18n.language;

  try {
    parsedBody = JSON.stringify(body);
  } catch (e) {
    throw new Error(`body for send to api isn't valid json`);
  }

  const headers = isAuth
    ? {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token || getAccessToken() || ''}`,
        'x-localization': languageKey,
      }
    : {
        'Content-Type': 'application/json; charset=utf-8 ',
        'x-localization': languageKey,
      };

  if (method === 'GET') {
    delete headers['Content-Type'];
  }
  return {
    method,
    headers,
    body: method === 'GET' ? null : parsedBody,
  };
};

const handleSuccess = (response) =>
  response.json().then((body) => ({
    ...body,
    response,
  }));

const handleFail = (response) =>
  response.json().then((body) => ({
    ...body,
    response,
  }));

const handleResponse = (response) => {
  if (response.ok) {
    return handleSuccess(response);
  }
  return handleFail(response).then((error) => {
    throw error;
  });
};

export const fetchGet = (url, params, isAuth = false, token) => {
  let urlParams = url;
  try {
    urlParams = new URL(url);
    Object.keys(params || {}).forEach((key) => urlParams.searchParams.append(key, params[key]));
  } catch (e) {
    console.warn('Url is invalid');
  }

  const requestOptions = genRequestOptions('GET', null, isAuth, token);
  return fetch(urlParams, requestOptions).then(handleResponse);
};

export const fetchPost = (url, body, isAuth = false, token) => {
  const requestOptions = genRequestOptions('POST', body, isAuth, token);
  return fetch(url, requestOptions).then(handleResponse);
};

export const fetchPut = (url, body, isAuth = false, token) => {
  const requestOptions = genRequestOptions('PUT', body, isAuth, token);
  return fetch(url, requestOptions).then(handleResponse);
};

export const fetchDelete = (url, body, isAuth) => {
  const requestOptions = genRequestOptions('DELETE', body, isAuth);
  return fetch(url, requestOptions).then(handleResponse);
};

export const getSuccessData = (res) => res.data;

export const getFailData = (res) => res.errors;

export const fetchPostImage = (url, body = {}, isAuth = false) => {
  const requestOptions = {
    method: 'POST',
    body,
    headers: isAuth
      ? {
          Authorization: `Bearer ${getAccessToken() || ''}`,
        }
      : undefined,
  };
  return fetch(url, requestOptions).then(handleResponse);
};

export const fetchPutFormData = (url, formData, options = {}) => {
  const method = 'PUT';
  const { isAuth, token } = options;
  const languageKey = i18n.language;

  const headers = isAuth
    ? {
        Authorization: `Bearer ${token || getAccessToken() || ''}`,
        'x-localization': languageKey,
      }
    : {
        'x-localization': languageKey,
      };

  const requestOptions = {
    method,
    headers,
    body: formData,
  };
  return fetch(url, requestOptions).then(handleResponse);
};

export const fetchPosFormData = (url, formData, options = {}) => {
  const method = 'POST';
  const { isAuth, token } = options;
  const languageKey = i18n.language;

  const headers = isAuth
    ? {
        Authorization: `Bearer ${token || getAccessToken() || ''}`,
        'x-localization': languageKey,
      }
    : {
        'x-localization': languageKey,
      };

  const requestOptions = {
    method,
    headers,
    body: formData,
  };
  return fetch(url, requestOptions).then(handleResponse);
};
