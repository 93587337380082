import AttatchFilePinkIcon from '../../../../../components/Icons/AttatchFilePinkIcon';
import DownloadPinkIcon from '../../../../../components/Icons/DownloadPinkIcon';
import EyelashIcon from '../../../../../components/Icons/EyelashPinkIcon';
import { useTranslationUtil } from '../../../../../hooks/useTranslationUtil';

const NewsAttatchment = ({ fileName, url }) => {
  const { t } = useTranslationUtil();

  const customFilename = fileName || 'document.pdf';

  return (
    <div className="mt-4 flex flex-row rounded-sm border-[2px] border-solid border-Lightgray100 bg-Lightgray50  px-8 py-5">
      <div className="my-auto">
        <AttatchFilePinkIcon />
      </div>
      <div className="my-auto ml-6 w-full text-xl font-bold leading-8 text-UIBlack">
        <div>{t('NewsAttachment')}</div>
        <div className="flex flex-row justify-between">
          <div className="my-auto text-sm font-normal">{customFilename}</div>
          <div className="flex flex-row">
            <button
              className="ml-5 flex flex-row duration-300 hover:scale-110"
              onClick={async () => {
                fetch(url)
                  .then((response) => {
                    response.blob().then((blob) => {
                      // Creating new object of PDF file
                      const fileURL = window.URL.createObjectURL(blob);
                      // Setting various property values
                      let alink = document.createElement('a');
                      alink.href = fileURL;
                      alink.target = '_blank';
                      alink.download = customFilename;
                      alink.click();
                    });
                  })
                  .catch((err) => console.error(err));
              }}
            >
              <div className="my-auto text-base font-bold text-NeonPink"> {t('Download')} </div>
              <div className="my-auto ml-3">
                <DownloadPinkIcon />
              </div>
            </button>
            <button
              className="ml-5 flex flex-row duration-300 hover:scale-110"
              onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}
            >
              <div className="my-auto  text-base font-bold text-NeonPink"> {t('read')}</div>
              <div className="my-auto ml-3">
                <EyelashIcon />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsAttatchment;
