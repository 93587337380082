import React from 'react';
import CollapseSection from '../../components/CollapseSection';
import CollapseItem from '../../components/CollapseSection/CollapseItem';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';

import enrollmentnewpdf from './3-enrollment.newpdf.pdf';

import สำเนาของปฏิทินการประชุมบัณฑิตวิทยาลัย from './assets/0/สำเนาของ-ปฏิทินการประชุมบัณฑิตวิทยาลัย-แล.pdf';

import cmu54 from './assets/1/cmu54.pdf';
import CMUDegreeName2559 from './assets/1/CMUDegreeName2559.pdf';
import DegreeName56 from './assets/1/DegreeName56.pdf';
import LV0YXX1Z from './assets/1/LV0YXX1Z.pdf';
import SV0LPR4M from './assets/1/SV0LPR4M.pdf';

import cmu55 from './assets/2/131150_4.doc';
import CurrAddmissionFlow2018 from './assets/2/CurrAddmissionFlow2018.pdf';
import CurrStandard2023 from './assets/2/เกณฑ์มาตรฐานหลักสูตร 2566.pdf';
import CurrCommitee from './assets/2/CurrCommitee.docx';
import FTES from './assets/2/FTES.pdf';
import LI0SQ6JH from './assets/2/LI0SQ6JH.pdf';
import s4 from './assets/2/s4.pdf';
import s5 from './assets/2/s5.pdf';
import s6 from './assets/2/s6.pdf';
import s7 from './assets/2/s7.pdf';

import CurrForAdmissionFinal from './assets/3/CurrForAdmissionFinal.pdf';
import syst from './assets/3/syst.pdf';
import TimeFrameOpenProveCurr from './assets/3/TimeFrameOpenProveCurr.pdf';

import จำนวนและคุณสมบัติของอาจารย์ผู้รับผิดชอบหลักสูตรและเรื่องการแต่งตั้งผู้เชี่ยวชาญเฉพาะ from './assets/4/9R0OH0K1.pdf';
import จำนวนและคุณสมบัติของอาจารย์ผู้รับผิดชอบหลักสูตร from './assets/4/251456.pdf';

import คู่มือการเสนอหลักสูตรใหม่และหลักสูตรปรับปรุง from './assets/5/510714.pdf';
import GradCMUAcademicActivityProcedure from './assets/5/Grad-CMU-Academic-Activity-Procedure.pdf';

import cull_p from './assets/6/cull_p.pdf';
import fc_open_cull from './assets/6/fc_open_cull.pdf';

import criteriondevelopmenmd58 from './assets/7/criterion developmen_md_58.pdf';
import CurrSTD20160905 from './assets/7/CurrSTD-20160905.pdf';
import management_stadard58 from './assets/7/management_stadard58.pdf';
import news2 from './assets/7/news2.pdf';
import News328072552 from './assets/7/News328072552.pdf';
import standard_curr_graduate58 from './assets/7/standard_curr_graduate58.pdf';
import tqf583 from './assets/7/tqf58-3.pdf';

import การปรับเปลี่ยนแนวทางการพิจารณาความสอดคล้องของหลักสูตรระดับอุดมศึกษาที่ผ่านการอนุมัติ from './assets/8/1000000606-1.pdf';
import คู่มือสำหรับผู้ปฏิบัติงานCHECurriculumOnline from './assets/8/1000000606-2.pdf';
import WorkflowCHECO from './assets/8/WorkflowCHECO.pdf';

import curr_art01 from './assets/9/curr_art01.pdf';
import Nurse2560 from './assets/9/Nurse2560.pdf';

import UA01 from './assets/10/UA01.pdf';
import UA02 from './assets/10/UA02.pdf';

import หลักเกณฑ์การกำหนดชื่อปริญญา51 from './assets/11/510709_1.pdf';
import หลักเกณฑ์การกำหนดชื่อปริญญา49 from './assets/11/510709_2.pdf';
import DegreeName2559 from './assets/11/DegreeName2559.pdf';
import GuidelinesCreditBank2562 from './assets/11/Guidelines-CreditBank-2562.pdf';
import Temp_2 from './assets/11/Temp_2.pdf';
import กฎกระทรวง65 from './assets/11/กฎกระทรวง-65.pdf';
import เกณฑ์มาตรฐานหลักสูตรระดับบัณฑิตศึกษา65 from './assets/11/เกณฑ์มาตรฐานหลักสูตรระดับบัณฑิตศึกษา-65.pdf';
import ประกาศกมอแนวทางการดำเนินงานคลังหน่วยกิ from './assets/11/ประกาศ-กมอ.-แนวทางการดำเนินงานคลังหน่วยกิ.pdf';
import ประกาศกมอหลักเกณฑ์และวิธีการเทียบโอนหน from './assets/11/ประกาศ-กมอ.-หลักเกณฑ์และวิธีการเทียบโอนหน.pdf';

import เงื่อนไขและข้อชี้แจงบางประการเกี่ยวกับรายกระบวนวิชา2 from './assets/12/131150_4.doc';
import แนวปฏิบัติในการแต่งตั้งคณะกรรมการร่างและปรับปรุงหลักสูตรระดับบัณฑิตศึกษา from './assets/12/510321_1.doc';
import CurriculumMapping from './assets/12/CurriculumMapping.pdf';
import CurrInstructor from './assets/12/CurrInstructor.pdf';
import CurrStaff from './assets/12/CurrStaff.pdf';
import GuideLinePlanAB from './assets/12/GuideLinePlanAB.pdf';
import PHDCurrManagement from './assets/12/PHDCurrManagement.pdf';
import prnews01 from './assets/12/pr-news01.pdf';
import t01 from './assets/12/t01.doc';
import WP10VHBQ from './assets/12/WP10VHBQ.pdf';
import WS0OIOMA from './assets/12/WS0OIOMA.pdf';

import CurrVnV120160905 from './assets/13/CurrVnV1-20160905.pdf';
import CurrVnV220160905 from './assets/13/CurrVnV2-20160905.pdf';
import KPIforFac20170531 from './assets/13/KPIforFac-20170531.docx';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';
import CollapseSectionContainer from '../../components/CollapseSection/CollapseSectionContainer';

const CurriculumGuidelinesPage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{t('ข้อบังคับ ประกาศ แนวปฏิบัติ (หลักสูตร) มหาวิทยาลัยเชียงใหม่')}</PageTitleContainer>
        <CollapseSectionContainer>
          <CollapseSection title={t('กำหนดการเสนอหลักสูตร')}>
            <CollapseItem pdfUrl={สำเนาของปฏิทินการประชุมบัณฑิตวิทยาลัย}>{t('ประจำปี พ.ศ. 2566')}</CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('ข้อบังคับ')}>
            <CollapseItem pdfUrl={CMUDegreeName2559}>
              {t('ข้อบังคับมหาวิทยาลัยเชียงใหม่ ว่าด้วยปริญญาในสาขาวิชาและอักษรย่อสำหรับสาขาวิชา พ.ศ. 2553')}
            </CollapseItem>
            <CollapseItem pdfUrl={DegreeName56}>{t('ข้อบังคับว่าด้วยชื่อปริญญา 56')}</CollapseItem>
            <CollapseItem pdfUrl={LV0YXX1Z}>
              {t(
                'ประกาศมหาวิทยาลัยเชียงใหม่ เรื่อง แนวปฏิบัติในการเสนอหลักสูตรใหม่และหลักสูตรปรับปรุงระดับบัณฑิตศึกษา'
              )}{' '}
            </CollapseItem>
            <CollapseItem pdfUrl={SV0LPR4M}>{t('ประกาศมหาวิทยาลัยเชียงใหม่ เรื่อง หลักสูตรนานาชาติ')}</CollapseItem>
            <CollapseItem pdfUrl={cmu54}>
              {t(
                'ประกาศ มหาวิทยาลัยเชียงใหม่ เรื่อง ข้อกำหนดพฤติกรรมด้านคุณธรรมจริยธรรมของนักศึกษามหาวิทยาลัยเชียงใหม่'
              )}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('แนวปฏิบัติเกี่ยวกับหลักสูตร')}>
            <CollapseItem pdfUrl={CurrAddmissionFlow2018}>
              {t('ขั้นตอนการร่าง/ปรับปรุงหลักสูตรและการเปิดรับสมัครนักศึกษาบัณฑิตศึกษา')}
            </CollapseItem>
            <CollapseItem pdfUrl={CurrStandard2023}>
              {t('ประกาศ เกณฑ์มาตรฐานการศึกษาระดับบัณฑิตศึกษา พ.ศ. 2566')}
            </CollapseItem>
            <CollapseItem pdfUrl={LI0SQ6JH}>{t('แนวปฏิบัติในการเขียนจำนวนหน่วยกิตกระบวนวิชา')}</CollapseItem>
            <CollapseItem pdfUrl={s4}>{t('แนวปฏิบัติเกี่ยวกับการเปิด - ปิด และปรับปรุงกระบวนวิชา')}</CollapseItem>
            <CollapseItem pdfUrl={s5}>{t('แนวปฏิบัติเกี่ยวกับการแก้ไขอักษรลำดับขั้น P')}</CollapseItem>
            <CollapseItem pdfUrl={s6}>{t('แนวปฏิบัติเกี่ยวกับการเปลี่ยนแปลงแผนกำหนดการศึกษา')}</CollapseItem>
            <CollapseItem pdfUrl={s7}>
              {t('แนวปฏิบัติเกี่ยวกับการบันทึกข้อมูลหลักสูตรและกระบวนวิชาในฐานข้อมูลของระบบ CMU MIS')}
            </CollapseItem>
            <CollapseItem pdfUrl={CurrCommitee}>
              {t('แนวปฏิบัติในการแต่งตั้งคณะกรรมการร่างและปรับปรุงหลักสูตรระดับบัณฑิตศึกษา')}
            </CollapseItem>
            <CollapseItem pdfUrl={FTES}>{t('การคำนวณ FTES')}</CollapseItem>
            {/* <CollapseItem pdfUrl={เงื่อนไขและข้อชี้แจงบางประการเกี่ยวกับรายกระบวนวิชา}>{t("เงื่อนไขและข้อชี้แจงบางประการเกี่ยวกับรายกระบวนวิชา")}</CollapseItem> */}
          </CollapseSection>

          <CollapseSection title={t('ระบบและกลไกในการทำหลักสูตรและกระบวนวิชา')}>
            <CollapseItem pdfUrl={syst}>{t('ระบบและกลไกการจัดทำหลักสูตร/กระบวนวิชา')}</CollapseItem>
            <CollapseItem pdfUrl={TimeFrameOpenProveCurr}>
              {t('กำหนดระยะเวลาในการนำเสนอการเปิดหลักสูตรใหม่/การปรับปรุงหลักสูตร')}
            </CollapseItem>
            <CollapseItem pdfUrl={CurrForAdmissionFinal}>
              {t('ขั้นตอนร่าง/ปรับปรุงหลักสูตรและการเปิดรับสมัครนักศึกษาบัณฑิตศึกษา')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('อาจารย์ประจำหลักสูตรและผู้รับผิดชอบหลักสูตร')}>
            <CollapseItem pdfUrl={จำนวนและคุณสมบัติของอาจารย์ผู้รับผิดชอบหลักสูตรและเรื่องการแต่งตั้งผู้เชี่ยวชาญเฉพาะ}>
              {t(
                'แนวปฏิบัติ เรื่อง จำนวนและคุณสมบัติของอาจารย์ผู้รับผิดชอบหลักสูตรและเรื่องการแต่งตั้งผู้เชี่ยวชาญเฉพาะ'
              )}
            </CollapseItem>
            <CollapseItem pdfUrl={จำนวนและคุณสมบัติของอาจารย์ผู้รับผิดชอบหลักสูตร}>
              {t('จำนวนและคุณสมบัติของอาจารย์ผู้รับผิดชอบหลักสูตร')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('คู่มือหลักสูตร')}>
            <CollapseItem pdfUrl={คู่มือการเสนอหลักสูตรใหม่และหลักสูตรปรับปรุง}>
              {t('คู่มือการเสนอหลักสูตรใหม่และหลักสูตรปรับปรุง ระดับบัณฑิตศึกษา')}
            </CollapseItem>
            <CollapseItem pdfUrl={GradCMUAcademicActivityProcedure}>
              {t('แนวปฏิบัติและขั้นตอนในการเสนอและพัฒนาหลักสูตรและกระบวนวิชาระดับบัณฑิตศึกษา (28 เม.ย 63)')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('คำนิยามและขั้นตอนการเสนอหลักสูตร')}>
            <CollapseItem pdfUrl={cull_p}>{t('คำนิยามและขั้นตอนการเปิด / ปรับปรุงหลักสูตร')}</CollapseItem>
            <CollapseItem pdfUrl={fc_open_cull}>{t('Flow Chart การเปิด / ปรับปรุงหลักสูตร')}</CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('กรอบมาตรฐานคุณวุฒิ ระดับอุดมศึกษาแห่งชาติ (TQF)')}>
            <CollapseItem pdfUrl={standard_curr_graduate58}>
              {t('ประกาศกระทรวงศึกษาธิการ เรื่อง เกณฑ์มาตรฐานหลักสูตรระดับบัณฑิตศึกษา พ.ศ. 2558')}
            </CollapseItem>
            <CollapseItem pdfUrl={criteriondevelopmenmd58}>
              {t('เกณฑ์มาตรฐานหลักสูตรระดับบัณฑิตศึกษา (แผนภูมิ)')}
            </CollapseItem>
            <CollapseItem pdfUrl={management_stadard58}>
              {t('ประกาศกระทรวงศึกษาธิการ เรื่อง แนวทางการบริหารเกณฑ์มาตรฐานหลักสูตรอุดมศึกษา พ.ศ. 2558')}
            </CollapseItem>
            <CollapseItem pdfUrl={tqf583}>
              {t(
                'ประกาศคณะกรรมการการอุดมศึกษา เรื่อง แนวทางการปฏิบัติตามกรอบมาตรฐานคุณวุฒิระดับอุดมศึกษาแห่งชาติ (ฉบับที่ 3) พ.ศ. 2558'
              )}
            </CollapseItem>
            <CollapseItem pdfUrl={news2}>
              {t('ประกาศกระทรวงศึกษาธิการ เรื่อง กรอบมาตรฐานคุณวุฒิระดับอุดมศึกษาแห่งชาติ พ.ศ. 2552')}
            </CollapseItem>
            <CollapseItem pdfUrl={News328072552}>
              {t(
                'ประกาศคณะกรรมการการอุดมศึกษา เรื่อง แนวทางการปฏิบัติตามกรอบมาตรฐานคุณวุฒิระดับอุดมศึกษาแห่งชาติ พ.ศ. 2552'
              )}
            </CollapseItem>
            <CollapseItem pdfUrl={CurrSTD20160905}>
              {t(
                'กรอบมาตรฐานคุณวุฒิระดับอุดมศึกษา มาตรฐานผลการเรียนรู้แผนที่การกระจายความรับผิดชอบ และการทวนสอบผลสัมฤทธิ์การเรียนรู้ : รศ.ดร.วิเชียร ชุติมาสกุล'
              )}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection
            title={t('การปรับเปลี่ยนแนวทางการพิจารณาความสอดคล้องของหลักสูตรระดับอุดมศึกษาที่ผ่านการอนุมัติ')}
          >
            <CollapseItem pdfUrl={WorkflowCHECO}>
              {t('Workflow ระบบ CHECO ระดับปริญญาตรีและบัณฑิตศึกษา/กระบวนวิชา')}
            </CollapseItem>
            <CollapseItem pdfUrl={การปรับเปลี่ยนแนวทางการพิจารณาความสอดคล้องของหลักสูตรระดับอุดมศึกษาที่ผ่านการอนุมัติ}>
              {t('การปรับเปลี่ยนแนวทางการพิจารณาความสอดคล้องของหลักสูตรระดับอุดมศึกษาที่ผ่านการอนุมัติ')}
            </CollapseItem>
            <CollapseItem pdfUrl={คู่มือสำหรับผู้ปฏิบัติงานCHECurriculumOnline}>
              {t('คู่มือสำหรับผู้ปฏิบัติงาน CHE Curriculum Online (CHE CO)')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('ประกาศกระทรวงศึกษาธิการ เรื่อง มาตรฐานคุณวุฒิสาขา/สาขาวิชา (มคอ.1)')}>
            <CollapseItem pdfUrl={Nurse2560}>{t('สาขาวิชาพยาบาลศาสตร์')}</CollapseItem>
            <CollapseItem pdfUrl={enrollmentnewpdf}>{t('สาขาวิชารัฐประศาสนศาสตร์')}</CollapseItem>
            <CollapseItem pdfUrl={curr_art01}>{t('สาขาวิชาศิลปกรรมศาสตร์')}</CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('ประกาศทบวงมหาวิทยาลัย')}>
            <CollapseItem pdfUrl={UA01}>
              {t(
                'ประกาศทบวงมหาวิทยาลัย เรื่อง หลักเกณฑ์การเทียบโอนผลการเรียนระดับปริญญาเข้าสู่การศึกษาในระบบ พ.ศ.2545'
              )}
            </CollapseItem>
            <CollapseItem pdfUrl={UA02}>
              {t('ประกาศทบวงมหาวิทยาลัยข้อแนะนำเกี่ยวกับแนวปฏิบัติที่ดีในการเทียบโอนผลการเรียนระดับปริญญา')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection
            title={t(
              'การปรับเปลี่ยนแประกาศกระทรวงนวทางการพิจารณาความสอดคล้องของหลักสูตรระดับอุดมศึกษาที่ผ่านการอนุมัติ'
            )}
          >
            <CollapseItem pdfUrl={เกณฑ์มาตรฐานหลักสูตรระดับบัณฑิตศึกษา65}>
              {t('ประกาศคณะกรรมการมาตรฐานการอุดมศึกษา เรื่อง เกณฑ์มาตรฐานหลักสูตรระดับบัณฑิตศึกษา พ.ศ. 2565')}
            </CollapseItem>
            <CollapseItem pdfUrl={ประกาศกมอแนวทางการดำเนินงานคลังหน่วยกิ}>
              {t('ประกาศคณะกรรมการมาตรฐานการอุดมศึกษา เรื่องnแนวทางการดำเนินงานคลังหน่วยกิตในระดับอุดมศึกษาพ.ศ. 2565')}
            </CollapseItem>
            <CollapseItem pdfUrl={ประกาศกมอหลักเกณฑ์และวิธีการเทียบโอนหน}>
              {t(
                'ประกาศคณะกรรมการมาตรฐานการอุดมศึกษา เรื่อง หลักเกณฑ์และวิธีการเทียบโอนหน่วยกิตและผลการศึกษาในระดับอุดมศึกษา พ.ศ. 2565'
              )}
            </CollapseItem>
            <CollapseItem pdfUrl={กฎกระทรวง65}>{t('กฎกระทรวง 2565')}</CollapseItem>
            <CollapseItem pdfUrl={DegreeName2559}>
              {t('ประกาศกระทรวงศึกษาธิการ เรื่อง หลักเกณฑ์การกำหนดชื่อปริญญา พ.ศ.2559')}
            </CollapseItem>
            <CollapseItem pdfUrl={LV0YXX1Z}>
              {t('แนวปฏิบัติในการเสนอหลักสูตรใหม่ และหลักสูตรปรับปรุง ระดับบัณฑิตศึกษา [ประกาศ ๑๒ กันยายน ๒๕๕๕]')}
            </CollapseItem>
            <CollapseItem pdfUrl={หลักเกณฑ์การกำหนดชื่อปริญญา49}>
              {t('ประกาศกระทรวงศึกษาธิการ เรื่อง หลักเกณฑ์การกำหนดชื่อปริญญา พ.ศ. 2549')}
            </CollapseItem>
            <CollapseItem pdfUrl={หลักเกณฑ์การกำหนดชื่อปริญญา51}>
              {t('ประกาศกระทรวงศึกษาธิการ เรื่อง หลักเกณฑ์การกำหนดชื่อปริญญา (ฉบับที่ 2) พ.ศ.2551')}
            </CollapseItem>
            <CollapseItem pdfUrl={enrollmentnewpdf}>
              {t('ประกาศกระทรวงศึกษาธิการ เรื่อง หลักเกณฑ์การกำหนดชื่อปริญญา (ฉบับที่ 3) พ.ศ. 2551')}
            </CollapseItem>
            <CollapseItem pdfUrl={enrollmentnewpdf}>
              {t('ประกาศกระทรวงศึกษาธิการ เรื่อง แนวทางความตกลงร่วมมือทางวิชาการระหว่างสถาบันอุดมศึกษาไทย')}
            </CollapseItem>
            <CollapseItem pdfUrl={Temp_2}>
              {t('ประกาศกระทรวงศึกษาธิการ เรื่อง การจัดการศึกษานอกสถานที่ตั้งของสถาบัน')}
            </CollapseItem>
            <CollapseItem pdfUrl={GuidelinesCreditBank2562}>
              {t(
                'ประกาศกระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม เรื่อง แนวทางการดำเนินงานระบบคลังหน่วยกิตระดับอุดมศึกษา พ.ศ.2562'
              )}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('แนวปฏิบัติ/ข้อชี้แจงเพิ่มเติมจาก สกอ.')}>
            <CollapseItem pdfUrl={prnews01}>
              {t('คำจำกัดความ รูปแบบ การเผยแพร่ และลักษณะคุณภาพของผลงานทางวิชาการ')}
            </CollapseItem>
            <CollapseItem pdfUrl={CurriculumMapping}>
              {t('การกำหนดผลการเรียนรู้ Curriculum Mapping ในรายละเอียด')}
            </CollapseItem>
            <CollapseItem pdfUrl={CurrStaff}>
              {t('ตอบข้อหารือเกี่ยวกับแนวปฏิบัติในการกำหนดอาจารย์ประจำหลักสูตรระดับบัณฑิตศึกษา (หนังสือจาก สกอ.)')}
            </CollapseItem>
            <CollapseItem pdfUrl={PHDCurrManagement}>
              {t('แนวทางในการจัดการการศึกษาระดับปริญญาเอก [21 มีนาคม 2561]')}
            </CollapseItem>
            <CollapseItem pdfUrl={GuideLinePlanAB}>{t('การเปิดสอนหลักสูตรปริญญาโท แผน ก และ แผน ข')}</CollapseItem>
            <CollapseItem pdfUrl={CurrInstructor}>{t('การกำหนดจำนวนอาจารย์ประจำหลักสูตร')}</CollapseItem>
            <CollapseItem pdfUrl={แนวปฏิบัติในการแต่งตั้งคณะกรรมการร่างและปรับปรุงหลักสูตรระดับบัณฑิตศึกษา}>
              {t('แนวปฏิบัติในการแต่งตั้งคณะกรรมการร่างและปรับปรุงหลักสูตรระดับบัณฑิตศึกษา [11/05/2554]')}
            </CollapseItem>
            <CollapseItem pdfUrl={เงื่อนไขและข้อชี้แจงบางประการเกี่ยวกับรายกระบวนวิชา2}>
              {t('เงื่อนไขและข้อชี้แจงบางประการเกี่ยวกับรายกระบวนวิชา')}
            </CollapseItem>
            <CollapseItem pdfUrl={t01}>{t('การจัดทำเอกสาร [05/11/2552]')}</CollapseItem>
            <CollapseItem pdfUrl={enrollmentnewpdf}>
              {t('รายละเอียดความหมายและตัวอย่างการเผยแพร่ผลงานวิจัย')}
            </CollapseItem>
            <CollapseItem pdfUrl={WS0OIOMA}>
              {t('หลักเกณฑ์ วิธีการรับรองคุณวุฒิและการกำหนดอัตราเงินเดือนของ สำนักงาน ก.พ. พ.ศ.2548')}
            </CollapseItem>
            <CollapseItem pdfUrl={enrollmentnewpdf}>
              {t('หลักเกณฑ์ วิธีการรับรองคุณวุฒิและการกำหนดอัตราเงินเดือนของ สำนักงาน ก.พ. พ.ศ.2550')}
            </CollapseItem>
            <CollapseItem pdfUrl={WP10VHBQ}>
              {t('หลักเกณฑ์ วิธีการรับรองคุณวุฒิและการกำหนดอัตราเงินเดือนของ สำนักงาน ก.พ. พ.ศ.2551')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('ประกาศกระทรวงศึกษาธิการ เรื่อง มาตรฐานคุณวุฒิสาขา/สาขาวิชา (มคอ.1)')}>
            <CollapseItem pdfUrl={KPIforFac20170531}>
              {t('ตัวบ่งชี้ของผลการดำเนินงาน ของหลักสูตรระดับบัณฑิตศึกษา [27 กันยายน 2560]')}
            </CollapseItem>
            <CollapseItem pdfUrl={CurrVnV120160905}>{t('การทวนสอบ : รศ.ดร.ณรุทธ์ สุทธจิตต์')}</CollapseItem>
            <CollapseItem pdfUrl={CurrVnV220160905}>
              {t('การทวนสอบผลสัมฤทธิ์ ผลการเรียนรู้ตามมาตรฐานคุณวุฒิอุดมศึกษา:รศ.ดร.ดรุณี รุจกรกานต์')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('Curriculum Tracking (ติดตามสถานะหลักสูตร) ของหลักสูตรระดับบัณฑิตศึกษา')}>
            <CollapseItem pdfUrl="https://o365cmu-my.sharepoint.com/:x:/g/personal/nartchulee_t_cmu_ac_th/Ed2XC58BSAhCi6rwHMSgj6cBfuMBKW0x5ZY3B5Nt6hxfVA?rtime=AUlk4vf22kg">
              {t('สถานภาพการเสนอที่ประชุม')}
            </CollapseItem>
            <CollapseItem pdfUrl="https://smart.grad.cmu.ac.th/?p=102">{t('สถานภาพการตรวจสอบหลักสูตร')}</CollapseItem>
          </CollapseSection>
        </CollapseSectionContainer>
      </CustomContainer>
    </Layout>
  );
};

export default CurriculumGuidelinesPage;
