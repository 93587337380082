import React, { useState } from 'react';
import Layout from '../../../components/Layout';
// import routeUrlProvider, { HOME } from '../../constants/route-paths';
import { convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { fetchPost } from '../../../utils/services/fetch';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../../../node_modules/react-datepicker/dist/react-datepicker.min.css';
import ReactDatePicker from 'react-datepicker';
import AttatchFilePinkIcon from '../../../components/Icons/AttatchFilePinkIcon';
import routeUrlProvider, { AdminRoutePath } from '../../../constants/route-paths';
import apiUrlProvider, { ADMIN_ADD_IMAGE, ADMIN_ADD_POSTER } from '../../../constants/api-endpoints';
import { getAccessToken } from '../../../utils/token';

const PosterAdd = ({ history }) => {
  const [imageTh, setImageTh] = useState(null);
  const [imageEn, setImageEn] = useState(null);

  const onUploadImageThChange = (e) => {
    const file = e.target.files[0];

    const data = new FormData();

    data.append(`file`, file, file.name);

    fetch(
      apiUrlProvider.get(ADMIN_ADD_IMAGE), 
      {
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${getAccessToken() || ''}`,
        }
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setImageTh(data);
      })
      .catch((err) => alert(err.error));
  };

  const onUploadImageEnChange = (e) => {
    const file = e.target.files[0];

    const data = new FormData();

    data.append(`file`, file, file.name);

    fetch(
      apiUrlProvider.get(ADMIN_ADD_IMAGE), 
      {
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${getAccessToken() || ''}`,
        }
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setImageEn(data);
      })
      .catch((err) => alert(err.error));
  };

  const onCreatePosterSubmit = (e) => {
    e.preventDefault()
    fetchPost(apiUrlProvider.get(ADMIN_ADD_POSTER), {
      startAt: new Date(),
      endAt: new Date(),
      imageTh: imageTh,
      imageEn: imageEn,
    }, true)
      .then((res) => {
        alert('เพิ่ม Poster สำเร็จ');
        history.push(routeUrlProvider.getForLink(AdminRoutePath.ADMIN_POSTER_LIST));
      })
      .catch((e) => {
        alert(e.error);
      });
  };

  return (
    <Layout>
      <div className="px-20">
        <div className="text-center text-lg">เพิ่ม Poster</div>
        <form>
          <div className="mb-4">
            <div>รูปภาพ (ภาษาไทย)</div>
            <div className="flex">
              {imageTh != null ? (
                <div className="mx-2 box-content grid h-64 w-64 flex-initial content-center rounded-[10px] border-2 border-NeonPink hover:cursor-pointer">
                  <img
                    src={imageTh.imageUrl}
                    alt={imageTh.imageName}
                    width={332}
                    height={187}
                    className="rounded-[2px]"
                  />
                </div>
              ) : (
                <div className="mx-2 box-content grid h-64 w-64 flex-initial content-center rounded-[10px] border-2 border-NeonPink hover:cursor-pointer">
                  <label className="h-max hover:cursor-pointer">
                    <div className="rounded-full text-center text-4xl font-black text-NeonPink">+</div>
                    <input className="hidden" type="file" onChange={onUploadImageThChange} />
                  </label>
                </div>
              )}
            </div>
          </div>

          <div className="mb-4">
            <div>รูปภาพ (ภาษาอังกฤษ)</div>
            <div className="flex">
              {imageEn != null ? (
                <div className="mx-2 box-content grid h-64 w-64 flex-initial content-center rounded-[10px] border-2 border-NeonPink hover:cursor-pointer">
                  <img
                    src={imageEn.imageUrl}
                    alt={imageEn.imageName}
                    width={332}
                    height={187}
                    className="rounded-[2px]"
                  />
                </div>
              ) : (
                <div className="mx-2 box-content grid h-64 w-64 flex-initial content-center rounded-[10px] border-2 border-NeonPink hover:cursor-pointer">
                  <label className="h-max hover:cursor-pointer">
                    <div className="rounded-full text-center text-4xl font-black text-NeonPink">+</div>
                    <input className="hidden" type="file" onChange={onUploadImageEnChange} />
                  </label>
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-center space-x-2" onClick={onCreatePosterSubmit}>
            <button
              type="button"
              className="bg-blue-600 text-white inline-block w-52 rounded bg-NeonPink px-6 py-2.5 text-xs font-medium leading-tight text-UIWhite shadow-md"
            >
              บันทึก
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default PosterAdd;
