import React, { useState } from 'react';
import RightArrowWithoutTail from '../Icons/RightArrowWithoutTail';

const CollapseSection = ({ title = '', children, forceUseThFont = false }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className=" py-6 text-[20px] font-medium leading-10 text-UIBlack">
        <div className="my-auto flex flex-row leading-7 hover:cursor-pointer" onClick={() => setOpen(!open)}>
          <div className={`${open ? 'rotate-90' : ''} z-0 mt-[6px]  h-fit w-fit`}>
            <RightArrowWithoutTail />
          </div>
          <div className={`${forceUseThFont ? 'font-ibm-plex-sans' : ''} ml-4 leading-10`}>{title}</div>
        </div>
        {open ? children : null}
      </div>
    </>
  );
};

export default CollapseSection;
