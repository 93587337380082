import React from 'react';
import EmailIcon from '../Icons/EmailIcon';

const Email = ({ children }) => (
  <div className="mt-1 flex flex-row">
    <div className="my-auto">
      <EmailIcon />
    </div>
    <div className="my-auto ml-[10px] font-open-sans text-xs leading-4 text-PrimaryDark">{children}</div>
  </div>
);

export default Email;
