import React from 'react';
import LeftArrow16pxLightGrayIcon from '../../Icons/LeftArrow16pxLightGrayIcon';
import { useHistory } from 'react-router-dom';
import routeUrlProvider from '../../../constants/route-paths';

const BackButton = ({ goToRoute, children }) => {
  const history = useHistory();
  return (
    <button onClick={() => history.push(routeUrlProvider.getForRoute(goToRoute))}>
      <div className="flex flex-row text-lg font-bold leading-6 text-Lightgray400">
        <div className="my-auto">
          <LeftArrow16pxLightGrayIcon />
        </div>
        <div className="my-auto ml-[10px]">{children}</div>
      </div>
    </button>
  );
};

export default BackButton;
