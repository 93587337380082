import Divider from '../../Divider';
import BackToTopButton from '../../Button/BackToTopButton';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';
import CustomContainer from '../../CustomContainer';

const Footer = ({
  showBackTotop = false,
  hideFooterDivider = false,
  removeSpaceBeforeFooterDivider = false,
  whiteTheme = false,
}) => {
  const { t, getFontFamily } = useTranslationUtil();
  return (
    <CustomContainer className=" w-full text-center font-dosis font-normal">
      <div className={removeSpaceBeforeFooterDivider ? '' : 'mt-[72px]'}>{showBackTotop && <BackToTopButton />}</div>
      {!hideFooterDivider && (
        <div
          className={`${
            removeSpaceBeforeFooterDivider ? '' : 'mt-[40px]'
          } flex flex-row justify-center rounded-sm opacity-40 `}
        >
          <Divider className={whiteTheme ? 'bg-UIWhite' : 'bg-Lightgray700'} />
        </div>
      )}

      <div className={`${getFontFamily()} ${whiteTheme ? 'text-UIWhite' : 'text-UIBlack'} pt-6 pb-14`}>
        Ⓒ {new Date().getFullYear()} - {t('copyright')} 
      </div>
    </CustomContainer>
  );
};

export default Footer;
