import LinkedinIcon from '../../Icons/linkedinIcon';

const LinkedInButton = ({
  url = 'https://www.linkedin.com/in/graduate-school-cmu-a917b1182',
}) => {
  return (
    <button onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}>
      <LinkedinIcon />
    </button>
  );
};
export default LinkedInButton;
