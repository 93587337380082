import React from 'react';
import Layout from '../../components/Layout';
import O4_1 from './assets/O4-1.pdf';
import O4_2 from './assets/O4-2.pdf';
import O6_1 from './assets/O6_1.pdf';
import O10 from './assets/O10.pdf';
import O11 from './assets/O11.pdf';
import O12 from './assets/O12.pdf';
import O13 from './assets/O13.pdf';
import O13_1 from './assets/O13_1.pdf';
import O13_2 from './assets/O13_2.pdf';
import O13_3 from './assets/O13_3.pdf';
import O13_4 from './assets/O13_4.pdf';
import O15 from './assets/O15.pdf';
import O16 from './assets/O16-ความพึงพอใจการให้บริการ.pdf';
import O18 from './assets/O18-แผนการใช้จ่ายงบประมาณ.pdf';
import O19 from './assets/O19.pdf';
import O20 from './assets/O20.pdf';
import O21 from './assets/O21.pdf';
import O23 from './assets/O23.pdf';
import O24 from './assets/O24.pdf';
import O25_1 from './assets/O25_1.pdf';
import O25_2 from './assets/O25_2.pdf';
import O26 from './assets/O26.pdf';
import O26_2 from './assets/O26_2.pdf';
import O27_1 from './assets/O27_1-หลักเกณฑ์การประเมินผลการปฎิบัติงาน.pdf';
import O27_2 from './assets/O27_2-หลักเกณฑ์จ้างพนักงานชั่วคราว.pdf';
import O27_3 from './assets/O27_3-หลักเกณฑ์การบรรจุพนักงานประจำ.pdf';
import O28 from './assets/O28.pdf';
import O29 from './assets/O29.pdf';
import O31 from './assets/O31.pdf';
import O34_1 from './assets/O34_1.pdf';
import O34_2 from './assets/O34_2.pdf';
import O35 from './assets/O35.pdf';
import O36 from './assets/O36.pdf';
import O37 from './assets/O37.pdf';
import O38 from './assets/O38.pdf';
import O38_3 from './assets/O38-3.pdf';
import O39 from './assets/O39.pdf';
import O40 from './assets/O40.pdf';
import O41_1 from './assets/O41_1.pdf';
import O42 from './assets/O42.pdf';
import O42_1 from './assets/O42_1.pdf';
import O42_2 from './assets/O42_2.pdf';
import O42_3 from './assets/O42_3.pdf';
import O43 from './assets/O43.pdf';
import routeUrlProvider, {
  ANNOUNCEMENTS_REGULATIONS_GUIDELINES,
  CONTACT_US,
  EXECUTIVE,
  HOME,
  NEWS,
  OPEN_DATA_NEWS,
  ORGANIZATION_STRUCTURE,
  VISION_AND_MISION,
} from '../../constants/route-paths';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import CustomContainer from '../../components/CustomContainer';
import TableContainer from '../../components/CommonTable/TableContainer';
import Row from '../../components/CommonTable/Row';
import Cell from '../../components/CommonTable/Cell';
import DetailItemLink from '../../components/CommonTable/DetailItemLink';
import DetailItem from '../../components/CommonTable/DetailItem';
import { NEWS_TYPES } from '../../utils/news';
import { OPEN_DATA_NEWS_KEY } from './OpenDataNews/news-data';

const OpenDataPage2565 = () => {
  // const history = useHistory();
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <div className="bg-gradien-GradPink-to-CMUPurple pt-[32px] pb-[48px] text-center text-[56px] font-medium leading-[71px] text-UIWhite  ">
          {t('OIT')}
        </div>
        <div className=" mt-[56px] font-ibm-plex-sans text-UIBlack">
          <div className="text-center text-[32px] leading-[52px]">{t('OIT2565')}</div>
          <TableContainer>
            <table class="mx-auto table-fixed">
              <thead>
                <tr>
                  <th className="pb-8 pl-10 text-left text-[20px] font-bold">รายการข้อมูล</th>
                  <th className="pb-8 pl-14 text-left text-[20px] font-bold">รายละเอียดของข้อมูล</th>
                </tr>
              </thead>
              <tbody className=" border-t-2 border-Lightgray400">
                <Row>
                  <Cell>1. โครงสร้าง</Cell>
                  <Cell>
                    <DetailItemLink url={routeUrlProvider.getForRoute(ORGANIZATION_STRUCTURE)}>
                      โครงสร้างองค์กรและการบริหารงานของบัณฑิตวิทยาลัย
                    </DetailItemLink>
                  </Cell>
                </Row>
                <Row>
                  <Cell>2. ข้อมูลผู้บริหาร</Cell>
                  <Cell>
                    <DetailItem>
                      <DetailItemLink url={routeUrlProvider.getForRoute(EXECUTIVE)}>
                        รายนามของผู้บริหารของบัณฑิตวิทยาลัย
                      </DetailItemLink>
                    </DetailItem>
                  </Cell>
                </Row>
                <Row>
                  <Cell>3. อำนาจหน้าที่ </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={routeUrlProvider.getForRoute(VISION_AND_MISION)}>
                        หน้าที่และความรับผิดชอบ
                      </DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O4_1}>ผลลัพธ์สำคัญ</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>4. แผนยุทธศาสตร์หรือแผนพัฒนาส่วนงาน</Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O4_1}>
                        แผนพัฒนาการศึกษาระดับบัณฑิตศึกษามหาวิทยาลัยเชียงใหม่ ระยะที่ 13
                      </DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O4_2}>แผนปฏิบัติการประจำปี พ.ศ. 2565</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>5. ข้อมูลการติดต่อ </Cell>
                  <Cell>
                    <DetailItemLink url={routeUrlProvider.getForRoute(CONTACT_US)}>
                      รายละเอียดเพื่อติดต่อบัณฑิตวิทยาลัย
                    </DetailItemLink>
                  </Cell>
                </Row>
                <Row>
                  <Cell> 6. กฎหมายที่เกี่ยวข้อง </Cell>
                  <Cell>
                    <DetailItemLink url={O6_1}>กฎหมายที่เกี่ยวข้องกับการดำเนินงาน</DetailItemLink>
                  </Cell>
                </Row>
                <Row>
                  <Cell>7. ข่าวประชาสัมพันธ์</Cell>
                  <Cell>
                    <DetailItemLink
                      url={`${routeUrlProvider.getForRoute(NEWS)}?type=${NEWS_TYPES.GRADUATE_SCHOOL_NEWS}`}
                    >
                      ข่าวประชาสัมพันธ์และกิจกรรมของบัณฑิตวิทยาลัย
                    </DetailItemLink>
                  </Cell>
                </Row>
                <Row>
                  <Cell>8. Q&A</Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={routeUrlProvider.getForRoute(HOME)}>
                        ช่องทางการสื่อสารหลักของบัณฑิตวิทยาลัย
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>9. Social Network</Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={routeUrlProvider.getForRoute(HOME)}>GSCMU Social Network</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>10. แผนดำเนินงานประจำปี</Cell>
                  <Cell>
                    <DetailItemLink url={O10}>แผนการดำเนินงานประจำปี2565</DetailItemLink>
                  </Cell>
                </Row>
                <Row>
                  <Cell>11. รายงานการกำกับติดตามการดำเนินงาน</Cell>
                  <Cell>
                    <DetailItemLink url={O11}>
                      รายงานผลการดำเนินงานของบัณฑิตวิทยาลัย ไตรมาส 1 ประจำปี 2565
                    </DetailItemLink>
                  </Cell>
                </Row>
                <Row>
                  <Cell>12. รายงานผลการดำเนินงานประจำปี</Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O12}>รายงานผลการดำเนินงานประจำปี 2565</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>13. คู่มือหรือมาตรฐานการปฏิบัติงาน </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={routeUrlProvider.getForRoute(ANNOUNCEMENTS_REGULATIONS_GUIDELINES)}>
                        คู่มือหลักเกณฑ์และแนวปฏิบัติของการจัดการบัณฑิตศึกษา
                      </DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O13}> คู่มือการจัดการข้อผิดพลาด ข้อบกพร่อง จากการปฏิบัติงาน</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O13_1}> แนวปฏิบัติและขั้นตอนการเสนอและพัฒนาหลักสูตร</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O13_2}> แนวปฏิบัติการตรวจสอบนักศึกษาพ้นสภาพ</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O13_3}> แนวปฏิบัติการลาพักการศึกษา</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O13_4}> แนวปฏิบัติการขอย้ายสาขา</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>14. คู่มือหรือมาตรฐานการให้บริการ </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={routeUrlProvider.getForRoute(ANNOUNCEMENTS_REGULATIONS_GUIDELINES)}>
                        คู่มือหลักเกณฑ์และแนวปฏิบัติของการจัดการบัณฑิตศึกษา
                      </DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O13}> คู่มือการจัดการข้อผิดพลาดข้อบกพร่องจากการปฏิบัติงาน</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O13_1}> แนวปฏิบัติและขั้นตอนการเสนอและพัฒนาหลักสูตร</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O13_2}> แนวปฏิบัติการตรวจสอบนักศึกษาพ้นสภาพ</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O13_3}> แนวปฏิบัติการลาพักการศึกษา</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O13_4}> แนวปฏิบัติการขอย้ายสาขา</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>15. ข้อมูลเชิงสถิติการให้บริการ </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O15}> สถิติการให้บริการของบัณฑิตวิทยาลัย</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>16. รายงานผลการสำรวจความพึงพอใจการให้บริการ </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O16}> รายงานความพึงพอใจ</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>17. E-Service</Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url="https://apply.grad.cmu.ac.th/certificate/dist/index.html">
                        ระบบขอหนังสือรับรองแบบออนไลน์
                      </DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url="https://cmu.to/apply2cmu">
                        ระบบรับสมัครนักศึกษาบัณฑิตศึกษาแบบออนไลน์
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>18. แผนการใช้จ่ายงบประมาณประจำปี </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O18}> แผนการใช้จ่ายงบประมาณ</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>19. รายงานการกำกับติดตามการใช้จ่ายงบประมาณประจำปี </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O19}>
                        รายงานผลการเบิกจ่ายงบประมาณ ประจำปีงบประมาณ2565 ไตรมาส 1
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>20. รายงานผลการใช้จ่ายงบประมาณประจำปี </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O20}> รายงานผลการเบิกจ่ายงบประมาณ ประจำปีงบประมาณ2564</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>21. แผนการจัดซื้อจัดจ้างหรือแผนการจัดหาพัสดุ </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O21}> แผนการจัดซื้อจัดจ้างของบัณฑิตวิทยาลัย</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>22. ประกาศต่าง ๆ เกี่ยวกับการจัดซื้อจัดจ้างหรือการจัดหาพัสดุ </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={`${routeUrlProvider.getForRoute(NEWS)}?type=${NEWS_TYPES.SUPPLY_NEWS}`}>
                        ประกาศการจัดซื้อจัดจ้างของบัณฑิตวิทยาลัย
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>23. สรุปผลการจัดซื้อจัดจ้างหรือการจัดหาพัสดุรายเดือน </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O23}> สรุปผลการจัดซื้อจัดจ้างรายเดือนของบัณฑิตวิทยาลัย</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>24. รายงานผลการจัดซื้อจัดจ้างหรือการจัดหาพัสดุประจำปี </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O24}> สรุปผลการจัดซื้อจัดจ้างของบัณฑิตวิทยาลัย</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>25. นโยบายการบริหารทรัพยากรบุคคล </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O25_1}> นโยบายการบริหาร ทรัพยากรบุคคล ของบัณฑิตวิทยาลัย</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O25_2}>
                        ตารางแสดงการเชื่อมโยงเชิงกลยุทธ์ ตัวชี้วัด ค่าเป้าหมาย และแผนพัฒนาบุคลากร
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>26. การดำเนินการตามนโยบายการบริหารทรัพยากรบุคคล </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O26}>
                        การดำเนินการตามนโยบายการบริหารทรัพยากรบุคคลของบัณฑิตวิทยาลัย
                      </DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O26_2}> ตารางแผนพัฒนาบุคคลของบัณฑิตวิทยาลัย</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>27. หลักเกณฑ์การบริหารและพัฒนาทรัพยากรบุคคล </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O27_1}> หลักเกณฑ์ประเมินผลการปฏิบัติงาน</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O27_2}> หลักเกณฑ์การจ้างพนักงานชั่วคราว</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O27_3}> หลักเกณฑ์การคัดเลือกบรรจุเป็นพนักงาน</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>28. รายงานผลการบริหารและพัฒนาทรัพยากรบุคคลประจำปี </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O28}> รายงานผลการบริหารและพัฒนาทรัพยากรบุคคล</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>29. แนวปฏิบัติการจัดการเรื่องร้องเรียนการทุจริตและประพฤติมิชอบ </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O29}> คู่มือการจัดการข้อผิดพลาด ข้อบกพร่อง จากการปฏิบัติงาน</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>30. ช่องทางแจ้งเรื่องร้องเรียนการทุจริตและประพฤติมิชอบ </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url="https://voc.cmu.ac.th/VOC1.aspx?OID=23">
                        คู่มือการจัดการข้อผิดพลาด ข้อบกพร่อง จากการปฏิบัติงาน
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>31. ข้อมูลเชิงสถิติ เรื่องร้องเรียนการทุจริตและประพฤติมิชอบ </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O31}> สถิติการรับเรื่องร้องเรียน ปี 2565 ไตรมาส 1</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>32. แนวปฏิบัติการจัดการ เรื่องร้องเรียนการทุจริตและประพฤติมิชอบ </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url="https://www.facebook.com/gradcmu/">ช่องทางการเสนอความคิดเห็น</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>33. การเปิดโอกาสให้เกิดการมีส่วนร่วม </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink
                        url={routeUrlProvider.getForLink(OPEN_DATA_NEWS, {
                          id: OPEN_DATA_NEWS_KEY.graduateStudentMeetExecutives,
                        })}
                      >
                        สโมสรนักศึกษาระดับบัณฑิตศึกษาเข้าพบผู้บริหาร
                      </DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink
                        url={routeUrlProvider.getForLink(OPEN_DATA_NEWS, {
                          id: OPEN_DATA_NEWS_KEY.crossInstitutional,
                        })}
                      >
                        พิธีลงนามบันทึกความเข้าใจลงทะเบียนเรียนข้ามสถาบัน
                      </DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink
                        url={routeUrlProvider.getForLink(OPEN_DATA_NEWS, {
                          id: OPEN_DATA_NEWS_KEY.okrs,
                        })}
                      >
                        ประชุมเพื่อทบทวนตัวชี้วัดคำรับรองการปฏิบัติงาน
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>34. เจตจำนงสุจริตของผู้บริหาร </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O34_1}> คำประกาศแสดงเจตจำนงสุจริต</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O34_2}> ประกาศแสดงเจตจำนงสุจริตการบริหาร</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>35. การมีส่วนร่วมของผู้บริหาร </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O35}>
                        คำสั่งแต่งตั้งคณะทำงานขับเคลื่อนระบบคุณธรรมและความโปร่งใส
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>36. การประเมินความเสี่ยงการทุจริตประจำปี </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O36}>
                        การประเมินความเสี่ยง การทุจริตในระบบบัณฑิตศึกษา ประจำปีงบประมาณ 2565
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>37. การดำเนินการเพื่อจัดการความเสี่ยงการทุจริต </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O37}> การดำเนินการเพื่อ จัดการความเสี่ยง การทุจริต</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>38. การเสริมสร้างวัฒนธรรมองค์กร </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O38}>
                        จริยธรรมสำหรับผู้ปฏิบัติงานใน มหาวิทยาลัยเชียงใหม่ / VDO Clib
                      </DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O38_3}> นโยบายส่งเสริมคุณธรรมและจริยธรรมในองค์กร</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>39. แผนปฏิบัติการป้องกันการทุจริต </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O39}> แผนป้องกันและปราบปรามการทุจริต ประจำปีงบประมาณ 2565</DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>40. การทุจริตประจำปี </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O40}>
                        รายงานความก้าวหน้าการดำเนินงานตามแผนป้องกันและปราบปรามการทุจริต ประจำปีงบประมาณ 2565 ไตรมาส 1
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>41. รายงานผลการดำเนินการป้องกันการทุจริตประจำปี </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O41_1}>
                        มาตรการป้องกันการทุจริตในการศึกษาและการละเมิดทรัพย์สินทางปัญญาในปริญญานิพนธ์
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>42. มาตรการส่งเสริมคุณธรรมและความโปร่งใสภายในส่วนงาน </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O42}>1. ผลการประเมินคุณธรรม มช. ปี 64</DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O42_1}>
                        2. รายงานผลการตรวจสอบภายในบัณฑิตวิทยาลัย ประจำปีงบประมาณ 2564
                      </DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O42_2}>
                        3. คำสั่งแต่งตั้งคณะทำงานขับเคลื่อนระบบคุณธรรมและความโปร่งใสของบัณฑิตวิทยาลัย
                      </DetailItemLink>
                    </div>
                    <div>
                      <DetailItemLink url={O42_3}>
                        4.
                        บัณฑิตวิทยาลัยมีแนวปฏิบัติด้านคุณธรรมและความโปร่งใสตามนโยบายคุณธรรมและความโปร่งใสมหาวิทยาลัยเชียงใหม่
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
                <Row>
                  <Cell>43. การดำเนินการตามมาตรการส่งเสริมคุณธรรมและความโปร่งใสภายในส่วนงาน </Cell>
                  <Cell>
                    <div>
                      <DetailItemLink url={O43}>
                        กิจกรรมส่งเสริมคุณธรรมและความโปร่งใสในการดำเนินงานบัณฑิตวิทยาลัย ข้อมูล ณ มกราคม 2565
                      </DetailItemLink>
                    </div>
                  </Cell>
                </Row>
              </tbody>
            </table>
          </TableContainer>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default OpenDataPage2565;
