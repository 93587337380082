import React from 'react';
import CollapseItem from '../../components/CollapseSection/CollapseItem';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';

import Nominationforgraduation from './assets/1-Nomination-for-graduation-1.pdf';
import มตืสถาgraduate from './assets/2-มตืสถา-graduate.pdf';
import แนวปฏิบัติการจบGuideline from './assets/แนวปฏิบัติการจบGuideline.pptx';
import แบบฟอร์มนักศึกษาจบ1462562 from './assets/แบบฟอร์มนักศึกษาจบ-14-6-2562-ล่าสุด-1.doc';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';
import CollapseSectionContainer from '../../components/CollapseSection/CollapseSectionContainer';

const CriteriaGraduationPage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{t('การจบการศึกษา')}</PageTitleContainer>

        <CollapseSectionContainer>
          <CollapseItem pdfUrl={Nominationforgraduation}>
            {t('Nomination for Graduation a Degree or Diploma')}
          </CollapseItem>
          <CollapseItem pdfUrl={มตืสถาgraduate}>{t('Determining the Graduation Date')}</CollapseItem>
          <CollapseItem pdfUrl={แนวปฏิบัติการจบGuideline}>
            {t('Guidelines on the List of Graduates for Graduation')}
          </CollapseItem>
          <CollapseItem pdfUrl="https://smart.grad.cmu.ac.th/?p=103">
            {t('List of Graduates and the Approval Date / รายชื่อผู้สำเร็จการศึกษา และวันที่ประชุมเห็นชอบ')}
          </CollapseItem>
          <CollapseItem pdfUrl={แบบฟอร์มนักศึกษาจบ1462562}>{t('แบบฟอร์มเสนอสำเร็จการศึกษา')}</CollapseItem>
        </CollapseSectionContainer>
      </CustomContainer>
    </Layout>
  );
};

export default CriteriaGraduationPage;
