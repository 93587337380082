import React, { useEffect, useState } from 'react';
import UserIcon from './assets/user.svg';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';
import CustomContainer from '../../../components/CustomContainer';
import Footer from '../../../components/Layout/Footer';
import { getPageViews } from '../../../services/visitorsCount/getVisitorsCount';
// import { getTodayVisitorCount, getTotalVisitorCount } from '../../../services/visitorsCount/getVisitorsCount';

const VisitorsSection = () => {
  const { t, formatDate } = useTranslationUtil();

  const [pageViewData, setPageViewData] = useState({
    total: 0,
    today: 0,
  });

  useEffect(() => {
    getPageViews()
      .then((data) => {
        setPageViewData(data);
      })
      .catch((error) => console.error('getPageViews error', error));
  }, []);

  return (
    <div className="bg-home-cover px-6 pt-12 text-UIWhite mix-blend-normal md:px-20">
      <CustomContainer>
        <div className="font-open-sans text-sm leading-5">
          {t('start_count')} : {formatDate(new Date('2023/01/01'))}
        </div>
        <div className="mt-[26px] flex flex-col justify-between md:flex-row">
          <div className="flex flex-row justify-between">
            <div>
              <div className=" font-dosis text-[40px] font-medium leading-[51px]">{t('visitor_online')}</div>{' '}
              <div className="mt-4 h-2 w-20 bg-UIWhite" />
            </div>
            <div className="my-auto font-dosis text-[56px] font-medium leading-[71px] md:hidden ">
              {(pageViewData.today ?? 0).toLocaleString('en-US')}
            </div>
          </div>
          <div className="my-auto hidden  font-dosis text-[56px] font-medium leading-[71px] md:block ">
            {(pageViewData.today ?? 0).toLocaleString('en-US')}
          </div>
          <div className="mt-8 md:mt-0">
            <div className="flex flex-row">
              <img src={UserIcon} alt="" />
              <div className="ml-[30px]">
                <div className=" text-center font-dosis text-[32px] font-medium leading-10">
                  {(pageViewData.total ?? 0).toLocaleString('en-US')}
                </div>
                <div className=" font-open-sans text-sm font-bold leading-5">{t('visit_this_site')}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 h-[1px] bg-UIWhite opacity-40" />
        <Footer hideFooterDivider removeSpaceBeforeFooterDivider whiteTheme />
      </CustomContainer>
    </div>
  );
};

export default VisitorsSection;
