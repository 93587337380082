import React from 'react';
import CMUPurpleUnderLine80x8 from '../../components/CMUPurpleUnderLine';
import ComputerHeartIcon from '../../components/Icons/ComputerHeartIcon';
import DartIcon from '../../components/Icons/DartIcon';
import LockedShieldIcon from '../../components/Icons/LockedShieldIcon';
import ScheduleBoardIcon from '../../components/Icons/ScheduleBoardIcon';
import ShieldPeopleIcon from '../../components/Icons/ShieldPeopleIcon';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import KwanpaveePongkasinpapob from './assets/KwanpaveePongkasinpapob.png';
import KrittaphatKaewchaiya from './assets/KrittaphatKaewchaiya.png';
import PrapaiphanSriphan from './assets/PrapaiphanSriphan.png';
import NartchuleePraditthongngam from './assets/NartchuleePraditthongngam.png';
import KongdetchPanyaperm from './assets/KongdetchPanyaperm.png';
import RadsadawanSudjai from './assets/RadsadawanSudjai.png';
import WongsakornSriwong from './assets/WongsakornSriwong.png';
import SuthaleeThongmee from './assets/SuthaleeThongmee.png';
import SudtiragLomket from './assets/SudtiragLomket.png';
import JariyaKumnasak from './assets/JariyaKumnasak.png';
import DutyItem from '../../components/GraduateStaffPageComponent/DutyItem';
import DutyItemIcon from '../../components/GraduateStaffPageComponent/DutyItemIcon';
import DutyItemText from '../../components/GraduateStaffPageComponent/DutyItemText';
import ProfileContainer from '../../components/GraduateStaffPageComponent/ProfileContainer';
import ProfileImage from '../../components/GraduateStaffPageComponent/ProfileImage';
import ProfileDetailContainer from '../../components/GraduateStaffPageComponent/ProfileDetailContainer';
import ProfileName from '../../components/GraduateStaffPageComponent/ProfileName';
import ProfilePosition from '../../components/GraduateStaffPageComponent/ProfilePosition';
import List from '../../components/GraduateStaffPageComponent/List';
import ListItem from '../../components/GraduateStaffPageComponent/ListItem';
import Phone from '../../components/GraduateStaffPageComponent/Phone';
import Email from '../../components/GraduateStaffPageComponent/Email';
import CustomContainer from '../../components/CustomContainer';
import PageTitleGraduateStaff from '../../components/GraduateStaffPageComponent/PageTitleGraduateStaff';
import ProfileList from '../../components/GraduateStaffPageComponent/ProfileList';
import StaffTeamTitle from '../../components/GraduateStaffPageComponent/StaffTeamTitle';
import DutyContainer from '../../components/GraduateStaffPageComponent/DutyContainer';
import GraduateStaffPageContainer from '../../components/GraduateStaffPageComponent/GraduateStaffPageContainer';
import FrontViewGradSchool from '../../components/GraduateStaffPageComponent/FrontViewGradSchool';

const AcademicServicePage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout headerTransparent removeSpaceBeforeFooterDivider>
      <CustomContainer>
        <PageTitleGraduateStaff title={t('AcademicService')} />
        <GraduateStaffPageContainer>
          {/* <FrontViewGradSchool mobile>
            {t('AcademicService')}, {t('chiang_mai_university')}
          </FrontViewGradSchool>
          <DutyContainer>
            <div className="col-span-2">
              <div className="mt-7 mb-12 text-[32px] font-medium leading-10 text-Lightgray500">
                {t('duty')}
                <div className="mt-14px">
                  <CMUPurpleUnderLine80x8 />
                </div>
              </div>
              <DutyItem>
                <DutyItemIcon>
                  <ShieldPeopleIcon />
                </DutyItemIcon>
                <DutyItemText> {t('ServiceforStudent')}</DutyItemText>
              </DutyItem>
              <DutyItem>
                <DutyItemIcon>
                  <DartIcon />
                </DutyItemIcon>
                <DutyItemText> {t('DevelopmentofCurriculum')}</DutyItemText>
              </DutyItem>
              <DutyItem>
                <DutyItemIcon>
                  <ScheduleBoardIcon />
                </DutyItemIcon>
                <DutyItemText>{t('InternationalRelations')}</DutyItemText>
              </DutyItem>
              <DutyItem>
                <DutyItemIcon>
                  <LockedShieldIcon />
                </DutyItemIcon>
                <DutyItemText> {t('AppointedDissertaionthesisCheck')}</DutyItemText>
              </DutyItem>
              <DutyItem>
                <DutyItemIcon>
                  <ComputerHeartIcon />
                </DutyItemIcon>
                <DutyItemText> {t('NominationforGraduationDegreeDiploma')}</DutyItemText>
              </DutyItem>
            </div>
            <FrontViewGradSchool>
              {t('AcademicService')}, {t('chiang_mai_university')}
            </FrontViewGradSchool>
          </DutyContainer> */}
          <StaffTeamTitle>{t('AcademicServiceTeam')}</StaffTeamTitle>
          <ProfileList>
            <ProfileContainer>
              <ProfileImage src={KwanpaveePongkasinpapob} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('KwanpaveePongkasinpapob')}</ProfileName>
                <ProfilePosition>{t('OperationalStaffManager')}</ProfilePosition>
                <List>
                  <ListItem>Head of the Educational Service Section</ListItem>
                </List>
                <Phone>0-5394-2434</Phone>
                <Email>anunya.k@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            {/*
            <ProfileContainer>
              <ProfileImage src={KrittaphatKaewchaiya} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('KrittaphatKaewchaiya')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Credits Transfer/Study Program Changing</ListItem>
                  <ListItem>Leave/Resign/Retire/Resume Students Status</ListItem>
                  <ListItem>Qualifying/Comprehensive Examination</ListItem>
                  <ListItem>Extension of Study/Extension of Submitting Thesis</ListItem>
                  <ListItem>Fulfill All Courses and Conditions</ListItem>
                </List>
                <Phone>0-5394-2435</Phone>
                <Email>krittaphat.k@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={PrapaiphanSriphan} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('PrapaiphanSriphan')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Thesis/IS Check</ListItem>
                  <ListItem>Instructor/Special Instructor</ListItem>
                  <ListItem>Specialist Instructor/Qualified Person</ListItem>
                  <ListItem>Dissertation/Thesis/IS Advisor workload</ListItem>
                </List>
                <Phone>0-5394-2423</Phone>
                <Email>littlepann@gmail.com</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={NartchuleePraditthongngam} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('NartchuleePraditthongngam')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>New or Curriculum Improvement</ListItem>
                  <ListItem>Curriculum Committee / Curriculum Improvement Committee</ListItem>
                  <ListItem>Template of Curriculum</ListItem>
                  <ListItem>Structure of Curriculum</ListItem>
                  <ListItem>CHECO</ListItem>
                </List>
                <Phone>0-5394-2407</Phone>
                <Email>nartchulee.t@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={KongdetchPanyaperm} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('KongdetchPanyaperm')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Credits Transfer/Study Program Changing</ListItem>
                  <ListItem>Leave/Resign/Retire/Resume Students Status</ListItem>
                  <ListItem>Qualifying/Comprehensive Examination</ListItem>
                  <ListItem>Extension of Study/Extension of Submitting Thesis</ListItem>
                  <ListItem>Fulfill all courses and Conditions</ListItem>
                </List>
                <Phone>0-5394-2433</Phone>
                <Email>kongdet.p@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={RadsadawanSudjai} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('RadsadawanSudjai')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>New or Curriculum Improvement</ListItem>
                  <ListItem>Curriculum Committee / Curriculum Improvement Committee</ListItem>
                  <ListItem>Template of Curriculum</ListItem>
                  <ListItem>Structure of Curriculum</ListItem>
                  <ListItem>CHECO</ListItem>
                </List>
                <Phone>0-5394-2407</Phone>
                <Email>radsadawan.s@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={WongsakornSriwong} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('WongsakornSriwong')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Submission of Thesis Title and Proposal</ListItem>
                  <ListItem>Foreign Language Conditions</ListItem>
                </List>
                <Phone>0-5394-2410</Phone>
                <Email>wongsakorn.s@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={SuthaleeThongmee} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('SuthaleeThongmee')}</ProfileName>
                <ProfilePosition>{t('AcademicianStudies')}</ProfilePosition>
                <List>
                  <ListItem>Visa Application/Visa Extension</ListItem>
                  <ListItem>Termination of Visa</ListItem>
                  <ListItem>Waive for Frees/Postpone/Extension for Payment</ListItem>
                  <ListItem>TICA Coordinator</ListItem>
                  <ListItem>International Orientation</ListItem>
                  <ListItem>Submission of Thesis Title and Proposal</ListItem>
                  <ListItem>Foreign Language Conditions</ListItem>
                </List>
                <Phone>0-5394-2422</Phone>
                <Email>suthalee.t@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={SudtiragLomket} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('SudtiragLomket')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>New or Curriculum Improvement</ListItem>
                  <ListItem>Curriculum Committee / Curriculum Improvement Committee</ListItem>
                  <ListItem>Template of Curriculum</ListItem>
                  <ListItem>Structure of Curriculum</ListItem>
                  <ListItem>CHECO</ListItem>
                </List>
                <Phone>0-5394-2408</Phone>
                <Email>swditrag.l@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={JariyaKumnasak} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('JariyaKumnasak')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Course Syllabus</ListItem>
                  <ListItem>Program Curriculum/ Course Syllabus Checking</ListItem>
                  <ListItem>Request for Using Research Tools</ListItem>
                </List>
                <Phone>0-5394-2408</Phone>
                <Email>jariya.kum@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
        */}
          </ProfileList>
        </GraduateStaffPageContainer>
      </CustomContainer>
    </Layout>
  );
};

export default AcademicServicePage;
