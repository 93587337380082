import React, { useEffect } from 'react';
import Layout from '../../../components/Layout';
import apiUrlProvider, { ADMIN_LOGIN_REDIRECT_WITH_CODE } from '../../../constants/api-endpoints';
import routeUrlProvider, { AdminRoutePath, HOME } from '../../../constants/route-paths';
// import { useAuth } from '../../../utils/auth';
import { fetchPost } from '../../../utils/services/fetch';
import { useToken } from '../../../utils/token';
import { useContextAuthManager } from '../../../components/AuthManager';

const LoginCallback = ({ history }) => {
  const { setToken } = useToken();
  // const { setIsLoggedIn } = useAuth();
  const { setIsLoggedIn } = useContextAuthManager();

  // useEffect(() => {
  //   const url = apiUrlProvider.get(ADMIN_LOGIN_REDIRECT_WITH_CODE);
  //   const searchParams = new URLSearchParams(document.location.search);
  //   const code = searchParams.get('code');
  //   fetchPost(url, { code: code })
  //     .then((res) => {
  //       setToken(res.accessToken);
  //       setIsLoggedIn(true);
  //     })
  //     .catch((err) => alert('Authentication failed.'));

  //   // history.push(routeUrlProvider.getForRoute(AdminRoutePath.ADMIN_NEWS_CREATE));
  // }, []);
  useEffect(() => {
    const url = apiUrlProvider.get(ADMIN_LOGIN_REDIRECT_WITH_CODE);
    const searchParams = new URLSearchParams(document.location.search);
    const code = searchParams.get('code');
    fetchPost(url, { code: code })
      .then((res) => {
        setToken(res.accessToken);
        console.log(res);
        setIsLoggedIn(true);

        history.push(routeUrlProvider.getForRoute(HOME));
      })
      .catch((err) => {
        console.log(err);
        alert('Authentication failed.');

        history.push(routeUrlProvider.getForRoute(HOME));
      });
  }, []);

  return <Layout></Layout>;
};

export default LoginCallback;
