import React from 'react';
import { useRef } from 'react';
import Slider from 'react-slick';

const ImageSlider = ({ images = [] }) => {
  const sliderRef = useRef(null);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    lazyLoad: true,
    fade: true,
    pauseOnHover: false,
    dots: true,
  };

  return (
    <Slider {...settings} ref={sliderRef} arrows={false}>
      {images.map((slide, id) => (
        <div key={id} className=" mx-auto">
          <img key={id} src={slide.imageUrl} alt={slide.title} className="mx-auto h-[600px] object-contain" />
        </div>
      ))}
    </Slider>
  );
};

export default ImageSlider;
