import React, { useState, useEffect } from 'react';
import Lightgray100Divider from '../../components/Divider/Lightgray100Divider';
import LastestNewsIcon from '../../components/Icons/LastestNewsIcon';
import Layout from '../../components/Layout';
import { getNewsi18nKey, getNewsQueryParams, getNewsTypeFromQueryParam, NEWS_TYPES } from '../../utils/news';
import { getListNews } from '../../services/news';
import NewsCard from './components/NewsCard';
import NewsGroupTitle from './components/NewsGroupTitle';
import { useQuery } from '../../hooks/useQuery';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import { useHistory } from 'react-router';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';

const NewsPage = () => {
  const { t, getFontFamily } = useTranslationUtil();
  const queryParams = useQuery();
  const history = useHistory();

  const [newsType, setNewsType] = useState(getNewsTypeFromQueryParam(queryParams.get('type')));
  const [page, setPage] = useState(1);
  const [news, setNews] = useState([]);
  const [lastFetch, setLastFetch] = useState([]);
  const perPage = 12;

  useEffect(() => {
    getListNews({ newsTypeQuery: getNewsQueryParams(newsType), page, perPage })
      .then((res) => {
        setNews([...news, ...res.data]);
        setLastFetch(res.data || []);
      })
      .catch((err) => console.error(' err: getListNews', err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    const newsTypeQuery = getNewsQueryParams(newsType);
    getListNews({ newsTypeQuery, page: 1, perPage })
      .then((res) => {
        if (newsTypeQuery) {
          history.push({ search: `type=${newsTypeQuery}` });
        } else {
          history.push({ search: '' });
        }
        setNews(res.data || []);
        setLastFetch(res.data || []);
        setPage(1);
      })
      .catch((err) => console.error(' err: getListNews when newsType change', err));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsType]);

  const updateNewsType = (type) => {
    if (newsType !== type) {
      setNewsType(type);
    }
  };

  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{t('news')}</PageTitleContainer>

        <div className="mt-[19px] flex h-14 flex-row justify-between overflow-x-auto px-3 md:px-10 lg:px-14 xl:px-20">
          <NewsGroupTitle selected={newsType === NEWS_TYPES.ALL} onClick={() => updateNewsType(NEWS_TYPES.ALL)}>
            {t('all')}
          </NewsGroupTitle>
          <NewsGroupTitle
            selected={newsType === NEWS_TYPES.GRADUATE_SCHOOL_NEWS}
            onClick={() => updateNewsType(NEWS_TYPES.GRADUATE_SCHOOL_NEWS)}
          >
            {t('graduate_school_news')}
          </NewsGroupTitle>
          <NewsGroupTitle
            selected={newsType === NEWS_TYPES.ADMISSION_SCHOLARSHIP_NEWS}
            onClick={() => updateNewsType(NEWS_TYPES.ADMISSION_SCHOLARSHIP_NEWS)}
          >
            {t('admission_scholarship_news')}
          </NewsGroupTitle>
          <NewsGroupTitle
            selected={newsType === NEWS_TYPES.SEMINAR_NEWS}
            onClick={() => updateNewsType(NEWS_TYPES.SEMINAR_NEWS)}
          >
            {t('seminar_news')}
          </NewsGroupTitle>
          <NewsGroupTitle
            selected={newsType === NEWS_TYPES.STUDENT_NEWS}
            onClick={() => updateNewsType(NEWS_TYPES.STUDENT_NEWS)}
          >
            {t('student_news')}
          </NewsGroupTitle>
          <NewsGroupTitle
            selected={newsType === NEWS_TYPES.SUPPLY_NEWS}
            onClick={() => updateNewsType(NEWS_TYPES.SUPPLY_NEWS)}
          >
            {t('supply_news')}
          </NewsGroupTitle>
          <NewsGroupTitle
            selected={newsType === NEWS_TYPES.JOBS_NEWS}
            onClick={() => updateNewsType(NEWS_TYPES.JOBS_NEWS)}
          >
            {t('jobs_news')}
          </NewsGroupTitle>
        </div>
        <div className="-mt-[4px] w-full">
          <Lightgray100Divider />
        </div>

        <div className="mt-[50px] flex flex-col px-3 text-center text-[40px] font-medium leading-[51px] text-Lightgray800 lg:flex-row lg:justify-between lg:px-20 lg:text-left">
          {t(getNewsi18nKey(newsType))}
          <div className="my-auto mx-auto flex w-fit flex-row lg:mx-0">
            <div className={`${getFontFamily(true)} my-auto text-base leading-[22px] text-UIBlack`}>
              {t('latest_news')}
            </div>
            <div className="my-auto ml-[16px]">
              <LastestNewsIcon />
            </div>
          </div>
        </div>
        <div className="grid gap-2 xs:grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
          {news.map((newsData, index) => (
            <NewsCard key={index} newsData={newsData} />
          ))}
        </div>
        {lastFetch.length > 0 && lastFetch.length === perPage ? (
          <div className="flex">
            <button
              className="mx-auto mt-16 rounded-sm bg-Lightgray50 p-3 text-lg font-bold text-Lightgray800"
              onClick={() => setPage(page + 1)}
            >
              {t('more')}
            </button>
          </div>
        ) : null}
      </CustomContainer>
    </Layout>
  );
};

export default NewsPage;
