import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  PinterestShareButton,
  PinterestIcon,
  LineShareButton,
  LineIcon,
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const SocialButtonWrapper = ({ children }) => (
  <div className="my-auto mx-4 flex overflow-hidden rounded-full">{children}</div>
);

const CopyToClipboardButton = ({ text }) => {
  const [isCopied, setIsCopied] = useState(false);

  const onCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  return (
    <CopyToClipboard text={text} options={{ message: 'copy' }} onCopy={onCopy}>
      <div className="relative w-16">
        <button className="  my-auto h-16 w-16 rounded-full px-4 hover:bg-Lightgray50">
          <ContentCopyIcon fontSize="large" />
        </button>
        {isCopied && <div className=" absolute w-full text-center text-lg font-bold text-NeonPink">Copied !</div>}
      </div>
    </CopyToClipboard>
  );
};

const SocialShareContent = ({ url }) => (
  <div className="flex flex-row">
    <SocialButtonWrapper>
      <FacebookShareButton url={url}>
        <FacebookIcon />
      </FacebookShareButton>
    </SocialButtonWrapper>
    <SocialButtonWrapper>
      <TwitterShareButton url={url}>
        <TwitterIcon />
      </TwitterShareButton>
    </SocialButtonWrapper>
    <SocialButtonWrapper>
      <EmailShareButton url={url}>
        <EmailIcon />
      </EmailShareButton>
    </SocialButtonWrapper>
    <SocialButtonWrapper>
      <LinkedinShareButton url={url}>
        <LinkedinIcon />
      </LinkedinShareButton>
    </SocialButtonWrapper>
    <SocialButtonWrapper>
      <TelegramShareButton url={url}>
        <TelegramIcon />
      </TelegramShareButton>
    </SocialButtonWrapper>
    <SocialButtonWrapper>
      <FacebookMessengerShareButton url={url}>
        <FacebookMessengerIcon />
      </FacebookMessengerShareButton>
    </SocialButtonWrapper>
    <SocialButtonWrapper>
      <PinterestShareButton url={url}>
        <PinterestIcon />
      </PinterestShareButton>
    </SocialButtonWrapper>
    <SocialButtonWrapper>
      <LineShareButton url={url}>
        <LineIcon />
      </LineShareButton>
    </SocialButtonWrapper>
    <CopyToClipboardButton text={url} />
  </div>
);

SocialShareContent.propTypes = {
  url: PropTypes.string.isRequired,
};

export default SocialShareContent;
