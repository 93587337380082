import React, { useState } from 'react';
import Layout from '../../../components/Layout';
import { convertFromRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { fetchPost } from '../../../utils/services/fetch';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../../../node_modules/react-datepicker/dist/react-datepicker.min.css';
import AttatchFilePinkIcon from '../../../components/Icons/AttatchFilePinkIcon';
import { dropdownNewsTypes } from '../../../utils/news';
import routeUrlProvider, { AdminRoutePath } from '../../../constants/route-paths';
import apiUrlProvider, { ADMIN_ADD_FILE, ADMIN_ADD_IMAGE, ADMIN_CREATE_NEWS } from '../../../constants/api-endpoints';
import { getAccessToken } from '../../../utils/token';

const NewsCreate = ({ history }) => {
  const defaultEditorContext = {
    entityMap: {},
    blocks: [{ key: '637gr', text: '', type: 'unstyled', depth: 0, inlineStyleRanges: [], entityRanges: [], data: {} }],
  };

  const contentStateTh = convertFromRaw(defaultEditorContext);
  const contentStateEn = convertFromRaw(defaultEditorContext);

  const [titleTh, setTitleTh] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [editorStateTh, setEditorStateTh] = useState(EditorState.createWithContent(contentStateTh));
  const [editorStateEn, setEditorStateEn] = useState(EditorState.createWithContent(contentStateEn));
  const [descriptionTh, setDescriptionTh] = useState(defaultEditorContext);
  const [descriptionEn, setDescriptionEn] = useState(defaultEditorContext);
  const [newsType, setNewsType] = useState('');
  const [imagesTh, setImagesTh] = useState([]);
  const [imagesEn, setImagesEn] = useState([]);
  const [filesTh, setFilesTh] = useState([]);
  const [filesEn, setFilesEn] = useState([]);

  const [isUseThImage, setIsUseThImage] = useState(false)
  const [isUseThFile, setIsUseThFile] = useState(false)

  const onDescriptionThChange = (descriptionTh) => setDescriptionTh(descriptionTh);
  const onEditorStateThChange = (editorStateTh) => {
    setEditorStateTh(editorStateTh);
  };
  const onDescriptionEnChange = (descriptionEn) => setDescriptionEn(descriptionEn);
  const onEditorStateEnChange = (editorStateEn) => {
    setEditorStateEn(editorStateEn);
  };
  const onNewsTypeChange = (value) => {
    console.log(value);
    setNewsType(value);
  };

  const newsTypes = Object.values(dropdownNewsTypes);

  const onUploadImageThChange = async (e) => {
    const files = e.target.files;
    if (files.length == 0) {
      e.target.value = null;
      return;
    }

    let responseData = [];
    for (var i = 0; i < files.length; i++) {
      const file = files[i];
      const data = new FormData();

      data.append(`file`, file, file.name);

      await fetch(
        apiUrlProvider.get(ADMIN_ADD_IMAGE), 
        {
          method: 'POST',
          body: data,
          headers: {
            Authorization: `Bearer ${getAccessToken() || ''}`,
          }
        }
      )
        .then(async (res) => {
          let pm = await res.json();
          if (res.ok) return pm;
          throw pm;
        })
        .then((data) => {
          responseData.push(data);
        })
        .catch((err) => {
          alert(err.error);
        });
    }

    setImagesTh([...imagesTh, ...responseData]);

    e.target.value = null;
  };

  const onUploadImageEnChange = async (e) => {
    const files = e.target.files;
    if (files.length == 0) {
      e.target.value = null;
      return;
    }

    let responseData = [];
    let responseError = null;
    for (var i = 0; i < files.length; i++) {
      const file = files[i];
      const data = new FormData();

      data.append(`file`, file, file.name);

      await fetch(
        apiUrlProvider.get(ADMIN_ADD_IMAGE), 
        {
          method: 'POST',
          body: data,
          headers: {
            Authorization: `Bearer ${getAccessToken() || ''}`,
          }
        }
      )
        .then(async (res) => {
          let pm = await res.json();
          if (res.ok) return pm;
          throw pm;
        })
        .then((data) => {
          responseData.push(data);
        })
        .catch((err) => (responseError = err));
    }

    if (responseError != null) alert(responseError);

    setImagesEn([...imagesEn, ...responseData]);

    e.target.value = null;
  };

  const onUploadFileThChange = async (e) => {
    const files = e.target.files;
    if (files.length == 0) {
      e.target.value = null;
      return;
    }

    let responseData = [];
    let responseError = null;
    for (var i = 0; i < files.length; i++) {
      const file = files[i];
      const data = new FormData();

      data.append(`file`, file, file.name);

      await fetch(
        apiUrlProvider.get(ADMIN_ADD_FILE), 
        {
          method: 'POST',
          body: data,
          headers: {
            Authorization: `Bearer ${getAccessToken() || ''}`,
          }
        }
      )
        .then(async (res) => {
          let pm = await res.json();
          if (res.ok) return pm;
          throw pm;
        })
        .then((data) => {
          responseData.push(data);
        })
        .catch((err) => (responseError = err));
    }

    if (responseError != null) alert(responseError);

    setFilesTh([...filesTh, ...responseData]);

    e.target.value = null;
  };

  const onUploadFileEnChange = async (e) => {
    const files = e.target.files;
    if (files.length == 0) {
      e.target.value = null;
      return;
    }

    let responseData = [];
    let responseError = null;
    for (var i = 0; i < files.length; i++) {
      const file = files[i];
      const data = new FormData();

      data.append(`file`, file, file.name);

      await fetch(
        apiUrlProvider.get(ADMIN_ADD_FILE), 
        {
          method: 'POST',
          body: data,
          headers: {
            Authorization: `Bearer ${getAccessToken() || ''}`,
          }
        }
      )
        .then(async (res) => {
          let pm = await res.json();
          if (res.ok) return pm;
          throw pm;
        })
        .then((data) => {
          responseData.push(data);
        })
        .catch((err) => (responseError = err));
    }

    if (responseError != null) alert(responseError);

    setFilesEn([...filesEn, ...responseData]);

    e.target.value = null;
  };

  const convertToPlainText = (rawContext) => {
    let toStr = '';
    console.log(rawContext);
    for (var i = 0; i < rawContext.blocks.length; i++) {
      toStr += rawContext.blocks[i].text + ' ';
    }
    return toStr
  }

  const onDeleteImageTh = (index) => {
    imagesTh.splice(index, 1);
    setImagesTh([...imagesTh]);
  };

  const onDeleteImageEn = (index) => {
    imagesEn.splice(index, 1);
    setImagesEn([...imagesEn]);
  };

  const onDeleteFileTh = (index) => {
    filesTh.splice(index, 1);
    setFilesTh([...filesTh]);
  };

  const onDeleteFileEn = (index) => {
    filesEn.splice(index, 1);
    setFilesEn([...filesEn]);
  };

  const onCreateNewsSubmit = (e) => {
    e.preventDefault();
    fetchPost(apiUrlProvider.get(ADMIN_CREATE_NEWS), {
      startAt: new Date(),
      endAt: new Date(),
      type: newsType,
      titleTh: titleTh,
      descriptionPlainTh: convertToPlainText(descriptionTh),
      descriptionTh: descriptionTh,
      titleEn: titleEn,
      descriptionPlainEn: convertToPlainText(descriptionEn),
      descriptionEn: descriptionEn,
      imagesTh: imagesTh,
      imagesEn: imagesEn,
      filesTh: filesTh,
      filesEn: filesEn,
      isUseThImage: isUseThImage,
      isUseThFile: isUseThFile,
    }, true)
    .then( res => {
      alert("เพิ่มข่าวสารสำเร็จ")
      history.push(routeUrlProvider.getForLink(AdminRoutePath.ADMIN_NEWS_LIST))
    })
    .catch(e => {
      alert(e.error)
    })
  }

  return (
    <Layout>
      <div className="px-20">
        <div className="text-center text-lg"> เพิ่มข่าวสาร </div>
        <form>
          <div className="mb-4">
            <label htmlFor="titleTh" className="text-gray-900 mb-1 block text-sm font-medium">
              หัวข้อ (ภาษาไทย)
            </label>
            <input
              type="text"
              id="titleTh"
              className="bg-gray-50 border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full rounded-lg border p-2.5 text-sm"
              onChange={(e) => setTitleTh(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="titleEn" className="text-gray-900 mb-1 block text-sm font-medium">
              หัวข้อ (ภาษาอังกฤษ)
            </label>
            <input
              type="text"
              id="titleEn"
              className="bg-gray-50 border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full rounded-lg border p-2.5 text-sm"
              onChange={(e) => setTitleEn(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="text-gray-900 mb-1 block text-sm font-medium">เนื้อหา (ภาษาไทย)</label>
            <div className="bg-gray-50 border-gray-300 rounded-lg border text-sm">
              <Editor
                wrapperClassName="demo-wrapper mx-1"
                editorClassName="demo-editor px-3"
                editorState={editorStateTh}
                onEditorStateChange={onEditorStateThChange}
                onContentStateChange={onDescriptionThChange}
              />
            </div>
          </div>

          <div className="mb-4">
            <label className="text-gray-900 mb-1 block text-sm font-medium">เนื้อหา (ภาษาอังกฤษ)</label>
            <div className="bg-gray-50 border-gray-300 rounded-lg border text-sm">
              <Editor
                wrapperClassName="demo-wrapper mx-1"
                editorClassName="demo-editor px-3"
                editorState={editorStateEn}
                onEditorStateChange={onEditorStateEnChange}
                onContentStateChange={onDescriptionEnChange}
              />
            </div>
          </div>

          <div className="mb-4">
            <div>ประเภทของข่าวสาร</div>
            {newsTypes.map((el, index) => (
              // <div key={index} className="form-check">
              //   <label className="form-check-label inline-block text-gray-800">
              //   <input
              //     type="radio"
              //     value={el.value}
              //     checked={newsType === el.value}
              //     onChange={(e) => onNewsTypeChange(e.target.value)}
              //     className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              //   />
              //   {el.name}
              //   </label>
              // </div>
              <div className='pl-2' key={index}>
                <label className='flex'>
                  <input
                    type="radio"
                    value={el.value}
                    checked={newsType === el.value}
                    onChange={(e) => onNewsTypeChange(e.target.value)}
                  />
                  <div className="ml-1">{el.name}</div>
                </label>
              </div>
            ))}
          </div>

          {/* <div className="mb-4">
            <div>วันที่เริ่มแสดง</div>
            <ReactDatePicker className='bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-25 p-2.5' selected={startDate} onChange={(date) => setStartDate(date)} />
          </div>

          <div className="mb-4">
            <div>วันที่สิ้นสุด</div>
            <ReactDatePicker className='bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-25 p-2.5' selected={endDate} onChange={(date) => setEndDate(date)} />
          </div> */}

          <div className="mb-4">
            <div>รูปภาพข่าว (ภาษาไทย)</div>
            <div className="flex">
              {imagesTh.map((image, index) => (
                <div
                  key={index}
                  className="relative mx-2 box-content grid h-32 w-32 flex-initial content-center rounded-[10px] border-2 border-NeonPink hover:cursor-pointer"
                >
                  <div
                    className="absolute top-0 right-0 z-40 inline-flex items-center justify-center p-2 text-NeonPink"
                    onClick={() => onDeleteImageTh(index)}
                  >
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </div>
                  <img src={image.imageUrl} alt={image.imageName} width={332} height={187} className="rounded-[2px]" />
                </div>
              ))}
              
              <div className="mx-2 box-content grid h-32 w-32 flex-initial content-center rounded-[10px] border-2 border-NeonPink hover:cursor-pointer">
                <label className="h-max hover:cursor-pointer">
                  <div className="rounded-full text-center text-4xl font-black text-NeonPink">+</div>
                  <input 
                    className='hidden' 
                    type="file" 
                    onChange={onUploadImageThChange} 
                    accept="image/png, image/jpeg" 
                    multiple
                  />
                </label>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <div>รูปภาพข่าว (ภาษาอังกฤษ)</div>

            <label className='flex pl-2'>
              <input
                type="checkbox"
                checked={isUseThImage}
                onChange={(e) => setIsUseThImage(!isUseThImage)}
              />
              <div className='ml-1'>ใช้ภาพชุดเดียวกับภาษาไทย</div>
            </label>

            { !isUseThImage ?
              (
                <div className='flex'>
                  {imagesEn.map((image, index) => (
                    <div key={index} className='relative flex-initial box-content h-32 w-32 border-2 mx-2 border-NeonPink rounded-[10px] grid content-center hover:cursor-pointer'>
                      <div className="absolute top-0 right-0 p-2 inline-flex items-center justify-center text-NeonPink z-40" onClick={() => onDeleteImageEn(index)}>
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </div>
                      <img
                        src={image.imageUrl}
                        alt={image.imageName}
                        width={332}
                        height={187}
                        className="rounded-[2px]"
                      />
                    </div>
                  ))}

                  <div className='flex-initial box-content h-32 w-32 border-2 mx-2 border-NeonPink rounded-[10px] grid content-center hover:cursor-pointer'>
                    <label className='h-max hover:cursor-pointer'>
                      <div className="rounded-full text-center font-black text-NeonPink text-4xl">+</div>
                      <input 
                        className='hidden' 
                        type="file" 
                        onChange={onUploadImageEnChange} 
                        accept="image/png, image/jpeg" 
                        multiple
                      />
                    </label>
                  </div>
                </div>
              )
              : (
                <div className='flex'>
                  {imagesTh.map((image, index) => (
                    <div key={index} className='relative flex-initial box-content h-32 w-32 border-2 mx-2 border-NeonPink rounded-[10px] grid content-center hover:cursor-pointer'>
                      <img
                        src={image.imageUrl}
                        alt={image.imageName}
                        width={332}
                        height={187}
                        className="rounded-[2px]"
                      />
                    </div>
                  ))}
                </div>
              )
            }
          </div>

          <div className="mb-4">
            <div>ไฟล์ประกอบ (ภาษาไทย)</div>
            <div className="flex">
              {filesTh.map((file, index) => (
                <div
                  key={index}
                  className="relative mx-2 box-content grid h-32 w-32 flex-initial place-content-center rounded-[10px] border-2 border-NeonPink hover:cursor-pointer"
                >
                  <div
                    className="absolute top-0 right-0 z-40 inline-flex items-center justify-center p-2 text-NeonPink"
                    onClick={() => onDeleteFileTh(index)}
                  >
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </div>
                  <div className="grid justify-items-center">
                    <AttatchFilePinkIcon></AttatchFilePinkIcon>
                    <div>{file.fileName}</div>
                  </div>
                </div>
              ))}

              <div className="mx-2 box-content grid h-32 w-32 flex-initial place-content-center rounded-[10px] border-2 border-NeonPink hover:cursor-pointer">
                <label className="h-max hover:cursor-pointer">
                  <div className="rounded-full text-center text-4xl font-black text-NeonPink">+</div>
                  <input className="hidden" type="file" onChange={onUploadFileThChange} multiple />
                </label>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <div>ไฟล์ประกอบ (ภาษาอังกฤษ)</div>

            <label className='flex pl-2'>
              <input
                type="checkbox"
                checked={isUseThFile}
                onChange={(e) => setIsUseThFile(!isUseThFile)}
              />
              <div className='ml-1'>ใช้ไฟล์ชุดเดียวกับภาษาไทย</div>
            </label>

            { !isUseThFile ?
              (
                <div className='flex'>
                  {filesEn.map((file, index) => (
                    <div key={index} className='relative flex-initial box-content h-32 w-32 border-2 mx-2 border-NeonPink rounded-[10px] grid place-content-center hover:cursor-pointer'>
                      <div className="absolute top-0 right-0 p-2 inline-flex items-center justify-center text-NeonPink z-40" onClick={() => onDeleteFileEn(index)}>
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </div>
                      <div className='grid justify-items-center'>
                        <AttatchFilePinkIcon></AttatchFilePinkIcon>
                        <div>{file.fileName}</div>
                      </div>
                    </div>
                  ))}

                  <div className='flex-initial box-content h-32 w-32 border-2 mx-2 border-NeonPink rounded-[10px] grid place-content-center hover:cursor-pointer'>
                    <label className='h-max hover:cursor-pointer'>
                      <div className="rounded-full text-center font-black text-NeonPink text-4xl">+</div>
                      <input className='hidden' type="file" onChange={onUploadFileEnChange} multiple/>
                    </label>
                  </div>
                </div>
              )
              : (
                <div className='flex'>
                  {filesTh.map((file, index) => (
                    <div key={index} className='relative flex-initial box-content h-32 w-32 border-2 mx-2 border-NeonPink rounded-[10px] grid place-content-center hover:cursor-pointer'>
                      <div className='grid justify-items-center'>
                        <AttatchFilePinkIcon></AttatchFilePinkIcon>
                        <div>{file.fileName}</div>
                      </div>
                    </div>
                  ))}
                </div>
              )
            }
          </div>

          <div className="flex justify-center space-x-2" onClick={onCreateNewsSubmit}>
            <button
              type="button"
              className="bg-blue-600 text-white inline-block w-52 rounded bg-NeonPink px-6 py-2.5 text-xs font-medium leading-tight text-UIWhite shadow-md"
            >
              บันทึก
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default NewsCreate;
