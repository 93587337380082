import React, { useEffect } from 'react';
import Layout from '../../../components/Layout';
import apiUrlProvider, { ADMIN_LOGIN_GOOGLE_CALLBACK } from '../../../constants/api-endpoints';
import routeUrlProvider, { AdminRoutePath, HOME, FAQ } from '../../../constants/route-paths';
import { useAuth } from '../../../utils/auth';
import { fetchGet, fetchPost } from '../../../utils/services/fetch';
import { useToken } from '../../../utils/token';
import { useContextAuthManager } from '../../../components/AuthManager'

const LoginCallbackGoogle = ({ history }) => {
  const { setToken } = useToken()
  const { setIsLoggedIn } = useContextAuthManager()

  useEffect( () => {
    const url = apiUrlProvider.get(ADMIN_LOGIN_GOOGLE_CALLBACK);
    const searchParams = new URLSearchParams(document.location.search);
    const code = searchParams.get('code')
    const urlWithQuery = `${url}?code=${code}`;
    fetchGet(urlWithQuery)
      .then((res) => {
        setToken(res.accessToken)
        setIsLoggedIn(true)

        history.push(routeUrlProvider.getForRoute(HOME));
      })
      .catch((err) => {
        console.log(err)
        alert('Authentication failed.')

        history.push(routeUrlProvider.getForRoute(HOME));
      });

  }, []);

  return (
    <Layout>
      
    </Layout>
  );
};

export default LoginCallbackGoogle;
