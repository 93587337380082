import React from 'react';

import CollapseItem from '../../components/CollapseSection/CollapseItem';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import TuitionFee65 from './assets/65-Tuition-Fee-update-05-2022-Rev1.pdf';
import TuitionFee66 from './assets/Tuition-Fee-Code-66-update-10-10-2023.pdf';
import TuitionFee67 from './assets/Tuition-Fee-Code-67-update-2024-02-14.pdf';
import TuitionFee63 from './assets/63-Tuition-Fee.pdf';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';
import CollapseSectionContainer from '../../components/CollapseSection/CollapseSectionContainer';

import EduResearchService from './assets/20211125090433.pdf';
import FeeAddOn38 from './assets/fee_addon_38.pdf';
import FeeAddOn37 from './assets/fee_addon_37.pdf';
import FeeAddOn36 from './assets/fee_addon_36.pdf';
import FeeAddOn35 from './assets/fee_addon_35.pdf';
import FeeAddOn34 from './assets/fee_addon_34.pdf';
import FeeAddOn33 from './assets/fee_addon_33.pdf';
import FeeAddOn32 from './assets/fee_addon_32.pdf';
import FeeAddOn31 from './assets/fee_addon_31.pdf';
import FeeAddOn30 from './assets/fee_addon_30.pdf';
import FeeAddOn29 from './assets/fee_addon_29.pdf';
import FeeAddOn28 from './assets/fee_addon_28.pdf';
import FeeAddOn27 from './assets/fee_addon_27.pdf';
import FeeAddOn26 from './assets/fee_addon_26.pdf';
import FeeAddOn25 from './assets/fee_addon_25.pdf';
import FeeAddOn24 from './assets/fee_addon_24.pdf';
import FeeAddOn23 from './assets/fee_addon_23.pdf';
import FeeAddOn22 from './assets/fee_addon_22.pdf';
import FeeAddOn21 from './assets/fee_addon_21.pdf';
import FeeAddOn20 from './assets/fee_addon_20.pdf';
import FeeAddOn19 from './assets/fee_addon_19.pdf';
import FeeAddOn18 from './assets/fee_addon_18.pdf';
import FeeAddOn17 from './assets/fee_addon_17.pdf';
import FeeAddOn16 from './assets/fee_addon_16.pdf';
import FeeAddOn15 from './assets/fee_addon_15.pdf';
import FeeAddOn14 from './assets/fee_addon_14.pdf';
import GradFeeAddOn2 from './assets/GradFeeAddOn2.pdf';
import FeeAddOn12 from './assets/fee_addon_12.pdf';
import FeeAddOn11 from './assets/fee_addon_11.pdf';
import FeeAddOn10 from './assets/fee_addon_10.pdf';
import FeeAddOn09 from './assets/fee_addon_09.pdf';
import FeeAddOn08 from './assets/fee_addon_08.pdf';
import GradFeeAddOn1 from './assets/GradFeeAddOn1.pdf';
import FeeAddOn07 from './assets/fee_addon_07.pdf';
import FeeAddOn06 from './assets/fee_addon_06.pdf';
import FeeAddOn05 from './assets/fee_addon_05.pdf';
import FeeAddOn04 from './assets/fee_addon_04.pdf';
import FeeAddOn03 from './assets/fee_addon_03.pdf';
import FeeAddOn02 from './assets/fee_addon_02.pdf';
import FeeAddOn01 from './assets/fee_addon_01.pdf';
import GradFeeAddOn3 from './assets/GradFeeAddOn3.pdf';
import GradFeeAddOn4 from './assets/GradFeeAddOn4.pdf';
import GradFeeAddOn5 from './assets/GradFeeAddOn5.pdf';
import GradFeeAddOn6 from './assets/GradFeeAddOn6.pdf';

const ProgramTutionFeePage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{t('TuitionFee')}</PageTitleContainer>
        
        <CollapseSectionContainer>
          <CollapseItem pdfUrl={TuitionFee67}>{t('Tuition Fee Code 67')}</CollapseItem>
        </CollapseSectionContainer>
        <CollapseSectionContainer>
          <CollapseItem pdfUrl={TuitionFee66}>{t('Tuition Fee Code 66')}</CollapseItem>
        </CollapseSectionContainer>
        <CollapseSectionContainer>
          <CollapseItem pdfUrl={TuitionFee65}>{t('Tuition Fee Code 65')}</CollapseItem>
        </CollapseSectionContainer>
        <CollapseSectionContainer>
          <CollapseItem pdfUrl={EduResearchService}>{t('ระเบียบมหาวิทยาลัยเชียงใหม่ ว่าด้วยค่าธรรมเนียมการศึกษา วิจัย และการใช้บริการมหาวิทยาลัย สำหรับนักศึกษาสมทบระดับบัณฑิตศึกษา พ.ศ.2564')}</CollapseItem>
          <CollapseItem pdfUrl={TuitionFee63}>{t('ระเบียมหาวิทยาลัยเชียงใหม่ ว่าด้วยค่าธรรมเนียมการศึกษาระดับบัณฑิตศึกษาแบบเหมาจ่าย (ฉบับที่ 3)  พ.ศ. 2563')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn38}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 38)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn37}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 37)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn36}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 36)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn35}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 35)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn34}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 34)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn33}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 33)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn32}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 32)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn31}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 31)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn30}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 30)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn29}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 29)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn28}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 28)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn27}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 27)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn26}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 26)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn25}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 25)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn24}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 24)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn23}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 23)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn22}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 22)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn21}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 21)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn20}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 20)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn19}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 19)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn18}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 18)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn17}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 17)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn16}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 16)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn15}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 15)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn14}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 14)')}</CollapseItem>
          <CollapseItem pdfUrl={GradFeeAddOn2}>{t('ระเบียบมหาวิทยาลัยเชียงใหม่ ว่าด้วยค่าธรรมเนียมการศึกษาระดับบัณฑิตศึกษาแบบเหมาจ่าย (ฉบับที่ 2) พ.ศ. 2559')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn12}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 12)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn11}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 11)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn10}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 10)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn09}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 9)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn08}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 8)')}</CollapseItem>
          <CollapseItem pdfUrl={GradFeeAddOn1}>{t('ระเบียบมหาวิทยาลัยเชียงใหม่ ว่าด้วยค่าธรรมเนียมการศึกษาระดับบัณฑิตศึกษาแบบเหมาจ่าย พ.ศ. 2556')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn07}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 7)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn06}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 6)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn05}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 5)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn04}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 4)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn03}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 3)')}</CollapseItem>
          <CollapseItem pdfUrl={GradFeeAddOn3}>{t('ค่าธรรมเนียมเหมาจ่าย สำหรับนักศึกษารหัส 56 เป็นต้นไป')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn02}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 2)')}</CollapseItem>
          <CollapseItem pdfUrl={FeeAddOn01}>{t('ค่าธรรมเนียมเหมาจ่าย (เพิ่มเติมครั้งที่ 1)')}</CollapseItem>
          <CollapseItem pdfUrl={GradFeeAddOn4}>{t('เรื่อง ยกเลิกการเรียกเก็บค่าธรรมเนียมนักศึกษาต่างชาติที่เข้าศึกษาในมหาวิทยาลัยเชียงใหม่ พ.ศ. 2556')}</CollapseItem>
          <CollapseItem pdfUrl={GradFeeAddOn5}>{t('ค่าธรรมเนียมในการสอบประมวลความรู้ วัดคุณสมบัติ สอบแก้ตัว')}</CollapseItem>
          <CollapseItem pdfUrl={GradFeeAddOn6}>{t('ค่าธรรมเนียมการศึกษา ประเภท ง : ค่าธรรมเนียมการศึกษาที่เรียกเก็บตามรายการทุกครั้ง สำหรับนักศึกษาบัณฑิตศึกษา')}</CollapseItem>

        </CollapseSectionContainer>

      </CustomContainer>

    </Layout>
  );
};

export default ProgramTutionFeePage;
