export const MOCK_TARA_SCHOLARSHIP_PIE_GRAPH_GROUP_BY_BRANCH = [
  {
    name_th: 'วิทยาศาสตร์และเทคโนโลยี',
    name_en: 'Science and Technology',
    student_amount: 135,
    student_percentage: '54.66%',
  },
  {
    name_th: 'มนุษยศาสตร์และสังคมศาสตร์',
    name_en: 'Humanities and Social Science',
    student_amount: 28,
    student_percentage: '11.34%',
  },
  {
    name_th: 'วิทยาศาสตร์สุขภาพ',
    name_en: 'Health Science',
    student_amount: 84,
    student_percentage: '34.01%',
  },
];
