import UserCommentIcon from '../../../../components/Icons/UserCommentIcon';
import { useTranslationUtil } from '../../../../hooks/useTranslationUtil';
import { useHistory } from 'react-router-dom';
import routeUrlProvider, { NEWS_DETAIL } from '../../../../constants/route-paths';
import { DefaultNoImage } from '../../../../components/Noimage';

const NewsCard = ({ newsData }) => {
  const { imagesTh, imagesEn, titleEn, titleTh, createdAt, publisher, id } = newsData;
  const { getDisplayText, formatDate, isThai, getFontFamily } = useTranslationUtil();
  const history = useHistory();

  const getLocalizedImageURL = (imagesTh, imagesEn) => {
    let images = isThai() ? imagesTh : imagesEn;
    return images && images.length > 0 ? images[0].imageUrl : DefaultNoImage;
  };

  // const [imgObj] = images || [];
  // const imageUrl = imgObj ? imgObj.imageUrl : DefaultNoImageUrl;
  const imageUrl = getLocalizedImageURL(imagesTh, imagesEn);
  const title = getDisplayText(titleTh, titleEn);

  const onClick = () => {
    history.push(routeUrlProvider.getForLink(NEWS_DETAIL, { id }), { newsData });
  };

  return (
    <div
      className="round-sm mx-auto mt-14 w-fit pb-3 duration-300 hover:scale-105 hover:cursor-pointer"
      onClick={onClick}
    >
      <img src={imageUrl} alt={title} className="h-[187px] w-[322px] rounded-[2px] object-contain" />
      <p className="mt-[32px] max-h-[60px] max-w-[322px] font-ibm-plex-sans text-[18px] font-bold leading-[29.7px] text-UIBlack line-clamp-2">
        {title}
      </p>
      <div className="mt-[26px] flex flex-row">
        <UserCommentIcon />
        <div className="ml-[10px] font-dosis text-[18px] font-extrabold leading-[23px]">{publisher}</div>
      </div>
      <div className={`${getFontFamily(true)} text-[14px] leading-[19px] text-Lightgray800`}>
        {formatDate(createdAt)}
      </div>
    </div>
  );
};

export default NewsCard;
