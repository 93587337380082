import React from 'react';

const ProfileImage = ({ src }) => (
  <img
    src={src}
    className="col-span-2 drop-shadow-[drop-shadow(0px_16px_32px_rgba(145,77,134,1))] md:max-w-[205px]"
    alt=""
  />
);

export default ProfileImage;
