import React from 'react';
import CollapseItem from '../../components/CollapseSection/CollapseItem';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';

const AnnualMeetingSchedulePage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{t('ตารางกิจกรรมผู้บริหาร')}</PageTitleContainer>
        <div>
          <center>
            <iframe src="https://calendar.google.com/calendar/embed?src=gradcmuth%40gmail.com&ctz=Asia%2FBangkok" width="800" height="600"></iframe>
          </center>
        </div>
        </CustomContainer>
    </Layout>
  );
};

export default AnnualMeetingSchedulePage;
