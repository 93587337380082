import { useEffect, useState } from 'react';
import { useTranslationUtil } from '../../../../hooks/useTranslationUtil';
import { useHistory } from 'react-router';
import routeUrlProvider, { LIFE_CMU } from '../../../../constants/route-paths';
import CustomContainer from '../../../../components/CustomContainer';
import MoreDetailButton from '../../../../components/Button/MoreDetailButton';

const ContentSwaper = ({ datas }) => {
  const { t } = useTranslationUtil();
  const history = useHistory();
  const [primaryIndex, setPrimaryIndex] = useState(0);
  const [secondaryIndex, setSecondaryIndex] = useState(1);

  useEffect(() => {
    const autoChangePrimaryImage = () => {
      const nextPrimary = primaryIndex === 0 ? 1 : 0;
      setPrimaryIndex(nextPrimary);
      setSecondaryIndex(nextPrimary === 0 ? 1 : 0);
    };

    const interval = setInterval(autoChangePrimaryImage, 6000);
    return () => clearInterval(interval);
  }, [primaryIndex]);

  const changePrimaryImageTo = (index) => {
    if (index === primaryIndex) {
      return;
    }
    setPrimaryIndex(index);
    setSecondaryIndex(index === 0 ? 1 : 0);
  };

  const onClick = () => history.push(routeUrlProvider.getForRoute(LIFE_CMU));

  const primaryDescription = datas.length ? datas[primaryIndex]?.description : '';
  const secondaryDescription = datas.length ? datas[secondaryIndex]?.description : '';

  return (
    <CustomContainer className=" mt-[119px] grid lg:max-h-[400px] lg:grid-cols-2 lg:pl-20">
      <div>
        <div className="hover:cursor-pointer lg:max-w-[434px]" onClick={onClick}>
          <div className=" text-center text-5xl font-medium leading-[60px] text-UIBlack md:mt-[65px] lg:text-left">
            {t('LifeAtCMU')}
          </div>
          <div className=" flex flex-col text-center text-lg font-bold leading-[22px] text-UIBlack lg:flex-row lg:justify-between lg:text-left">
            <div> {t('chiang_mai_university')}</div>{' '}
            <div className="mx-auto mt-3 lg:mx-0 lg:mt-0">
              <MoreDetailButton text={t('Details')} onClick={onClick} />
            </div>
          </div>
          <Description>{primaryDescription}</Description>
        </div>

        <div className="mx-auto mt-10 flex w-[72px] flex-row justify-between lg:mx-0">
          <button onClick={() => changePrimaryImageTo(0)}>
            <div className={`h-1 w-8 bg-GradPink ${primaryIndex !== 0 ? ' opacity-40' : ''}`}></div>
          </button>
          <button onClick={() => changePrimaryImageTo(1)}>
            <div className={`h-1 w-8 bg-GradPink ${primaryIndex !== 1 ? ' opacity-40' : ''}`}></div>
          </button>
        </div>
      </div>
      <div className=" mt-16 max-h-[400px] bg-home-admission md:mt-10 md:max-h-[400px] md:bg-none lg:mt-0">
        <PrimaryImage url={datas ? datas[primaryIndex]?.image : ''} alt={primaryDescription} />
        <SecondaryImage url={datas ? datas[secondaryIndex]?.image : ''} alt={secondaryDescription} />
      </div>
    </CustomContainer>
  );
};

const PrimaryImage = ({ url = '', alt = '' }) => {
  return (
    <div>
      <img src={url} alt={alt} className="w-full rounded-sm object-cover object-bottom lg:h-[400px]" />
    </div>
  );
};

const SecondaryImage = ({ url = '', alt = '' }) => {
  return (
    <div className=" relative bottom-[50px] ml-6 md:mx-auto md:w-fit lg:bottom-[142px] lg:-ml-12">
      <img src={url} alt={alt} className=" h-[178px] w-[267px] rounded-sm shadow-xl" />
    </div>
  );
};

const Description = ({ children }) => {
  const { getFontFamily } = useTranslationUtil();
  return (
    <div
      className={`${getFontFamily(
        true
      )} mt-[33px] px-2 text-center text-base leading-[22px] text-Lightgray800 md:px-0 lg:max-w-[434px] lg:text-left`}
    >
      {children}
    </div>
  );
};

export default ContentSwaper;
