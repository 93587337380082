import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ElephantSvg } from '../FactsCard/assets/elephant.svg';

const FactCard = ({ number, title }) => {
  const numberWithCommaFormat = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return (
    <div className="mx-auto mt-10 flex w-[332px] flex-col items-center md:mt-14">
      <div className="h-[187px] w-[332px]">
        <div className="top-[-242px] flex h-[187px] flex-row rounded-[16px] bg-gradient-to-r from-PrimaryLight  to-SecondaryLight  font-dosis font-semibold leading-[189px] text-UIWhite drop-shadow-md">
          <div className="absolute flex w-full flex-col justify-between py-6">
            <div className="flex items-stretch justify-end">
              <div className=" absolute">
                <ElephantSvg />
              </div>
            </div>
          </div>
          <div className="mx-auto mt-4">
            <div className="text-center text-[96px] leading-[121px]">{numberWithCommaFormat}</div>
            <div className="text-center font-ibm-plex-sans text-[24px] leading-[30px]"> {title}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

FactCard.propTypes = {
  description: PropTypes.string,
  number: PropTypes.string,
  ordinalNumberChar: PropTypes.string,
  title: PropTypes.string,
};

export default FactCard;
