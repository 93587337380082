import PropTypes from 'prop-types';
import RightArrow16pxIcon from '../../Icons/RightArrow16pxIcon';
import { useState } from 'react';

const MoreDetailButton = ({
  text = 'More',
  onClick = () => {},
  textColor = 'text-Lightgray400',
  fillArrowColor = '',
}) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <button onClick={onClick} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      <div className="flex flex-col justify-center leading-[61px] ">
        <div
          className={`flex flex-row whitespace-nowrap text-[18px] font-bold leading-[23px] hover:text-NeonPink ${textColor}`}
        >
          {text}
          <div className="my-auto ml-[10px] leading-[23px]">
            <RightArrow16pxIcon className={isHover ? 'fill-NeonPink' : fillArrowColor} />
          </div>
        </div>
      </div>
    </button>
  );
};

MoreDetailButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

export default MoreDetailButton;
