import React from 'react';
import CMUPurpleUnderLine80x8 from '../../components/CMUPurpleUnderLine';
import DartIcon from '../../components/Icons/DartIcon';
import ScheduleBoardIcon from '../../components/Icons/ScheduleBoardIcon';
import ShieldPeopleIcon from '../../components/Icons/ShieldPeopleIcon';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import Image1 from '../../components/GraduateStaffPageComponent/assets/front-view-grad.png';
import ThanyapornRangtasee from './assets/ThanyapornRangtasee.png';
import PanupongRinkhiao from './assets/PanupongRinkhiao.png';
import JareeratYamoon from './assets/JareeratYamoon.png';
import KhunponMaymun from './assets/KhunponMaymun.png';
import DutyItem from '../../components/GraduateStaffPageComponent/DutyItem';
import DutyItemIcon from '../../components/GraduateStaffPageComponent/DutyItemIcon';
import DutyItemText from '../../components/GraduateStaffPageComponent/DutyItemText';
import ProfileContainer from '../../components/GraduateStaffPageComponent/ProfileContainer';
import ProfileImage from '../../components/GraduateStaffPageComponent/ProfileImage';
import ProfileDetailContainer from '../../components/GraduateStaffPageComponent/ProfileDetailContainer';
import ProfileName from '../../components/GraduateStaffPageComponent/ProfileName';
import ProfilePosition from '../../components/GraduateStaffPageComponent/ProfilePosition';
import List from '../../components/GraduateStaffPageComponent/List';
import ListItem from '../../components/GraduateStaffPageComponent/ListItem';
import Phone from '../../components/GraduateStaffPageComponent/Phone';
import Email from '../../components/GraduateStaffPageComponent/Email';
import CustomContainer from '../../components/CustomContainer';
import PageTitleGraduateStaff from '../../components/GraduateStaffPageComponent/PageTitleGraduateStaff';
import ProfileList from '../../components/GraduateStaffPageComponent/ProfileList';
import StaffTeamTitle from '../../components/GraduateStaffPageComponent/StaffTeamTitle';
import GraduateStaffPageContainer from '../../components/GraduateStaffPageComponent/GraduateStaffPageContainer';
import DutyContainer from '../../components/GraduateStaffPageComponent/DutyContainer';
import FrontViewGradSchool from '../../components/GraduateStaffPageComponent/FrontViewGradSchool';

const StudentDevelopmentPage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout headerTransparent removeSpaceBeforeFooterDivider>
      <CustomContainer>
        <PageTitleGraduateStaff title={t('StudentDevelopment')} />
        <GraduateStaffPageContainer>
          {/* <FrontViewGradSchool mobile>
            {t('StudentDevelopment')}, {t('chiang_mai_university')}
          </FrontViewGradSchool>
          <DutyContainer>
            <div className="col-span-2">
              <div className="mt-7 mb-12 text-[32px] font-medium leading-10 text-Lightgray500">
                {t('duty')}
                <div className="mt-14px">
                  <CMUPurpleUnderLine80x8 />
                </div>
              </div>
              <DutyItem>
                <DutyItemIcon>
                  <ShieldPeopleIcon />
                </DutyItemIcon>
                <DutyItemText> {t('Scholarship')}</DutyItemText>
              </DutyItem>
              <DutyItem>
                <DutyItemIcon>
                  <DartIcon />
                </DutyItemIcon>
                <DutyItemText> {t('GraduateStudentAffairs')}</DutyItemText>
              </DutyItem>
              <DutyItem>
                <DutyItemIcon>
                  <ScheduleBoardIcon />
                </DutyItemIcon>
                <DutyItemText>{t('Dissertation')}</DutyItemText>
              </DutyItem>
            </div>
            <FrontViewGradSchool>
              {t('StudentDevelopment')}, {t('chiang_mai_university')}
            </FrontViewGradSchool>
          </DutyContainer> */}
          <StaffTeamTitle>{t('StudentDevelopmentTeam')}</StaffTeamTitle>
          <ProfileList>
            <ProfileContainer>
              <ProfileImage src={ThanyapornRangtasee} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('ThanyapornRangtasee')}</ProfileName>
                <ProfilePosition>{t('OperationalStaffManager')}</ProfilePosition>
                <List>
                  <ListItem>Head of Student Development Section</ListItem>
                </List>
                <Phone>0-5394-2409</Phone>
                <Email>thanyaporn.ch@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={PanupongRinkhiao} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('PanupongRinkhiao')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Graduate Student Affairs</ListItem>
                </List>
                <Phone>0-5394-2409</Phone>
                <Email>panupong.r@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={JareeratYamoon} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('JareeratYamoon')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Student Scholarship</ListItem>
                </List>
                <Phone>0-5394-2415</Phone>
                <Email>jareerat.y@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={KhunponMaymun} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('KhunponMaymun')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Dissertation</ListItem>
                </List>
                <Phone>0-5394-2415</Phone>
                <Email>kunpon.m@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
          </ProfileList>
        </GraduateStaffPageContainer>
      </CustomContainer>
    </Layout>
  );
};

export default StudentDevelopmentPage;
