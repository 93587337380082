import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import Layout from '../../components/Layout';
import SectionTitle from './components/SectionTitle';
import Container from './components/Container';
import Loading from '../../components/Loading';
import BackToTopButton from '../../components/Button/BackToTopButton';
import { useLocation } from 'react-router';
import CustomContainer from '../../components/CustomContainer';

const CmuPresidentialScholarshipSection = lazy(() => import('./CmuPresidentialScholarshipSection'));
const CmuPresidentialActiveRecruitmentScholarshipSection = lazy(() =>
  import('./CmuPresidentialActiveRecruitmentScholarshipSection')
);
const TaRaAcademicYear2022Section = lazy(() => import('./TaRaAcademicYear2022Section'));
const TippSection = lazy(() => import('./TippSection'));
const TaRaActiveRecruitment2022Section = lazy(() => import('./TaRaActiveRecruitment2022Section'));
const AcademicActivityScholarshipSection = lazy(() => import('./AcademicActivityScholarshipSection'));
const NewGraduateStudentScholarshipSection = lazy(() => import('./NewGraduateStudentScholarshipSection'));
const EnglishUpskillSection = lazy(() => import('./EnglishUpskillSection'));

const ScholarshipPage = () => {
  const { t } = useTranslationUtil();
  const location = useLocation();
  const { hash } = location;
  let cmuPresidentialScholarshipSectionRef = useRef(null);
  let cmuPresidentialActiveRecruitmentScholarshipSectionRef = useRef(null);
  let taRaAcademicYear2022SectionRef = useRef(null);
  let taRaActiveRecruitment2022SectionRef = useRef(null);
  let tippSectionRef = useRef(null);
  let academicActivityScholarshipSectionRef = useRef(null);
  let newGraduateStudentScholarshipSectionRef = useRef(null);
  let englishUpskillSectionRef = useRef(null);

  const scrollToSection = (sectionRef) => {
    setTimeout(() => {
      if (sectionRef && sectionRef.scrollIntoView) {
        sectionRef.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }, 500);
  };

  useEffect(() => {
    switch (hash) {
      case '#ta-ra':
        scrollToSection(taRaAcademicYear2022SectionRef);
        break;
      case '#president':
        scrollToSection(cmuPresidentialScholarshipSectionRef);
        break;
      case '#other':
        scrollToSection(tippSectionRef);
        break;
      default:
        scrollToSection(englishUpskillSectionRef);
        break;
    }
  }, [hash]);

  return (
    <Layout>
      <CustomContainer>
        <div className=" bg-gradien-GradPink-to-CMUPurple pt-[32px] pb-[48px] text-center text-[56px] font-medium leading-[71px] text-UIWhite  ">
          {t('Scholarship')}
        </div>
        <Container>
          <div>
            <SectionTitle>{t('AllScholarship')}</SectionTitle>
            <ul className="list ml-3 list-outside list-disc">
              <div className="px-5 text-lg font-bold leading-6 text-Lightgray600 md:px-20">
                <li
                  onClick={() => scrollToSection(cmuPresidentialScholarshipSectionRef)}
                  className="hover:cursor-pointer"
                >
                  Cmu Presidential Scholarship
                </li>
                <li
                  onClick={() => scrollToSection(cmuPresidentialActiveRecruitmentScholarshipSectionRef)}
                  className="hover:cursor-pointer"
                >
                  Cmu Presidential Active Recruitment Scholarship
                </li>
                <li onClick={() => scrollToSection(taRaAcademicYear2022SectionRef)} className="hover:cursor-pointer">
                  Ta & Ra Recruitment
                </li>
                <li
                  onClick={() => scrollToSection(taRaActiveRecruitment2022SectionRef)}
                  className="hover:cursor-pointer"
                >
                  Ta & Ra Active Recruitment
                </li>
                <li onClick={() => scrollToSection(tippSectionRef)} className="hover:cursor-pointer">
                  Thailand International Postgraduate Programme (TIPP)
                </li>
                <li
                  onClick={() => scrollToSection(academicActivityScholarshipSectionRef)}
                  className="hover:cursor-pointer"
                >
                  Academic Activity Scholarship
                </li>
                <li
                  onClick={() => scrollToSection(newGraduateStudentScholarshipSectionRef)}
                  className="hover:cursor-pointer"
                >
                  New Graduate Student Scholarship (Coming In 2023)
                </li>
                <li onClick={() => scrollToSection(englishUpskillSectionRef)} className="hover:cursor-pointer">
                  โครงการพัฒนาทักษะภาษาอังกฤษสำหรับนักศึกษาบัณฑิตศึกษา
                </li>
              </div>
            </ul>
          </div>
          <Suspense fallback={<Loading />}>
            <div ref={(ref) => (cmuPresidentialScholarshipSectionRef = ref)}>
              <CmuPresidentialScholarshipSection />
            </div>
            <div ref={(ref) => (cmuPresidentialActiveRecruitmentScholarshipSectionRef = ref)}>
              <CmuPresidentialActiveRecruitmentScholarshipSection />
            </div>
            <div ref={(ref) => (taRaAcademicYear2022SectionRef = ref)}>
              <TaRaAcademicYear2022Section />
            </div>

            <div ref={(ref) => (taRaActiveRecruitment2022SectionRef = ref)}>
              <TaRaActiveRecruitment2022Section />
            </div>
            <div ref={(ref) => (tippSectionRef = ref)}>
              <TippSection />
            </div>
            <div ref={(ref) => (academicActivityScholarshipSectionRef = ref)}>
              <AcademicActivityScholarshipSection />
            </div>
            <div ref={(ref) => (newGraduateStudentScholarshipSectionRef = ref)}>
              <NewGraduateStudentScholarshipSection />
            </div>
            <div ref={(ref) => (englishUpskillSectionRef = ref)}>
              <EnglishUpskillSection />
            </div>
            <div className="m-auto mt-[30px] flex flex-row justify-center">
              <BackToTopButton />
            </div>
          </Suspense>
        </Container>
      </CustomContainer>
    </Layout>
  );
};

export default ScholarshipPage;
