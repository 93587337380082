import { ReactComponent as UpArrow } from '../../../assets/up-arrow.svg';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';

const VoiceOfCustomerButton = () => {
  const { t, getFontFamily } = useTranslationUtil();

  const onClick = () => {
    window.open('https://voc.cmu.ac.th/Choose.aspx', '_blank', 'noopener,noreferrer');
  };
  return (
    <button className="mt-[16px]" onClick={onClick}>
      <div className="flex   flex-row justify-center border-[2px] border-solid border-NeonPink px-5">
        <div className={`${getFontFamily(true)} flex flex-col justify-center font-bold leading-[55px] text-NeonPink`}>
          {t('VoiceOfCustomer')}
        </div>
        <div className="ml-[10px] flex rotate-90 flex-col justify-center leading-[55px]">
          <UpArrow />
        </div>
      </div>
    </button>
  );
};

export default VoiceOfCustomerButton;
