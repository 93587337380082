import { useCallback, useState } from 'react';

const ACCESS_TOKEN_KEY = 'access_token';

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const saveAccessToken = (token) => {
  return localStorage.setItem(ACCESS_TOKEN_KEY, token);
};

export const clearToken = () => {
  localStorage.clear();
};

export const useToken = () => {
  const [tokenState, setTokenState] = useState({
    accessToken: getAccessToken(),
  });

  const setToken = useCallback((accessToken) => {
    setTokenState({
      accessToken,
    });
    saveAccessToken(accessToken || '');
  }, []);

  const removeToken = useCallback(() => {
    setTokenState({ accessToken: '' });
    clearToken();
  }, []);

  return {
    setToken,
    removeToken,
    token: tokenState,
  };
};
