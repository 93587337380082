import React from 'react';

const IconWrapper = ({ children, disabled = false, onClick = () => {} }) => (
  <div
    onClick={() => {
      if (!disabled) {
        onClick();
      }
    }}
    className={`rounded-sm py-2 px-2  ${
      disabled
        ? 'text-Lightgray200 hover:cursor-not-allowed'
        : 'text-Lightgray700 hover:cursor-pointer hover:bg-Lightgray200'
    }`}
  >
    {children}
  </div>
);

export default IconWrapper;
