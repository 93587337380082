import okrImage1 from './assets/การประชุมพื่อทบทวนตัวชี้วัดคำรับรองการปฏิบัติงานของส่วนงาน (ตัวชี้วัด OKRs) ประจำปี 2565/okrs (1).jpg';
import okrImage2 from './assets/การประชุมพื่อทบทวนตัวชี้วัดคำรับรองการปฏิบัติงานของส่วนงาน (ตัวชี้วัด OKRs) ประจำปี 2565/okrs (2).jpg';
import okrImage3 from './assets/การประชุมพื่อทบทวนตัวชี้วัดคำรับรองการปฏิบัติงานของส่วนงาน (ตัวชี้วัด OKRs) ประจำปี 2565/okrs (3).jpg';
import okrImage4 from './assets/การประชุมพื่อทบทวนตัวชี้วัดคำรับรองการปฏิบัติงานของส่วนงาน (ตัวชี้วัด OKRs) ประจำปี 2565/okrs (4).jpg';
import okrImage5 from './assets/การประชุมพื่อทบทวนตัวชี้วัดคำรับรองการปฏิบัติงานของส่วนงาน (ตัวชี้วัด OKRs) ประจำปี 2565/okrs (5).jpg';
import okrImage6 from './assets/การประชุมพื่อทบทวนตัวชี้วัดคำรับรองการปฏิบัติงานของส่วนงาน (ตัวชี้วัด OKRs) ประจำปี 2565/okrs (6).jpg';
import singCrossInstitutionalImage1 from './assets/พิธีลงนามบันทึกความเข้าใจการลงทะเบียนเรียนข้ามสถาบัน/1 (1).jpg';
import singCrossInstitutionalImage2 from './assets/พิธีลงนามบันทึกความเข้าใจการลงทะเบียนเรียนข้ามสถาบัน/1 (2).jpeg';
import singCrossInstitutionalImage3 from './assets/พิธีลงนามบันทึกความเข้าใจการลงทะเบียนเรียนข้ามสถาบัน/1 (3).jpeg';
import graduateStudentMeetExecutivesImage1 from './assets/สโมสรนักศึกษาบัณฑิตศึกษา พบปะ ผู้บริหาร/1.jpg';
import graduateStudentMeetExecutivesImage2 from './assets/สโมสรนักศึกษาบัณฑิตศึกษา พบปะ ผู้บริหาร/2.jpg';
import graduateStudentMeetExecutivesImage3 from './assets/สโมสรนักศึกษาบัณฑิตศึกษา พบปะ ผู้บริหาร/3.jpg';
import graduateStudentMeetExecutivesImage4 from './assets/สโมสรนักศึกษาบัณฑิตศึกษา พบปะ ผู้บริหาร/4.jpg';
import graduateStudentMeetExecutivesImage5 from './assets/สโมสรนักศึกษาบัณฑิตศึกษา พบปะ ผู้บริหาร/5.jpg';
import graduateStudentMeetExecutivesImage6 from './assets/สโมสรนักศึกษาบัณฑิตศึกษา พบปะ ผู้บริหาร/6.jpg';
import graduateStudentMeetExecutivesImage7 from './assets/สโมสรนักศึกษาบัณฑิตศึกษา พบปะ ผู้บริหาร/7.jpg';

export const OPEN_DATA_NEWS_KEY = {
  okrs: 'okrs',
  crossInstitutional: 'cross-institutional-registration',
  graduateStudentMeetExecutives: 'graduate-student-clubs-meet-with-executives',
};

export const openDataNews = {
  [OPEN_DATA_NEWS_KEY.graduateStudentMeetExecutives]: {
    title: 'สโมสรนักศึกษาบัณฑิตศึกษา พบปะ ผู้บริหารฯ',
    images: [
      graduateStudentMeetExecutivesImage1,
      graduateStudentMeetExecutivesImage2,
      graduateStudentMeetExecutivesImage3,
      graduateStudentMeetExecutivesImage4,
      graduateStudentMeetExecutivesImage5,
      graduateStudentMeetExecutivesImage6,
      graduateStudentMeetExecutivesImage7,
    ],
    description:
      'รองศาสตราจารย์ ดร.อภิชาต โสภาแดง คณบดีบัณฑิตวิทยาลัย พร้อมด้วยผู้บริหารและบุคลากร ให้การต้อนรับคณะกรรมการบริหารสโมสรนักศึกษาบัณฑิตศึกษา มหาวิทยาลัยเชียงใหม่ เนื่องในโอกาสขอเข้าพบปะ ผู้บริหาร เพื่อปรึกษาหารือแนวการทางการดำเนินกิจกรรมเสริมหลักสูตร กิจกรรมกีฬาระดับบัณฑิตศึกษา และรับนโยบายและแผนการดำเนินงาน ระยะ 4 ปี ของบัณฑิตวิทยาลัย ในวันศุกร์ที่ 21 มกราคม 2565 เวลา 09.00 น. ณ ห้องประชุม 1-101 ชั้น 1 อาคารบัณฑิตวิทยาลัย มหาวิทยาลัยเชียงใหม่',
    createdAt: '2022-01-03',
    publisher: 'panupong grad',
  },
  [OPEN_DATA_NEWS_KEY.crossInstitutional]: {
    title: 'พิธีลงนามบันทึกความเข้าใจการลงทะเบียนเรียนข้ามสถาบัน',
    images: [singCrossInstitutionalImage1, singCrossInstitutionalImage2, singCrossInstitutionalImage3],
    description:
      'รองศาสตราจารย์ ดร.อภิชาต โสภาแดง คณบดีบัณฑิตวิทยาลัย ผู้แทนอธิการบดี มหาวิทยาลัยเชียงใหม่ เข้าร่วมประชุมพิธีลงนามบันทึกความเข้าใจการลงทะเบียนเรียนข้ามสถาบัน ระหว่างสถาบันสมาชิกที่ประชุมคณะผู้บริหารบัณฑิตศึกษามหาวิทยาลัยของรัฐและมหาวิทยาลัยในกํากับของรัฐ (ทคบร.) จำนวน 25 สถาบัน โดยมี ศาสตราจารย์ ดร.นพ.สิริฤกษ์ ทรงศิวิไล ปลัดกระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม เป็นประธานในพิธี และ ศาสตราจารย์ ดร.พญ.พัชรีย์ เลิศฤทธิ์ คณบดีบัณฑิตวิทยาลัย มหาวิทยาลัยมหิดล ประธานที่ประชุมคณะผู้บริหารบัณฑิตศึกษามหาวิทยาลัยของรัฐ และมหาวิทยาลัยในกํากับของรัฐ (ทคบร.) ร่วมเป็นพยานลงนาม ในวันพฤหัสบดีที่ 13 มกราคม 2565 ผ่านระบบออนไลน์ Zoom Meeting',
    createdAt: '2022-01-21',
    publisher: 'panupong grad',
  },

  [OPEN_DATA_NEWS_KEY.okrs]: {
    title: 'การประชุมพื่อทบทวนตัวชี้วัดคำรับรองการปฏิบัติงานของส่วนงาน (ตัวชี้วัด OKRs) ประจำปี 2565 ',
    images: [okrImage1, okrImage2, okrImage3, okrImage4, okrImage5, okrImage6],
    description:
      'รองศาสตราจารย์ ดร.อภิชาต โสภาแดง คณบดีบัณฑิตวิทยาลัย นำทีมผู้บริหาร หัวหน้างานและบุคลากรอาวุโส เพื่อทบทวนตัวชี้วัดคำรับรองการปฏิบัติงานของส่วนงาน (ตัวชี้วัด OKRs) ประจำปี 2565 เมื่อวันอาทิตย์ที่ 26 กันยายน 2564 ณ ห้องประชุมตะวัน กังวานพงศ์ ชั้น 4 อาคารยุทธศาสตร์ สำนักงานมหวิทยาลัย',
    createdAt: '2021-09-27',
    publisher: 'grad',
  },
};
