import React from 'react';
import CollapseItem from '../../components/CollapseSection/CollapseItem';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import กำหนดการประชุมประจำปี67 from './assets/calendar 2024 and curr submit date (แก้ไขกำหนดส่งหลักสูตร).pdf';
import กำหนดการประชุมประจำปี66 from './assets/5_annualmeeting2566.pdf';
import กำหนดการประชุมประจำปี65 from './assets/ปฏิทินการประชุมบัณฑิตวิทยาลัย-และมหาวิทย-1.pdf';
import กำหนดการประชุมประจำปี64 from './assets/กำหนดการประชุมประจำปี-64.pdf';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';

const AnnualMeetingSchedulePage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{t('กำหนดการประชุมประจำปี')}</PageTitleContainer>

        <div className=" mx-auto max-w-2xl">
          <CollapseItem pdfUrl={กำหนดการประชุมประจำปี67}>{t('กำหนดการประชุมประจำปี 2567')}</CollapseItem>
          <CollapseItem pdfUrl={กำหนดการประชุมประจำปี66}>{t('กำหนดการประชุมประจำปี 2566')}</CollapseItem>
          <CollapseItem pdfUrl={กำหนดการประชุมประจำปี65}>{t('กำหนดการประชุมประจำปี 2565')}</CollapseItem>
          <CollapseItem pdfUrl={กำหนดการประชุมประจำปี64}>{t('กำหนดการประชุมประจำปี 2564')}</CollapseItem>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default AnnualMeetingSchedulePage;
