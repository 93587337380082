import MainPage from './MainPage.json';
import NewsPage from './NewsPage.json';
import AboutPage from './AboutPage.json';
import OrganizationStructurePage from './OrganizationStructurePage';
import VisionAndMissionPage from './VisionAndMissionPage.json';
import DirectiveBoard from './DirectiveBoard.json';
import GraduateSchoolOffice from './GraduateSchoolOffice';
import Executive from './Executive.json';
import FactsAndFigure from './FactsAndFigure.json';
import ForeignLanguageCondition from './ForeignLanguageCondition.json';
import InterdisciplinaryPrograms from './InterdisciplinaryPrograms.json';
import EnrollmentAcademicCalendar from './EnrollmentAcademicCalendar.json';
import AnnouncementsRegulationsGuidelines from './AnnouncementsRegulationsGuidelines';
import AnnualMeetingSchedule from './AnnualMeetingSchedule.json';
import CourseTemplate from './CourseTemplate.json';
import CriteriaGraduation from './CriteriaGraduation.json';
import CurriculumGuidelines from './CurriculumGuidelines.json';
import GraduateStudentsAcceptance from './GraduateStudentsAcceptance.json';
import GraduateStudiesCommitteeAndAdministration from './GraduateStudiesCommitteeAndAdministration.json';
import Graduation from './Graduation.json';
import GraduationProposalDocumentsSchedule from './GraduationProposalDocumentsSchedule.json';
import GuidelineProcedure from './GuidelineProcedure.json';
import Lecturer from './Lecturer.json';
import RegulationsAnnouncementGuidelines from './RegulationsAnnouncementGuidelines.json';
import Thesis from './Thesis.json';
import ThesisAwardInGraduateSchoolCmu from './ThesisAwardInGraduateSchoolCmu.json';
import Visa from './Visa.json';
import QualifyApplication from './QualifyApplication.json';
import SearchByFaculty from './SearchByFaculty.json';
import Navigation from './Navigation.json';
import ProgramTutionFee from './ProgramTutionFee.json';
import ScholarShip from './Scholarship.json';
import DeanReport from './DeanReport.json';
import Prospectus from './Prospectus.json';
import CheckListOfJournals from './ChecklistOfJournals.json';
import LifeCMU from './LifeCMU.json';
import AnnualReport from './AnnualReport.json';
import Km from './Km.json';
import OpenData from './OpenData.json';
import PolicyPlan from './PolicyPlan.json';
import ContactUs from './ContactUs.json';
import CookieConsent from './CookieConsent.json';

const en = {
  translation: {
    ...MainPage,
    ...NewsPage,
    ...AboutPage,
    ...OrganizationStructurePage,
    ...VisionAndMissionPage,
    ...DirectiveBoard,
    ...GraduateSchoolOffice,
    ...Executive,
    ...FactsAndFigure,
    ...ForeignLanguageCondition,
    ...InterdisciplinaryPrograms,
    ...EnrollmentAcademicCalendar,
    ...AnnouncementsRegulationsGuidelines,
    ...AnnualMeetingSchedule,
    ...CourseTemplate,
    ...CriteriaGraduation,
    ...CurriculumGuidelines,
    ...GraduateStudentsAcceptance,
    ...GraduateStudiesCommitteeAndAdministration,
    ...Graduation,
    ...GraduationProposalDocumentsSchedule,
    ...GuidelineProcedure,
    ...Lecturer,
    ...RegulationsAnnouncementGuidelines,
    ...Thesis,
    ...ThesisAwardInGraduateSchoolCmu,
    ...Visa,
    ...QualifyApplication,
    ...SearchByFaculty,
    ...Navigation,
    ...ProgramTutionFee,
    ...ScholarShip,
    ...DeanReport,
    ...Prospectus,
    ...CheckListOfJournals,
    ...LifeCMU,
    ...AnnualReport,
    ...Km,
    ...OpenData,
    ...PolicyPlan,
    ...ContactUs,
    ...CookieConsent,
  },
};

export default en;
