import React from 'react';
import coverImage from './assets/cover.png';
import PageTitleContainerWithBackgroundImage from '../PageTitleContainerWithBackgroundImage';
import GraduateStaffPageTitle from './GraduateStaffPageTitle';

const PageTitleGraduateStaff = ({ title }) => {
  return (
    <PageTitleContainerWithBackgroundImage coverImage={coverImage} mixBlendMode=" mix-blend-overlay">
      <GraduateStaffPageTitle title={title} />
    </PageTitleContainerWithBackgroundImage>
  );
};

export default PageTitleGraduateStaff;
