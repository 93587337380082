import React from 'react';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import MoreDetailButton from '../../components/Button/MoreDetailButton';
import IsiCover from './assets/ISI-Database.jpg';
import ScopusCover from './assets/Scopus-Database.jpg';
import SjrCover from './assets/SJR.jpg';
import TciCover from './assets/TCI.jpg';
import JournalsThailandCover from './assets/Journals-Thailand.jpg';
import AcademicJournalsCover from './assets/List-of-academic-journals.jpg';
import ListOfAcademicJournalPdf from './assets/list-of-academic-journals.pdf';
import CircleGradient from './assets/circle-gradient.svg';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';

const ChecklistJournalCard = ({ coverImg, title, detail, externalUrl }) => {
  const { t, getFontFamily } = useTranslationUtil();
  const fontFamily = getFontFamily();
  // const [isHover, setIsHover] = useState(false);

  const onClick = () => {
    if (externalUrl) {
      window.open(externalUrl, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div
      className="z-10  mx-auto my-3 h-[460px] w-[252px] gap-7 rounded-sm bg-UIWhite p-4 shadow-sm duration-300 hover:cursor-pointer hover:bg-SecondaryLight md:my-10"
      // onMouseEnter={() => setIsHover(true)}
      // onMouseLeave={() => setIsHover(false)}
      onClick={onClick}
    >
      <div className=" h-full  ">
        <img src={coverImg} alt={title} className="rounded-sm " />

        <div className="m-auto justify-center">
          <div className={`${fontFamily}  my-2  text-[32px] font-medium leading-10 text-Lightgray700`}>{title}</div>
          {/* {isHover && <div className={`${fontFamily} leading-[26px] text-Lightgray700`}>{detail}</div>} */}

          <MoreDetailButton text={t('Details')} onClick={onClick} />
        </div>
      </div>
    </div>
  );
};

const CheckListOfJournalsPage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{t('Nav_ChecklistofJournals')}</PageTitleContainer>
        <div className="bg-Lightgray50 ">
          <div className="z-10 grid grid-cols-1 py-10 md:grid-cols-3">
            <ChecklistJournalCard
              coverImg={IsiCover}
              title={t('IsiDatabase')}
              detail={t('IsiDatabaseDetail')}
              externalUrl="https://jcr.clarivate.com/jcr/home"
            />
            <ChecklistJournalCard
              coverImg={ScopusCover}
              title={t('ScopusDatabase')}
              detail={t('ScopusDatabaseDetail')}
              externalUrl="https://www.scopus.com/home.uri"
            />
            <ChecklistJournalCard
              coverImg={SjrCover}
              title={t('SJRDatabase')}
              detail={t('SJRDatabaseDetail')}
              externalUrl="https://www.scimagojr.com/"
            />
            <ChecklistJournalCard
              coverImg={TciCover}
              title={t('TCIDatabase')}
              detail={t('TCIDatabaseDetail')}
              externalUrl="https://tci-thailand.org/list%20journal.php"
            />
            <ChecklistJournalCard
              coverImg={JournalsThailandCover}
              title={t('JournalsThailand')}
              detail={t('JournalsThailandDetail')}
              externalUrl="https://jcr.clarivate.com/jcr/home"
            />
            <ChecklistJournalCard
              coverImg={AcademicJournalsCover}
              title={t('ListOfAcademicJournals')}
              detail={t('ListOfAcademicJournalsDetail')}
              externalUrl={ListOfAcademicJournalPdf}
            />
          </div>
          <div className="-z-10 -mt-[570px] flex flex-row justify-end">
            <img src={CircleGradient} alt="" className="z-0" />
          </div>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default CheckListOfJournalsPage;
