import React from 'react';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';

const CourseStructor = ({ url }) => {
  const { t } = useTranslationUtil();
  return (
    <div className=" mt-4 text-NeonPink">
      <a className=" text-NeonPink" href={url} target="_blank" rel="noreferrer">
        {t('CoursesStructure')}
      </a>
    </div>
  );
};

export default CourseStructor;
