import createRouteUrlProvider from '../utils/routeUrlProvider';

export const HOME = 'HOME';
export const DASHBOARD = 'DASHBOARD';
export const LOGIN = 'LOGIN';
export const INFO = 'INFO';
export const FOREIGN_LANGUAGE_CONDITION = 'FOREIGN_LANGUAGE_CONDITION';
export const NEWS = 'NEWS';
export const POSTER = 'POSTER';
export const NEWS_DETAIL = 'NEWS_DETAIL';
export const ABOUT = 'ABOUT';
export const ORGANIZATION_STRUCTURE = 'ORGANIZATION_STRUCTURE';
export const VISION_AND_MISION = 'VISION_MISION';
export const DIRECTIVE_BOARD = 'DIRECTIVE_BOARD';
export const EXECUTIVE_CALENDAR = 'EXECUTIVE_CALENDAR';
export const KM = 'KM';
export const OPEN_DATA = 'OPEN_DATA';
export const OPEN_DATA_2565 = 'OPEN_DATA_2565';
export const OPEN_DATA_2566 = 'OPEN_DATA_2566';
export const OPEN_DATA_NEWS = 'OPEN_DATA_NEWS';
export const GRADUATE_SCHOOL_TEAM = 'GRADUATE_SCHOOL_TEAM';
export const GRADUATE_SCHOOL_OFFICE = 'GRADUATE_SCHOOL_OFFICE';
export const GENERAL_ADMINISTRATION = 'GENERAL_ADMINISTRATION';
export const FINANCIAL_TREASURY_AND_INVENTORY = 'FINANCIAL_TREASURY_AND_INVENTORY';
export const POLICY_AND_PLANNING_SECTION = 'POLICY_AND_PLANNING_SECTION';
export const ACADEMIC_SERVICE = 'ACADEMIC_SERVICE';
export const STUDENT_DEVELOPMENT = 'STUDENT_DEVELOPMENT';
export const INTERDISCIPLINARY_PROGRAM_AND_QA = 'INTERDISCIPLINARY_PROGRAM_AND_QA';
export const FAQ = 'FAQ';
export const CONTACT_US = 'CONTACT_US';

export const EXECUTIVE = 'EXECUTIVE';
export const FACTS_AND_FIGURES = 'FACTS_AND_FIGURES';
export const ANNUA_REPORT = 'ANNUA_REPORT';
export const LIFE_CMU = 'LIFE_CMU';
export const PODCAST_LIVE = 'PODCAST_LIVE';
export const INTERDISCIPLINARY_PROGRAMS = 'INTERDISCIPLINARY_PROGRAMS';
export const PROGRAM_TUTION_FEE = 'PROGRAM_TUTION_FEE';
export const ADMISSION_PROCESS = 'ADMISSION_PROCESS';
export const INTERDISCIPLINARY_PROGRAMS_MAN_AND_ENVIRONMENTAL = 'INTERDISCIPLINARY_PROGRAMS_MAN_AND_ENVIRONMENTAL';
export const INTERDISCIPLINARY_PROGRAMS_BIOTECTHNOLOGY = 'INTERDISCIPLINARY_PROGRAMS_BIOTECTHNOLOGY';
export const INTERDISCIPLINARY_PROGRAMS_FORENSICS_SCIENCE = 'INTERDISCIPLINARY_PROGRAMS_FORENSICS_SCIENCE';
export const INTERDISCIPLINARY_PROGRAMS_SPORT_SCIENCE = 'INTERDISCIPLINARY_PROGRAMS_SPORT_SCIENCE';
export const INTERDISCIPLINARY_PROGRAMS_TECHNOLOGY_AND_INTERDISCIPLINARY =
  'INTERDISCIPLINARY_PROGRAMS_TECHNOLOGY_AND_INTERDISCIPLINARY';
export const INTERDISCIPLINARY_PROGRAMS_MENTAL_HEALTH = 'INTERDISCIPLINARY_PROGRAMS_MENTAL_HEALTH';
export const INTERDISCIPLINARY_PROGRAMS_CONTEMPORARY_CHINESE_LANGUAGE_TEACHING =
  'INTERDISCIPLINARY_PROGRAMS_CONTEMPORARY_CHINESE_LANGUAGE_TEACHING';
export const INTERDISCIPLINARY_PROGRAMS_INTEGRATED_SCIENCE = 'INTERDISCIPLINARY_PROGRAMS_INTEGRATED_SCIENCE';
export const POLICY_AND_PLAN = 'POLICY_AND_PLAN';
export const ENROLLMENT_ACADEMIC_CALENDAR = 'ENROLLMENT_ACADEMIC_CALENDAR';
export const REGULATIONS_ANNOUNCEMENT_GUIDELINES = 'REGULATIONS_ANNOUNCEMENT_GUIDELINES';
export const THESIS = 'THESIS';
export const THESIS_AWARD_IN_GRADUATE_SCHOOL_CMU = 'THESIS_AWARD_IN_GRADUATE_SCHOOL_CMU';
export const GRADUATION = 'GRADUATION';
export const GUIDELINE_PROCEDURE = 'GUIDELINE_PROCEDURE';
export const VISA = 'VISA';
export const QUALIFY_APPLICATION = 'QUALIFY_APPLICATION';
export const GRADUATE_STUDIES_COMMITTEE_AND_ADMINISTRATION = 'GRADUATE_STUDIES_COMMITTEE_AND_ADMINISTRATION';
export const ANNOUNCEMENTS_REGULATIONS_GUIDELINES = 'ANNOUNCEMENTS_REGULATIONS_GUIDELINES';
export const CURRICULUM_GUIDELINES = 'CURRICULUM_GUIDELINES';
export const COURSE_TEMPLATE = 'COURSE_TEMPLATE';
export const GRADUATE_STUDENTS_ACCEPTANCE = 'GRADUATE_STUDENTS_ACCEPTANCE';
export const CRITERIA_GRADUATION = 'CRITERIA_GRADUATION';
export const LECTURER = 'LECTURER';
export const ANNUAL_MEETING_SCHEDULE = 'ANNUAL_MEETING_SCHEDULE';
export const GRADUATION_PROPOSAL_DOCUMENTS_SCHEDULE = 'GRADUATION_PROPOSAL_DOCUMENTS_SCHEDULE';
export const SEARCH_BY_FACULTY = 'SEARCH_BY_FACULTY';
export const SCHOLARSHIP = 'SCHOLARSHIP';
export const DEAN_REPORT = 'DEAN_REPORT';
export const PROSPECTUS = 'PROSPECTUS';
export const CHECKLIST_OF_JOURNALS = 'CHECKLIST_OF_JOURNALS';
export const THESIS_DISSERTATION_IS_CHECKING = 'THESIS_DISSERTATION_IS_CHECKING';

export class AdminRoutePath {
  static ADMIN_LOGIN = 'ADMIN_LOGIN';
  static ADMIN_NEWS_LIST = 'ADMIN_NEWS_LIST';
  static ADMIN_NEWS_CREATE = 'ADMIN_NEWS_CREATE';
  static ADMIN_NEWS_EDIT = 'ADMIN_NEWS_EDIT';
  static ADMIN_BANNER_LIST = 'ADMIN_BANNER_LIST';
  static ADMIN_BANNER_ADD = 'ADMIN_BANNER_ADD';
  static ADMIN_BANNER_EDIT = 'ADMIN_BANNER_EDIT';
  static ADMIN_POSTER_LIST = 'ADMIN_POSTER_LIST';
  static ADMIN_POSTER_ADD = 'ADMIN_POSTER_ADD';
  static ADMIN_LOGIN_REDIRECT = 'ADMIN_LOGIN_REDIRECT';
  static ADMIN_LOGIN_REDIRECT_GOOGLE = 'ADMIN_LOGIN_REDIRECT_GOOGLE';
}

const routeUrlProvider = createRouteUrlProvider();

routeUrlProvider.set(HOME, '/');
routeUrlProvider.set(DASHBOARD, '/dashboard');
// routeUrlProvider.set(LOGIN, '/login');
routeUrlProvider.set(INFO, '/info');
routeUrlProvider.set(FOREIGN_LANGUAGE_CONDITION, '/foreign-language-condition');
routeUrlProvider.set(NEWS, '/news');
routeUrlProvider.set(POSTER, '/posters');
routeUrlProvider.set(NEWS_DETAIL, '/news/:id');
routeUrlProvider.set(ABOUT, '/about');
routeUrlProvider.set(ORGANIZATION_STRUCTURE, '/organization-structure');
routeUrlProvider.set(VISION_AND_MISION, '/vision-and-mission');
routeUrlProvider.set(DIRECTIVE_BOARD, '/directive-board');
routeUrlProvider.set(KM, '/km');
routeUrlProvider.set(OPEN_DATA, '/open-data');
routeUrlProvider.set(OPEN_DATA_2565, '/open-data-2565');
routeUrlProvider.set(OPEN_DATA_2566, '/open-data-2566');
routeUrlProvider.set(OPEN_DATA_NEWS, '/open-data-news/:id');

routeUrlProvider.set(GRADUATE_SCHOOL_TEAM, '/graduate-school-team');
routeUrlProvider.set(GRADUATE_SCHOOL_OFFICE, '/graduate-school-office');
routeUrlProvider.set(GENERAL_ADMINISTRATION, '/general-administration');
routeUrlProvider.set(FINANCIAL_TREASURY_AND_INVENTORY, '/financial-treasury-and-inventory');
routeUrlProvider.set(POLICY_AND_PLANNING_SECTION, '/policy-and-planning-section');
routeUrlProvider.set(ACADEMIC_SERVICE, '/academic-service');
routeUrlProvider.set(STUDENT_DEVELOPMENT, '/student-development');
routeUrlProvider.set(INTERDISCIPLINARY_PROGRAM_AND_QA, '/interdisciplinary-program-and-qa');

routeUrlProvider.set(EXECUTIVE, '/executive');
routeUrlProvider.set(EXECUTIVE_CALENDAR, '/executive-calendar');
routeUrlProvider.set(FACTS_AND_FIGURES, '/facts-and-figure');
routeUrlProvider.set(ANNUA_REPORT, '/annual-report');
routeUrlProvider.set(LIFE_CMU, '/life-cmu');
routeUrlProvider.set(PODCAST_LIVE, '/podcast-live');
routeUrlProvider.set(INTERDISCIPLINARY_PROGRAMS, '/interdisciplinary-programs');
routeUrlProvider.set(PROGRAM_TUTION_FEE, '/program-tution-fee');
routeUrlProvider.set(ADMISSION_PROCESS, '/admission-process');
routeUrlProvider.set(
  INTERDISCIPLINARY_PROGRAMS_MAN_AND_ENVIRONMENTAL,
  '/interdisciplinary-programs/man-and-environmental-management-page'
);
routeUrlProvider.set(INTERDISCIPLINARY_PROGRAMS_BIOTECTHNOLOGY, '/interdisciplinary-programs/biotecthnology');
routeUrlProvider.set(INTERDISCIPLINARY_PROGRAMS_FORENSICS_SCIENCE, '/interdisciplinary-programs/forensics-science');
routeUrlProvider.set(INTERDISCIPLINARY_PROGRAMS_SPORT_SCIENCE, '/interdisciplinary-programs/sport-science');
routeUrlProvider.set(
  INTERDISCIPLINARY_PROGRAMS_TECHNOLOGY_AND_INTERDISCIPLINARY,
  '/interdisciplinary-programs/technology'
);
routeUrlProvider.set(INTERDISCIPLINARY_PROGRAMS_MENTAL_HEALTH, '/interdisciplinary-programs/mental-health');
routeUrlProvider.set(
  INTERDISCIPLINARY_PROGRAMS_CONTEMPORARY_CHINESE_LANGUAGE_TEACHING,
  '/interdisciplinary-programs/contemporary-chinese-language-teaching'
);
routeUrlProvider.set(INTERDISCIPLINARY_PROGRAMS_INTEGRATED_SCIENCE, '/interdisciplinary-programs/integrated-science');
routeUrlProvider.set(POLICY_AND_PLAN, '/policy-and-plan');
routeUrlProvider.set(ENROLLMENT_ACADEMIC_CALENDAR, '/enrollment-academic-calendar');
routeUrlProvider.set(REGULATIONS_ANNOUNCEMENT_GUIDELINES, '/regulations-announcement-guidelines');
routeUrlProvider.set(THESIS, '/thesis');
routeUrlProvider.set(THESIS_AWARD_IN_GRADUATE_SCHOOL_CMU, '/thesis-award-in-graduate-school-cmu');
routeUrlProvider.set(GRADUATION, '/graduation');
routeUrlProvider.set(GUIDELINE_PROCEDURE, '/guideline-procedure');
routeUrlProvider.set(VISA, '/visa');
routeUrlProvider.set(QUALIFY_APPLICATION, '/qualify-application');

routeUrlProvider.set(GRADUATE_STUDIES_COMMITTEE_AND_ADMINISTRATION, '/graduate-studies-committee-and-administration');
routeUrlProvider.set(ANNOUNCEMENTS_REGULATIONS_GUIDELINES, '/announcements-regulations-guidelines');
routeUrlProvider.set(CURRICULUM_GUIDELINES, '/curriculum-guidelines');
routeUrlProvider.set(COURSE_TEMPLATE, '/course-template');
routeUrlProvider.set(GRADUATE_STUDENTS_ACCEPTANCE, '/graduate-students-acceptance');
routeUrlProvider.set(CRITERIA_GRADUATION, '/criteria-graduation');
routeUrlProvider.set(LECTURER, '/lecturer');
routeUrlProvider.set(ANNUAL_MEETING_SCHEDULE, '/annual-meeting-schedule');
routeUrlProvider.set(GRADUATION_PROPOSAL_DOCUMENTS_SCHEDULE, '/graduation-proposal-documents-schedule');
routeUrlProvider.set(SEARCH_BY_FACULTY, '/search-by-faculty');
routeUrlProvider.set(SCHOLARSHIP, '/scholarship');
routeUrlProvider.set(DEAN_REPORT, '/deans-report');
routeUrlProvider.set(PROSPECTUS, '/prospectus');
routeUrlProvider.set(CHECKLIST_OF_JOURNALS, '/checklist-of-journals');
routeUrlProvider.set(THESIS_DISSERTATION_IS_CHECKING, '/thesis-dissertation-is-checking');
routeUrlProvider.set(CONTACT_US, '/contact-us');

routeUrlProvider.set(AdminRoutePath.ADMIN_LOGIN, '/admin/login');
routeUrlProvider.set(AdminRoutePath.ADMIN_NEWS_LIST, '/admin/news');
routeUrlProvider.set(AdminRoutePath.ADMIN_NEWS_CREATE, '/admin/news/create');
routeUrlProvider.set(AdminRoutePath.ADMIN_NEWS_EDIT, '/admin/news/edit/:id');
routeUrlProvider.set(AdminRoutePath.ADMIN_BANNER_LIST, '/admin/banners');
routeUrlProvider.set(AdminRoutePath.ADMIN_BANNER_ADD, '/admin/banners/add');
routeUrlProvider.set(AdminRoutePath.ADMIN_BANNER_EDIT, '/admin/banners/edit/:id');
routeUrlProvider.set(AdminRoutePath.ADMIN_POSTER_LIST, '/admin/posters');
routeUrlProvider.set(AdminRoutePath.ADMIN_POSTER_ADD, '/admin/posters/add');
routeUrlProvider.set(AdminRoutePath.ADMIN_LOGIN_REDIRECT, '/admin/login/callback');
routeUrlProvider.set(AdminRoutePath.ADMIN_LOGIN_REDIRECT_GOOGLE, '/admin/login/callback/google');

export default routeUrlProvider;
