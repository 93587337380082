import React from 'react';
import CollapseSection from '../../components/CollapseSection';
import CollapseItem from '../../components/CollapseSection/CollapseItem';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import PageTitleContainer from '../../components/PageTitleContainer';
import CollapseSectionContainer from '../../components/CollapseSection/CollapseSectionContainer';
import graduatedLetterform from './assets/graduatedLetter/แบบฟอร์มนักศึกษาจบ-14-6-2562.doc';
import TransferringStudents from './assets/changingProgram/1.1-TransferringStudents-12-55-เปลี่ยนแผน.pdf';
import FMASA03Flow from './assets/changingProgram/FM-AS-A-03-Flow-ย้ายสาขาวิชาภายในคณะ-เปล่นแผนการศึก.doc';
import GS2014F106TransferProgram from './assets/changingProgram/6-GS-2014-F106-TransferProgramย้ายสาขาภายในคณะ.doc';
import GSForm109ย้ายสาขาวิชาระหว่างคณะ from './assets/transferProgram/1-GS-Form109ย้ายสาขาวิชาระหว่างคณะ.doc';
import การขอย้ายสาขาวิชา from './assets/transferDegree/1-P-การขอย้ายสาขาวิชา.doc';
import GSForm107ย้ายสาขาวิชาระหว่างคณะ from './assets/transferDegree/2-GS-Form107-request-form-for-tranferring.doc';
import leave from './assets/takingLeave/4-leave.pdf';
import RequestLeaveStep from './assets/takingLeave/4-P-การลาพัก-Request_Leave_step.pdf';
import GS2014F105Leaveล from './assets/takingLeave/5-GS-2014-F105-Leaveลาพัก.doc';
import resign from './assets/resigning/5-resign.pdf';
import AnnouncementResignation from './assets/resigning/20170403-CMU-GS-Announcement-about-Resignation-ลาอก-1.pdf';
import GS2014F100General from './assets/resigning/3-GS-2014-F100-Generalคำร้องทั่วไป.doc';
import StudentStatusTermination from './assets/termination/6-Student-status-termination.pdf';
import AnnouncementStudentStatusRestoration from './assets/termination/20160411-CMU-GS-Announcement-on-Student-Status-Restoration-คืนสภาพ-1.pdf';
import Restoration from './assets/regaining/7-Restoration.pdf';
import StudyPlan from './assets/duration/8-Study-Plan.pdf';
import Extension from './assets/extension/9-Extension-8-55-2.pdf';
import GS2014F103Extension from './assets/extension/4-GS-2014-F103-Extensionขยายเวลาการศึกษา.doc';
import GSF0022015Progress from './assets/report/7-GS-F002-2015-Progressรายงานความก้าวหน้า.doc';

const GuidelineProcedurePage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <PageTitleContainer>{t('GuidelineProcedure')}</PageTitleContainer>

      <CollapseSectionContainer>
        <CollapseSection title={t('Graduated Letter')}>
          <CollapseItem pdfUrl={graduatedLetterform}>{t('MemoMessageToProposeGraduation')}</CollapseItem>
        </CollapseSection>

        <CollapseSection title={t('Changing Program/Type/Plan of Study')}>
          <CollapseItem pdfUrl={TransferringStudents}>{t('Announcement')}</CollapseItem>
          <CollapseItem pdfUrl={FMASA03Flow}>{t('Procedure')}</CollapseItem>
          <CollapseItem pdfUrl={GS2014F106TransferProgram}>{t('Download Form')}</CollapseItem>
        </CollapseSection>

        <CollapseSection title={t('Transfer to Another Program in Different Faculty')}>
          <CollapseItem pdfUrl={TransferringStudents}>{t('Announcement')}</CollapseItem>
          <CollapseItem pdfUrl={การขอย้ายสาขาวิชา}>{t('Procedure')}</CollapseItem>
          <CollapseItem pdfUrl={GSForm109ย้ายสาขาวิชาระหว่างคณะ}>{t('Download Form')}</CollapseItem>
        </CollapseSection>

        <CollapseSection title={t('Transferring Degree Program')}>
          <CollapseItem pdfUrl={TransferringStudents}>{t('Announcement')}</CollapseItem>
          <CollapseItem pdfUrl={การขอย้ายสาขาวิชา}>{t('Procedure')}</CollapseItem>
          <CollapseItem pdfUrl={GSForm107ย้ายสาขาวิชาระหว่างคณะ}>{t('Download Form')}</CollapseItem>
        </CollapseSection>

        <CollapseSection title={t('Taking Leave or Maintaining Student Status')}>
          <CollapseItem pdfUrl={leave}>{t('Announcement')}</CollapseItem>
          <CollapseItem pdfUrl={RequestLeaveStep}>{t('Procedure')}</CollapseItem>
          <CollapseItem pdfUrl={GS2014F105Leaveล}>{t('Download Form')}</CollapseItem>
        </CollapseSection>

        <CollapseSection title={t('Resigning from Graduate Studies')}>
          <CollapseItem pdfUrl={resign}>{t('Announcement')}</CollapseItem>
          <CollapseItem pdfUrl={AnnouncementResignation}>{t('Procedure')}</CollapseItem>
          <CollapseItem pdfUrl={GS2014F100General}>{t('Download Form')}</CollapseItem>
        </CollapseSection>

        <CollapseSection title={t('Student Status Termination')}>
          <CollapseItem pdfUrl={StudentStatusTermination}>{t('Announcement')}</CollapseItem>
          <CollapseItem pdfUrl={AnnouncementStudentStatusRestoration}>{t('Procedure')}</CollapseItem>
        </CollapseSection>

        <CollapseSection title={t('Regaining Graduate School Student Status')}>
          <CollapseItem pdfUrl={Restoration}>{t('Announcement')}</CollapseItem>
          <CollapseItem pdfUrl={GS2014F100General}>{t('Download Form')}</CollapseItem>
        </CollapseSection>

        <CollapseSection title={t('Duration of Study')}>
          <CollapseItem pdfUrl={StudyPlan}>{t('Study Plan')}</CollapseItem>
        </CollapseSection>

        <CollapseSection title={t('Extension of study for student before code 59')}>
          <CollapseItem pdfUrl={Extension}>{t('Announcement')}</CollapseItem>
          <CollapseItem pdfUrl={GS2014F103Extension}>{t('Download Form')}</CollapseItem>
        </CollapseSection>

        <CollapseSection title={t('Progress of study report form')}>
          <CollapseItem pdfUrl={GSF0022015Progress}>{t('Download Form')}</CollapseItem>
        </CollapseSection>

        <CollapseSection title={t('General form')}>
          <CollapseItem pdfUrl={GS2014F100General}>{t('Download Form')}</CollapseItem>
        </CollapseSection>
      </CollapseSectionContainer>
    </Layout>
  );
};

export default GuidelineProcedurePage;
