import React from 'react';
import CMUPurpleUnderLine80x8 from '../../components/CMUPurpleUnderLine';
import DartIcon from '../../components/Icons/DartIcon';
import ShieldPeopleIcon from '../../components/Icons/ShieldPeopleIcon';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import coverImage from '../../components/GraduateStaffPageComponent/assets/cover.png';
import Image1 from '../../components/GraduateStaffPageComponent/assets/front-view-grad.png';
import NipapornRaksapakdee from './assets/NipapornRaksapakdee.png';
import ChiraphornRattanavisutamorn from './assets/ChiraphornRattanavisutamorn.png';
import TossapolPumdit from './assets/TossapolPumdit.png';
import DutyItem from '../../components/GraduateStaffPageComponent/DutyItem';
import DutyItemIcon from '../../components/GraduateStaffPageComponent/DutyItemIcon';
import DutyItemText from '../../components/GraduateStaffPageComponent/DutyItemText';
import ProfileContainer from '../../components/GraduateStaffPageComponent/ProfileContainer';
import ProfileImage from '../../components/GraduateStaffPageComponent/ProfileImage';
import ProfileDetailContainer from '../../components/GraduateStaffPageComponent/ProfileDetailContainer';
import ProfileName from '../../components/GraduateStaffPageComponent/ProfileName';
import ProfilePosition from '../../components/GraduateStaffPageComponent/ProfilePosition';
import List from '../../components/GraduateStaffPageComponent/List';
import ListItem from '../../components/GraduateStaffPageComponent/ListItem';
import Phone from '../../components/GraduateStaffPageComponent/Phone';
import Email from '../../components/GraduateStaffPageComponent/Email';
import CustomContainer from '../../components/CustomContainer';
import PageTitleGraduateStaff from '../../components/GraduateStaffPageComponent/PageTitleGraduateStaff';
import ProfileList from '../../components/GraduateStaffPageComponent/ProfileList';
import StaffTeamTitle from '../../components/GraduateStaffPageComponent/StaffTeamTitle';
import GraduateStaffPageContainer from '../../components/GraduateStaffPageComponent/GraduateStaffPageContainer';
import DutyContainer from '../../components/GraduateStaffPageComponent/DutyContainer';
import FrontViewGradSchool from '../../components/GraduateStaffPageComponent/FrontViewGradSchool';

const PolicyandPlanningSectionPage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout headerTransparent removeSpaceBeforeFooterDivider>
      <CustomContainer>
        <PageTitleGraduateStaff title={t('PolicyandPlanning')} />
        <GraduateStaffPageContainer>
          {/* <FrontViewGradSchool mobile>
            {t('PolicyandPlanning')}, {t('chiang_mai_university')}
          </FrontViewGradSchool> */}
          {/* <DutyContainer>
            <div className="col-span-2">
              <div className="mt-7 mb-12 text-[32px] font-medium leading-10 text-Lightgray500">
                {t('duty')}
                <div className="mt-14px">
                  <CMUPurpleUnderLine80x8 />
                </div>
              </div>
              <DutyItem>
                <DutyItemIcon>
                  <ShieldPeopleIcon />
                </DutyItemIcon>
                <DutyItemText> {t('BudgetPlan')}</DutyItemText>
              </DutyItem>
              <DutyItem>
                <DutyItemIcon>
                  <DartIcon />
                </DutyItemIcon>
                <DutyItemText> {t('PolicyPlan')}</DutyItemText>
              </DutyItem>
            </div>

            <FrontViewGradSchool>
              {t('PolicyandPlanning')}, {t('chiang_mai_university')}
            </FrontViewGradSchool>
          </DutyContainer> */}
          <StaffTeamTitle>{t('PolicyandPlanningTeam')}</StaffTeamTitle>
          <ProfileList>
            <ProfileContainer>
              <ProfileImage src={NipapornRaksapakdee} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('NipapornRaksapakdee')}</ProfileName>
                <ProfilePosition>{t('OperationalStaffManager')}</ProfilePosition>
                <List>
                  <ListItem>
                    Preparation of Follow-up and Reporting of the Risk Management Plan of the Graduate School
                  </ListItem>
                  <ListItem>Preparation of Budget for Expenditure in Advance</ListItem>
                  <ListItem>Manpower Plan</ListItem>
                  <ListItem>
                    System Development and Information Technology Services and Information Technology Services
                  </ListItem>
                  <ListItem>Providing Audiovisual Equipment</ListItem>
                  <ListItem>Create an Annual Operating Plan</ListItem>
                  <ListItem>Review the Educational Development Plan for the Graduate School</ListItem>
                  <ListItem>Risk Management Plan</ListItem>
                  <ListItem>Manage Information in the Cmu E-planning System</ListItem>
                </List>
                <Phone>0-5394-2418</Phone>
                <Email>nipaporn.rak@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={ChiraphornRattanavisutamorn} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('ChiraphornRattanavisutamorn')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Budgeting and Budgeting Process</ListItem>
                  <ListItem>Follow Up and Report the Performance and Use of the Annual Expenditure Budget</ListItem>
                </List>
                <Phone>0-5394-2418</Phone>
                <Email>chiraphorn.rat@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={TossapolPumdit} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('TossapolPumdit')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Budgeting and Budgeting Process</ListItem>
                  <ListItem>Follow Up and Report the Performance and Use of the Annual Expenditure Budget</ListItem>
                </List>
                <Phone>0-5394-2418</Phone>
                <Email>tossapol.p@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
          </ProfileList>
        </GraduateStaffPageContainer>
      </CustomContainer>
    </Layout>
  );
};

export default PolicyandPlanningSectionPage;
