import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import routeUrlProvider, { AdminRoutePath, HOME } from '../../../constants/route-paths';
import { getListNewsAdmin, deleteNewsAdmin } from '../../../services/adminNews';
import ReactPaginate from 'react-paginate';
import { dropdownNewsTypes } from '../../../utils/news';

const NewsList = ({ history }) => {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [news, setNews] = useState([]);
  const [typeFilter, setTypeFilter] = useState('ALL');
  const perPage = 15;

  useEffect(() => { 
    getNewsPaginate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, typeFilter]);

  const newsTypes = Object.values(dropdownNewsTypes);

  const getNewsPaginate = () => {
    getListNewsAdmin({ newsTypeQuery: typeFilter, page, perPage })
      .then((res) => {
        setTotalPage(res.totalPage);
        setNews([...res.data]);
      })
      .catch((err) => console.log(' err: ', err));
  }

  const deleteNews = async (id) => {
    if (window.confirm("ต้องการจะลบข่าวสาร") == false) return

    await deleteNewsAdmin(id).then()

    getNewsPaginate()
  }

  const handlePageClick = (event) => {
    const selectingPage = event.selected + 1
    setPage(selectingPage)
  };

  const onTypeFilterChange = (event) => {
    setTypeFilter(event.target.value)
  }


  return (
    <Layout>
      <div className="text-center "> จัดการข่าวสาร </div>
      <div className="text-center " onClick={() => { history.push(routeUrlProvider.getForLink(AdminRoutePath.ADMIN_NEWS_CREATE)) }}> เพิ่ม </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full">
                <thead className="border-b">
                  <tr>
                    <th scope="col" className="text-sm font-medium px-6 py-4 text-left">
                      หัวข้อ
                    </th>
                    <th scope="col" className="text-sm font-medium px-6 py-4 text-left">
                      ประเภท
                      <select id="lang" onChange={onTypeFilterChange} value={typeFilter} className="ml-3 px-2 py-1">
                        <option value="ALL">All</option>
                          {newsTypes.map((el, index) => (
                            <option value={el.value} key={index}>{el.name}</option>
                          ))}
                      </select>
                    </th>
                    <th scope="col" className="text-sm font-medium px-6 py-4 text-left">
                      วันที่สร้าง
                    </th>
                    <th scope="col" className="text-sm font-medium px-6 py-4 text-left">
                      สร้างโดย
                    </th>
                    <th scope="col" className="text-sm font-medium px-6 py-4 text-left">
                    </th>
                    <th scope="col" className="text-sm font-medium px-6 py-4 text-left">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {news.map((newsData, index) => (
                    <tr key={index} className="border-b">
                      <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
                        {newsData.titleTh}
                      </td>
                      <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
                        {newsData.type}
                      </td>
                      <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
                        {new Date(newsData.createdAt).toDateString()}
                      </td>
                      <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
                        {newsData.createdByName}
                      </td>
                      <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
                        <div onClick={() => history.push(routeUrlProvider.getForLink(AdminRoutePath.ADMIN_NEWS_EDIT, {id: newsData.id}))}>แก้ไข</div>
                      </td>
                      <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
                        <div onClick={() => deleteNews(newsData.id)}>ลบ</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center">
        <div class="inline-flex items-center -space-x-px">
          <ReactPaginate
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            pageCount={totalPage}
            previousLabel="<"
            pageClassName="px-3 py-2 leading-tight hover:text-NeonPink"
            previousClassName="block px-3 py-2 ml-0 leading-tight rounded-l-lg hover:text-NeonPink"
            nextLabel=">"
            nextClassName="block px-3 py-2 leading-tight bg-white rounded-r-lg hover:text-NeonPink"
            breakLabel="..."
            breakClassName="px-3 py-2 leading-tight hover:text-NeonPink"
            containerClassName="inline-flex items-center -space-x-px"
            activeClassName="text-NeonPink"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </Layout>
  );
};

export default NewsList;
