import React from 'react';
import PhoneIcon from '../Icons/PhoneIcon';
const Phone = ({ children }) => (
  <div className="mt-6 flex flex-row">
    <div className="my-auto ml-1">
      <PhoneIcon />
    </div>
    <div className="my-auto ml-[11px] text-lg font-bold leading-6 text-PrimaryDark">{children}</div>
  </div>
);

export default Phone;
