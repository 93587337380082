import React from 'react';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import _1 from './assets/1.jpg';
import _2 from './assets/2.jpg';
import _3 from './assets/3.jpg';
import _4 from './assets/4.jpg';
import ThinkingImg from './assets/thinking.svg';
import CustomContainer from '../../components/CustomContainer';
import StepImage from '../../components/AddmissionProcess/StepImage';
import StepNumberCircle from '../../components/AddmissionProcess/StepNumberCircle';
import StepTitle from '../../components/AddmissionProcess/StepTitle';
import StepDescription from '../../components/AddmissionProcess/StepDescription';
import StepDetailContainer from '../../components/AddmissionProcess/StepDetailContainer';
import StepSectionContainer from '../../components/AddmissionProcess/StepSectionContainer';
import StepDescriptionContainer from '../../components/AddmissionProcess/StepDescriptionContainer';
import PageTitleContainer from '../../components/PageTitleContainer';

const ContentContainer = ({ children }) => <div className="px-3 md:px-10 lg:px-14 xl:px-20">{children}</div>;

const AdmissionProcessPagePage = () => {
  const { t } = useTranslationUtil();

  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{t('Nav_GraduateSchoolTeam')}</PageTitleContainer>
        <div className="lg:mx-auto lg:w-fit">
          <StepSectionContainer>
            <StepImage src={_1} alt="" className="lg:-mt-5 " />
            <StepDetailContainer>
              <StepNumberCircle>1</StepNumberCircle>
              <StepDescriptionContainer>
                <StepTitle>{t('Nav_GraduateSchoolOffice')}</StepTitle>
                <StepDescription>{t('ManagementAndAdministration')}</StepDescription>
                <StepDescription>{t('PlanWork')}</StepDescription>
                <StepDescription>{t('CreateFacultySchedule')}</StepDescription>
                <StepDescription>{t('ServerAndNetwork')}</StepDescription>
                <StepDescription>{t('ItService')}</StepDescription>
              </StepDescriptionContainer>
            </StepDetailContainer>
          </StepSectionContainer>
          <StepSectionContainer className="flex flex-row pt-0 pb-6">
            <StepImage src={_2} alt="" />
            <StepDetailContainer>
              <StepNumberCircle>2</StepNumberCircle>
              <StepDescriptionContainer>
                <StepTitle>{t('Nav_GeneralAdministration')}</StepTitle>
                <StepDescription>{t('GraduateStudentAdmission')}</StepDescription>
                <StepDescription>{t('Correspondence')}</StepDescription>
                <StepDescription>{t('HumanitiesResource')}</StepDescription>
                <StepDescription>{t('PremisesandVehiclesSubdivision')}</StepDescription>
              </StepDescriptionContainer>
            </StepDetailContainer>
          </StepSectionContainer>
          <StepSectionContainer className="flex flex-row py-0">
            <StepImage src={_3} alt="" />
            <StepDetailContainer>
              <StepNumberCircle>3</StepNumberCircle>
              <StepDescriptionContainer>
                <StepTitle>{t('Nav_FinancialTreasuryInventory')}</StepTitle>
                <StepDescription>{t('BudgetDisbusement')}</StepDescription>
                <StepDescription>{t('SchorlarshipDisbusement')}</StepDescription>
                <StepDescription>{t('AdvanceLoanDisburse')}</StepDescription>
                <StepDescription>{t('SalaryandWelfarePayment')}</StepDescription>
                <StepDescription>{t('FeePaymentService')}</StepDescription>
              </StepDescriptionContainer>
            </StepDetailContainer>
          </StepSectionContainer>
          <StepSectionContainer className="flex flex-row pt-0 pb-0">
            <StepImage src={_4} alt="" />
            <StepDetailContainer>
              <StepNumberCircle>4</StepNumberCircle>
              <StepDescriptionContainer>
                <StepTitle>{t('Nav_PolicyandPlanningSection')}</StepTitle>
                <StepDescription>{t('BudgetPlan')}</StepDescription>
                <StepDescription>{t('PolicyPlan')}</StepDescription>
              </StepDescriptionContainer>
            </StepDetailContainer>
          </StepSectionContainer>
          <StepSectionContainer className="flex flex-row pt-14 pb-0">
            <StepImage src={_1} alt="" />
            <StepDetailContainer>
              <StepNumberCircle>5</StepNumberCircle>
              <StepDescriptionContainer>
                <StepTitle>{t('Nav_AcademicService')}</StepTitle>
                <StepDescription>
                  <StepDescription>{t('ServiceforStudent')}</StepDescription>
                  <StepDescription>{t('DevelopmentofCurriculum')}</StepDescription>
                  <StepDescription>{t('InternationalRelations')}</StepDescription>
                  <StepDescription>{t('AppointedDissertaionthesisCheck')}</StepDescription>
                  <StepDescription>{t('NominationforGraduationDegreeDiploma')}</StepDescription>
                </StepDescription>
              </StepDescriptionContainer>
            </StepDetailContainer>
          </StepSectionContainer>
          <StepSectionContainer className="flex flex-row pt-0 pb-6">
            <StepImage src={_3} alt="" />
            <StepDetailContainer>
              <StepNumberCircle>6</StepNumberCircle>
              <StepDescriptionContainer>
                <StepTitle>{t('Nav_InterdisciplinaryProgramQA')}</StepTitle>
                <StepDescription>{t('InterdisciplinaryProgram')}</StepDescription>
                <StepDescription>{t('EducationalQualityAssurance')}</StepDescription>
                <StepDescription>{t('InstituteResearch')}</StepDescription>
              </StepDescriptionContainer>
            </StepDetailContainer>
          </StepSectionContainer>

          <StepSectionContainer className="flex flex-row  py-10">
            <div>
              <img src={_2} alt="" className=" h-[270px] w-full rounded-sm object-cover lg:w-[480px]" />
              <img src={ThinkingImg} alt="" className="ml-[110px] -mt-20 rounded-sm object-cover" />
            </div>
            <StepDetailContainer className="-mt-10 md:-mt-10 lg:mt-10">
              <StepNumberCircle>7</StepNumberCircle>
              <StepDescriptionContainer>
                <StepTitle>{t('Nav_StudentDevelopment')}</StepTitle>
                <StepDescription>{t('Scholarship')}</StepDescription>
                <StepDescription>{t('GraduateStudentAffairs')}</StepDescription>
                <StepDescription>{t('Dissertation')}</StepDescription>
              </StepDescriptionContainer>
            </StepDetailContainer>
          </StepSectionContainer>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default AdmissionProcessPagePage;
