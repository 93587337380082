export const NEWS_TYPES = {
  ALL: 'all',
  GRADUATE_SCHOOL_NEWS: 'graduate-school-news',
  ADMISSION_SCHOLARSHIP_NEWS: 'admission-and-scholarship-news',
  SEMINAR_NEWS: 'seminar-news',
  STUDENT_NEWS: 'student-news',
  SUPPLY_NEWS: 'supply-news',
  JOBS_NEWS: 'job-news',
};

export const NewsTypeQueryParam = {
  ALL: 'all',
  GRADUATE_SCHOOL_NEWS: 'graduate-school-news',
  ADMISSION_SCHOLARSHIP_NEWS: 'admission-and-scholarship-news',
  SEMINAR_NEWS: 'seminar-news',
  STUDENT_NEWS: 'student-news',
  SUPPLY_NEWS: 'supply-news',
  JOBS_NEWS: 'job-news',
};

export const dropdownNewsTypes = {
  'seminar-news': { name: 'Seminar News', value: 'seminar-news' },
  'student-news': { name: 'Student News', value: 'student-news' },
  'supply-news': { name: 'Supply News', value: 'supply-news' },
  'job-news': { name: 'Job News', value: 'job-news' },
  'graduate-school-news': { name: 'Graduate School News', value: 'graduate-school-news' },
  'admission-and-scholarship-news': { name: 'Admission & Scholarship News', value: 'admission-and-scholarship-news' },
};

export const getNewsi18nKey = (newsType) => {
  switch (newsType) {
    case NEWS_TYPES.GRADUATE_SCHOOL_NEWS:
      return 'graduate_school_news';
    case NEWS_TYPES.ADMISSION_SCHOLARSHIP_NEWS:
      return 'admission-and-scholarship-news';
    case NEWS_TYPES.SEMINAR_NEWS:
      return 'seminar_news';
    case NEWS_TYPES.STUDENT_NEWS:
      return 'student_news';
    case NEWS_TYPES.SUPPLY_NEWS:
      return 'supply_news';
    case NEWS_TYPES.JOBS_NEWS:
      return 'jobs_news';
    default:
      return 'all_news';
  }
};

export const getNewsQueryParams = (newsType) => {
  switch (newsType) {
    case NEWS_TYPES.GRADUATE_SCHOOL_NEWS:
      return NewsTypeQueryParam.GRADUATE_SCHOOL_NEWS;
    case NEWS_TYPES.ADMISSION_SCHOLARSHIP_NEWS:
      return NewsTypeQueryParam.ADMISSION_SCHOLARSHIP_NEWS;
    case NEWS_TYPES.SEMINAR_NEWS:
      return NewsTypeQueryParam.SEMINAR_NEWS;
    case NEWS_TYPES.STUDENT_NEWS:
      return NewsTypeQueryParam.STUDENT_NEWS;
    case NEWS_TYPES.SUPPLY_NEWS:
      return NewsTypeQueryParam.SUPPLY_NEWS;
    case NEWS_TYPES.JOBS_NEWS:
      return NewsTypeQueryParam.JOBS_NEWS;
    default:
      return null;
  }
};

export const getNewsTypeFromQueryParam = (queryParam) => {
  const isOneOfNewsType = Object.values(NEWS_TYPES).includes(queryParam);

  if (isOneOfNewsType) {
    return queryParam;
  }

  return NEWS_TYPES.ALL;
};
