import React from 'react';
import Layout from '../../../components/Layout';
import BackToInterdisciplinaryProgramsButton from '../components/BackToInterdisciplinaryProgramsButton';
import Cover from './assets/Cover.jpg';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';
import PageTitle from '../components/PageTitle';
import Paragraph from '../components/Paragraph';
import ParagraphTitle from '../components/ParagraphTitle';
import UlList from '../components/UlList';
import CourseStructor from '../components/CourseStructor';
import ColorGradientBottomImage from '../components/ColorGradientBottomImage';
import { useLocation } from 'react-router-dom';
import BackToSearchByFacultyButton from '../components/BackToSearchByFacultyButton';
import CoverImage from '../components/CoverImage';
import ContentWrapper from '../components/ContentWrapper';
import CustomContainer from '../../../components/CustomContainer';

const ManAndEnvironmentalManagementPage = () => {
  const { t, isEng } = useTranslationUtil();
  const location = useLocation();
  const { fromSearchByFaculty } = location?.state || {};
  return (
    <Layout>
      <CustomContainer>
        {fromSearchByFaculty ? <BackToSearchByFacultyButton /> : <BackToInterdisciplinaryProgramsButton />}
        <PageTitle>{t('ManAndEnvironmentalManagement')}</PageTitle>
        <CoverImage src={Cover} alt="" />
        <ContentWrapper>{isEng() ? <ContentEng /> : <ContentTh />}</ContentWrapper>
        <ColorGradientBottomImage />
        {fromSearchByFaculty ? <BackToSearchByFacultyButton /> : <BackToInterdisciplinaryProgramsButton />}
      </CustomContainer>
    </Layout>
  );
};

const ContentEng = () => {
  return (
    <>
      <ParagraphTitle>Man and Environmental Management</ParagraphTitle>
      <div>Importance and background of the program</div>
      <Paragraph>
        Environment is every single being surrounding humans. It can be both concrete and abstract; living things and
        non-living things; natural and human-made. Humans are components in the environment that play the role in
        changing the environment and nature itself the most. The role of humans is to be the consumer, the creator and
        the annihilator of the environment. If humans can comprehend the impact which the environment can give to them;
        know how to consume natural resources frugally and efficiently; and have conscious to cherish and maintain the
        environment, then humans and environment can coexist in harmony and equilibrium.
      </Paragraph>
      <Paragraph>
        The environmental management can be proceeded by many methods such as using Science and Technology for
        Environmental Management, or applying Policies and Measurement for the management. The goal is set on human
        resources development since it is believed that if humans understand environmental problems and have conscious
        in cherishing the environment, the environmental problems which have currently been threatening human's
        well-being and quality of life will be alleviated or obliterated eventually.
      </Paragraph>
      <Paragraph>
        Master of Arts Program in Man and Environment Management is aiming to develop the related crew and educate them
        about the understanding of the environmental development. The applied policies and measurement are expected to
        concern many aspects including Economic and Social aspect, Moral and Ethical aspect, also the education system
        as well. All are to manage humans to protect and consume resources wisely along with having the conscious and
        moral in the environmental aspect.
      </Paragraph>
      <Paragraph>
        The educational management of this program will be proceeded in the integration of many branches of related
        knowledge. This is to allow the possibility to produce advanced crew in the type of researchers, planners,
        project administrators or environmental educators. This crew will be the ones who understand and recognize the
        environmental problems and are able to manage human resources by applying the various methods for a better
        environmental development in the future.
      </Paragraph>
      <ParagraphTitle>Objective of the program</ParagraphTitle>
      <Paragraph>
        To emphasize on developing the crew with professional skills and to give them knowledge and abilities in the
        following aspects:
      </Paragraph>
      <UlList>
        <li>
          To be knowledgeable in environmental problems and the importance of humans towards the environment in the
          issue of human's quality of life, recognition, awareness and behaviors which are related to environmental
          problems
        </li>
        <li>
          To be knowledgeable and skillful in policies analysis and project management planning. Or be able to publicize
          information and knowledge about environment, or manage the Environmental Education in teaching and learning
          activities. Also, to be able to give the supervision consultancy about curriculum development and teaching in
          both formal and non-formal education{' '}
        </li>
        <li>
          To be knowledgeable and skillful in conducting research or developing management approach, and efficiently and
          appropriately solving the problems of humans and environment{' '}
        </li>
        <li>
          To be moral, ethical and conceivable about environmental problems and be able to manage human resources by
          applying various methods to develop better environment in the future
        </li>
      </UlList>
      <ParagraphTitle>Faculty/division with this program</ParagraphTitle>
      <Paragraph>Humanities, Education and Social Sciences</Paragraph>
      <ParagraphTitle>Courses</ParagraphTitle>
      <div>Scheme A: Type A2, Regular session, class: Mon-Fri</div>
      <div>Scheme B: Special session, class: Sat-Sun</div>
      <div>This course takes 2 years or 4 semesters</div>
      <CourseStructor url="http://mis.cmu.ac.th/TQF/TQF2/CurriculumPublic.aspx?EID=65108544-88a7-4f9e-a04c-d70797f154b3" />

      <ParagraphTitle>Academic Fee</ParagraphTitle>
      <div> Scheme A: Type A2, Regular session and Scheme B: Special session</div>
      <div>Lump sum payment: 165,000 baht/course (Pay as installments: 41,250 baht/semester)</div>
      <ParagraphTitle>Office</ParagraphTitle>
      <div>Man and Environmental Management Program</div>
      <div>Interdisciplinary Programs and Quality Assurance Department</div>
      <div>1st floor, Graduate School, Chiang Mai University</div>
      <div>Huai Kaew Road, T. Suthep, Muang District, Chiang Mai, 50200</div>
      <ParagraphTitle>Program's website</ParagraphTitle>
      <div>
        Facebook:
        <a href="https://www.facebook.com/mem.cmu" className="ml-2  text-NeonPink">
          https://www.facebook.com/mem.cmu
        </a>
      </div>
      <div>
        Facebook Alumni:
        <a href="https://www.facebook.com/memalumniclub.cmu" className="ml-2 text-NeonPink">
          https://www.facebook.com/memalumniclub.cmu
        </a>
      </div>
    </>
  );
};

const ContentTh = () => {
  return (
    <>
      <ParagraphTitle>
        สาขาวิชาการจัดการมนุษย์กับสิ่งแวดล้อม <br /> (Man and Environmental Management)
      </ParagraphTitle>
      <ParagraphTitle>ชื่อหลักสูตร</ParagraphTitle>
      <p className=" mt-6">
        <b>ไทย</b> : ศิลปศาสตรมหาบัณฑิต สาขาวิชาการจัดการมนุษย์กับสิ่งแวดล้อม <br /> <b>อังกฤษ</b> : Master of Arts
        Program in Man and Environment Management
      </p>
      <ParagraphTitle>ชื่อปริญญา</ParagraphTitle>
      <p className=" mt-6">
        <b>ชื่อเต็ม</b> : ศิลปศาสตรมหาบัณฑิต (การจัดการมนุษย์กับสิ่งแวดล้อม) <br /> <b> ชื่อย่อ </b>: ศศ.ม.
        (การจัดการมนุษย์กับสิ่งแวดล้อม)
      </p>
      <ParagraphTitle>อังกฤษ :</ParagraphTitle>
      <p className=" mt-6">
        <b>ชื่อเต็ม</b> : Master of Arts ( Man and Environment Management ) <br /> <b> ชื่อย่อ </b>: M.A. ( Man and
        Environment Management )
      </p>
      <ParagraphTitle>ความสำคัญและที่มาของหลักสูตร</ParagraphTitle>
      <Paragraph>
        สิ่งแวดล้อม คือ ทุกสิ่งทุกอย่างที่อยู่รอบตัวมนุษย์ ทั้งที่เป็นรูปธรรม และนามธรรม ทั้งที่มีชีวิตและ ไม่มีชีวิต
        ทั้งที่มนุษย์ไม่สามารถสร้างสรรค์ และที่สร้างสรรค์เองได้
        มนุษย์เป็นองค์ประกอบของสิ่งแวดล้อมที่มีบทบาทต่อการเปลี่ยนแปลงของสิ่งแวด ล้อมและธรรมชาติมากที่สุด
        มนุษย์มีบทบาททั้งเป็นผู้บริโภคสิ่งแวดล้อม สร้างสรรค์สิ่งแวดล้อมและทำลายสิ่งแวดล้อม
        หากมนุษย์มีความเข้าใจถึงผลกระทบของสิ่งแวดล้อมที่มีต่อมนุษย์ รู้จักใช้ทรัพยากรธรรมชาติอย่างประหยัด
        และเกิดประโยชน์สูงสุดและมีจิตสำนึกในอันที่จะรักษาและทะนุถนอมสิ่งแวดล้อม ที่ดีให้คงอยู่
        มนุษย์และสิ่งแวดล้อมก็สามารถจะดำรงอยู่ได้อย่างผสมกลมกลืนและไม่ขาดสมดุล
      </Paragraph>
      <Paragraph>
        หลักการจัดการสิ่งแวดล้อมนั้น อาจดำเนินได้หลายลักษณะไม่ว่าจะเป็นการใช้วิทยาศาสตร์
        และเทคโนโลยีในการจัดการสิ่งแวดล้อม หรือการใช้นโยบายและมาตรการต่าง ๆ มาดำเนินการ
        โดยเป้าหมายอยู่ที่การพัฒนาทรัพยากรมนุษย์ โดยมีความเชื่อว่าหากมนุษย์มีความเข้าใจเกี่ยวกับปัญหาสิ่งแวดล้อม
        และมีจิตสำนึกในการอนุรักษ์สิ่งแวดล้อมแล้ว ปัญหาสิ่งแวดล้อมที่กำลังเป็นปัญหาใหญ่หลวงที่คุกคามชีวิตความเป็นอยู่และ
        คุณภาพชีวิตในปัจจุบัน ก็จะบรรเทาหรือหมดสิ้นไปในที่สุด
      </Paragraph>
      <Paragraph>
        หลักสูตรศิลปศาสตรมหาบัณฑิต สาขาวิชาการจัดการมนุษย์และสิ่งแวดล้อม
        มีเป้าหมายในการพัฒนาบุคลากรที่เกี่ยวข้องให้มีความรู้ความเข้าใจในการ จัดการสิ่งแวดล้อม
        โดยยึดหลักการใช้นโยบายและมาตรการด้านต่าง ๆ เช่น ด้านเศรษฐกิจและสังคม ด้านกฎหมาย ด้านคุณธรรมและจริยธรรม
        รวมทั้งกระบวนการทางการศึกษา เป็นต้น เพื่อจัดการให้มนุษย์ร่วมกันอนุรักษ์ และใช้ทรัพยากรอย่างมีคุณค่า
        ตลอดจนมีจิตสำนึกและจริยธรรมทางด้านสิ่งแวดล้อม
      </Paragraph>
      <Paragraph>
        การจัดการศึกษาในหลักสูตรดังกล่าวนี้จะดำเนินการในลักษณะของการบูรณาการ ศาสตร์ต่าง ๆ ที่เกี่ยวข้องเข้าด้วยกัน
        เพื่อให้สามารถผลิตบุคลากรชั้นสูงในรูปของนักวิจัย นักวางแผน นักบริหารโครงการ หรือนักการศึกษาด้านสิ่งแวดล้อม
        ที่มีความเข้าใจและตระหนักถึงปัญหาสิ่งแวดล้อม และสามารถจัดการทรัพยากรมนุษย์โดยใช้กระบวนการต่าง ๆ
        เพื่อการพัฒนาสิ่งแวดล้อมที่ดีได้ต่อไป
      </Paragraph>
      <ParagraphTitle>วัตถุประสงค์ของหลักสูตร</ParagraphTitle>
      <Paragraph>
        เน้นการพัฒนาบุคลากรที่มีพื้นฐานทางวิชาชีพต่าง ๆ ให้ได้รับการปลูกฝังให้มีความรู้ความสามารถในแนวทางต่อไปนี้
      </Paragraph>
      <UlList>
        <li>
          เป็นผู้มีความรู้ ความเข้าใจในปัญหาสิ่งแวดล้อม
          และความสำคัญของมนุษย์ต่อสิ่งแวดล้อมในประเด็นทางด้านคุณภาพชีวิตของมนุษย์ การรับรู้ ความตระหนัก
          และพฤติกรรมของมนุษย์ในส่วนที่เกี่ยวข้องกับปัญหาสิ่งแวดล้อม
        </li>
        <li>
          เป็นผู้มีความรู้ ความสามารถในการวิเคราะห์นโยบายและการวางแผนบริหารโครงการหรือ เผยแพร่ความรู้ด้านสิ่งแวดล้อม
          หรือดำเนินการทางด้านสิ่งแวดล้อมศึกษา ในลักษณะของการจัดกิจกรรมการเรียนการสอน
          การนิเทศให้คำปรึกษาแนะนำเกี่ยวกับการพัฒนาหลักสูตรและการสอนทั้งในระบบและ นอกระบบโรงเรียน
        </li>
        <li>
          เป็นผู้มีความรู้ ความสามารถในการวิจัย หรือพัฒนาแนวทางการจัดการ
          และการแก้ปัญหามนุษย์และสิ่งแวดล้อมได้อย่างมีประสิทธิภาพและเหมาะสม
        </li>
        <li>
          เป็นผู้มีคุณธรรม จริยธรรม มีความเข้าใจตระหนักถึงปัญหาสิ่งแวดล้อมและสามารถจัดการ ทรัพยากรมนุษย์
          โดยใช้กระบวนการต่าง ๆ เพื่อการพัฒนาสิ่งแวดล้อมที่ดีได้ต่อไป
        </li>
      </UlList>
      <ParagraphTitle>คณะ/ส่วนงานที่ร่วมจัดการเรียนการสอน</ParagraphTitle>
      <Paragraph>คณะมนุษยศาสตร์ คณะศึกษาศาสตร์ และคณะสังคมศาสตร์</Paragraph>

      <ParagraphTitle>การเรียนการสอน</ParagraphTitle>
      <div className="ml-6">
        <div>หลักสูตรแผน ก แบบ ก 2 ภาคปกติ เรียนวันจันทร์ – วันศุกร์</div>
        <div>หลักสูตรแผน ข ภาคพิเศษ เรียนวันเสาร์ และ วันอาทิตย์</div>
        <div>ใช้เวลาเรียนตลอดหลักสูตร 2 ปี หรือ 4 ภาคการศึกษา</div>
        <CourseStructor url="http://mis.cmu.ac.th/TQF/TQF2/CurriculumPublic.aspx?EID=65108544-88a7-4f9e-a04c-d70797f154b3" />
      </div>

      <ParagraphTitle>ค่าธรรมเนียมการศึกษา</ParagraphTitle>
      <Paragraph>
        หลักสูตรแผน ก แบบ ก 2 ภาคปกติ และหลักสูตรแผน ข ภาคพิเศษ เหมาจ่ายหลักสูตรละ 165,000 บาท โดยแบ่งจ่ายเป็นราย
        ภาคการศึกษา ๆ ละ 41,250 บาท
      </Paragraph>
      <ParagraphTitle>สำนักงาน</ParagraphTitle>
      <div> สาขาวิชาการจัดการมนุษย์กับสิ่งแวดล้อม</div>
      <div> งานสหวิทยาการและประกันคุณภาพการศึกษา</div>
      <div> ชั้น 1 อาคารบัณฑิตวิทยาลัย มหาวิทยาลัยเชียงใหม่</div>
      <div> ถนนห้วยแก้ว ตำบลสุเทพ อำเภอเมือง จังหวัดเชียงใหม่ 50200</div>
    </>
  );
};

export default ManAndEnvironmentalManagementPage;
