import React, { useState } from 'react';
import MoreDetailButton from '../../../../../components/Button/MoreDetailButton';
import NewsContentCard from '../NewsContentCard';
import NewsTab from '../NewsTab';
import { useHistory } from 'react-router-dom';
import { NEWS_TYPES } from '../../../../../utils/news';
import routeUrlProvider, { NEWS, NEWS_DETAIL } from '../../../../../constants/route-paths';
import { useTranslationUtil } from '../../../../../hooks/useTranslationUtil';
import HomePageNewsCard from '../../../../../components/Home/NewsCard';

const TabsIndex = {
  SEMINAR: 0,
  STUDENT: 1,
  SUPPLY: 2,
};

const TabsDetails = [
  {
    name: 'SEMINAR',
  },
  {
    name: 'STUDENT',
  },
  {
    name: 'SUPPLY',
  },
];

const NewsContentControll = ({ seminarNews = [], studentNews = [], supplyNews = [] }) => {
  const history = useHistory();
  const { t } = useTranslationUtil();
  const [CurrentTabIndex, setCurrentTabIndex] = useState(TabsIndex.STUDENT);

  const getNowContentDisplay = () => {
    if (CurrentTabIndex === TabsIndex.STUDENT) {
      return studentNews;
    } else if (CurrentTabIndex === TabsIndex.SUPPLY) {
      return supplyNews;
    } else {
      return seminarNews;
    }
  };

  const getCurrentNewsType = () => {
    if (CurrentTabIndex === TabsIndex.STUDENT) {
      return NEWS_TYPES.STUDENT_NEWS;
    } else if (CurrentTabIndex === TabsIndex.SUPPLY) {
      return NEWS_TYPES.SUPPLY_NEWS;
    } else {
      return NEWS_TYPES.SEMINAR_NEWS;
    }
  };

  return (
    <>
      <div className="grid grid-cols-3 lg:grid-cols-5">
        <div className="col-span-3 grid grid-cols-3 items-center lg:col-start-2">
          {TabsDetails.map((tabDetail, id) => (
            <NewsTab key={id} selected={id === CurrentTabIndex} onClick={() => setCurrentTabIndex(id)}>
              {t(tabDetail.name)}
            </NewsTab>
          ))}
        </div>
        <div className="hidden w-full justify-end lg:flex">
          <MoreDetailButton
            text={t('More News')}
            onClick={() => {
              history.push({ pathname: routeUrlProvider.getForRoute(NEWS), search: `?type=${getCurrentNewsType()}` });
            }}
          />
        </div>
      </div>

      <div className="mt-5 flex flex-row flex-wrap gap-y-10">
        {getNowContentDisplay().map((newsData) => {
          // const { createdAt, titleTh, titleEn, id } = newsData;
          return (
            // <NewsContentCard
            //   id={id}
            //   key={id}
            //   date={formatDate(createdAt)}
            //   nDaysAgo={timeAgo.format(new Date(createdAt))}
            //   detail={getDisplayText(titleTh, titleEn)}
            //   onClick={() => {
            //     history.push(routeUrlProvider.getForLink(NEWS_DETAIL, { id }), { newsData });
            //   }}
            // />
            <HomePageNewsCard newsData={newsData} />
          );
        })}
      </div>

      <div className="mt-8 flex w-full justify-center md:hidden">
        <MoreDetailButton
          text={t('More News')}
          onClick={() => {
            history.push({ pathname: routeUrlProvider.getForRoute(NEWS), search: `?type=${getCurrentNewsType()}` });
          }}
        />
      </div>
    </>
  );
};
export default NewsContentControll;
