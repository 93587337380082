import env from '../utils/env';
import createApiUrlProvider from '../utils/apiUrlProvider';

const apiProvider = createApiUrlProvider();

apiProvider.setBaseUrl(env.get('API_URL') || '');

export const LOGIN = 'LOGIN';
export const GET_USER_INFORMATION = 'GET_USER_INFORMATION';
export const GET_NEWS = 'NEWS';
export const GET_NEWS_ID = 'GET_NEWS_ID';
export const GET_ADMISSION_BANNERS = 'GET_ADMISSION_BANNERS';
export const GET_UPDATE_BANNERS = 'GET_UPDATE_BANNERS';
export const GET_POSTERS = 'GET_POSTERS';
export const GET_PAGE_VIEWS = 'GET_PAGE_VIEWS';

apiProvider.set(LOGIN, '/login');
apiProvider.set(GET_USER_INFORMATION, '/info');
apiProvider.set(GET_NEWS, '/news');
apiProvider.set(GET_NEWS_ID, '/news/:id');
apiProvider.set(GET_ADMISSION_BANNERS, '/banners');
apiProvider.set(GET_UPDATE_BANNERS, '/banners');
apiProvider.set(GET_POSTERS, '/posters');
apiProvider.set(GET_PAGE_VIEWS, '/page-views');

// LOGIN
export const ADMIN_LOGIN_GOOGLE_CALLBACK = 'ADMIN_LOGIN_GOOGLE_CALLBACK';
apiProvider.set(ADMIN_LOGIN_GOOGLE_CALLBACK, '/admin/login/google/callback');

// LOGIN
export const ADMIN_LOGIN_REDIRECT_WITH_CODE = 'ADMIN_LOGIN_REDIRECT_WITH_CODE';
apiProvider.set(ADMIN_LOGIN_REDIRECT_WITH_CODE, '/admin/login/callback');

// MANAGE NEWS
export const ADMIN_GET_NEWS_LIST = 'ADMIN_GET_NEWS_LIST';
apiProvider.set(ADMIN_GET_NEWS_LIST, '/admin/news');
export const ADMIN_CREATE_NEWS = 'ADMIN_CREATE_NEWS';
apiProvider.set(ADMIN_CREATE_NEWS, '/admin/news');
export const ADMIN_DELETE_NEWS = 'ADMIN_DELETE_NEWS';
apiProvider.set(ADMIN_DELETE_NEWS, '/admin/news/:id');
export const ADMIN_GET_NEWS_GET_BY_ID = 'ADMIN_GET_NEWS_GET_BY_ID';
apiProvider.set(ADMIN_GET_NEWS_GET_BY_ID, '/admin/news/:id');
export const ADMIN_UPDATE_NEWS = 'ADMIN_UPDATE_NEWS';
apiProvider.set(ADMIN_UPDATE_NEWS, '/admin/news/:id');

// MANAGE BANNER
export const ADMIN_GET_BANNER_LIST = 'ADMIN_GET_BANNER_LIST';
apiProvider.set(ADMIN_GET_BANNER_LIST, '/admin/banners');
export const ADMIN_GET_BANNER_GET_BY_ID = 'ADMIN_GET_BANNER_GET_BY_ID';
apiProvider.set(ADMIN_GET_BANNER_GET_BY_ID, '/admin/banners/:id');
export const ADMIN_ADD_BANNER = 'ADMIN_ADD_BANNER';
apiProvider.set(ADMIN_ADD_BANNER, '/admin/banners');

// MANAGE POSTER
export const ADMIN_GET_POSTER_LIST = 'ADMIN_GET_POSTER_LIST';
apiProvider.set(ADMIN_GET_POSTER_LIST, '/admin/posters');
export const ADMIN_ADD_POSTER = 'ADMIN_ADD_POSTER';
apiProvider.set(ADMIN_ADD_POSTER, '/admin/posters');

// MANAGE IMAGE (BANNER + POSTER)
export const ADMIN_ADD_IMAGE = 'ADMIN_ADD_IMAGE';
apiProvider.set(ADMIN_ADD_IMAGE, '/admin/images');
export const ADMIN_DELETE_IMAGE = 'ADMIN_DELETE_IMAGE';
apiProvider.set(ADMIN_DELETE_IMAGE, '/admin/images/:id');

// MANAGE FILE
export const ADMIN_ADD_FILE = 'ADMIN_ADD_FILE';
apiProvider.set(ADMIN_ADD_FILE, '/admin/files');

export default apiProvider;
