import { useState, useEffect, useRef } from 'react';
import LeftArrow32px from '../../../components/Icons/LeftArrow32pxIcon';
import NewsCard from './NewsCard';
import Slider from 'react-slick';
import { getListNews } from '../../../services/news';
import { NEWS_TYPES, getNewsQueryParams } from '../../../utils/news';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';
import CMUPurpleUnderLine80x8 from '../../../components/CMUPurpleUnderLine';
import useWindowDimensions from '../../../hooks/useWindowDimension';
import CustomContainer from '../../../components/CustomContainer';

const GraduateSchoolNewsSection = () => {
  const sliderRef = useRef(null);
  const { t } = useTranslationUtil();
  const [news, setNews] = useState([]);
  const { widthLessThanMD } = useWindowDimensions();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: news.length < 3 ? news.length : 3,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
  };

  if (widthLessThanMD) {
    settings.slidesToShow = 1;
  }

  useEffect(() => {
    getListNews({ newsTypeQuery: getNewsQueryParams(NEWS_TYPES.GRADUATE_SCHOOL_NEWS), page: 1, perPage: 10 })
      .then((res) => {
        setNews(res.data || []);
      })
      .catch((err) => console.log(' err ', err));
  }, []);

  if (news.length === 0) {
    return;
  }

  return (
    <div className=" mt-[100px] min-h-[633px] bg-graduate-school-news">
      <CustomContainer>
        <div className="mx-20 flex flex-row justify-between pt-[80px]">
          <button className="my-auto hidden md:block" type="button" onClick={() => sliderRef?.current?.slickPrev()}>
            <LeftArrow32px />
          </button>
          <div className=" text-[48px] font-medium leading-[61px] text-UIBlack xs:mx-auto xs:text-center md:m-0 md:text-left">
            {t('GRADUATE SCHOOL NEWS')}

            <div className="mx-auto mt-2 flex flex-row justify-center sm:justify-start  ">
              <CMUPurpleUnderLine80x8 />
            </div>
          </div>

          <button className="my-auto hidden rotate-180 md:block" onClick={() => sliderRef?.current?.slickNext()}>
            <LeftArrow32px />
          </button>
        </div>

        <Slider {...settings} ref={sliderRef}>
          {news.map((newsData, index) => (
            <NewsCard key={index} newsData={newsData} />
          ))}
        </Slider>
        <div className="mt-10 flex flex-row justify-between px-10 pb-10 md:hidden">
          <button className="my-auto" type="button" onClick={() => sliderRef?.current?.slickPrev()}>
            <LeftArrow32px />
          </button>
          <button className="my-auto rotate-180" onClick={() => sliderRef?.current?.slickNext()}>
            <LeftArrow32px />
          </button>
        </div>
      </CustomContainer>
    </div>
  );
};

export default GraduateSchoolNewsSection;
