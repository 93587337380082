import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import Layout from '../../../components/Layout';
import routeUrlProvider, { OPEN_DATA } from '../../../constants/route-paths';
//import routeUrlProvider, { OPEN_DATA_2565 } from '../../../constants/route-paths';
//import routeUrlProvider, { OPEN_DATA_2566 } from '../../../constants/route-paths';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';
import LeftArrow16pxLightGrayIcon from '../../../components/Icons/LeftArrow16pxLightGrayIcon';
import FacebookPinkIcon from '../../../components/Icons/FacebookPinkIcon';
import TwitterPinkIcon from '../../../components/Icons/TwitterPinkIcon';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import CustomContainer from '../../../components/CustomContainer';
import ImageSlider from './components/ImageSlider';
import { openDataNews } from './news-data';
import NotFoundPage from '../../404NotFound';
import SocialShareButton from '../../News/NewsDetail/components/SocialShareButton';

const Container = ({ children }) => <CustomContainer className=" px-3 md:px-10 lg:px-16 ">{children}</CustomContainer>;

const OpenDataNewsPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { t, formatDate } = useTranslationUtil();
  const fullUrl = window.location.href;

  if (!openDataNews[id]) {
    return <NotFoundPage />;
  }

  const images = openDataNews[id]?.images.map((imageUrl) => ({
    title: openDataNews[id].title,
    imageUrl,
  }));

  const onClickBack = () => {
    location.key ? history.goBack() : history.push(routeUrlProvider.getForRoute(OPEN_DATA));
  };

  return (
    <Layout>
      <Container>
        <div className="mt-10 flex flex-row  justify-between">
          <button onClick={onClickBack}>
            <div className="flex flex-row whitespace-nowrap text-lg font-bold leading-6 text-Lightgray400">
              <div className="my-auto">
                <LeftArrow16pxLightGrayIcon />
              </div>
              <div className="my-auto ml-[10px]">{t('back')}</div>
            </div>
          </button>
          <div className="flex flex-row gap-x-1 md:gap-x-6 lg:gap-x-[30px]">
            <div className="my-auto flex">
              <FacebookShareButton url={fullUrl}>
                <FacebookPinkIcon />
              </FacebookShareButton>
            </div>
            <div className="my-auto flex ">
              <TwitterShareButton url={fullUrl}>
                <TwitterPinkIcon />
              </TwitterShareButton>
            </div>
            <SocialShareButton url={fullUrl} />
          </div>
        </div>
        <div></div>
        <div className="mt-10 border-l-[5px] border-l-CMUPurple">
          <div className={` ml-6 text-2xl font-semibold leading-10 text-UIBlack`}>{openDataNews[id].title}</div>
          <div className="ml-6 flex flex-row text-Lightgray800">
            <div className=" my-auto text-lg font-bold leading-10">{openDataNews[id].publisher}</div>
            <div className="my-auto ml-8 text-sm leading-5">{formatDate(openDataNews[id].createdAt)}</div>
          </div>
        </div>
        <div className="mt-6">{<ImageSlider images={images} />}</div>
        <p className="  mt-14 px-2 text-lg leading-[1.875rem] text-UIBlack md:px-8 lg:px-14 xl:px-40">
          {openDataNews[id].description}
        </p>
      </Container>
    </Layout>
  );
};

export default OpenDataNewsPage;
