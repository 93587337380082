import React, { useRef } from 'react';
import CMUPurpleUnderLine80x8 from '../../components/CMUPurpleUnderLine';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import AboutDetailItem from './AboutDetailItem';
import CmuImage1 from './assets/about-cmu-1.jpg';
import CmuImage2 from './assets/about-cmu-2.jpg';
import CmuImage3 from './assets/about-cmu-3.jpg';
import GradImage1 from './assets/about-grad-cmu-1.jpg';
import GradImage2 from './assets/about-grad-cmu-2.png';
import GradImage3 from './assets/about-grad-cmu-3.jpg';
import { useLocation } from 'react-router';
import ReactPlayer from 'react-player';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';

const About = () => {
  const location = useLocation();
  const { t, getFontFamily } = useTranslationUtil();

  const { hash } = location;

  let gradRef = useRef(null);

  const scrollToGrad = () => {
    setTimeout(() => {
      gradRef?.scrollIntoView({
        behavior: 'smooth',
      });
    }, 500);
  };
  if (hash === '#gradcmu' && gradRef) {
    scrollToGrad();
  }

  return (
    <Layout hideFooterDivider removeSpaceBeforeFooterDivider>
      <PageTitleContainer>
        <CustomContainer>{t('about_cmu')}</CustomContainer>
      </PageTitleContainer>
      <CustomContainer>
        <div className="lg:grid lg:grid-cols-5">
          <div className="lg:col-span-3">
            <img className="-mt-6 h-[400px]" src={CmuImage1} alt={t('about_cmu_image_description')} />
            <div
              className={`${getFontFamily(true)} mt-4  px-6 text-center text-xs leading-4 text-Lightgray700 md:px-0`}
            >
              {t('about_cmu_image_description')}
            </div>
          </div>
          <div className="m-auto w-fit px-6 md:w-[25em] md:px-0 lg:col-span-2">
            <div className="mt-16 text-[32px] font-medium leading-10 text-UIBlack">{t('chiang_mai_university')}</div>
            <div className="mt-6">
              <CMUPurpleUnderLine80x8 />
            </div>
            <div className={`${getFontFamily(true)} mt-6 text-sm leading-[160%]`}>{t('about_cmu_title')}</div>
            <AboutDetailItem> {t('about_cmu_detail1')}</AboutDetailItem>
          </div>
        </div>
        <div className="mt-14 grid lg:grid-cols-5">
          <div className="mx-auto hidden w-fit md:w-[25em] lg:col-span-2 lg:inline-block">
            <AboutDetailItem> {t('about_cmu_detail2')}</AboutDetailItem>
          </div>
          <div className="flex flex-row justify-end lg:col-span-3">
            <img src={CmuImage2} alt={t('about_cmu_detail2')} className="md:max-w-[90%] lg:max-w-full" />
          </div>
          <div className="mx-auto mt-8 px-6 md:w-[25em] md:px-0 lg:hidden">
            <AboutDetailItem> {t('about_cmu_detail2')}</AboutDetailItem>
          </div>
        </div>
        <div className="grid-cols-5 lg:grid">
          <div className="col-span-3 mt-[72px] lg:-m-8">
            <img src={CmuImage3} alt={t('about_cmu_detail3')} className="md:max-w-[90%] lg:max-w-full" />
          </div>
          <div className="m-auto mt-7 px-6 md:col-span-2 md:w-[25em] md:px-0">
            <AboutDetailItem> {t('about_cmu_detail3')}</AboutDetailItem>
          </div>
        </div>
        <div
          ref={(ref) => (gradRef = ref)}
          className="mt-20 bg-gradien-GradPink-to-CMUPurple pt-6 pb-2 text-center text-[40px] font-medium leading-[71px] text-UIWhite md:pt-[96px] md:pb-[44px] md:pl-20 md:text-left md:text-[56px]"
        >
          {t('about_grad_cmu')}
        </div>
        <div className="mt-8 lg:mt-0 lg:grid lg:grid-cols-5">
          <div className="my-12 mx-auto w-fit px-6 md:w-[25em] md:px-0 lg:col-span-2">
            <AboutDetailItem> {t('about_grad_cmu_detail1')}</AboutDetailItem>
          </div>
          <div className="mt-8 w-full lg:col-span-3 lg:-mt-7">
            <img src={GradImage1} alt={t('about_grad_cmu_detail1')} />
            <div className="flex flex-row justify-end overflow-hidden 2xl:mr-[10em]">
              <img
                className="-mt-9 h-[300px] rounded-sm lg:-mt-12 "
                src={GradImage2}
                alt={t('about_grad_cmu_detail2') + t('about_grad_cmu_detail3')}
              />
            </div>
          </div>
        </div>
        <div className="md:mx-auto md:w-[25em] lg:mx-0 lg:-mt-[90px] lg:grid lg:w-full lg:grid-cols-5">
          <div className="m-auto px-8 text-[40px] font-medium leading-[51px] text-Lightgray500 lg:col-span-2 lg:w-96 ">
            {t('about_grad_cmu_detail2')}
          </div>
          <div className="mx-auto mt-10 px-8 lg:col-span-3 lg:mx-0 lg:mt-5 lg:max-w-[319px] lg:px-0">
            <div className="text-[32px] font-medium leading-10 text-UIBlack">{t('about_grad_cmu_graduate_study')}</div>
            <div className="mt-6">
              <CMUPurpleUnderLine80x8 />
            </div>
            <div className="mt-6">{t('about_grad_cmu_detail3')}</div>
          </div>
        </div>
        <div className="mt-[108px] text-center text-[32px] font-medium leading-10 text-Lightgray500">
          {t('AboutGraduateStudyAtCMU')}
        </div>
        <div className="mx-auto mt-10 h-72 w-full max-w-[52.5em] md:h-96 lg:h-[29.5em]">
          <ReactPlayer url="https://www.youtube.com/watch?v=0ssM3g1XQkI" width="100%" height="100%" controls />
        </div>
        <div className="mt-20">
          <img src={GradImage3} alt="" />
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default About;
