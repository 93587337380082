import { useHistory } from 'react-router-dom';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';
import routeUrlProvider, { ABOUT } from '../../../constants/route-paths';
import imageUrl from './assets/img.jpg';
import PinkBlurCircleImg from '../assets/pink-blur-circle.png';
import MoreDetailButton from '../../../components/Button/MoreDetailButton';
import CustomContainer from '../../../components/CustomContainer';
// const imageUrl = 'https://sv1.picz.in.th/images/2022/12/13/Gm8IWR.jpg';
// const thumbnail ='https://sv1.picz.in.th/images/2022/12/13/Gm8IWR.th.jpg'

const GraduateSchoolSection = () => {
  const { t } = useTranslationUtil();
  const history = useHistory();
  return (
    <CustomContainer className=" mt-6 flex flex-row justify-center md:mt-[88px]   md:justify-between xl:justify-around">
      <div className="px-6 md:ml-20 md:px-0">
        <div className="font-meduim text-[48px] leading-[60px] md:text-[72px]">{t('Graduate School')}</div>
        <div className="font-meduim text-[28px] leading-[60px] md:mt-[20px] md:text-[32px] lg:text-[48px]">
          {t('chiang_mai_university')}
        </div>
        <div className=" mt-[16px] h-[8px] w-[80px] bg-CMUPurple"></div>
        <p className="font-meduim mt-[32px] max-w-[382px] text-[32px] leading-[40px]">
          {t('Raise the graduate education to international standard')}
        </p>
        <div className="mt-4">
          <MoreDetailButton
            text={t('More About Us')}
            onClick={() => {
              history.push({ pathname: routeUrlProvider.getForRoute(ABOUT), hash: 'gradcmu' });
            }}
          />
        </div>
      </div>
      <div className="relative hidden md:block">
        <img
          src={imageUrl}
          alt=""
          width={514}
          height={250}
          className=" relative rounded-[2px] shadow-[-24px_24px_0px_rgba(0,0,0,0.3)] shadow-Lightgray100"
        />
      </div>
      <img src={PinkBlurCircleImg} alt="" className="absolute right-0 -z-10 -mt-60" />
    </CustomContainer>
  );
};

export default GraduateSchoolSection;
