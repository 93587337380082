import { useHistory } from 'react-router-dom';
import { useTranslationUtil } from '../../../../hooks/useTranslationUtil';
import routeUrlProvider, { NEWS_DETAIL } from '../../../../constants/route-paths';

const JobNewsCard = ({ newsData }) => {
  const { createdAt, titleTh, titleEn, id } = newsData;
  const { getDisplayText, timeAgo, formatDate, getFontFamily } = useTranslationUtil();
  const history = useHistory();

  return (
    <div
      className="mx-auto flex w-full flex-col px-3 py-6 hover:cursor-pointer md:mt-0 md:w-[204px] md:py-0"
      onClick={() => history.push(routeUrlProvider.getForLink(NEWS_DETAIL, { id }), { newsData })}
    >
      <div className=" text-[16px] font-bold leading-[23px] text-Lightgray600">{formatDate(createdAt)}</div>
      <div className={`${getFontFamily(true)} mt-1  text-sm leading-4 text-Lightgray600`}>
        {timeAgo.format(new Date(createdAt))}
      </div>
      <div className="mt-6 font-ibm-plex-sans text-base font-bold leading-[26px] text-Lightgray800 line-clamp-5">
        {getDisplayText(titleTh, titleEn)}
      </div>
    </div>
  );
};

export default JobNewsCard;
