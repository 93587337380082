const CloseIcon = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2.53366 19.3337L0.666992 17.467L8.13366 10.0003L0.666992 2.53366L2.53366 0.666992L10.0003 8.13366L17.467 0.666992L19.3337 2.53366L11.867 10.0003L19.3337 17.467L17.467 19.3337L10.0003 11.867L2.53366 19.3337Z"
      fill="#F06292"
    />
  </svg>
);
export default CloseIcon;
