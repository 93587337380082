import TwitterIcon from '../../Icons/TwitterIcon';

const TwitterButton = ({ url = 'https://twitter.com/gradcmu' }) => {
  return (
    <button onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}>
      <TwitterIcon />
    </button>
  );
};
export default TwitterButton;
