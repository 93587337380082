import React, { useState } from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player/youtube';
import CircleBackground from './assets/circle-background.svg';
import PlayButtonImg from './assets/play-button.svg';
import { afterCloseModal, afterOpenModal } from '../../../utils/modal';
import useWindowDimensions from '../../../hooks/useWindowDimension';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    borderRadius: 0,
    border: 0,
  },
};

const YoutubePlayerModal = ({ coverImage, url, autoPlay = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { widthLessThanMD } = useWindowDimensions();

  const getWidthHeight = () => {
    let width = 694;
    let height = 462;
    if (widthLessThanMD) {
      width = 360;
      height = 200;
    }

    return { width, height };
  };

  return (
    <>
      <div className="relative flex w-[150px] items-center justify-center md:w-[300px] lg:w-[566px]">
        <img src={CircleBackground} className="h-full w-[566px] object-cover  " alt="" />
        <div
          className="absolute flex items-center justify-center hover:cursor-pointer "
          onClick={() => setIsOpen(true)}
        >
          <img
            src={coverImage}
            className="drop-shadow-podcase-cover w-full max-w-[125px] rounded-[16.9067px] md:max-w-[225px] lg:max-w-[317px]"
            alt=""
          />
          <img src={PlayButtonImg} className="absolute" alt="" />
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        onAfterClose={afterCloseModal}
        onAfterOpen={afterOpenModal}
      >
        <ReactPlayer playing={autoPlay} url={url} {...getWidthHeight()} controls={true} />
      </Modal>
    </>
  );
};

export default YoutubePlayerModal;
