import React from 'react';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import CoverStudent from './assets/CoverStudent.png';
import PosterMain from './assets/PosterMain.png';
import QRCode from './assets/QRCode.png';
import BGCard from './assets/BGCard.jpg';
import RightArrow16pxIcon from '../../components/Icons/RightArrow16pxIcon';
import ManAndEnvironmentalImg from './assets/ManAndEnvironmental.jpg';
import BiotechnologyImg from './assets/Biotechnology.jpg';
import TechnologyAndInterImg from './assets/TechnologyAndInterdisciplinaryManagement.jpg';
import ForensicScienceImg from './assets/ForensicScience.jpg';
import SportsScienceImg from './assets/SportsScience.jpg';
import ColorGradientImg from './assets/ColorGradient.png';
import MentalHealth from './assets/MentalHealth.jpg';
import ContemporaryChineseLanguageTeaching from './assets/ContemporaryChineseLanguageTeaching.jpg';
import IntegratedScience from './assets/IntegratedScience.jpg';

import {
  INTERDISCIPLINARY_PROGRAMS_BIOTECTHNOLOGY,
  INTERDISCIPLINARY_PROGRAMS_FORENSICS_SCIENCE,
  INTERDISCIPLINARY_PROGRAMS_MAN_AND_ENVIRONMENTAL,
  INTERDISCIPLINARY_PROGRAMS_SPORT_SCIENCE,
  INTERDISCIPLINARY_PROGRAMS_TECHNOLOGY_AND_INTERDISCIPLINARY,
  INTERDISCIPLINARY_PROGRAMS_MENTAL_HEALTH,
  INTERDISCIPLINARY_PROGRAMS_CONTEMPORARY_CHINESE_LANGUAGE_TEACHING,
  INTERDISCIPLINARY_PROGRAMS_INTEGRATED_SCIENCE,
} from '../../constants/route-paths';
import InterdisciplinaryProgramCard from '../../components/InterdisciplinaryProgramCard';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';

const InterdisciplinaryProgramsPage = () => {
  const { t, isEng, isThai } = useTranslationUtil();

  return (
    <Layout removeSpaceBeforeFooterDivider hideFooterDivider>
      <CustomContainer>
        <PageTitleContainer>{t('InterdisciplinaryPrograms')}</PageTitleContainer>

        <div className="max-h-[500px]">
          <div className="absolute -z-10 w-full bg-Lightgray50">
            <img
              src={CoverStudent}
              alt=""
              className="-z-10 h-[491px] w-full object-cover mix-blend-overlay md:h-[500px] "
            />
          </div>
          <img src={PosterMain} alt="" className="z-50 mx-auto w-full max-w-[305px] md:max-w-[432px]" />
        </div>
        <div className="relative">
          <div className="absolute -z-10 max-h-[600px] w-full">
            <img src={BGCard} alt="" className="h-[614px] w-full object-cover md:h-[600px]" />
          </div>
          <div className="pt-8">
            <img src={QRCode} alt="" className="z-10 mx-auto " />
          </div>

          <div className="text-center text-[40px] font-medium leading-[51px] text-UIWhite">{t('OSS')}</div>
          <div className="text-UIWhite">
            <div className="text-center "> {t('ForFacultyMembers')}</div>
            <div className="text-center"> {t('ForStudents')}</div>
          </div>

          <button
            className="mx-auto mt-4 flex flex-row rounded-sm bg-UIWhite px-4 py-2"
            onClick={() =>
              window.open(
                'https://forms.office.com/Pages/ResponsePage.aspx?id=3_GBz1neKUyR2qLf0EqnUfBMkpHIOThAuXSiRSeCa01UMjlOTDhFTE5ZVFE1TkJFNkJHOFJaWDdTTi4u',
                '_blank',
                'noopener,noreferrer'
              )
            }
          >
            <div className="my-auto text-lg font-bold leading-6 text-NeonPink"> {t('Submit')}</div>
            <div className="my-auto ml-4 ">
              <RightArrow16pxIcon className=" fill-NeonPink" />
            </div>
          </button>
          <div className="mt-[72px] pt-20 text-center text-[40px] font-medium leading-[51px]">{t('OurPrograms')}</div>
          <div className="mt-12 grid grid-cols-1 gap-2 gap-y-28 md:grid-cols-3">
            {/* <InterdisciplinaryProgramCard
              img={ManAndEnvironmentalImg}
              route={INTERDISCIPLINARY_PROGRAMS_MAN_AND_ENVIRONMENTAL}
            >
              {t('ManAndEnvironmentalManagement')}
            </InterdisciplinaryProgramCard> */}
            {/* <InterdisciplinaryProgramCard img={BiotechnologyImg} route={INTERDISCIPLINARY_PROGRAMS_BIOTECTHNOLOGY}>
              {t('Biotechnology')}
            </InterdisciplinaryProgramCard> */}
            {isEng() && (
              <>
                <InterdisciplinaryProgramCard
                   
                  img={BiotechnologyImg}
                  route={INTERDISCIPLINARY_PROGRAMS_BIOTECTHNOLOGY}
                >
                  
                  {t('Biotechnology')}
                </InterdisciplinaryProgramCard>
  
                <InterdisciplinaryProgramCard
                  img={TechnologyAndInterImg}
                  route={INTERDISCIPLINARY_PROGRAMS_TECHNOLOGY_AND_INTERDISCIPLINARY}
                >
                  {t('TechnologyAndInterdisciplinaryManagement')}
                </InterdisciplinaryProgramCard>
                <InterdisciplinaryProgramCard
                  img={ForensicScienceImg}
                  route={INTERDISCIPLINARY_PROGRAMS_FORENSICS_SCIENCE}
                >
                  {t('ForensicScience')}
                </InterdisciplinaryProgramCard>
                <InterdisciplinaryProgramCard img={SportsScienceImg} route={INTERDISCIPLINARY_PROGRAMS_SPORT_SCIENCE}>
                  {t('SportsScience')}
                </InterdisciplinaryProgramCard>
                <InterdisciplinaryProgramCard img={MentalHealth} route={INTERDISCIPLINARY_PROGRAMS_MENTAL_HEALTH}>
                  {t('MentalHealth')}
                </InterdisciplinaryProgramCard>
                <InterdisciplinaryProgramCard
                  img={ContemporaryChineseLanguageTeaching}
                  route={INTERDISCIPLINARY_PROGRAMS_CONTEMPORARY_CHINESE_LANGUAGE_TEACHING}
                >
                  {t('ContemporaryChineseLanguageTeaching')}
                </InterdisciplinaryProgramCard>
                <InterdisciplinaryProgramCard
                  img={IntegratedScience}
                  route={INTERDISCIPLINARY_PROGRAMS_INTEGRATED_SCIENCE}
                >
                  {t('IntegratedScience')}
                </InterdisciplinaryProgramCard>
                
              </>
            )}

            {isThai() && (
              <>
                <InterdisciplinaryProgramCard
                  img={BiotechnologyImg}
                  route={INTERDISCIPLINARY_PROGRAMS_BIOTECTHNOLOGY}
                >
                  {t('Biotechnology')}
                </InterdisciplinaryProgramCard>

                <InterdisciplinaryProgramCard
                  img={TechnologyAndInterImg}
                  route={INTERDISCIPLINARY_PROGRAMS_TECHNOLOGY_AND_INTERDISCIPLINARY}
                >
                  {t('TechnologyAndInterdisciplinaryManagement')}
                </InterdisciplinaryProgramCard>
                <InterdisciplinaryProgramCard
                  img={ForensicScienceImg}
                  route={INTERDISCIPLINARY_PROGRAMS_FORENSICS_SCIENCE}
                >
                  {t('ForensicScience')}
                </InterdisciplinaryProgramCard>
                <InterdisciplinaryProgramCard img={SportsScienceImg} route={INTERDISCIPLINARY_PROGRAMS_SPORT_SCIENCE}>
                  {t('SportsScience')}
                </InterdisciplinaryProgramCard>
                <InterdisciplinaryProgramCard img={MentalHealth} route={INTERDISCIPLINARY_PROGRAMS_MENTAL_HEALTH}>
                  {t('MentalHealth')}
                </InterdisciplinaryProgramCard>
                <InterdisciplinaryProgramCard
                  img={ContemporaryChineseLanguageTeaching}
                  route={INTERDISCIPLINARY_PROGRAMS_CONTEMPORARY_CHINESE_LANGUAGE_TEACHING}
                >
                  {t('ContemporaryChineseLanguageTeaching')}
                </InterdisciplinaryProgramCard>
                <InterdisciplinaryProgramCard
                  img={IntegratedScience}
                  route={INTERDISCIPLINARY_PROGRAMS_INTEGRATED_SCIENCE}
                >
                  {t('IntegratedScience')}
                </InterdisciplinaryProgramCard>
                
              </>
            )}
          </div>
        </div>

        <div className="-z-10 -mt-[450px] flex h-[600px] w-full flex-row justify-end overflow-hidden lg:-mt-80">
          <img src={ColorGradientImg} alt="" className="-z-10 h-[917px] w-[917px]" />
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default InterdisciplinaryProgramsPage;
