import { ResponsiveContainer } from 'recharts';

const CustomTable = ({ header = [], body = [] }) => {
  return (
    <ResponsiveContainer width="100%" height={100}>
      <table className="border-slate-500 table-fixed border-collapse border text-center text-Lightgray600">
        <thead>
          <tr>
            {header.map((data) => (
              <th className="border-slate-500 border">{data}</th>
            ))}
            {/* <th className="border-slate-500 border">{t('HumanitiesandSocialScience')}</th>
            <th className="border-slate-500 border">{t('HealthScience')}</th> */}
          </tr>
        </thead>
        <tbody>
          {body.map((row) => (
            <tr>
              {row.map((column) => (
                <td className="border-slate-500 border">{column}</td>
              ))}
            </tr>
          ))}
          {/* <tr>
            <td className="border-slate-500 border">94</td>
            <td className="border-slate-500 border">61</td>
            <td className="border-slate-500 border">73</td>
          </tr> */}
        </tbody>
      </table>
    </ResponsiveContainer>
  );
};
export default CustomTable;
