import CustomContainer from '../../components/CustomContainer';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';

const NotFoundPage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <div className="h-full w-full">
          <div className=" m-auto h-fit w-fit p-10 text-6xl text-Lightgray400 md:p-20">{t('PageNotFound')}</div>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default NotFoundPage;
