import React from 'react';
import Layout from '../../components/Layout';
import CoverImage from './assets/cover.jpg';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import DeanReportPDF from './assets/dean-report.pdf';
import PDFViewer from '../../components/PDFViewer';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainerWithBackgroundImage from '../../components/PageTitleContainerWithBackgroundImage';

const DeansReportPage = () => {
  const { t } = useTranslationUtil();

  return (
    <Layout headerTransparent>
      <CustomContainer>
        <div className="bg-Lightgray100">
          <PageTitleContainerWithBackgroundImage coverImage={CoverImage}>
            {t('DeansReport')}
          </PageTitleContainerWithBackgroundImage>

          <div className="mt-10 text-center text-UIBlack">
            <div className=" font-dosis text-2xl font-medium leading-10 ">{t('DeansReportTopic')}</div>
            <div className=" text-sm font-bold leading-6 ">{t('DeansReportDate')}</div>
          </div>
          <div className="m-auto min-h-[913px] w-fit items-center py-11">
            <PDFViewer pdf={DeanReportPDF} fileName="dean-report.pdf" />
          </div>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default DeansReportPage;
