import React from 'react';
import CollapseItem from '../../components/CollapseSection/CollapseItem';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import บันทึกแจ้งรับนักศึกษาสมทบ18กค65 from './assets/2.-บันทึกแจ้งรับนักศึกษาสมทบ-18-กค-65.doc';
import CurrForAdmissionFinal from './assets/CurrForAdmissionFinal.pdf';
import แจ้งแนวปฏิบัติการรับนักศึกษาสมทบ from './assets/แจ้งแนวปฏิบัติการรับนักศึกษาสมทบ.pdf';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';
import CollapseSectionContainer from '../../components/CollapseSection/CollapseSectionContainer';

const GraduateStudentsAcceptancePage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{t('การรับนักศึกษาระดับบัณฑิตศึกษา')}</PageTitleContainer>
        <CollapseSectionContainer>
          <CollapseItem pdfUrl={แจ้งแนวปฏิบัติการรับนักศึกษาสมทบ}>
            {t('แนวปฏิบัติการรับนักศึกษาสมทบระดับบัณฑิตศึกษา')}
          </CollapseItem>
          <CollapseItem pdfUrl={บันทึกแจ้งรับนักศึกษาสมทบ18กค65}>
            {t('บันทึกแจ้งการรับนักศึกษาสมทบระดับบัณฑิตศึกษา')}
          </CollapseItem>
          <CollapseItem pdfUrl={CurrForAdmissionFinal}>
            {t('ขั้นตอนการร่าง/ปรับปรุงหลักสูตร และการเปิดรับสมัครนักศึกษาบัณฑิตศึกษา')}
          </CollapseItem>
        </CollapseSectionContainer>
      </CustomContainer>
    </Layout>
  );
};

export default GraduateStudentsAcceptancePage;
