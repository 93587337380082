import CloseIcon from '../../Icons/CloseIcon';

const CloseButton = ({ onClick = () => {} }) => {
  return (
    <button onClick={onClick}>
      <div className=" w-16 h-16 bg-UIWhite shadow-md rounded-[50%] m-auto flex justify-center items-center">
        <CloseIcon />
      </div>
    </button>
  );
};
export default CloseButton;
