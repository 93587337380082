import { useState } from 'react';
// import { LoginButtonTheme } from '../../../constants/login-button-theme';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';
// import LoginButton from '../../Button/LoginButton';
// import GraduateCapIcon from '../../Icons/GraduateCapIcon';
// import GraduateCapIconBlack from '../../Icons/GraduateCapIconBlack';
// import MenuIcon from '../../Icons/MenuIcon';
import CrossIcon from '../../Icons/CrossIcon';
import MenuIconBlack from '../../Icons/MenuIconBlack';
import GradCmuLogo from '../../Logo/GradCmuLogo';
import GradCmuLogoBlack from '../../Logo/GradCmuLogoBlack';
import NavigateBar from './NavigateBarV2';
import { useHistory } from 'react-router';
import routeUrlProvider, { CONTACT_US, HOME } from '../../../constants/route-paths';
import PhoneCallIcon from './assets/phone-call.svg';
import ThaiFlag from './assets/flags/thai.png';
import EnglishFlag from './assets/flags/eng.png';
import AdminNavigateBar from './NavigateBarV2/components/AdminNavigateBar';
import { useContextAuthManager } from '../../AuthManager';

const HeaderV2 = ({ headerTransparent = false }) => {
  const history = useHistory();
  // const { t, changeLanguage, isEng, getFontFamily } = useTranslationUtil();
  const { t, changeLanguage, isEng } = useTranslationUtil();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const { isLoggedIn } = useContextAuthManager();

  return (
    <>
      <div className="CustomContainer z-50 flex h-[80px] w-full flex-row justify-end  bg-[#ffffffcc] text-UIBlack backdrop-blur ">
        <div
          className={`${headerTransparent ? 'border-b border-UIWhite ' : ''} flex w-[95%] flex-row justify-between `}
        >
          <div className="flex flex-row  items-center">
            <div
              className="hover:cursor-pointer md:mr-7"
              onClick={() => history.push(routeUrlProvider.getForRoute(HOME))}
            >
              {headerTransparent ? <GradCmuLogo /> : <GradCmuLogoBlack />}
            </div>
            <div className="hidden h-full xl:block">
              <NavigateBar />
            </div>
          </div>
          <div className="flex w-fit flex-row items-center leading-[22px]">
{/*            <button
              //className="flex h-10 w-[120px] items-center justify-center"
             // onClick={() => history.push(routeUrlProvider.getForRoute(CONTACT_US))}
            >
              <div
                className={`flex cursor-pointer gap-x-1 font-bold tracking-wider text-NeonPink shadow-NeonPink text-shadow-text-change`}
              >
                <img src={PhoneCallIcon} alt="" />
                <div className="my-auto "> {t('ContactUs')}</div>
              </div>
  </button> */}
            <button
              className="ml-2 flex h-10  w-12 items-center justify-center lg:ml-8 xl:mr-[72px]"
              onClick={() => changeLanguage()}
            >
              <div
                className={`cursor-pointer font-bold tracking-wider text-NeonPink shadow-NeonPink text-shadow-text-change`}
              >
                <img width="38px" src={isEng() ? ThaiFlag : EnglishFlag} alt={isEng() ? 'TH flag' : 'EN flag'} />
              </div>
            </button>
            <div className="mr-4 md:px-8 xl:hidden">
              <button onClick={() => setIsOpenMenu(!isOpenMenu)} className="z-30">
                <div className="mx-auto w-[30px] text-center"> {isOpenMenu ? <CrossIcon /> : <MenuIconBlack />}</div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="CustomContainer xl:hidden">{isOpenMenu && <NavigateBar />}</div>
      {isLoggedIn && <AdminNavigateBar />}
    </>
  );
};

export default HeaderV2;
