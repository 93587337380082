import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import routeUrlProvider, { AdminRoutePath, HOME } from '../../../constants/route-paths';
import { getListBannerAdmin } from '../../../services/adminBanner';
import { deleteImageAdmin } from '../../../services/adminImage';
import ReactPaginate from 'react-paginate';


const BannerList = ({ history }) => {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [banners, setBanners] = useState([]);
  const perPage = 10;

  useEffect(() => {
    getBannerPaginate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getBannerPaginate = () => {
    getListBannerAdmin({ page, perPage })
      .then((res) => {
        setTotalPage(res.totalPage);
        setBanners([...res.data]);
      })
      .catch((err) => console.log(' err: ', err));
  }

  const deleteBanner = async (id) => {
    if (window.confirm("ต้องการจะลบ Banner") == false) return

    await deleteImageAdmin(id).then()

    getBannerPaginate()
  }

  const handlePageClick = (event) => {
    const selectingPage = event.selected + 1
    setPage(selectingPage)
  };

  return (
    <Layout>
      <div className="text-center "> จัดการ Banner </div>
      <div className="text-center " onClick={() => { history.push(routeUrlProvider.getForLink(AdminRoutePath.ADMIN_BANNER_ADD)) }}> เพิ่ม </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full">
                <thead className="border-b">
                  <tr>
                    <th scope="col" className="text-sm font-medium px-6 py-4 text-left">
                      banner
                    </th>
                    <th scope="col" className="text-sm font-medium px-6 py-4 text-left">
                      ประเภท
                    </th>
                    <th scope="col" className="text-sm font-medium px-6 py-4 text-left">
                      ภาษา
                    </th>
                    <th scope="col" className="text-sm font-medium px-6 py-4 text-left">
                      วันที่แสดง
                    </th>
                    <th scope="col" className="text-sm font-medium px-6 py-4 text-left">
                      วันที่สิ้นสุด
                    </th>
                    <th scope="col" className="text-sm font-medium px-6 py-4 text-left">
                      สร้างโดย
                    </th>
                    <th scope="col" className="text-sm font-medium px-6 py-4 text-left">
                    </th>                    
                    <th scope="col" className="text-sm font-medium px-6 py-4 text-left">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {banners.map((banner, index) => (
                    <tr key={index} className="border-b">
                      <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
                        <img 
                          src={banner.imageUrl}
                          class="aspect-[4/3] max-w-xs"
                        />
                      </td>
                      <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
                        {banner.type}
                      </td>
                      <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
                        {banner.language}
                      </td>
                      <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
                        {new Date(banner.startAt).toDateString()}
                      </td>
                      <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
                        {new Date(banner.endAt).toDateString()}
                      </td>
                      <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
                        {banner.createdByName}
                      </td>
                      <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
                      <div onClick={() => history.push(routeUrlProvider.getForLink(AdminRoutePath.ADMIN_BANNER_EDIT, {id: banner.id}))}>แก้ไข</div>
                      </td>
                      <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
                        <div onClick={() => deleteBanner(banner.id)}>ลบ</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center">
        <div class="inline-flex items-center -space-x-px">
          <ReactPaginate
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            pageCount={totalPage}
            previousLabel="<"
            pageClassName="px-3 py-2 leading-tight hover:text-NeonPink"
            previousClassName="block px-3 py-2 ml-0 leading-tight rounded-l-lg hover:text-NeonPink"
            nextLabel=">"
            nextClassName="block px-3 py-2 leading-tight bg-white rounded-r-lg hover:text-NeonPink"
            breakLabel="..."
            breakClassName="px-3 py-2 leading-tight hover:text-NeonPink"
            containerClassName="inline-flex items-center -space-x-px"
            activeClassName="text-NeonPink"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
      {/* <div className='flex'>
        {bannersTh.map((image, index) => (
        <div key={index} className='relative flex-initial box-content h-32 w-32 border-2 mx-2 border-NeonPink rounded-[10px] grid content-center hover:cursor-pointer'>
          <div className="absolute top-0 right-0 p-2 inline-flex items-center justify-center text-NeonPink z-40" onClick={() => onDeleteImageTh(index)}>
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
          <img
            src={image.imageUrl}
            alt={image.imageName}
            width={332}
            height={187}
            className="rounded-[2px]"
          />
        </div>
        ))}
      </div> */}
    </Layout>
  );
};

export default BannerList;
