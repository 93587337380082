import FacebookIcon from '../../Icons/FacebookIcon';

const FacebookButton = ({ url = 'https://www.facebook.com/gradcmu/' }) => {
  return (
    <button onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}>
      <FacebookIcon />
    </button>
  );
};
export default FacebookButton;
