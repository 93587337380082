import React from 'react';
import CMUPurpleUnderLine80x8 from '../../components/CMUPurpleUnderLine';
import DartIcon from '../../components/Icons/DartIcon';
import ScheduleBoardIcon from '../../components/Icons/ScheduleBoardIcon';
import ShieldPeopleIcon from '../../components/Icons/ShieldPeopleIcon';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import Image1 from '../../components/GraduateStaffPageComponent/assets/front-view-grad.png';
import UnyarathEmsiranan from './assets/UnyarathEmsiranan.png';
import WarawanChutima from './assets/WarawanChutima.png';
import ThanwaratYoddee from './assets/ThanwaratYoddee.png';
import SupawadeeUtaisaen from './assets/SupawadeeUtaisaen.png';
import PalidaPremjai from './assets/PalidaPremjai.png';
import EmikaMengmala from './assets/EmikaMengmala.png';
import NatthineeSawat from './assets/NatthineeSawat.png';
import RapassaThewabhuditr from './assets/RapassaThewabhuditr.png';
import KanyanatUmmee from './assets/KanyanatUmmee.png';
import SuristsaSuphagule from './assets/SuristsaSuphagule.png';
import DutyItem from '../../components/GraduateStaffPageComponent/DutyItem';
import DutyItemIcon from '../../components/GraduateStaffPageComponent/DutyItemIcon';
import DutyItemText from '../../components/GraduateStaffPageComponent/DutyItemText';
import ProfileContainer from '../../components/GraduateStaffPageComponent/ProfileContainer';
import ProfileImage from '../../components/GraduateStaffPageComponent/ProfileImage';
import ProfileDetailContainer from '../../components/GraduateStaffPageComponent/ProfileDetailContainer';
import ProfileName from '../../components/GraduateStaffPageComponent/ProfileName';
import ProfilePosition from '../../components/GraduateStaffPageComponent/ProfilePosition';
import List from '../../components/GraduateStaffPageComponent/List';
import ListItem from '../../components/GraduateStaffPageComponent/ListItem';
import Phone from '../../components/GraduateStaffPageComponent/Phone';
import Email from '../../components/GraduateStaffPageComponent/Email';
import CustomContainer from '../../components/CustomContainer';
import PageTitleGraduateStaff from '../../components/GraduateStaffPageComponent/PageTitleGraduateStaff';
import ProfileList from '../../components/GraduateStaffPageComponent/ProfileList';
import StaffTeamTitle from '../../components/GraduateStaffPageComponent/StaffTeamTitle';
import GraduateStaffPageContainer from '../../components/GraduateStaffPageComponent/GraduateStaffPageContainer';
import DutyContainer from '../../components/GraduateStaffPageComponent/DutyContainer';
import FrontViewGradSchool from '../../components/GraduateStaffPageComponent/FrontViewGradSchool';

const InterdisciplinaryProgramandQAPage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout headerTransparent removeSpaceBeforeFooterDivider>
      <CustomContainer>
        <PageTitleGraduateStaff title={t('InterdisciplinaryProgramandQA')} />
        <GraduateStaffPageContainer>
          {/* <FrontViewGradSchool mobile>
            {t('InterdisciplinaryProgramandQA')}, {t('chiang_mai_university')}
          </FrontViewGradSchool>
          <DutyContainer>
            <div className="col-span-2">
              <div className="mt-7 mb-12 text-[32px] font-medium leading-10 text-Lightgray500">
                {t('duty')}
                <div className="mt-14px">
                  <CMUPurpleUnderLine80x8 />
                </div>
              </div>
              <DutyItem>
                <DutyItemIcon>
                  <ShieldPeopleIcon />
                </DutyItemIcon>
                <DutyItemText> {t('InterdisciplinaryProgram')}</DutyItemText>
              </DutyItem>
              <DutyItem>
                <DutyItemIcon>
                  <DartIcon />
                </DutyItemIcon>
                <DutyItemText> {t('EducationalQualityAssurance')}</DutyItemText>
              </DutyItem>
              <DutyItem>
                <DutyItemIcon>
                  <ScheduleBoardIcon />
                </DutyItemIcon>
                <DutyItemText>{t('InstituteResearch')}</DutyItemText>
              </DutyItem>
            </div>
            <FrontViewGradSchool>
              {t('InterdisciplinaryProgramandQA')}, {t('chiang_mai_university')}
            </FrontViewGradSchool>
          </DutyContainer> */}
          <StaffTeamTitle>{t('InterdisciplinaryProgramandQATeam')}</StaffTeamTitle>
          <ProfileList>
            <ProfileContainer>
              <ProfileImage src={UnyarathEmsiranan} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('UnyarathEmsiranan')}</ProfileName>
                <ProfilePosition>{t('OperationalStaffManager')}</ProfilePosition>
                <List>
                  <ListItem>Interdisciplinary Program</ListItem>
                  <ListItem>Preparation of the New Curriculum</ListItem>
                  <ListItem>Thesis Operation</ListItem>
                  <ListItem>General Advisor Appointment</ListItem>
                </List>
                <Phone>0-5394-2430</Phone>
                <Email>unyarath.em@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={WarawanChutima} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('WarawanChutima')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Academic Work Credits Transfer/Study Program Changing Leave/Resign/Retire/Resume Students Status Qualifying/Comprehensive/Thesis/IS Examination Extension of Study/Extension of Submitting Thesis Fulfill All Courses and Conditions Waive for Frees/Postpone/Extension for Payment Thesis Operation</ListItem>

                  <ListItem>Academic Work</ListItem>
                </List>
                <Phone>0-5394-2429</Phone>
                <Email>warawan.c@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={ThanwaratYoddee} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('ThanwaratYoddee')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Preparation of New Curriculum</ListItem>
                  <ListItem>Main Administrative Work</ListItem>
                  <ListItem>Academic Work</ListItem>
                </List>
                <Phone>0-5394-2427</Phone>
                <Email>thanwarat.y@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={SupawadeeUtaisaen} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('SupawadeeUtaisaen')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Master of Science</ListItem>
                  <ListItem>Program in Technology and Interdisciplinary Management</ListItem>
                </List>
                <Phone>0-5394-2420</Phone>
                <Email>supawadee.u@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={PalidaPremjai} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('PalidaPremjai')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Master of Program in Sports Science</ListItem>
                  <ListItem>Master of Forensic Program in Science</ListItem>
                </List>
                <Phone>0-5394-2426</Phone>
                <Email>palida.p@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={EmikaMengmala} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('EmikaMengmala')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Master of Arts Program in Contemporary Chinese Language Teaching</ListItem>
                </List>
                <Phone>0-5394-2428</Phone>
                <Email>emika.m@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={NatthineeSawat} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('NatthineeSawat')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>iMMH Coordinator</ListItem>
                  <ListItem>Master of Science Program in Mental Health (International Program)</ListItem>
                </List>
                <Phone>0-5394-2424</Phone>
                <Email>natthinee.s@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={RapassaThewabhuditr} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('RapassaThewabhuditr')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>iMMH Coordinator</ListItem>
                  <ListItem>Master of Science Program in Mental Health (International Program)</ListItem>
                </List>
                <Phone>0-5394-2424</Phone>
                <Email>-</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={KanyanatUmmee} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('KanyanatUmmee')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Master of Arts and Science Program in Integrated  Science</ListItem>
                  <ListItem>Doctor of Philosophy Program in Integrated  Science</ListItem>
                  <ListItem>Preparation of New Curriculum</ListItem>
                </List>
                <Phone>-</Phone>
                <Email> Kanyanat.u@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={SuristsaSuphagule} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('SuristsaSuphagule')}</ProfileName>
                <ProfilePosition>{t('OperationalStaff')}</ProfilePosition>
                <List>
                  <ListItem>Master of Science Program in Biotechnology</ListItem>
                  <ListItem>Doctor of Science Program in Biotechnology</ListItem>
                </List>
                <Phone>0-5394-2424</Phone>
                <Email> suristsa.su@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>


          </ProfileList>
        </GraduateStaffPageContainer>
      </CustomContainer>
    </Layout>
  );
};

export default InterdisciplinaryProgramandQAPage;
