export const FACTS_AND_FIGURES_DATA = [
  {
    amount: 6832,
    i18nKeyName: 'CurrentStudents',
  },
  {
    amount: 228,
    i18nKeyName: 'CurrentPrograms',
  },
  // {
  //   amount: 6832,
  //   i18nKeyName: 'InternationalStudent',
  // },
  {
    amount: 2370,
    i18nKeyName: 'CurrentLecturer',
  },
];
