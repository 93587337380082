import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import routeUrlProvider, { AdminRoutePath, HOME } from '../../../constants/route-paths';
import { deleteImageAdmin } from '../../../services/adminImage';
import { getListPosterAdmin } from '../../../services/adminPoster';
import ReactPaginate from 'react-paginate';

const PosterList = ({ history }) => {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [posters, setPosters] = useState([]);
  const perPage = 500;

  useEffect(() => {
    getPosterPaginate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getPosterPaginate = () => {
    getListPosterAdmin({ page, perPage })
      .then((res) => {
        setTotalPage(res.totalPage);
        setPosters([...res.data]);
      })
      .catch((err) => console.log(' err: ', err));
  }

  const deleteImage = async (id) => {
    if (window.confirm("ต้องการจะลบ Poster") == false) return

    await deleteImageAdmin(id).then()

    getPosterPaginate()
  }

  const handlePageClick = (event) => {
    const selectingPage = event.selected + 1
    setPage(selectingPage)
  };

  return (
    <Layout>
      <div className="text-center "> จัดการ Poster </div>
      <div className="text-center " onClick={() => { history.push(routeUrlProvider.getForLink(AdminRoutePath.ADMIN_POSTER_ADD)) }}> เพิ่ม </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full">
                <thead className="border-b">
                  <tr>
                    <th scope="col" className="text-sm font-medium px-6 py-4 text-left">
                      poster
                    </th>
                    <th scope="col" className="text-sm font-medium px-6 py-4 text-left">
                      ภาษา
                    </th>
                    <th scope="col" className="text-sm font-medium px-6 py-4 text-left">
                      สร้างโดย
                    </th>
                    <th scope="col" className="text-sm font-medium px-6 py-4 text-left">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {posters.map((poster, index) => (
                    <tr key={index} className="border-b">
                      <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
                        <img 
                          src={poster.imageUrl}
                          class="aspect-[3/4] max-h-80"
                        />
                      </td>
                      <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
                        {poster.language}
                      </td>
                      <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
                        {poster.createdByName}
                      </td>
                      <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
                        <div onClick={() => deleteImage(poster.id)}>ลบ</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center">
        <div class="inline-flex items-center -space-x-px">
          <ReactPaginate
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            pageCount={totalPage}
            previousLabel="<"
            pageClassName="px-3 py-2 leading-tight hover:text-NeonPink"
            previousClassName="block px-3 py-2 ml-0 leading-tight rounded-l-lg hover:text-NeonPink"
            nextLabel=">"
            nextClassName="block px-3 py-2 leading-tight bg-white rounded-r-lg hover:text-NeonPink"
            breakLabel="..."
            breakClassName="px-3 py-2 leading-tight hover:text-NeonPink"
            containerClassName="inline-flex items-center -space-x-px"
            activeClassName="text-NeonPink"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </Layout>
  );
};

export default PosterList;
