import React from 'react';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import CookieConsent, { resetCookieConsentValue } from 'react-cookie-consent';

const CookieConsentBanner = () => {
  const { t, getFontFamily } = useTranslationUtil();
  resetCookieConsentValue();
  return (
    <div className={`${getFontFamily()} text-center text-lg`}>
      {/* <CookieConsent buttonText={t('AcceptAll')} buttonStyle={{ background: '#fff', borderRadius: '8px' }} expires={90}> */}
      <CookieConsent
        buttonText={t('AcceptAll')}
        buttonStyle={{ background: '#fff', borderRadius: '8px' }}
        expires={90}
        containerClasses="md:px-20"
        buttonWrapperClasses="mx-auto"
      >
        {t('CookieConsentText')}
      </CookieConsent>
    </div>
  );
};

export default CookieConsentBanner;
