import PropTypes from 'prop-types';
import React from 'react';

const PageTitleContainerWithBackgroundImage = ({
  coverImage,
  children,
  className = '',
  mixBlendMode = 'mix-blend-multiply',
}) => {
  return (
    <>
      <div className={`relative h-[195px]  bg-pink-big-background  lg:h-[307px] ${className}`}>
        <img src={coverImage} className={`h-full w-full object-cover ${mixBlendMode}`} alt="cover" />
        <div className="absolute bottom-10 px-5 text-[56px] font-medium text-UIWhite text-shadow-page-title lg:bottom-16 lg:px-20">
          {children}
        </div>
      </div>
    </>
  );
};

PageTitleContainerWithBackgroundImage.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  coverImage: PropTypes.string.isRequired,
};

export default PageTitleContainerWithBackgroundImage;
