import React from 'react';
import CMUPurpleUnderLine80x8 from '../../components/CMUPurpleUnderLine';
import ComputerHeartIcon from '../../components/Icons/ComputerHeartIcon';
import DartIcon from '../../components/Icons/DartIcon';
import LockedShieldIcon from '../../components/Icons/LockedShieldIcon';
import ScheduleBoardIcon from '../../components/Icons/ScheduleBoardIcon';
import ShieldPeopleIcon from '../../components/Icons/ShieldPeopleIcon';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import WasanaThampitakImage from './assets/WasanaThampitak.jpg';
import PaphachonSanluang from './assets/PaphachonSanluang.jpg';
import NatthananKongsichai from './assets/NatthananKongsichai.jpg';
import KorrawanKhotchabutrarat from './assets/KorrawanKhotchabutrarat.jpg';
import WashiraKonkaew from './assets/WashiraKonkaew.jpg';
import RavisadaYannawong from './assets/RavisadaYannawong.png';
import DutyItem from '../../components/GraduateStaffPageComponent/DutyItem';
import DutyItemIcon from '../../components/GraduateStaffPageComponent/DutyItemIcon';
import DutyItemText from '../../components/GraduateStaffPageComponent/DutyItemText';
import ProfileContainer from '../../components/GraduateStaffPageComponent/ProfileContainer';
import ProfileImage from '../../components/GraduateStaffPageComponent/ProfileImage';
import ProfileDetailContainer from '../../components/GraduateStaffPageComponent/ProfileDetailContainer';
import ProfileName from '../../components/GraduateStaffPageComponent/ProfileName';
import ProfilePosition from '../../components/GraduateStaffPageComponent/ProfilePosition';
import List from '../../components/GraduateStaffPageComponent/List';
import ListItem from '../../components/GraduateStaffPageComponent/ListItem';
import Phone from '../../components/GraduateStaffPageComponent/Phone';
import Email from '../../components/GraduateStaffPageComponent/Email';
import CustomContainer from '../../components/CustomContainer';
import PageTitleGraduateStaff from '../../components/GraduateStaffPageComponent/PageTitleGraduateStaff';
import ProfileList from '../../components/GraduateStaffPageComponent/ProfileList';
import StaffTeamTitle from '../../components/GraduateStaffPageComponent/StaffTeamTitle';
import FrontViewGradSchool from '../../components/GraduateStaffPageComponent/FrontViewGradSchool';
import DutyContainer from '../../components/GraduateStaffPageComponent/DutyContainer';
import GraduateStaffPageContainer from '../../components/GraduateStaffPageComponent/GraduateStaffPageContainer';

const GraduateSchoolOfficePage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout removeSpaceBeforeFooterDivider>
      <CustomContainer>
        <PageTitleGraduateStaff title={t('GraduateSchoolOffice')} />
        <GraduateStaffPageContainer>
          {/* <FrontViewGradSchool mobile>
            {t('GraduateSchoolOffice')}, {t('chiang_mai_university')}
          </FrontViewGradSchool> */}
          {/* <DutyContainer>
            <div>
              <div className="mt-7 mb-12 text-[32px] font-medium leading-10 text-Lightgray500">
                {t('duty')}
                <div className="mt-14px">
                  <CMUPurpleUnderLine80x8 />
                </div>
              </div>
              <DutyItem>
                <DutyItemIcon>
                  <ShieldPeopleIcon />
                </DutyItemIcon>
                <DutyItemText> {t('ManagementAndAdministration')}</DutyItemText>
              </DutyItem>
              <DutyItem>
                <DutyItemIcon>
                  <DartIcon />
                </DutyItemIcon>
                <DutyItemText> {t('PlanWork')}</DutyItemText>
              </DutyItem>
              <DutyItem>
                <DutyItemIcon>
                  <ScheduleBoardIcon />
                </DutyItemIcon>
                <DutyItemText>{t('CreateFacultySchedule')}</DutyItemText>
              </DutyItem>
              <DutyItem>
                <DutyItemIcon>
                  <LockedShieldIcon />
                </DutyItemIcon>
                <DutyItemText> {t('ServerAndNetwork')}</DutyItemText>
              </DutyItem>
              <DutyItem>
                <DutyItemIcon>
                  <ComputerHeartIcon />
                </DutyItemIcon>
                <DutyItemText> {t('ItService')}</DutyItemText>
              </DutyItem>
            </div>
            <FrontViewGradSchool>
              {t('GraduateSchoolOffice')}, {t('chiang_mai_university')}
            </FrontViewGradSchool>
          </DutyContainer> */}
          <StaffTeamTitle>{t('GraduateSchoolOfficeTeam')}</StaffTeamTitle>
          <ProfileList>
            <ProfileContainer>
              <ProfileImage src={WasanaThampitakImage} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('WasanaThampitak')}</ProfileName>
                <ProfilePosition>{t('FacultySecretary')}</ProfilePosition>
                <List>
                  <ListItem>Faculty Secretary</ListItem>
                  <ListItem>Educational Quality Assurance</ListItem>
                </List>
                <Phone>0-5394-2414</Phone>
                <Email>wasana.t@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>

            <ProfileContainer>
              <ProfileImage src={PaphachonSanluang} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('PaphachonSanluang')}</ProfileName>
                <ProfilePosition>{t('ComputerAcademician')}</ProfilePosition>
                <List>
                  <ListItem>Server, OS, Network Systems, Private and Public Cloud</ListItem>
                  <ListItem>Develop work systems and provide information and information services</ListItem>
                  <ListItem>Update and backup data systematically</ListItem>
                  <ListItem>Back-end : PHP, API provide</ListItem>
                  <ListItem>Front-end : Full stack dev., Node.js, React</ListItem>
                  <ListItem>Student 365 (Line app.), Staff and Student Portal</ListItem>
                  <ListItem>Security</ListItem>
                  <ListItem>Coordinate with ITSC and software license</ListItem>
                </List>
                <Phone>0-5394-2418</Phone>
                <Email>chayoot.s@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={NatthananKongsichai} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('NatthananKongsichai')}</ProfileName>
                <ProfilePosition>{t('ComputerAcademician')}</ProfilePosition>
                <List>
                  <ListItem>Web developer (Admission system , Scholarship sytem , Letter of Recommendation)</ListItem>
                  <ListItem>Development of graduate support service system</ListItem>
                  <ListItem>Information technology and information technology services and report</ListItem>
                  <ListItem>Quick win dev. (Research Title, Scholarship, Publication)</ListItem>
                  <ListItem>Back-end : PHP</ListItem>
                  <ListItem>Front-end : Node.js, React</ListItem>
                </List>
                <Phone>0-5394-2418</Phone>
                <Email>natthanan.k@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>

            <ProfileContainer>
              <ProfileImage src={WashiraKonkaew} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('WashiraKonkaew')}</ProfileName>
                <ProfilePosition>{t('DivisionStaff')}</ProfilePosition>
                <List>
                  <ListItem>International Relations</ListItem>
                  <ListItem>Public Relations</ListItem>
                  <ListItem>Corporate Communication</ListItem>
                </List>
                <Phone>0-5394-2405</Phone>
                <Email>washira.k@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={RavisadaYannawong} className="max-w-[205px]" alt="" />
              <ProfileDetailContainer>
                <ProfileName>{t('RavisadaYannawong')}</ProfileName>
                <ProfilePosition>{t('ProjectStaff')}</ProfilePosition>
                <List>
                  <ListItem>เจ้าหน้าที่ประสานงานโครงการจัดเตรียมระบบนิเวศ (Ecosystem)</ListItem>
                  <ListItem>สำหรับพัฒนาหลักสูตรพหุวิทยาการ (Multidisciplinary Programs: MDPs</ListItem>
                </List>
                <Phone>0-5394-2418</Phone>
                <Email>	ravisada.y@cmu.ac.th</Email>
              </ProfileDetailContainer>
            </ProfileContainer>

          </ProfileList>
        </GraduateStaffPageContainer>
      </CustomContainer>
    </Layout>
  );
};

export default GraduateSchoolOfficePage;
