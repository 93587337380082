import { useEffect, useState } from 'react';
import NewsContentControl from './components/NewsContentControl';
import { NEWS_TYPES, getNewsQueryParams } from '../../../utils/news';
import { getListNews } from '../../../services/news';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';
import CustomContainer from '../../../components/CustomContainer';

const NewsSection = () => {
  const { t, getApiLanguageCode, nowLanguageCode } = useTranslationUtil();
  const [seminarNews, setSeminarNews] = useState([]);
  const [studentNews, setStudentNews] = useState([]);
  const [supplyNews, setSupplyNews] = useState([]);

  useEffect(() => {
    const languageCode = getApiLanguageCode();
    getListNews({
      newsTypeQuery: getNewsQueryParams(NEWS_TYPES.STUDENT_NEWS),
      page: 1,
      perPage: 3,
      language: languageCode,
    })
      .then((res) => {
        setStudentNews(res.data || []);
      })
      .catch((err) => console.error(' err getList STUDENT_NEWS  ', err));
    getListNews({
      newsTypeQuery: getNewsQueryParams(NEWS_TYPES.SEMINAR_NEWS),
      page: 1,
      perPage: 3,
      language: languageCode,
    })
      .then((res) => {
        setSeminarNews(res.data || []);
      })
      .catch((err) => console.error(' err getList SEMINAR_NEWS', err));
    getListNews({
      newsTypeQuery: getNewsQueryParams(NEWS_TYPES.SUPPLY_NEWS),
      page: 1,
      perPage: 3,
      language: languageCode,
    })
      .then((res) => {
        setSupplyNews(res.data || []);
      })
      .catch((err) => console.error(' err getList SUPPLY_NEWS', err));
  }, [nowLanguageCode]);

  if (seminarNews.length === 0 && studentNews.length === 0 && supplyNews.length === 0) {
    return;
  }

  return (
    <CustomContainer className="xl-px-48 lg:px-20">
      {/* <div className=" text-center text-[64px] font-light leading-[80px] text-UIBlack">{t('news')}</div> */}
      <div className=" text-center text-[48px] font-medium leading-[61px]">{t('news')}</div>
      <div className="mt-12">
        <NewsContentControl seminarNews={seminarNews} studentNews={studentNews} supplyNews={supplyNews} />
      </div>
    </CustomContainer>
  );
};

export default NewsSection;
