export const MOCK_MULTIDISCIPLINARY_PROGRAM_PIE_GRAPH_GROUP_BY_MASTER = [
  {
    name_th: 'เทคโนโลยีชีวภาพ',
    name_en: 'Biotechnology',
    student_amount: 34,
    student_percentage: '23.29%',
  },
  {
    name_th: 'เทคโนโลยีและสหวิทยาการจัดการ',
    name_en: 'Technology and Interdisciplinary Management',
    student_amount: 21,
    student_percentage: '14.38%',
  },
  {
    name_th: 'นิติวิทยาศาสตร์',
    name_en: 'Forensic Science',
    student_amount: 17,
    student_percentage: '11.64%',
  },
  {
    name_th: 'วิทยาศาสตร์การกีฬา',
    name_en: 'Sports Science',
    student_amount: 19,
    student_percentage: '13.01%',
  },
  {
    name_th: 'สุขภาพจิต',
    name_en: 'Mental Health',
    student_amount: 20,
    student_percentage: '13.70',
  },
  {
    name_th: 'การสอนภาษาจีนร่วมสมัย',
    name_en: 'Contemporary Chinese Language Teaching',
    student_amount: 35,
    student_percentage: '23.97%',
  },
  {
    name_th: 'บูรณาการศาสตร์',
    name_en: 'Integrated Science',
    student_amount: 0,
    student_percentage: '0%',
  },
];
