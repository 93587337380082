import React from 'react';
import CollapseSection from '../../components/CollapseSection';
import CollapseItem from '../../components/CollapseSection/CollapseItem';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import CustomContainer from '../../components/CustomContainer';
import enrollmentnewpdf from './assets/enroll/3-enrollment.newpdf.pdf';
import RegistrationforUsingUniversitysServicesofGraduateStudents from './assets/enroll/20120120-CMU-Announcement-on-Using-U-Services-ใช้บริการ.pdf';
import AcademicCalendar2022 from './assets/calendar/REG-Academic-Calendar-2565.pdf';
import AcademicCalendar2023 from './assets/calendar/REG-Academic-Calendar-2566.pdf';
import PageTitleContainer from '../../components/PageTitleContainer';
import CollapseSectionContainer from '../../components/CollapseSection/CollapseSectionContainer';

const EnrollmentAcademicCalendarPage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{t('EnrollmentAcademicCalendar')}</PageTitleContainer>

        <CollapseSectionContainer>
          <CollapseSection title={t('EnrollmentDetail')}>
            <CollapseItem pdfUrl={enrollmentnewpdf}>{t('EnrollmentDetail')}</CollapseItem>
            <CollapseItem pdfUrl={RegistrationforUsingUniversitysServicesofGraduateStudents}>
              {t('RegistrationforUsingUniversitysServicesofGraduateStudents')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('AcademicCalendar')}>
            <CollapseItem pdfUrl={AcademicCalendar2023}>{t('AcademicCalendar2023')}</CollapseItem>
            <CollapseItem pdfUrl={AcademicCalendar2022}>{t('AcademicCalendar2022')}</CollapseItem>
          </CollapseSection>
        </CollapseSectionContainer>
      </CustomContainer>
    </Layout>
  );
};

export default EnrollmentAcademicCalendarPage;
