import React from 'react';

const StepNumberCircle = ({ children }) => (
  <div>
    <div className="mx-auto h-20 w-20 items-center rounded-full border-[1.5px] border-solid border-PrimaryDark text-center align-middle text-[28px] font-medium leading-[80px] text-PrimaryDark">
      {children}
    </div>
  </div>
);

export default StepNumberCircle;
