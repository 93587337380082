import React from 'react';
import { ReactComponent as UpArrow } from '../../../assets/up-arrow.svg';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';

const BackToTopButton = () => {
  const { t, getFontFamily } = useTranslationUtil();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // for smoothly scrolling
    });
  };

  return (
    <button onClick={scrollToTop} type="button">
      <div className="flex flex-row ">
        <div className={`${getFontFamily(true)}  text-lg font-bold leading-6 text-NeonPink`}>{t('back_to_top')}</div>
        <div className="ml-[26px]">
          <UpArrow />
        </div>
      </div>
    </button>
  );
};

export default BackToTopButton;
