import React from 'react';
import Modal from 'react-modal';
import CrossIcon from '../../Icons/CrossIcon';
import { afterCloseModal, afterOpenModal } from '../../../utils/modal';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 30,
    borderRadius: 8,
    border: 0,
  },
};

const ComingSoonModal = ({ isOpen = false, onRequestClose = () => {} }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      onAfterClose={afterCloseModal}
      onAfterOpen={afterOpenModal}
    >
      <div className="absolute top-3 right-3 flex flex-row justify-end">
        <button onClick={onRequestClose}>
          <CrossIcon />
        </button>
      </div>
      <div className=" mt-3 pb-3 text-2xl font-bold ">Coming Soon</div>
    </Modal>
  );
};

export default ComingSoonModal;
