import Layout from '../../components/Layout';
import CoverImage from './assets/banner.png';
import PodcastCoverEp1 from './assets/podcast-ep-1.png';
import PodcastCoverEp2 from './assets/podcast-ep-2.png';
import PodcastCoverEp3 from './assets/podcast-ep-3.png';
import PodcastCoverEp4 from './assets/podcast-ep-4.png';
import YoutubePlayerModal from '../../components/Modal/YoutubePlayerModal';
import PodcastEpContainer from './components/PodcastEpContainer';
import EpTextContainer from './components/EpTextContainer';
import EpNumber from './components/EpNumber';
import EpTitle from './components/EpTitle';
import LigthgrayDivider from './components/LigthgrayDivider';
import SpeakerDetail from './components/SpeakerDetail';
import SpeakerPosition from './components/SpeakerPosition';
import CustomContainer from '../../components/CustomContainer';

const MobileYoutubeContainer = ({ children }) => (
  <div className="mt-20  flex flex-col md:mt-11 lg:hidden">{children}</div>
);
const SpeakerContainer = ({ children }) => <div className="flex w-full flex-row justify-between">{children}</div>;
const DesktopYoutubeContainer = ({ children }) => <div className="hidden lg:flex">{children}</div>;

const PodcastLive = () => {
  return (
    <Layout headerTransparent>
      <CustomContainer>
        <div className="relative h-[257px] bg-opacity-30 bg-pink-big-background">
          <img src={CoverImage} className="h-full w-full object-cover mix-blend-overlay" alt="" />
          <div className="relative">
            <div className="absolute bottom-12 px-3 text-[56px] font-medium text-UIWhite text-shadow-page-title md:px-5  lg:pl-20">
              Podcast Live
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <PodcastEpContainer bgLightgray100>
            <EpTextContainer>
              <EpNumber>EP. 01</EpNumber>
              <EpTitle>ทุน Active Presidential, TA/RA และทุนการศึกษา ระดับบัณฑิตศึกษา มช.</EpTitle>
              <SpeakerContainer>
                <div>
                  <LigthgrayDivider />
                  <SpeakerDetail>
                    Associate Professor
                    <br />
                    Apichat Sopadang, Ph.D.
                  </SpeakerDetail>
                  <SpeakerPosition>Dean of Graduate School</SpeakerPosition>
                </div>
                <MobileYoutubeContainer>
                  <YoutubePlayerModal
                    coverImage={PodcastCoverEp1}
                    autoPlay
                    url="https://www.youtube.com/watch?v=jc73dxrW5gY"
                  />
                </MobileYoutubeContainer>
              </SpeakerContainer>
            </EpTextContainer>
            <DesktopYoutubeContainer>
              <YoutubePlayerModal
                coverImage={PodcastCoverEp1}
                autoPlay
                url="https://www.youtube.com/watch?v=jc73dxrW5gY"
              />
            </DesktopYoutubeContainer>
          </PodcastEpContainer>
          <PodcastEpContainer>
            <EpTextContainer>
              <EpNumber>EP. 02</EpNumber>
              <EpTitle>มาทำความรู้จักการทดสอบทักษะภาษาอังกฤษ ป.โท-ป.เอก กันเถอะ</EpTitle>
              <SpeakerContainer>
                <div>
                  <LigthgrayDivider />
                  <SpeakerDetail>
                    Associate Professor
                    <br />
                    Wantida Chaiyana, Ph.D.
                  </SpeakerDetail>
                  <SpeakerPosition>Associate Dean of Graduate School</SpeakerPosition>
                </div>
                <MobileYoutubeContainer>
                  <YoutubePlayerModal
                    coverImage={PodcastCoverEp2}
                    autoPlay
                    url="https://www.youtube.com/watch?v=UPGWLpf5D4w"
                  />
                </MobileYoutubeContainer>
              </SpeakerContainer>
            </EpTextContainer>
            <DesktopYoutubeContainer>
              <YoutubePlayerModal
                coverImage={PodcastCoverEp2}
                autoPlay
                url="https://www.youtube.com/watch?v=UPGWLpf5D4w"
              />
            </DesktopYoutubeContainer>
          </PodcastEpContainer>
          <PodcastEpContainer bgLightgray100>
            <EpTextContainer>
              <EpNumber>EP. 03</EpNumber>
              <EpTitle>
                STUDENT LIVE @CMU <br />
                การเตรียมความพร้อมใช้ชีวิตนักศึกษาระดับบัณฑิตศึกษา
              </EpTitle>
              <SpeakerContainer>
                <div>
                  <LigthgrayDivider />
                  <SpeakerDetail>
                    Associate Professor
                    <br />
                    Suraphong Lorsomradee, Ph.D.
                  </SpeakerDetail>
                  <SpeakerPosition>Associate Dean of Graduate School</SpeakerPosition>
                </div>
                <MobileYoutubeContainer>
                  <YoutubePlayerModal
                    coverImage={PodcastCoverEp3}
                    autoPlay
                    url="https://www.youtube.com/watch?v=zlYEcSkDPgA"
                  />
                </MobileYoutubeContainer>
              </SpeakerContainer>
            </EpTextContainer>
            <DesktopYoutubeContainer>
              <YoutubePlayerModal
                coverImage={PodcastCoverEp3}
                autoPlay
                url="https://www.youtube.com/watch?v=zlYEcSkDPgA"
              />
            </DesktopYoutubeContainer>
          </PodcastEpContainer>
          <PodcastEpContainer>
            <EpTextContainer>
              <EpNumber>EP. 04</EpNumber>
              <EpTitle>เปิดรับสมัครเรียน ป.โท ป.มช. รอบ 2 พร้อมโอกาสทุนการศึกษา</EpTitle>
              <SpeakerContainer>
                <div>
                  <LigthgrayDivider />
                  <SpeakerDetail>
                    Associate Professor
                    <br />
                    Wantida Chaiyana, Ph.D.
                  </SpeakerDetail>
                  <SpeakerPosition>Associate Dean of Graduate School</SpeakerPosition>
                </div>
                <MobileYoutubeContainer>
                  <YoutubePlayerModal
                    coverImage={PodcastCoverEp4}
                    autoPlay
                    url="https://www.youtube.com/watch?v=9a17-zVpnfA"
                  />
                </MobileYoutubeContainer>
              </SpeakerContainer>
            </EpTextContainer>
            <DesktopYoutubeContainer>
              <YoutubePlayerModal
                coverImage={PodcastCoverEp4}
                autoPlay
                url="https://www.youtube.com/watch?v=9a17-zVpnfA"
              />
            </DesktopYoutubeContainer>
          </PodcastEpContainer>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default PodcastLive;
