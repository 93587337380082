import React from 'react';
import Layout from '../../components/Layout';
import { ReactComponent as BgEffectSVG } from './assets/white-circle-bg.svg';
import BgWhiteCircle from './assets/white-circle-bg.svg';
import CoverImage from './assets/Cover.png';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import ApichatSopadang from './assets/ApichatSopadang.png';
import ApichatSopadangModal from './assets/ApichatSopadangModal.jpg';
import EmailWhiteIcon from '../../components/Icons/EmailWhiteIcon';
import PhoneWhiteIcon from '../../components/Icons/PhoneWhiteIcon';
import EkkaratBoonchieng from './assets/EkkaratBoonchieng.jpg';
import EkkaratBoonchiengModal from './assets/EkkaratBoonchiengModal.jpg';
import SuraphongLorsomradee from './assets/SuraphongLorsomradee.jpg';
import SuraphongLorsomradeeModal from './assets/SuraphongLorsomradeeModal.jpg';
import ThongchaiPhuwanatwichit from './assets/ThongchaiPhuwanatwichit.jpg';
import ThongchaiPhuwanatwichitModal from './assets/ThongchaiPhuwanatwichitModal.jpg';
import WantidaChaiyana from './assets/WantidaChaiyana.jpg';
import WantidaChaiyanaModal from './assets/WantidaChaiyanaModal.jpg';
import KasinPrakobwaitayakit from './assets/KasinPrakobwaitayakit.jpg';
import KasinPrakobwaitayakitModal from './assets/KasinPrakobwaitayakitModal.jpg';
import SalineeSantiteerakul from './assets/SalineeSantiteerakul.jpg';
import SalineeSantiteerakulModal from './assets/SalineeSantiteerakulModal.jpg';
import TulayaTulardilok from './assets/TulayaTulardilok.jpg';
import TulayaTulardilokModal from './assets/TulayaTulardilokModal.jpg';
import VorathamonCherapanukorn from './assets/VorathamonCherapanukorn.jpg';
import VorathamonCherapanukornModal from './assets/VorathamonCherapanukornModal.jpg';

import ProfileHistoryModal, { Phone, Email } from './components/ProfileHistoryModal';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainerWithBackgroundImage from '../../components/PageTitleContainerWithBackgroundImage';

const EmailWhite = ({ children }) => (
  <a className="mt-1 ml-1 flex flex-row" href={`mailto:${children}`} target="_blank" rel="noopener noreferrer">
    <div className="my-auto">
      <EmailWhiteIcon />
    </div>
    <div className="my-auto ml-[10px] font-dosis  text-lg text-[10px] font-bold leading-[13px] text-UIWhite md:text-lg md:leading-6">
      {children}
    </div>
  </a>
);

const PhoneWhite = ({ children }) => (
  <div className="mt-6 flex flex-row">
    <div className="my-auto ml-1">
      <PhoneWhiteIcon />
    </div>
    <div className="my-auto ml-[11px] font-dosis text-[10px] font-bold leading-[13px] text-UIWhite md:text-lg md:leading-6">
      {children}
    </div>
  </div>
);

const ProfileContainer = ({ children }) => <div className="mt-10 grid grid-cols-1 md:grid-cols-2">{children}</div>;
const ProfileDetailContainer = ({ children }) => (
  <div className=" mx-auto mt-8 flex h-full flex-col md:mx-0 md:mt-0 md:justify-between">{children}</div>
);
const ProfilePosition = ({ children }) => <div className="text-lg font-bold leading-6">{children}</div>;
const ProfileName = ({ children }) => <div>{children}</div>;
const ProfileWorkPositon = ({ children }) => <div>{children}</div>;
const ProfileWorkListItem = ({ children }) => <li>{children}</li>;
const ProfileWorkListContainer = ({ children }) => <ul className="list-inside list-disc">{children}</ul>;
const ProfileImage = ({ src }) => (
  <img src={src} alt="" className="mx-auto h-[300px] w-[205px] shadow-[-0px_14px_42px_#FFC6FF] " />
);
const ProfileGroupContainer = ({ children }) => (
  <div className="grid grid-cols-1 gap-y-10 px-2 lg:grid-cols-2 lg:px-20">{children}</div>
);

const ExecutivePage = () => {
  const { t } = useTranslationUtil();

  return (
    <Layout removeSpaceBeforeFooterDivider hideFooterDivider>
      <CustomContainer>
        <div className="overflow-hidden">
          {/* <div className="absolute -z-0 px-40 pt-28 text-[56px] font-medium leading-[71px] text-UIWhite text-shadow-xl">
            {t('Executive')}
          </div>
          <img src={CoverImage} className="z-0 -mt-topbar mix-blend-lighten " alt="" /> */}
          <PageTitleContainerWithBackgroundImage coverImage={CoverImage}>
            {t('Executive')}
          </PageTitleContainerWithBackgroundImage>
          <div className="absolute flex w-full flex-row justify-center">
            <img
              src={ApichatSopadang}
              alt=""
              className="absolute mx-auto mt-[92px] h-[216px] w-[157px] md:-mt-[71px] md:h-[378px] md:w-[274px] lg:h-[378px] lg:w-[274px] "
            />
            <div className="mt-8 flex w-full  flex-row justify-between px-3 text-UIWhite lg:px-10 xl:px-20">
              <div className="flex flex-col justify-between">
                <div className=" max-w-[135px] text-2xl font-medium leading-[30px] md:max-w-[200px] md:text-[40px] md:leading-[51px]">
                  {t('DeanOfGraduateSchool')}
                </div>
                <ProfileHistoryModal
                  addPaddingTop
                  whiteButtonTheme
                  imgSrc={ApichatSopadangModal}
                  position={t('DeanOfGraduateSchool')}
                  titlePositionName={t('associate_professor')}
                  name={t('ApichatSopadang')}
                  email={'apichat.s@cmu.ac.th'}
                  phoneNumber="+66-5394-2402"
                  academicRelatedWork={
                    <>
                      <li>
                        Vice Chairman the Council of the Graduate Studies Administrators of Public and Autonomous
                        Universities (CGAU) 2021-2022
                      </li>
                      <li>
                        Vice Chairman the Council of the Graduate Studies Administrators of Thailand (CGAT) 2021-2022
                      </li>
                      <li>
                        Head of Excellence Center in Logistics and Supply Chain Management (E-LSCM), Chiang Mai
                        University
                      </li>
                    </>
                  }
                  researchArea={
                    <>
                      <li>Supply Chain and Logistics Management</li>
                      <li>Multiple Criteria Decision Making</li>
                      <li>Performance Measurement</li>
                      <li>Risk Management</li>
                      <li>Lean System and Sustainable Supply Chain</li>
                      <li>Aviation Logistics</li>
                    </>
                  }
                />
              </div>
              <div>
                <div className="text-right text-[12px] font-medium leading-4 md:text-[24px] md:leading-[30px] lg:text-[32px] lg:leading-[40px]">
                  {t('associate_professor')}
                </div>
                <div className="mr-0 ml-auto max-w-[110px] text-right text-[18px] font-medium leading-[23px] md:max-w-[231px] md:text-[24px] md:leading-[30px] lg:max-w-full lg:text-[40px] lg:leading-[51px]">
                  {t('ApichatSopadang')}
                </div>
                <div className="flex w-full flex-row justify-end">
                  <div className="mt-[13px] h-2 w-20 bg-UIWhite" />
                </div>
                <div className="mx-auto mt-[90px] max-w-[181px]">
                  <PhoneWhite>+66-5394-2402</PhoneWhite>
                  <EmailWhite>apichat.s@cmu.ac.th</EmailWhite>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="relative">
            <div className="absolute z-0 mx-auto w-fit">
              <BgEffectSVG />
            </div>
          </div> */}

          <div className="h-[307px] bg-pink-big-foreground"></div>
          <div className=" mx-auto  -mt-[461px] w-[100px] md:w-fit ">
            <BgEffectSVG />
            {/* <img src={BgWhiteCircle} alt="" /> */}
          </div>
        </div>

        <div className="mx-auto mt-16 w-fit text-[32px] font-medium leading-10 text-Lightgray500 lg:mx-0 lg:px-20 ">
          {t('associate_dean')}
        </div>

        <ProfileGroupContainer>
          <ProfileContainer>
            <ProfileImage src={EkkaratBoonchieng} />
            <ProfileDetailContainer>
              <div>
                <ProfilePosition>{t('associate_professor')}</ProfilePosition>
                <ProfileName>{t('EkkaratBoonchieng')}</ProfileName>
                <ProfileWorkPositon>{t('AssociateDeanofGraduateSchool')}</ProfileWorkPositon>
                <ProfileWorkListContainer>
                  <ProfileWorkListItem>Student Development Affair</ProfileWorkListItem>
                  <ProfileWorkListItem>Information Technology</ProfileWorkListItem>
                </ProfileWorkListContainer>
                <ProfileHistoryModal
                  imgSrc={EkkaratBoonchiengModal}
                  position={t('AssociateDeanofGraduateSchool')}
                  titlePositionName={t('associate_professor')}
                  name={t('EkkaratBoonchieng')}
                  email={'ekkarat.boonchieng@cmu.ac.th'}
                  educationalBackground={
                    <>
                      <li>
                        ECTI Technical Chair (Computers) Electrical Engineering/ Electronics, Computer,
                        Telecommunications and Information Technology Association of Thailand (ECTI Thailand)
                      </li>
                      <li>Director Centre of Excellence in Community Health informatics, Chiang Mai University</li>
                      <li>Deputy Director Chiang Mai University Service Center (UNISERV)</li>
                    </>
                  }
                  academicRelatedWork={
                    <>
                      <li>
                        ECTI Technical Chair (Computers) Electrical Engineering/ Electronics, Computer,
                        Telecommunications and Information Technology Association of Thailand (ECTI Thailand)
                      </li>
                      <li>Director Centre of Excellence in Community Health informatics, Chiang Mai University</li>
                      <li>Deputy Director Chiang Mai University Service Center (UNISERV)</li>
                    </>
                  }
                  researchArea={
                    <>
                      <li>Data communication</li>
                      <li>Computer networking</li>
                      <li>Computer security</li>
                      <li>Computer graphics</li>
                      <li>Image processing</li>
                      <li>science and Biomedical engineering</li>
                    </>
                  }
                />
              </div>
              <Email>ekkarat.boonchieng@cmu.ac.th</Email>
            </ProfileDetailContainer>
          </ProfileContainer>
          <ProfileContainer>
            <ProfileImage src={SuraphongLorsomradee} />
            <ProfileDetailContainer>
              <div>
                <ProfilePosition>{t('associate_professor')}</ProfilePosition>
                <ProfileName>{t('SuraphongLorsomradee')}</ProfileName>
                <ProfileWorkPositon>{t('AssociateDeanofGraduateSchool')}</ProfileWorkPositon>
                <ProfileWorkListContainer>
                  <ProfileWorkListItem>International Relations</ProfileWorkListItem>
                  <ProfileWorkListItem>Strategy Planning</ProfileWorkListItem>
                </ProfileWorkListContainer>
                <ProfileHistoryModal
                  imgSrc={SuraphongLorsomradeeModal}
                  position={t('AssociateDeanofGraduateSchool')}
                  titlePositionName={t('associate_professor')}
                  name={t('SuraphongLorsomradee')}
                  email={'suraphong.lor@cmu.ac.th'}
                  educationalBackground={
                    <>
                      <li>
                        Ph.D. in Medicine from Keio University Tokyo Japan in cardiac output and hemodynamic monitoring,
                        research interests include hemodynamic monitoring, ERAS and goal directed therapy for organ
                        protection.
                      </li>
                    </>
                  }
                  academicRelatedWork={
                    <>
                      The fellowship in cardiac anesthesia and transplantation anesthesia from Antwerp University
                      Hospital, Belgium.
                      <li>
                        TIVA training center, division of cardiac anesthesia, division of liver transplantation
                        anesthasia, department of anesthesiology, Chiang Mai Hospital, Thailand and Adjunct Professor,
                        University of INDONESIA
                      </li>
                      <li>
                        Director of International Cooperation of the Society of Cardiothoracic and Vascular
                        Anesthesiologists of Thailand (SCAT), Board of Directors of Asia Anesthesia Forum (AAF), and
                        Board of Directors of Asian{' '}
                      </li>
                      <li>Society of Cardiothoracic Anesthesia (ASCA).</li>
                      <li>
                        The scientific chairman of ASCA2022 which will be held in November 10-13, 2022 in CHIANG MAI,
                        THAILAND
                      </li>
                      <li>
                        An international educator of anesthesia and critical care medicine in Asia, especially the
                        practical fluid and hemodynamic management (GDTEducAsia).
                      </li>
                    </>
                  }
                />
              </div>
              <Email>suraphong.lor@cmu.ac.th</Email>
            </ProfileDetailContainer>
          </ProfileContainer>
          <ProfileContainer>
            <ProfileImage src={ThongchaiPhuwanatwichit} />
            <ProfileDetailContainer>
              <div>
                <ProfilePosition>{t('associate_professor')}</ProfilePosition>
                <ProfileName>{t('ThongchaiPhuwanatwichit')}</ProfileName>
                <ProfileWorkPositon>{t('AssociateDeanofGraduateSchool')}</ProfileWorkPositon>
                <ProfileWorkListContainer>
                  <ProfileWorkListItem>Administer</ProfileWorkListItem>
                  <ProfileWorkListItem>Operation Plan</ProfileWorkListItem>
                </ProfileWorkListContainer>
                <ProfileHistoryModal
                  imgSrc={ThongchaiPhuwanatwichitModal}
                  position={t('AssociateDeanofGraduateSchool')}
                  titlePositionName={t('associate_professor')}
                  name={t('ThongchaiPhuwanatwichit')}
                  email={'thongchai.phu@cmu.ac.th'}
                  fildsOfInterest={
                    <>
                      <li>Home and Community</li>
                      <li>Community Resources Management</li>
                      <li>Community Management Innovation</li>
                      <li>Community Welfare</li>
                      <li>Cross-border Tourism</li>
                      <li>Cultural Tourism Management</li>
                      <li>Human and Environment Management</li>
                      <li>Human Resources Management</li>
                      <li>Zen Philosophy and Human Art</li>
                    </>
                  }
                />
              </div>
              <Email>thongchai.phu@cmu.ac.th</Email>
            </ProfileDetailContainer>
          </ProfileContainer>
          <ProfileContainer>
            <ProfileImage src={WantidaChaiyana} />
            <ProfileDetailContainer>
              <div>
                <ProfilePosition>{t('associate_professor')}</ProfilePosition>
                <ProfileName>{t('WantidaChaiyana')}</ProfileName>
                <ProfileWorkPositon>{t('AssociateDeanofGraduateSchool')}</ProfileWorkPositon>
                <ProfileWorkListContainer>
                  <ProfileWorkListItem>Academic</ProfileWorkListItem>
                  <ProfileWorkListItem>Research</ProfileWorkListItem>
                </ProfileWorkListContainer>
                <ProfileHistoryModal
                  imgSrc={WantidaChaiyanaModal}
                  position={t('AssociateDeanofGraduateSchool')}
                  titlePositionName={t('associate_professor')}
                  name={t('WantidaChaiyana')}
                  email={'wantida.chaiyana@cmu.ac.th'}
                  phoneNumber="+66-5394-2404"
                  educationalBackground={
                    <>
                      <li>
                        Doctor of Philosophy (Pharmacy), The Royal Golden Jubilee Ph.D. Program, Chiang Mai University,
                        Thailand
                      </li>
                      <li>
                        Master of Business Administration (Business Administration), Chiang Mai University, Thailand
                      </li>
                      <li>Bachelor of Pharmacy (First-Class Honors, Gold Medal), Chiang Mai University, Thailand</li>
                    </>
                  }
                  researchArea={
                    <>
                      <li>Pharmaceutical technology</li>
                      <li>Nanotechnology</li>
                      <li>Transdermal delivery system</li>
                      <li>Drug delivery system</li>
                      <li>Drug and cosmetic product development</li>
                      <li>Anti-wrinkle</li>
                      <li>Performance test on skin</li>
                      <li>Satisfactory evaluation</li>
                      <li>Biological activities determination</li>
                      <li>Irritation test</li>
                    </>
                  }
                />
              </div>
              <div>
                <Phone>+66-5394-2404</Phone>
                <Email>wantida.chaiyana@cmu.ac.th</Email>
              </div>
            </ProfileDetailContainer>
          </ProfileContainer>
        </ProfileGroupContainer>
        <div className="mt-[88px]  bg-Lightgray50 pt-14 pb-24">
          <div className=" px-16 text-[32px] font-medium leading-10 text-Lightgray500">{t('assistant_dean')}</div>
          <ProfileGroupContainer>
            <ProfileContainer>
              <ProfileImage src={KasinPrakobwaitayakit} />
              <ProfileDetailContainer>
                <div>
                  <ProfilePosition>{t('assistant_professor')}</ProfilePosition>
                  <ProfileName>{t('KasinPrakobwaitayakit')}</ProfileName>
                  <ProfileWorkPositon>{t('AssistantDeanOfGraduateSchool')}</ProfileWorkPositon>
                  <ProfileWorkListContainer>
                    <ProfileWorkListItem>Administer</ProfileWorkListItem>
                    <ProfileWorkListItem>Digital Innovation</ProfileWorkListItem>
                  </ProfileWorkListContainer>
                  <ProfileHistoryModal
                    imgSrc={KasinPrakobwaitayakitModal}
                    position={t('AssistantDeanOfGraduateSchool')}
                    titlePositionName={t('associate_professor')}
                    name={t('KasinPrakobwaitayakit')}
                    email={'kasin.p@cmu.ac.th'}
                    educationalBackground={
                      <>
                        <li>Master degree in Physical Electronics Tokyo Institute of Technology, Japan</li>
                        <li>
                          Bachelor degree in Engineering (Electrical Engineering), Chiang Mai University, Thailand
                        </li>
                      </>
                    }
                    researchArea={
                      <>
                        <li>Electrical Engineering</li>
                        <li>Electronic Circuit Design</li>
                        <li>Computational Intelligence</li>
                        <li>Neural Network</li>
                        <li>Genetic Algorithm</li>
                        <li>Fuzzy System</li>
                        <li>Computer Network</li>
                        <li>Computer Programming</li>
                        <li>Database Systems</li>
                      </>
                    }
                  />
                </div>
                <div>
                  <Email>kasin.p@cmu.ac.th</Email>
                </div>
              </ProfileDetailContainer>
            </ProfileContainer>

            <ProfileContainer>
              <ProfileImage src={SalineeSantiteerakul} />
              <ProfileDetailContainer>
                <div>
                  <ProfilePosition>{t('assistant_professor')}</ProfilePosition>
                  <ProfileName>{t('SalineeSantiteerakul')}</ProfileName>
                  <ProfileWorkPositon>{t('AssistantDeanOfGraduateSchool')}</ProfileWorkPositon>
                  <ProfileWorkListContainer>
                    <ProfileWorkListItem>Academic</ProfileWorkListItem>
                    <ProfileWorkListItem>Interdisciplinary</ProfileWorkListItem>
                  </ProfileWorkListContainer>
                  <ProfileHistoryModal
                    imgSrc={SalineeSantiteerakulModal}
                    position={t('AssistantDeanOfGraduateSchool')}
                    titlePositionName={t('assistant_professor')}
                    name={t('SalineeSantiteerakul')}
                    email={'salinee.s@cmu.ac.th'}
                    educationalBackground={
                      <>
                        <li>
                          Postdoctoral Decision & Information Sciences for Production Systems(DISP) Laboratory,
                          Université Lumière Lyon 2, France
                        </li>
                        <li>Ph.D. Informatique. Université Lumière Lyon2, France</li>
                        <li>M.Eng Industrial Engineering. Chulalongkorn University, Thailand</li>
                        <li>B.Eng Industrial Engineering (First Class Honor). Chiang Mai University, Thailand</li>
                      </>
                    }
                    researchArea={
                      <>
                        <li>Sustainable supply chain management</li>
                        <li>Sustainability performance measurement</li>
                        <li>Supply chain and logistics management</li>
                        <li>Engineering management</li>
                        <li>Industry4.0</li>
                        <li>Future Skills in Industrial Engineering</li>
                      </>
                    }
                  />
                </div>
                <div>
                  <Email>salinee.s@cmu.ac.th</Email>
                </div>
              </ProfileDetailContainer>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileImage src={TulayaTulardilok} />
              <ProfileDetailContainer>
                <div>
                  <ProfilePosition>{t('assistant_professor')}</ProfilePosition>
                  <ProfileName>{t('TulayaTulardilok')}</ProfileName>
                  <ProfileWorkPositon>{t('AssistantDeanOfGraduateSchool')}</ProfileWorkPositon>
                  <ProfileWorkListContainer>
                    <ProfileWorkListItem>Budget Planning</ProfileWorkListItem>
                    <ProfileWorkListItem>Organization Development</ProfileWorkListItem>
                    <ProfileWorkListItem>Education Innovation</ProfileWorkListItem>
                  </ProfileWorkListContainer>
                  <ProfileHistoryModal
                    imgSrc={TulayaTulardilokModal}
                    position={t('AssistantDeanOfGraduateSchool')}
                    titlePositionName={t('associate_professor')}
                    name={t('TulayaTulardilok')}
                    email={'tulaya.t@cmu.ac.th'}
                    educationalBackground={
                      <>
                        <li>Ph.D. Accounting, Royal Holloway and Bedford New College, University of London</li>
                        <li>M.S. Administrative Studies, Boston College, Massachusetts, USA.</li>
                        <li>B.A. Accounting, Chiang Mai University, Thailand</li>
                      </>
                    }
                    researchArea={
                      <>
                        <li>Sustainability Accounting and Reporting</li>
                        <li>Managerial Accounting</li>
                        <li>Project Management and Feasibility</li>
                      </>
                    }
                  />
                </div>
                <div>
                  <Email>tulaya.t@cmu.ac.th</Email>
                </div>
              </ProfileDetailContainer>
            </ProfileContainer>

            <ProfileContainer>
              <ProfileImage src={VorathamonCherapanukorn} />
              <ProfileDetailContainer>
                <div>
                  <ProfilePosition>{t('assistant_professor')}</ProfilePosition>
                  <ProfileName>{t('VorathamonCherapanukorn')}</ProfileName>
                  <ProfileWorkPositon>{t('AssistantDeanOfGraduateSchool')}</ProfileWorkPositon>
                  <ProfileWorkListContainer>
                    <ProfileWorkListItem>Student Development Affair</ProfileWorkListItem>
                    <ProfileWorkListItem>International Relations</ProfileWorkListItem>
                  </ProfileWorkListContainer>
                  <ProfileHistoryModal
                    imgSrc={VorathamonCherapanukornModal}
                    position={t('AssistantDeanOfGraduateSchool')}
                    titlePositionName={t('associate_professor')}
                    name={t('VorathamonCherapanukorn')}
                    email={'vimolboon.c@cmu.ac.th'}
                    academicRelatedWork={
                      <>
                        <li>
                          Vice Chairman the Council of the Graduate Studies Administrators of Public and Autonomous
                          Universities (CGAU) 2021-2022
                        </li>
                        <li>
                          Vice Chairman the Council of the Graduate Studies Administrators of Thailand (CGAT) 2021-2022
                        </li>
                        <li>
                          Head of Excellence Center in Logistics and Supply Chain Management (E-LSCM), Chiang Mai
                          University
                        </li>
                      </>
                    }
                    researchArea={
                      <>
                        <li>Supply Chain and Logistics Management</li>
                        <li>Multiple Criteria Decision Making</li>
                        <li>Performance Measurement</li>
                        <li>Risk Management</li>
                        <li>Lean System and Sustainable Supply Chain</li>
                        <li>Aviation Logistics</li>
                      </>
                    }
                  />
                </div>
                <div>
                  <Email>vimolboon.c@cmu.ac.th</Email>
                </div>
              </ProfileDetailContainer>
            </ProfileContainer>
          </ProfileGroupContainer>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default ExecutivePage;
