import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SocialShareContent from '../../../../../components/SocialShareContent';
import SharePinkIcon from '../../../../../components/Icons/SharePinkIcon';
import Modal from 'react-modal';
import { useTranslationUtil } from '../../../../../hooks/useTranslationUtil';
import { afterCloseModal, afterOpenModal } from '../../../../../utils/modal';

const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 30,
    borderRadius: 8,
    border: 0,
  },
};

const SocialShareButton = ({ url }) => {
  const { t } = useTranslationUtil();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const onRequestClose = () => setIsOpenModal(false);
  return (
    <>
      <button
        className="h-10 w-[184px] rounded-sm border-[1px] border-NeonPink bg-UIWhite"
        onClick={() => setIsOpenModal(true)}
      >
        <div className="flex flex-row justify-center">
          <div className="my-auto text-lg font-bold leading-6 text-NeonPink">{t('ShareThisnews')}</div>
          <div className="my-auto ml-3">
            <SharePinkIcon />
          </div>
        </div>
      </button>
      <Modal
        isOpen={isOpenModal}
        onRequestClose={onRequestClose}
        style={customModalStyles}
        onAfterClose={afterCloseModal}
        onAfterOpen={afterOpenModal}
      >
        <SocialShareContent url={url} />
      </Modal>
    </>
  );
};

SocialShareButton.propTypes = {
  url: PropTypes.string.isRequired,
};

export default SocialShareButton;
