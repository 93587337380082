import { Route, Redirect } from 'react-router-dom';
import { useContextAuthManager } from '../index';

const PrivateRoute = ({ exact, path, ...props }) => {
  const { isLoggedIn, publicPath } = useContextAuthManager();

  if (!publicPath) {
    throw new Error('You need to provide "publicPath" prop for AuthManager');
  }
  return (
    <Route
      exact={exact}
      path={path}
      render={() => {
        if (!isLoggedIn) {
          return <Redirect to={publicPath} />;
        }
        return <Route path={path} exact={exact} {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
