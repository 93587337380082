import React from 'react';
import Layout from '../../components/Layout';

import CoverImage from './assets/banner.png';
import FoodCenterImage from './assets/food-center.png';
import ITSCCornerImage from './assets/itsc-corner.png';
import NewDormImage from './assets/new-dorm.png';

import AngKaewImage from './assets/ang-kaew.png';
import TadChompooImage from './assets/tad-chompoo.png';

import HealthCenterImage from './assets/health-center.png';
import PurpleBusImage from './assets/purple-bus.png';
// import SportImage from './assets/sport.png';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';

const LifeCMU = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout removeSpaceBeforeFooterDivider hideFooterDivider>
      <CustomContainer>
        <PageTitleContainer>{t('LifeCMU')}</PageTitleContainer>
        <div className="flex flex-col">
          <div>
            <img src={CoverImage} className=" w-full object-cover " alt="" />
          </div>
          <div className="mt-4 flex w-full flex-col xl:flex-row">
            <div className="flex xl:h-[293px] xl:w-[742px]">
              <img src={FoodCenterImage} alt={t('CMUFoodCenterDescription')} />
            </div>
            <div className="mx-auto flex w-full max-w-[390px] flex-col py-14 px-3 md:px-0 xl:mx-0 xl:pl-16">
              <div className="text-[40px] font-medium">{t('FacilitiesActivities')}</div>
              <div className="mt-8 text-2xl font-semibold">{t('CMUFoodCenter')}</div>
              <div className="mt-6 h-2 w-20 bg-CMUPurple"></div>
              <div className="mt-6">{t('CMUFoodCenterDescription')}</div>
            </div>
          </div>

          <div className="flex w-full flex-col justify-end xl:flex-row">
            <div className="mt-10 flex xl:mt-[72px] xl:hidden">
              <img src={ITSCCornerImage} alt={t('ITSCCorner')} />
            </div>
            <div className="mx-auto mt-8 flex w-full max-w-[390px] flex-col px-3 md:mt-12 xl:mx-0 xl:mt-0 xl:pr-16">
              <div className="text-2xl font-semibold">{t('ITSCCorner')}</div>
              <div className="mt-6 h-2 w-20 bg-CMUPurple"></div>
              <div className="mt-6">{t('ITSCCornerDescription')}</div>
            </div>
            <div className="mt-[72px] hidden h-[293px] xl:flex">
              <img src={ITSCCornerImage} alt={t('ITSCCorner')} />
            </div>
          </div>

          <div className="lg:md-10 mt-16 flex w-full flex-col md:mt-12 xl:mt-[-32px] xl:flex-row">
            <div className="flex lg:h-[293px]">
              <img src={NewDormImage} alt={t('NewDormitory')} />
            </div>
            <div className="mx-auto flex w-full max-w-[390px] flex-col px-3 md:px-0 xl:mx-0 xl:py-14 xl:pl-16">
              <div className="mt-8 text-2xl font-semibold">{t('NewDormitory')}</div>
              <div className="mt-6 h-2 w-20 bg-CMUPurple"></div>
              <div className="mt-6">{t('NewDormitoryDescription')}</div>
            </div>
          </div>

          <div className="mt-24 h-[154px] md:h-[293px]">
            <img src={AngKaewImage} className="h-full w-full object-cover" alt="" />
          </div>
          <div className="mt-2 h-[154px] md:h-[293px]">
            <img src={TadChompooImage} className="h-full w-full object-cover" alt="" />
          </div>
          <div className="mx-auto flex items-center justify-center px-3 pt-12 md:px-0 lg:mx-0">
            <div className="flex w-full max-w-[390px] flex-col xl:max-w-[519px]">
              <div className="text-2xl font-semibold">{t('AngKaew')} </div>
              <div className="mt-6 h-2 w-20 bg-CMUPurple"></div>
              <div className="mt-6">{t('AngKaewDescription')}</div>
            </div>
          </div>

          <div className="mt-16 flex w-full flex-col items-center justify-end bg-Lightgray50 lg:mt-32 lg:flex-row">
            <div className="lg:hidden">
              <img src={HealthCenterImage} alt={t('CMUHealthCenterDescription')} />
            </div>
            <div className="flex w-full max-w-[390px] flex-col py-12 px-3 pt-8 pr-16 md:px-0 lg:px-5 xl:mr-14 xl:px-0">
              <div className="text-2xl font-semibold">{t('CMUHealthCenter')}</div>
              <div className="mt-6 h-2 w-20 bg-CMUPurple"></div>
              <div className="mt-6">{t('CMUHealthCenterDescription')}</div>
            </div>
            <div className="hidden h-[293px] lg:flex">
              <img src={HealthCenterImage} alt="" />
            </div>
          </div>

          <div className="mt-16 flex items-center justify-center px-3 md:mt-20 md:px-0 xl:mt-0 xl:pt-[108px]">
            <div className="flex w-full max-w-[390px] flex-col xl:max-w-[519px]">
              <div className="text-2xl font-semibold">{t('ElectricBus')}</div>
              <div className="mt-6 h-2 w-20 bg-CMUPurple"></div>
              <div className="mt-6">{t('ElectricBusDescription')}</div>
            </div>
          </div>

          {/* <div className="mt-4 flex w-full flex-col ">
            <div className="flex h-[293px]">
              <img src={SportImage} alt="" />
            </div>
            <div className="flex w-full max-w-[390px] flex-col py-14 pl-16">
              <div className="mt-8 text-2xl font-semibold">{t('NewSportsCenter')}</div>
              <div className="mt-6 h-2 w-20 bg-CMUPurple"></div>
              <div className="mt-6">{t('NewSportsCenterDescription')}</div>
            </div>
          </div> */}

          <div className="mt-8 h-[154px] md:mt-10 md:h-[293px] lg:mt-20">
            <img src={PurpleBusImage} className="h-full w-full object-cover" alt={t('ElectricBusDescription')} />
          </div>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default LifeCMU;
