import React from 'react';
import CollapseSection from '../../components/CollapseSection';
import CollapseItem from '../../components/CollapseSection/CollapseItem';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';
import CollapseSectionContainer from '../../components/CollapseSection/CollapseSectionContainer';
import FormatGraddigestA5 from './assets/Format-Graddigest-A5.pdf';
import GradDigest2021 from './assets/Grad-Digest-2021.pdf';
import GradDigest2020 from './assets/Grad-digest-2020.pdf';
import GradDigest2563 from './assets/Grad-digest-6-2563.pdf';
import GradDigest2022 from './assets/Grad-Digest-2022.pdf';
import Gradigest5 from './assets/Grad-digest-5.pdf';
import GradThesisAward2022ResultedAnnouncement from './assets/20221220154659.pdf';
import GradThesisAward2022Announcement from './assets/65-9-2-Announcement-65.pdf';
import GradThesisAward2022OfferForm from './assets/FM-SD-18.docx';

const ThesisAwardInGraduateSchoolCmuPage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{t('Thesis Award In Graduate School CMU')}</PageTitleContainer>

        <CollapseSectionContainer>
          <CollapseSection title="Graddigest">
            <CollapseItem pdfUrl={FormatGraddigestA5}>{'Graddigest Format Example A5'}</CollapseItem>
            <CollapseItem pdfUrl={GradDigest2022}>{'Graddigest  2022'}</CollapseItem>
            <CollapseItem pdfUrl={GradDigest2021}>{'Graddigest  2021'}</CollapseItem>
            <CollapseItem pdfUrl={GradDigest2020}>{'Graddigest  2020'}</CollapseItem>
            <CollapseItem pdfUrl={GradDigest2563}>{'Graddigest  2019'}</CollapseItem>
            <CollapseItem pdfUrl={Gradigest5}>{'Graddigest  2018'}</CollapseItem>
          </CollapseSection>

          <CollapseSection title="Grad Thesis Award">
            <CollapseItem pdfUrl={GradThesisAward2022ResultedAnnouncement}>
              {t('Grad Thesis Award 2022 Resulted Announcement')}
            </CollapseItem>
            <CollapseItem externalLinkUrl="https://dev.grad.cmu.ac.th/thesis_award/index.php">
              {t('Grad Thesis Award 2022 Offer System')}
            </CollapseItem>
            <CollapseItem pdfUrl={GradThesisAward2022Announcement}>
              {t('Grad Thesis Award 2022 Announcement')}
            </CollapseItem>
            <CollapseItem pdfUrl={GradThesisAward2022OfferForm}>{t('Grad Thesis Award 2022 Offer Form')}</CollapseItem>
          </CollapseSection>
        </CollapseSectionContainer>
      </CustomContainer>
    </Layout>
  );
};

export default ThesisAwardInGraduateSchoolCmuPage;
