import { BrowserRouter } from 'react-router-dom';
import Router from './Router';
import AuthManager from './components/AuthManager';
import routeUrlProvider, { HOME, DASHBOARD } from './constants/route-paths';
import Modal from 'react-modal';
import CookieConsentBanner from './components/CookieConsentBanner';
import MessengerCustomerChat from 'react-messenger-customer-chat';

const App = () => {
  return (
    <BrowserRouter>
      <AuthManager publicPath={routeUrlProvider.getForLink(HOME)} privatePath={routeUrlProvider.getForLink(DASHBOARD)}>
        <Router />
      </AuthManager>
      <CookieConsentBanner />
      <MessengerCustomerChat pageId="128962267141027" appId="494423461549031" />
    </BrowserRouter>
  );
};
Modal.setAppElement('#root');
export default App;
