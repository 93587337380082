import { useLocation } from 'react-router';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import Footer from './Footer';
import { useEffect } from 'react';
import HeaderV2 from './HeaderV2';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Layout = ({
  children,
  showFooter = true,
  headerTransparent = false,
  showBackTotop = false,
  hideFooterDivider = false,
  removeSpaceBeforeFooterDivider = false,
}) => {
  const { getFontFamily } = useTranslationUtil();

  return (
    // <div className="container mx-auto">
    <div className=" mx-auto ">
      <ScrollToTop />
      <div className={`${getFontFamily()}`}>
        {/* <Header headerTransparent={headerTransparent} /> */}
        <HeaderV2 />
        {children}
        {showFooter && (
          <Footer
            showBackTotop={showBackTotop}
            hideFooterDivider={hideFooterDivider}
            removeSpaceBeforeFooterDivider={removeSpaceBeforeFooterDivider}
          />
        )}
      </div>
    </div>
  );
};

export default Layout;
