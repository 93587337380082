import React from 'react';
import CollapseSection from '../../components/CollapseSection';
import CollapseItem from '../../components/CollapseSection/CollapseItem';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import enrollmentnewpdf from './3-enrollment.newpdf.pdf';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';
import CollapseSectionContainer from '../../components/CollapseSection/CollapseSectionContainer';
import NominationforGraduation from './assets/1-Nomination-for-graduation-1.pdf';
import มตืสถาgraduate from './assets/2-มตืสถา-graduate.pdf';
import แนวปฏิบัติการจบGuideline from './assets/แนวปฏิบัติการจบGuideline.pptx';
import แบบฟอร์มนักศึกษาจบ from './assets/แบบฟอร์มนักศึกษาจบ-14-6-2562-ล่าสุด-1.doc';

const GraduationPage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{t('Graduation')}</PageTitleContainer>

        <CollapseSectionContainer>
          <CollapseSection title={t('Criteria Graduation')}>
            <CollapseItem pdfUrl={NominationforGraduation}>
              {t('Nomination for graduation a degree or diploma')}
            </CollapseItem>
            <CollapseItem pdfUrl={มตืสถาgraduate}>{t('Determining the graduation date')}</CollapseItem>
            <CollapseItem pdfUrl={แนวปฏิบัติการจบGuideline}>
              {t('Guidelines on the list of graduates for graduation')}
            </CollapseItem>
            <CollapseItem externalLinkUrl="https://smart.grad.cmu.ac.th/?p=103">
              {t('List of Graduates and the approval date')}
            </CollapseItem>
            <CollapseItem pdfUrl={แบบฟอร์มนักศึกษาจบ}>{t('Graduation Proposal Form')}</CollapseItem>
          </CollapseSection>

          {/* <CollapseSection title={t('Check List of Journals')}>
            <CollapseItem pdfUrl={enrollmentnewpdf}>{'ISI Database'}</CollapseItem>
            <CollapseItem pdfUrl={enrollmentnewpdf}>{'SCOPUS Database'}</CollapseItem>
            <CollapseItem pdfUrl={enrollmentnewpdf}>{'SJR Database'}</CollapseItem>
            <CollapseItem pdfUrl={enrollmentnewpdf}>{'TCI Database'}</CollapseItem>
            <CollapseItem pdfUrl={enrollmentnewpdf}>{'JOURNALS THAILAND'}</CollapseItem>
            <CollapseItem pdfUrl={enrollmentnewpdf}>{'LIST OF ACADEMIC JOURNALS'}</CollapseItem>
          </CollapseSection> */}
        </CollapseSectionContainer>
      </CustomContainer>
    </Layout>
  );
};

export default GraduationPage;
