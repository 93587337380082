const PhoneCallIcon = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M4.82667 10.3867C6.74667 14.16 9.84 17.2533 13.6133 19.1733L16.5467 16.24C16.92 15.8667 17.44 15.76 17.9067 15.9067C19.4 16.4 21 16.6667 22.6667 16.6667C23.0203 16.6667 23.3594 16.8071 23.6095 17.0572C23.8595 17.3072 24 17.6464 24 18V22.6667C24 23.0203 23.8595 23.3594 23.6095 23.6095C23.3594 23.8595 23.0203 24 22.6667 24C16.6551 24 10.8897 21.6119 6.63891 17.3611C2.38809 13.1103 0 7.34491 0 1.33333C0 0.979711 0.140476 0.640573 0.390524 0.390524C0.640573 0.140476 0.979711 0 1.33333 0H6C6.35362 0 6.69276 0.140476 6.94281 0.390524C7.19286 0.640573 7.33333 0.979711 7.33333 1.33333C7.33333 3 7.6 4.6 8.09333 6.09333C8.24 6.56 8.13333 7.08 7.76 7.45333L4.82667 10.3867Z"
      fill={className || '#F06292'}
    />
  </svg>
);
export default PhoneCallIcon;
