import React from 'react';
import Layout from '../../../components/Layout';
import BackToInterdisciplinaryProgramsButton from '../components/BackToInterdisciplinaryProgramsButton';
import Cover from './assets/Cover.jpg';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';
import PageTitle from '../components/PageTitle';
import Paragraph from '../components/Paragraph';
import ParagraphTitle from '../components/ParagraphTitle';
import UlList from '../components/UlList';
import CourseStructor from '../components/CourseStructor';
import ColorGradientBottomImage from '../components/ColorGradientBottomImage';
import { useLocation } from 'react-router-dom';
import BackToSearchByFacultyButton from '../components/BackToSearchByFacultyButton';
import CoverImage from '../components/CoverImage';
import ContentWrapper from '../components/ContentWrapper';
import CustomContainer from '../../../components/CustomContainer';

const FlexRowJustifyBetween = ({ children }) => (
  <div className=" flex flex-row justify-between px-2 md:px-20">{children}</div>
);

const TechnologyAndInterdisciplinaryManagementPage = () => {
  const { t, isEng } = useTranslationUtil();
  const location = useLocation();
  const { fromSearchByFaculty } = location?.state || {};
  return (
    <Layout>
      <CustomContainer>
        {fromSearchByFaculty ? <BackToSearchByFacultyButton /> : <BackToInterdisciplinaryProgramsButton />}
        <PageTitle>{t('TechnologyAndInterdisciplinaryManagement')}</PageTitle>
        <CoverImage src={Cover} alt="" />
        <ContentWrapper>{isEng() ? <ContentEng /> : <ContentTh />}</ContentWrapper>
        <ColorGradientBottomImage />
        {fromSearchByFaculty ? <BackToSearchByFacultyButton /> : <BackToInterdisciplinaryProgramsButton />}
      </CustomContainer>
    </Layout>
  );
};

const ContentEng = () => {
  return (
    <>
      <ParagraphTitle>

      <div className=" mt-4 text-NeonPink">
        <a
          className=" text-NeonPink"
          href="https://www.mids.cmu.ac.th/?p=technologymgt&&lang=en"
        >
          Click for more details
        </a>
      </div>

      </ParagraphTitle>


    </>
  );
};

const ContentTh = () => {
  return (
    <>
      <ParagraphTitle>

      <div className=" mt-4 text-NeonPink">
        <a
          className=" text-NeonPink"
          href="https://www.mids.cmu.ac.th/?p=technologymgt&&lang=th"
        >
          คลิกดูรายละเอียด
        </a>
      </div>

      </ParagraphTitle>


    </>
  );
};

export default TechnologyAndInterdisciplinaryManagementPage;
