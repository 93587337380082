import React from 'react';
import DownloadIcon from '../../Icons/DownloadIcon';
import { getFilenameFromPath } from '../../../utils/pdf';

const DownloadPDFButton = ({ pdfUrl = '', filename }) => {
  const onClick = () => {
    if (pdfUrl) {
      // using Java Script method to get PDF file
      fetch(pdfUrl).then((response) => {
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.target = '_blank';

          if (filename) {
            alink.download = filename;
          } else {
            // get filename from path
            alink.download = getFilenameFromPath(pdfUrl);
          }
          alink.click();
        });
      });
    }
  };
  return (
    <button onClick={() => onClick()} className="h-fit w-fit rounded-sm bg-NeonPink p-[12px] px-[15px]">
      <DownloadIcon />
    </button>
  );
};

export default DownloadPDFButton;
