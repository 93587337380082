import React from 'react';
import Layout from '../../components/Layout';
import CollapseItem from '../../components/CollapseSection/CollapseItem';

import CoverImage from './assets/annual_report_cover.png';
import GradualteCmuPdf from './assets/gradualte-cmu-pdf.png';
import AnnualReport2021 from './assets/annual-report-2021.png';
import AnnualReport2020 from './assets/annual-report-2020.png';
import AnnualReport2019 from './assets/annual-report-2019.png';
import AnnualReport2018 from './assets/annual-report-2018.png';
import AnnualReport2017 from './assets/annual-report-2017.png';
import AnnualReport2016 from './assets/annual-report-2016.png';
import AnnualReport2021Pdf from './assets/annual-report-2021.pdf';
import AnnualReport2020Pdf from './assets/annual-report-2020.pdf';
import AnnualReport2019Pdf from './assets/annual-report-2019.pdf';
import AnnualReport2018Pdf from './assets/annual-report-2018.pdf';
import AnnualReport2017Pdf from './assets/annual-report-2017.pdf';
import AnnualReport2016Pdf from './assets/annual-report-2016.pdf';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainerWithBackgroundImage from '../../components/PageTitleContainerWithBackgroundImage';

// TODO: change pdf 2021 , 2020

const AnnualReport = () => {
  const { t } = useTranslationUtil();

  const PDFList = [
    {
      title: t('Annual Report 2021'),
      image: AnnualReport2021,
      pdf: AnnualReport2021Pdf,
    },
    {
      title: t('Annual Report 2020'),
      image: AnnualReport2020,
      pdf: AnnualReport2020Pdf,
    },
    {
      title: t('Annual Report 2019'),
      image: AnnualReport2019,
      pdf: AnnualReport2019Pdf,
    },
    {
      title: t('Annual Report 2018'),
      image: AnnualReport2018,
      pdf: AnnualReport2018Pdf,
    },
    {
      title: t('Annual Report 2017'),
      image: AnnualReport2017,
      pdf: AnnualReport2017Pdf,
    },
    {
      title: t('Annual Report 2016'),
      image: AnnualReport2016,
      pdf: AnnualReport2016Pdf,
    },
  ];

  return (
    <Layout headerTransparent>
      <CustomContainer>
        <PageTitleContainerWithBackgroundImage coverImage={CoverImage}>
          {t('Nav_AnnualReport')}
        </PageTitleContainerWithBackgroundImage>
        <div className="grid grid-cols-1 gap-x-20 gap-y-14  bg-Lightgray100 px-20 py-[72px] md:grid-cols-2 lg:grid-cols-3">
          {PDFList.map((data, idx) => (
            <div key={idx} className="flex h-full flex-col">
              <img
                src={data.image}
                className="w-full object-cover hover:cursor-pointer hover:object-contain"
                alt=""
                onClick={() => window.open(data.pdf, '_blank', 'noopener,noreferrer')}
              />
              <CollapseItem light pdfUrl={data.pdf} fileName={`${data.title}.pdf`}>
                {data.title}
              </CollapseItem>
            </div>
          ))}
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default AnnualReport;
