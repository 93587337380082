import React from 'react';
import CollapseSection from '../../components/CollapseSection';
import CollapseItem from '../../components/CollapseSection/CollapseItem';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import DuplicateIcon from './assets/duplicate-icon.svg';
import MoneyIcon from './assets/money-icon.svg';
import EngToChIcon from './assets/eng-to-ch-icon.svg';
import CalendarIcon from './assets/calendar-month-icon.svg';
import { useHistory } from 'react-router';
import routeUrlProvider, {
  ENROLLMENT_ACADEMIC_CALENDAR,
  FOREIGN_LANGUAGE_CONDITION,
} from '../../constants/route-paths';
import FeePDF from './assets/Tuition-Fee-update-05-2022-Rev1.pdf';
import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';
import CollapseSectionContainer from '../../components/CollapseSection/CollapseSectionContainer';
import มาตรการป้องกันการทุจริต from './assets/procedure/ไทย-อังกฤษ-มาตรการป้องกันการทุจริต.pdf';
import NewThesis64 from './assets/procedure/New-Thesis-64.pdf';
import StepAndGuidelineDoing from './assets/procedure/Step-and-Guideline-in-Doing-Note.pdf';
import GradThesisFlow from './assets/procedure/Grad-Thesis-Flow-ปริญญานิพนธ์-new1.pdf';
import เกณฑ์และแนวปฏิบัติการเผยแพร่ผลงานปริญญา from './assets/procedure/เกณฑ์และแนวปฏิบัติการเผยแพร่ผลงานปริญญา-1.pdf';
import ประกาศบัณฑิตวิทยาลัยลงนาม12กค64 from './assets/procedure/ENG_-Thai-ประกาศบัณฑิตวิทยาลัย-ลงนาม12ก.ค.64-Note.pdf';
import GuidelineForThesisProposal from './assets/guidline/4-Guideline-for-thesis-proposal.pdf';
import FMASA062 from './assets/form/FM-AS-A-06-2.docx';
import GS2014F101ProposalForm from './assets/form/GS-2014-F101-Proposal-Form.doc';
import GS2014F108ChangingTitle from './assets/form/GS-2014-F108-ChangingTitle.doc';
import s13ProposalFormArt from './assets/form/s1_3-Proposal-Form-Art.pdf';
import FMASA07 from './assets/consign/FM-AS-A-07.doc';
import ThesisISPrintingFormat from './assets/handbook/10-Thesis-IS_Printing_Format-คู่มือพิมพ์.pdf';
import ThesisISPrintingFormatตัวอย่างการพิมพ์ from './assets/ex/003_Thesis-IS_Printing_Format-ตัวอย่างการพิมพ์-2.pdf';
import ThesisISPrintingEnglishnew64 from './assets/template/001-Thesis-IS-Printing-Front-Matter-English-new64-1.docx';
import ThesisISPrintingThainew64 from './assets/template/001-Thesis-IS-Printing-Front-Matter-Thai-new64.docx';
import Thesis1 from './assets/template/Thesis-1.docx';
import Thesis2 from './assets/template/Thesis-2.docx';
import GS2014F110ThesisISExam from './assets/exam/GS-2014-F110-ThesisISExam.doc';
import Locked2014F112Concealing from './assets/conceal/9-Locked-2014-F112-Concealing-ขอปกปิด.doc';
import copyrights from './assets/watermark/copyrights.jpg';
import watermark1 from './assets/watermark/watermark1.pdf';
import WaterMarkGuildPdf from './assets/คู่มือการทำลายน้ำ (Watermark Guideline).pdf';
import WaterMarkImg from './assets/watermark.jpg';

const TitleContainer = ({ children, icon, internalRoute, externalUrl }) => {
  const history = useHistory();

  const onClick = () => {
    if (internalRoute) {
      history.push(routeUrlProvider.getForRoute(internalRoute));
    } else if (externalUrl) {
      window.open(externalUrl, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className="mx-auto flex h-full w-full ">
      <div
        className="mx-auto flex flex-row duration-500 hover:scale-110 hover:cursor-pointer hover:text-NeonPink md:flex-col lg:flex-row"
        onClick={onClick}
      >
        <div className="m-auto">
          <img src={icon} alt="" />
        </div>
        <div className="my-auto ml-4 md:ml-1 md:text-center lg:ml-4 lg:text-left">{children}</div>
      </div>
    </div>
  );
};
const EnTitleText = ({ children }) => <div className="font-dosis text-2xl leading-8">{children}</div>;
const ThTitleText = ({ children }) => <div className="mt-2 font-ibm-plex-sans leading-7">{children}</div>;

const ThesisPage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{t('Thesis')}</PageTitleContainer>
        <div className="grid grid-cols-1 gap-y-4 divide-Lightgray200 bg-gradient-pink-foreign-language-page py-10 px-5 md:grid-cols-2 md:gap-y-0 md:divide-x-2">
          <TitleContainer icon={DuplicateIcon} externalUrl="http://student.grad.cmu.ac.th/">
            {t('TurnItIn')}
          </TitleContainer>
          {/* <TitleContainer icon={MoneyIcon} externalUrl={FeePDF}>
            <EnTitleText>Tution Fee</EnTitleText>
            <ThTitleText>ค่าธรรมเนียมการศึกษา</ThTitleText>
          </TitleContainer> */}
          <TitleContainer icon={EngToChIcon} internalRoute={FOREIGN_LANGUAGE_CONDITION}>
            {t('Language')}
          </TitleContainer>
          {/* <TitleContainer icon={CalendarIcon} internalRoute={ENROLLMENT_ACADEMIC_CALENDAR}>
            <EnTitleText>Academic Calendar</EnTitleText>
            <ThTitleText>ปฏิทินการศึกษา</ThTitleText>
          </TitleContainer> */}
        </div>
        <CollapseSectionContainer>
          <CollapseSection title={t('ThesisProcedures')}>
            <CollapseItem pdfUrl={มาตรการป้องกันการทุจริต}>{t('MeasuresOnPreventionOfCorruption2021')}</CollapseItem>
            <CollapseItem pdfUrl={NewThesis64}>{t('StepandGuidelinesinDoingThesis64')}</CollapseItem>
            <CollapseItem pdfUrl={StepAndGuidelineDoing}>{t('StepsandGuidelinesinDoingThesisIssueno1')}</CollapseItem>
            <CollapseItem pdfUrl={GradThesisFlow}>{t('ThesisFlowchart')}</CollapseItem>
            <CollapseItem pdfUrl={เกณฑ์และแนวปฏิบัติการเผยแพร่ผลงานปริญญา}>
              {t('CriteriaandOperatingProcedureforThesisPublication')}
            </CollapseItem>
            <CollapseItem pdfUrl={ประกาศบัณฑิตวิทยาลัยลงนาม12กค64}>
              {t('MeasuresonPreventionofCorruptioninEducationandIntellectualPropertyInfringementintheThesis')}
            </CollapseItem>
          </CollapseSection>

          {/* PROCEDURES AND GUIDELINES FOR PROPOSAL OF THE THESIS PROJECT
ขั้นตอนและแนวทางในการเสนอโครงการวิทยานิพนธ์ */}

          <CollapseSection title={t('Guidelines')}>
            <CollapseItem pdfUrl={GuidelineForThesisProposal}>{t('GuidelinesforThesisISProposal')}</CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('RequestForm')}>
            <CollapseItem pdfUrl={FMASA062}>{t('ThesisProposalForm')}</CollapseItem>
            <CollapseItem pdfUrl={GS2014F101ProposalForm}>
              {t('RequestFormforDissertationThesisISTitleandProposalApproval')}
            </CollapseItem>
            <CollapseItem pdfUrl={GS2014F108ChangingTitle}>
              {t('RequestFormforChangingTitleofDissertationThesisIS')}
            </CollapseItem>
            <CollapseItem pdfUrl={s13ProposalFormArt}>
              {t('OutlineforThesisFacultyofFineArtsinVisualArts')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('LetterofConsenttoConsignIntellectualProperties')}>
            <CollapseItem pdfUrl={FMASA07}>{t('LetterofConsenttoConsignIntellectualProperties')}</CollapseItem>
          </CollapseSection>

          {/* WRITING AND PRINTING THESIS
การเขียนและการพิมพ์วิทยานิพนธ์ */}

          <CollapseSection title={t('HandbookofWritingandPrintingofThesis')}>
            <CollapseItem pdfUrl={ThesisISPrintingFormat}>{t('ThesisISprintingformat')}</CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('Example')}>
            <CollapseItem pdfUrl={ThesisISPrintingFormatตัวอย่างการพิมพ์}>{t('ExampleThesis2015')}</CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('Template')}>
            <CollapseItem pdfUrl={ThesisISPrintingEnglishnew64}>{t('ThesisTemplateEnglish')}</CollapseItem>
            <CollapseItem pdfUrl={ThesisISPrintingThainew64}>{t('ThesisTemplateThai')}</CollapseItem>
            <CollapseItem pdfUrl={Thesis1}>{t('ExampleThesisPattern1')}</CollapseItem>
            <CollapseItem pdfUrl={Thesis2}>{t('ExampleThesisPattern2')}</CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('ThesisisExamination')}>
            <CollapseItem pdfUrl={GS2014F110ThesisISExam}>
              {t('ApplicationFormforDissertationThesisISExamination')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('ConcealingDisserationThesisIS')}>
            <CollapseItem pdfUrl={Locked2014F112Concealing}>
              {t('RequestFormforConcealingDissertationThesisiSWithoutPublication')}
            </CollapseItem>
          </CollapseSection>
          {/* 
        {t('ThesisDissertationISchecking')}
        {t(
          'StudentsubmitthecompleteThesisDissertationISfilewhichisinthecorrectformatandhastheapprovalpagecompletelysignedbythecommitteesadvisorandorthesisadvisoryboard'
        )}
        {t(
          'Thesubmittedfilemustbeapdffileandcompiledas1filewithnowatermarkfromthefrontcovertostudentsbiographyStudentmustsubmitthefiletoGraduateSchoolwithin60daysstartingfromthedaythatstudenttakestheDefenseExaminationatgradcmu'
        )}
        {t('OncethestaffhasalreadycheckedtheThesisDissertationISthefilewillbesentbacktostudenttodotheeditingasguided')}
        {t(
          'Afterbeingcompletelydonewiththeeditingstudentwillbeputtingthewatermarkonthepaperandsubmititbacktosystemasguided'
        )} */}

          <CollapseSection title={t('ThesisDocumentSubmission')}>
            <CollapseItem pdfUrl={GradThesisFlow}>{t('ThesisFlowchart')}</CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('DewateringPhDThesis')}>
            <CollapseItem pdfUrl={copyrights}>{t('Watermark')}</CollapseItem>
            <CollapseItem pdfUrl={watermark1}>{t('WatermarkGuideline')}</CollapseItem>
          </CollapseSection>
        </CollapseSectionContainer>
      </CustomContainer>
      <CustomContainer>
        <br></br>
        <br></br>
        <div className="px-3 md:px-10 lg:px-14 xl:px-20">
          <div className="mx-auto w-fit">
            <div className="text-center text-[40px] font-medium leading-[66px]">{t('AcademicSubmissions')}</div>
            <ul className="ml-5 list-inside">
              <li>
                1. {t('VisitWeb')}{' '}
                <a
                  href="http://student.grad.cmu.ac.th"
                  target="_blank"
                  rel="noopener noreferrer"
                  className=" text-NeonPink"
                >
                  http://student.grad.cmu.ac.th
                </a>
              </li>
              <li>2. {t('SubmitFiles')}</li>
            </ul>
            {/* <div className="mt-12 text-center text-[30px] font-medium leading-9 md:text-[40px] md:leading-[66px]">
              {t('DewateringThesis')}
            </div> */}
            {/* <CollapseItem jpgUrl={WaterMarkImg} fileName="ภาพสำหรับทำลายน้ำ (Watermark).jpg">
              {t('Watermark')}
            </CollapseItem>
            <CollapseItem pdfUrl={WaterMarkGuildPdf} fileName="คู่มือการทำลายน้ำ (Watermark Guideline).pdf">
              {t('WatermarkGuideline')}
            </CollapseItem> */}
          </div>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default ThesisPage;
