import React from 'react';
import PropTypes from 'prop-types';

const PageTitleContainer = ({ children, forceUseThFont = false }) => {
  return (
    <div
      className={`${
        forceUseThFont ? ' font-ibm-plex-sans ' : ''
      } bg-gradien-GradPink-to-CMUPurple pt-[16px]  pb-[32px] text-center text-[36px] font-medium leading-10 text-UIWhite md:pb-[16px] md:text-[36px] md:leading-[71px]`}
    >
      {children}
    </div>
  );
};

PageTitleContainer.propTypes = {
  children: PropTypes.any,
};

export default PageTitleContainer;
