import YoutubeIcon from '../../Icons/YoutubeIcon';

const YouTubeButton = ({ url = 'https://www.youtube.com/@gradcmu5228' }) => {
  return (
    <button onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}>
      <YoutubeIcon />
    </button>
  );
};
export default YouTubeButton;
