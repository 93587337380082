import { useState } from 'react';
import CloseButton from '../../Button/CloseButton';
import PhonecallButton from '../../Button/PhonecallButton';

const PhoneCallModal = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={className}>
      {isOpen && (
        <div className="absolute bottom-24 mr-12 h-[69px] w-[315px] rounded-[100px] bg-UIWhite bg-opacity-90 font-dosis text-Lightgray800">
          <div className=" ml-10 text-lg font-bold leading-[24px]">Call Center</div>
          <div className=" w-full text-center text-[40px] font-medium leading-[38px]">+66 5394 2400</div>
        </div>
      )}
      <div className="text-right">
        {isOpen ? (
          <CloseButton onClick={() => setIsOpen(false)} />
        ) : (
          <PhonecallButton onClick={() => setIsOpen(true)} />
        )}
      </div>
    </div>
  );
};

export default PhoneCallModal;
