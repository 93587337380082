import { useEffect, useRef, useState } from 'react';
import LeftArrowGrandPinkIcon from '../../../components/Icons/LeftArrowGrandPinkIcon';
import { getAnounceImagesSliderData } from '../../../services/anounceImagesSlider/getAnounceImagesSliderData';
import Slider from 'react-slick';
import useWindowDimensions from '../../../hooks/useWindowDimension';
import Modal from 'react-modal';
import MoreDetailButton from '../../../components/Button/MoreDetailButton';
import { useTranslationUtil } from '../../../hooks/useTranslationUtil';
import { afterCloseModal, afterOpenModal } from '../../../utils/modal';
import CustomContainer from '../../../components/CustomContainer';

const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    position: 'fixed',
    'z-index': '30',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 8,
    border: 0,
    outline: 'none',
  },
};

const ImageItem = ({ src = '', imageUrl = '', isCenter = false }) => {
  const [isShowModal, setIsShowModal] = useState(false);

  const onClick = () => {
    setIsShowModal(true);
  };

  return (
    <>
      <div className="m-auto h-[520px]">
        {isCenter ? (
          <div className="my-auto h-fit">
            <img
              src={src || imageUrl}
              alt=""
              className="m-auto h-[520px] w-[367px] object-contain hover:cursor-pointer"
              onClick={onClick}
            />
          </div>
        ) : (
          <div className="mt-[120px] ">
            <img
              src={src || imageUrl}
              alt=""
              className="m-auto h-[289px] w-[205px] object-contain hover:cursor-pointer"
              onClick={onClick}
            />
          </div>
        )}
      </div>
      <Modal
        isOpen={isShowModal}
        onRequestClose={() => setIsShowModal(false)}
        style={customModalStyles}
        onAfterClose={afterCloseModal}
        onAfterOpen={afterOpenModal}
        className="relative z-30 md:h-fit md:w-fit "
      >
        <img src={src || imageUrl} alt="" className="mx-auto w-[60%] object-contain md:h-full " />
      </Modal>
    </>
  );
};

const AnnounceImageSliderSection = () => {
  const { t, getDisplayObject, isThai, isEng, nowLanguageCode } = useTranslationUtil();
  const { windowDimensions } = useWindowDimensions();
  const sliderRef = useRef(null);

  const [listDataTh, setListDataTh] = useState([]);
  const [listDataEn, setListDataEn] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const listData = getDisplayObject(listDataTh, listDataEn);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: listData.length < 3 ? listData.length : 3,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    centerMode: true,
    slidesPerRow: 1,
    pauseOnHover: true,
  };

  if (windowDimensions.width <= 1024) {
    settings.slidesToShow = 1;
  }

  useEffect(() => {
    if (isThai() && listDataTh.length === 0) {
      getAnounceImagesSliderData('TH')
        .then((res) => {
          setListDataTh(res.data);
        })
        .catch((err) => console.log('err ', err));
    } else if (isEng() && listDataEn.length === 0) {
      getAnounceImagesSliderData('EN')
        .then((res) => {
          setListDataEn(res.data);
        })
        .catch((err) => console.log('err ', err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nowLanguageCode]);

  const slideNext = () => {
    sliderRef?.current?.slickNext();
    setCurrentIndex((currentIndex + 1) % listData.length);
  };

  const slidePrev = () => {
    sliderRef?.current?.slickPrev();
    setCurrentIndex(currentIndex === 0 ? listData.length - 1 : (currentIndex - 1) % listData.length);
  };

  if (listData.length === 0) {
    return;
  }

  return (
    <CustomContainer className="z-0 md:px-20 md:pt-6 lg:pt-12">
      <div className="flex justify-between xs:flex-col md:flex-row ">
        <div className="w-full pb-10 text-[48px] font-medium leading-[61px] text-UIBlack xs:mx-auto xs:text-center md:m-0 md:text-center lg:text-center">
          {t('Poster')}
        </div>
      </div>
      <div className="flex flex-row justify-center md:justify-between">
        <button onClick={slidePrev} className="hidden md:block">
          <LeftArrowGrandPinkIcon />
        </button>
        <div className="my-auto h-[519px] w-[90%] overflow-hidden">
          <Slider {...settings} ref={sliderRef} afterChange={(id) => setCurrentIndex(id)}>
            {listData.map((data, id) => (
              <ImageItem key={id} src={data.imageUrl} imageUrl={data.imageUrl} alt="" isCenter={currentIndex === id} />
            ))}
          </Slider>
        </div>
        <button onClick={slideNext} className="hidden rotate-180 md:block">
          <LeftArrowGrandPinkIcon />
        </button>
      </div>
      <div className=" mt-7 flex w-full flex-row flex-wrap justify-center xs:hidden md:flex">
        <div className="mx-auto flex flex-row">
          {listDataTh.map((_, id) => (
            <div
              key={id}
              onClick={() => {
                setCurrentIndex(id);
                sliderRef.current.slickGoTo(id);
              }}
              className={`mx-1 h-2  bg-CMUPurple hover:cursor-pointer ${
                currentIndex === id ? ' w-20' : 'w-8 opacity-40'
              }`}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-row justify-between md:hidden">
        <button onClick={slidePrev} className="p-4">
          <LeftArrowGrandPinkIcon />
        </button>
        <div className="my-auto  flex w-full flex-row justify-center ">
          <div className=" mx-auto flex flex-row">
            {listDataTh.map((_, id) => (
              <div
                key={id}
                onClick={() => {
                  setCurrentIndex(id);
                  sliderRef.current.slickGoTo(id);
                }}
                className={`mx-1 h-2  bg-CMUPurple hover:cursor-pointer ${
                  currentIndex === id ? ' w-4' : 'w-2 opacity-40'
                }`}
              />
            ))}
          </div>
        </div>
        <button className=" rotate-180 p-4" onClick={slideNext}>
          <LeftArrowGrandPinkIcon />
        </button>
      </div>
      <div className="relative my-10 mx-auto w-fit lg:absolute lg:right-0 lg:-mt-7 lg:mr-20">
        <MoreDetailButton
          text={t('More Posters')}
          onClick={() => window.open('/posters', '_blank', 'noopener,noreferrer')}
        />
      </div>
    </CustomContainer>
  );
};

// TODO: check react slick height issue https://github.com/akiran/react-slick/issues/732
export default AnnounceImageSliderSection;
