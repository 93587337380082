import React from 'react';

const RightArrowWithoutTail = ({ className }) => {
  const fillClass = className ? className : '#E494D3';
  return (
    <svg
      width="18"
      height="28"
      viewBox="0 0 18 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={fillClass}
    >
      <path
        d="M0.66703 3.29L3.95703 0L17.957 14L3.95703 28L0.66703 24.71L11.3537 14L0.66703 3.29Z"
        fill={fillClass}
      />
    </svg>
  );
};

export default RightArrowWithoutTail;
