import React from 'react';
import ColorGradientImg from './assetes/ColorGradient.png';

const ColorGradientBottomImage = () => {
  return (
    <div className=" -z-10 -mt-[450px] flex h-[600px] w-full flex-row justify-end overflow-hidden lg:-mt-80">
      <img src={ColorGradientImg} alt="" className=" -z-10 h-[917px] w-[917px]" />
    </div>
  );
};

export default ColorGradientBottomImage;
