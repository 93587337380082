import { fetchDelete, fetchGet } from '../../utils/services/fetch';

import apiUrlProvider, { ADMIN_GET_BANNER_LIST, ADMIN_GET_BANNER_GET_BY_ID } from '../../constants/api-endpoints';

export const getListBannerAdmin = ({ newsTypeQuery, page = 1, perPage = 10 }) => {
  const url = apiUrlProvider.get(ADMIN_GET_BANNER_LIST);
  const urlWithQuery = `${url}?page=${page}&perPage=${perPage}${newsTypeQuery ? `&type=${newsTypeQuery}` : ''}`;
  return fetchGet(urlWithQuery, null, true);
};

export const getBannerByIdAdmin = (id) => {
  const url = apiUrlProvider.get(ADMIN_GET_BANNER_GET_BY_ID, { id });
  return fetchGet(url, null, true);
};