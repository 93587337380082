import React from 'react';
import CollapseSection from '../../components/CollapseSection';
import CollapseItem from '../../components/CollapseSection/CollapseItem';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import CustomContainer from '../../components/CustomContainer';
import ChiangMaiUniversityRegulationonGraduateEducation2566 from './assets/reg/Chiang Mai University Regulations (2566)-11-09-2566.pdf';
import ChiangMaiUniversityRegulationonGraduateEducation2561 from './assets/reg/2-cmu_rule2-61-Eng.pdf';
import ChiangMaiUniversityRegulationonGraduateEducation2559 from './assets/reg/1-CMU-GEReg-59-Eng.pdf';
import ForeignLanguageConditionsforGraduateStudents64 from './assets/foreign/ขึ้นเว็บ-ต.ค.65.pdf';
import ForeignLanguageConditionsforGraduateStudents63 from './assets/foreign/Foreign-63-Note.pdf';
import ForeignLanguageConditionsforGraduateStudents5962 from './assets/foreign/3-GELangCondition59-trans.pdf';

import StepsandGuidelinesinDoingThesisIssueno1 from './assets/thesis/Step-and-Guideline-in-Doing-Note.pdf';
import StepsandGuidelinesinDoingThesis64 from './assets/thesis/New-Thesis-64.pdf';
import CriteriaandOperatingProcedureforThesisPublication from './assets/thesis/Criteria-and-Operating-Note-65.pdf';
import MeasuresonPreventionofCorruptioninEducationandIntellectualPropertyInfringementintheThesis from './assets/thesis/ENG_-Thai-ประกาศบัณฑิตวิทยาลัย-ลงนาม12ก.ค.64-Note.pdf';

import ProceduresforChangingDegreeProgramsTransferringStudentsandTransferringCreditsforGraduateStudy from './assets/changing/5-TransferringStudents-12-55-ChangePlan.pdf';
import ProceduresforResignStudentStatus from './assets/resign/Resignation-ลาอก-10-60-Note.pdf';

import ProcedureforExtensionofStudy from './assets/extension/ประกาศ-1663-อนุโลมขยายเวลา.pdf';
import PageTitleContainer from '../../components/PageTitleContainer';
import CollapseSectionContainer from '../../components/CollapseSection/CollapseSectionContainer';

const RegulationsAnnouncementGuidelinesPage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{t('RegulationsAnnouncementGuidelines')}</PageTitleContainer>

        <CollapseSectionContainer>
          <CollapseSection title={t('Regulations/Announcement/Guidelines')}>
            <CollapseItem pdfUrl={ChiangMaiUniversityRegulationonGraduateEducation2566}>
              {t('ChiangMaiUniversityRegulationonGraduateEducation2566')}
            </CollapseItem>
            <CollapseItem pdfUrl={ChiangMaiUniversityRegulationonGraduateEducation2561}>
              {t('ChiangMaiUniversityRegulationonGraduateEducation2561')}
            </CollapseItem>
            <CollapseItem pdfUrl={ChiangMaiUniversityRegulationonGraduateEducation2559}>
              {t('ChiangMaiUniversityRegulationonGraduateEducation2559')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('ForeignLanguageCondition')}>
            <CollapseItem pdfUrl={ForeignLanguageConditionsforGraduateStudents64}>
              {t('ForeignLanguageConditionsforGraduateStudents64')}
            </CollapseItem>
            <CollapseItem pdfUrl={ForeignLanguageConditionsforGraduateStudents63}>
              {t('ForeignLanguageConditionsforGraduateStudents63')}
            </CollapseItem>
            <CollapseItem pdfUrl={ForeignLanguageConditionsforGraduateStudents5962}>
              {t('ForeignLanguageConditionsforGraduateStudents5962')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('Thesis')}>
            <CollapseItem pdfUrl={StepsandGuidelinesinDoingThesisIssueno1}>
              {t('StepsandGuidelinesinDoingThesisIssueno1')}
            </CollapseItem>
            <CollapseItem pdfUrl={StepsandGuidelinesinDoingThesis64}>
              {t('StepsandGuidelinesinDoingThesis64')}
            </CollapseItem>
            <CollapseItem pdfUrl={CriteriaandOperatingProcedureforThesisPublication}>
              {t('CriteriaandOperatingProcedureforThesisPublication')}
            </CollapseItem>
            <CollapseItem
              pdfUrl={MeasuresonPreventionofCorruptioninEducationandIntellectualPropertyInfringementintheThesis}
            >
              {t('MeasuresonPreventionofCorruptioninEducationandIntellectualPropertyInfringementintheThesis')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('ProceduresforChangingDegreePrograms')}>
            <CollapseItem
              pdfUrl={ProceduresforChangingDegreeProgramsTransferringStudentsandTransferringCreditsforGraduateStudy}
            >
              {t('ProceduresforChangingDegreeProgramsTransferringStudentsandTransferringCreditsforGraduateStudy')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('ProceduresforResignStudentStatus')}>
            <CollapseItem pdfUrl={ProceduresforResignStudentStatus}>
              {t('ProceduresforResignGraduateSchoolStudentStatus')}
            </CollapseItem>
          </CollapseSection>

          <CollapseSection title={t('ExtensionofStudy')}>
            <CollapseItem pdfUrl={ProcedureforExtensionofStudy}>{t('ProcedureforExtensionofStudy')}</CollapseItem>
          </CollapseSection>
        </CollapseSectionContainer>
      </CustomContainer>
    </Layout>
  );
};

export default RegulationsAnnouncementGuidelinesPage;
