import React from 'react';
import Layout from '../../components/Layout';
import routeUrlProvider, { HOME } from '../../constants/route-paths';

const Info = ({ history }) => {
  return (
    <Layout>
      <div className="text-center "> Info </div>
      <div className=" w-full text-center">
        <button onClick={() => history.push(routeUrlProvider.getForLink(HOME))}>
          <div className=" w-[200px] h-[40px] leading-[40px] bg-GradPink ">
            Go to home
          </div>
        </button>
      </div>
    </Layout>
  );
};

export default Info;
