export const downloadPdf = (fileUrl) => {
  // using Java Script method to get PDF file
  fetch(fileUrl).then((response) => {
    response.blob().then((blob) => {
      // Creating new object of PDF file
      const localFileURL = window.URL.createObjectURL(blob);
      // Setting various property values
      let alink = document.createElement('a');
      alink.href = localFileURL;
      alink.click();
    });
  });
};

export const getLocalDownloadedPdfFromUrl = async (fileUrl) => {
  return await fetch(fileUrl).then((response) => {
    response
      .blob()
      .then((blob) => {
        // Creating new object of PDF file
        const localFileURL = window.URL.createObjectURL(blob);
        return localFileURL;
      })
      .catch((error) => {
        console.log(' Cannot get pdf : ', error);
      });
  });
};

export const getFilenameFromPath = (pdfPath) => {
  return pdfPath?.split('/').pop() || 'download.pdf';
};
