import React from 'react';

const StepDetailContainer = ({ children, className = '' }) => {
  return (
    <div className={`mt-5 flex flex-col px-3 pb-10 md:mt-[55px] md:flex-row md:px-10 lg:-ml-5 lg:px-0 ${className}`}>
      {children}
    </div>
  );
};

export default StepDetailContainer;
